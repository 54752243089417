import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { updateFinancePeerInclusionAction } from 'actions/company_metas/update-finance-peer-inclusion-action';
import { handleErrorAction } from 'actions/users/handle-error-action';
import BarChartComponent from 'components/elements/bar/BarChartComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';
import { peerColumnsFromMergedRows } from 'utils/companyList-utils';
import { formatRevenueForPeers, formatRevenueForPeersPerPerson } from 'utils/finance-utils';
import { determineRenderCell } from 'utils/table-utils';

const columnSettings = Object.freeze({
  company_name: { width: 240 },
  description: { flex: 1 },
  rev_per_head: { width: 130 },
  num_employees: { width: 130 }
});

const sxExtra = {
  '.MuiDataGrid-cell': {
    border: '0.5px solid',
    borderColor: 'greyColors.grey100'
  },
  '.MuiDataGrid-columnsPanelRow': {
    border: '0.5px solid',
    borderColor: 'greyColors.grey100'
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: 'background.bg100'
  },
  '.MuiDataGrid-pinnedColumnHeaders': {
    border: 'none',
    backgroundColor: 'background.bg100',
    boxShadow: 'none'
  },
  '.MuiDataGrid-columnHeader': {
    border: '1px solid',
    borderColor: 'greyColors.grey100'
  },
  '.table-section': {
    border: '1px solid white',
    backgroundColor: 'background.bg50'
  },
  '.MuiDataGrid-columnHeader--filledGroup': {
    '.MuiDataGrid-columnHeaderTitleContainer': {
      borderBottom: 'none'
    }
  },
  '.running': { padding: 0, borderColor: 'white', borderLeft: 'none', borderRight: 'none' }
};

function PeerFinanceCard({ open, handleClose, data }) {
  const { reference_companies: referenceCompanies, company, companyMetaId, listId } = data || {};
  const [checked, setChecked] = React.useState([]);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (referenceCompanies && referenceCompanies.length > 0) {
      const initial = referenceCompanies
        .filter((item) => item.included)
        .map((_item, index) => index);
      setChecked(initial.length > 0 ? initial : [0]);
    }
  }, [referenceCompanies]);

  const setCheckedByIndex = (index, value) => {
    // Do not allow all to be unchecked
    if (checked.length === 1 && checked.includes(index)) {
      return;
    }
    if (checked.includes(index)) {
      // Edge
      if (value) {
        return;
      }
      // Remove
      setChecked(checked.filter((item) => item !== index));
      return;
    }
    // Edge
    if (!value) {
      return;
    }
    // Add
    setChecked([...checked, index]);
  };

  const handleUpdate = () => {
    handleClose();
    reduxDispatch(updateFinancePeerInclusionAction(companyMetaId, listId, checked));
  };

  const columns = peerColumnsFromMergedRows(
    referenceCompanies,
    columnSettings,
    checked,
    setCheckedByIndex
  );
  const dataGridColumns = useMemo(() => {
    const companyColumnName = 'Company';
    return columns.map((col) => ({
      field: col.id,
      headerName: col.id === 'company_name' ? companyColumnName : col.name,
      url: col?.url,
      icon: col?.icon,
      type: col?.data_type,
      width: columnSettings?.[col.id]?.width,
      flex: columnSettings?.[col.id]?.flex,
      description: col?.description,
      disableColumnMenu: !col.allowEditHeader,
      disableReorder: true,
      hideSortIcons:
        col.data_type === 'url_icon' ||
        col.data_type === 'url' ||
        col.data_type === 'multiple_url_icons' ||
        col.disableSort,
      sortable:
        col.data_type !== 'url_icon' ||
        col.data_type === 'url' ||
        col.data_type === 'multiple_url_icons',
      headerAlign: 'left',
      renderCell: determineRenderCell(col.data_type),
      valueFormatter: col.valueFormatter,
      valueOptions: col.valueOptions,
      filterable: !col.disableFilter,
      disableExport: col?.disableExport,
      extraParams: col.extraParams
    }));
  }, [columns]);
  const dataGridRows = useMemo(() => {
    const localDataGridRows = [];
    const numRows = columns[0].values.length;
    const startIndex = 0;
    for (let index = 0; index < numRows; index++) {
      const row = columns.reduce((obj, col) => ({ ...obj, [col.id]: col.values[index] }), {
        id: index + startIndex
      });
      localDataGridRows.push(row);
    }
    return localDataGridRows;
  }, [columns]);

  const avgRevenuePerHead = referenceCompanies
    ?.filter((item) => checked.includes(item?.id))
    ?.map((item) => item.revenue)
    .reduce((acc, item, _index, arr) => acc + item / arr.length, 0);
  const newEstimate = avgRevenuePerHead * (company?.num_employees || 0);
  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      title="Peer Finance"
      maxWidth={1078}
      bottomBar={
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            paddingX: '16px'
          }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleUpdate}
            disabled={
              JSON.stringify(
                referenceCompanies
                  .filter((item) => item.included)
                  .map((_item, index) => index)
                  .sort()
              ) === JSON.stringify(checked.sort())
            }>
            Update
          </Button>
        </Stack>
      }
      height="95%">
      <Stack sx={{ padding: '16px' }} justifyContent="center" gap="16px">
        <Stack gap="16px" sx={{ paddingY: '8px' }}>
          <Stack direction="row" alignItems="center" gap="8px">
            {company?.logoUrl && (
              <Avatar src={company.logoUrl} sx={{ width: 24, height: 24 }} variant="square" />
            )}
            <Typography variant="paragraphMedium" color="primary.primary100" noWrap>
              {company.name}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="paragraphSemiBold" color="indicator.info">
              ${formatRevenueForPeers(newEstimate)}M
            </Typography>
            <Typography variant="paragraphSemiBold" color="indicator.info">
              Estimated Revenue
            </Typography>
          </Stack>
          <Typography variant="paragraph">{company.description}</Typography>
        </Stack>
        {referenceCompanies && (
          <Stack direction="row" gap="16px" alignItems="center" sx={{ height: '300px' }}>
            <Stack
              justifyContent="flex-start"
              sx={{
                width: '300px',
                height: '100%',
                borderRight: '1px solid',
                borderColor: 'background.bg75'
              }}>
              <Typography variant="paragraphSemiBold" color="greyColors.grey300">
                Avg. revenue per head
              </Typography>
              <Typography variant="largeNumber" color="indicator.info">
                ${formatRevenueForPeersPerPerson(avgRevenuePerHead)}
                <Typography variant="paragraph" color="greyColors.grey300">
                  K
                </Typography>
              </Typography>
              <Typography
                variant="paragraphSemiBold"
                color="greyColors.grey300"
                sx={{ paddingTop: '32px' }}>
                Est. total for {company.name}
              </Typography>
              <Typography variant="largeNumber" color="indicator.info">
                ${formatRevenueForPeers(newEstimate)}
                <Typography variant="paragraph" color="greyColors.grey300">
                  M
                </Typography>
              </Typography>
            </Stack>
            <Stack sx={{ width: '100%', height: '100%' }}>
              <Typography
                variant="paragraphSemiBold"
                color="greyColors.grey300"
                sx={{ paddingLeft: '50px' }}>
                Revenue per head of reference companies ($K)
              </Typography>
              <BarChartComponent
                logoLabels={referenceCompanies?.map((item) => ({
                  name: item.id,
                  logo_url: item.logo_url,
                  justLogo: true
                }))}
                labels={referenceCompanies?.map((item) => item.id)}
                values={referenceCompanies?.map((item) => item.revenue / 1000)}
                colors={referenceCompanies?.map((item) => {
                  if (checked.includes(item?.id)) {
                    return appTheme.palette.graphColors.graph2;
                  }
                  return appTheme.palette.background.bg150;
                })}
                noXLabel
                onClicks={referenceCompanies?.map((item) => () => {
                  setCheckedByIndex(item?.id, !checked.includes(item?.id));
                })}
              />
            </Stack>
          </Stack>
        )}
        <Box sx={{ height: 180 * dataGridRows.length + 57 }}>
          {referenceCompanies && (
            <DataGridPro
              onError={(errorData) =>
                dispatch(handleErrorAction(errorData.error, errorData.errorInfo))
              }
              rows={dataGridRows}
              columns={dataGridColumns}
              disableSelectionOnClick={true}
              hideFooter={true}
              rowHeight={180}
              pagination={false}
              sx={sxExtra}
            />
          )}
        </Box>
      </Stack>
    </ModalComponent>
  );
}

PeerFinanceCard.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object
};

export default PeerFinanceCard;
