const CONFIG = Object.freeze({
  MiniCompetitorsLandscapeElement: {
    logoSize: 15,
    warpThreshold: 0.25
  },
  CompetitorsLandscapeElement: {
    logoSize: 10,
    warpThreshold: 0.2
  }
});

const DEFAULT_CONFIG = {
  logoSize: 20,
  warpThreshold: 0.2
};

export function getConfigByElementId(elementId) {
  return elementId in CONFIG ? CONFIG[elementId] : DEFAULT_CONFIG;
}

/* eslint-disable id-length */
function sortByX(xValues, yValues) {
  const points = xValues.map((xVal, index) => ({
    x: xVal,
    y: yValues[index],
    originalIndex: index
  }));
  points.sort((point1, point2) => {
    const key1 = point1.x;
    const key2 = point2.x;
    if (key1 < key2) return -1;
    if (key2 < key1) return 1;
    return 0;
  });
  return points;
}

function deSort(points) {
  points.sort((point1, point2) => {
    const key1 = point1.originalIndex;
    const key2 = point2.originalIndex;
    if (key1 < key2) return -1;
    if (key2 < key1) return 1;
    return 0;
  });
}

function translateReleventPoints(points, index1, distance, direction = 'x') {
  for (let index2 = index1; index2 < points.length; index2++) {
    points[index2][direction] += distance;
  }
}

/* eslint-disable no-mixed-operators */
export default function warpPoints(xValues, yValues, threshold = 0.1) {
  // Get initial ranges of X and Y
  const DX = Math.max(...xValues) - Math.min(...xValues);
  const DY = Math.max(...yValues) - Math.min(...yValues);

  // Make sorted copy
  const points = sortByX(xValues, yValues);

  // Loop through X
  for (let index1 = 1; index1 < points.length; index1++) {
    for (let index2 = index1; index2 < points.length; index2++) {
      // Check if X threshold violated
      const dx = points[index2].x - points[index1].x;
      if (dx / DX < threshold) {
        // Check if y threshold violated
        if ((points[index2].x - points[index1].x) / DY < threshold) {
          // Move all relevant points accordingly
          const distance = DX * threshold - dx;
          translateReleventPoints(points, index2, distance);
        }
      } else {
        // If NOT violated, move onto next point
        break;
      }
    }
  }

  deSort(points);

  const xWarped = points.map((point) => point.x);
  const yWarped = points.map((point) => point.y);

  return { xWarped, yWarped };
}
