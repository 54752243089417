import { TILE_TYPE } from 'constants/tiles';
import { moveFolderResource } from 'reducer/folders-slice';

export function moveFolderResourceAction(id, type, currentFolderId, newFolderId, onComplete) {
  return (dispatch, _getState, apiClient) => {
    if (currentFolderId === newFolderId || (!currentFolderId && !newFolderId)) {
      onComplete?.(false);
      return;
    }
    const payload = {
      destination_folder_id: newFolderId
    };
    const intId = parseInt(id, 10);
    if (type === TILE_TYPE.DISCOVERY) {
      payload.company_list_id = intId;
    } else {
      payload.record_id = intId;
    }
    dispatch(moveFolderResource({ id: intId, type, currentFolderId, newFolderId }));
    apiClient.folderResources
      .moveFolderResource(payload)
      .then((res) => {
        if (!res.ok) {
          dispatch(
            moveFolderResource({ id: intId, type, currentFolderId, newFolderId, undo: true })
          );
        }
        onComplete?.(res.ok);
      })
      .catch(() => {
        dispatch(moveFolderResource({ id: intId, type, currentFolderId, newFolderId, undo: true }));
        onComplete?.(false);
      });
  };
}
