import { TOAST_TYPES } from 'constants/toasts';
import { updateTeam } from 'reducer/teams-slice';

export function updateTeamAction(teamId, name, user_ids, addToast) {
  return (dispatch, _getState, apiClient) => {
    apiClient.teams
      .updateTeam(teamId, { name, user_ids })
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updateTeam({ team: res.data }));
          addToast('Team updated', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to update team', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to update team', TOAST_TYPES.ERROR);
      });
  };
}
