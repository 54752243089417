import BaseClient from './BaseClient';

const URL_PREFIX = 'persons';

export default class PersonsClient extends BaseClient {
  fetchAllPersons({ num = 50, all = false }) {
    return this.get(`${URL_PREFIX}?N=${num}&all=${all ? '1' : '0'}`);
  }

  submitPerson(payload) {
    return this.post(URL_PREFIX, payload);
  }

  deletePerson(personId) {
    return this.httpDelete(`${URL_PREFIX}/${personId}`);
  }

  fetchPersonData(personId) {
    return this.get(`${URL_PREFIX}/${personId}`);
  }

  fetchPersonCard(personId) {
    return this.get(`${URL_PREFIX}/${personId}/card`);
  }

  fetchPersonProgress(personId) {
    return this.get(`${URL_PREFIX}/${personId}/progress`);
  }

  submitAutocomplete(searchName) {
    return this.post(`${URL_PREFIX}/autocomplete`, { searchName });
  }
}
