import { Typography } from '@mui/material';
import { loadExplore } from 'actions/explore/load-explore-action';
import { updateRunProgress } from 'actions/runs/monitor-research-progress-action';
import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { dispatch } from 'hooks/AppStateProvider';
import { usePusher } from 'hooks/PusherProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiscoveryProgress } from 'reducer/explore-slice';
import { getCompanyListIdByDiscoveryId } from 'selectors/discoveries';
import { getRunProgressChannelName } from 'utils/pusher-utils';

const processProgressCompany = (id, progress) => {
  dispatch(updateRunProgress(id, progress));
  if (progress >= 100) {
    dispatch(updateRecentlyFinishedRun(id, 'runs'));
  }
};

const processProgressDiscovery = (id, progress, reduxDisaptch, listId) => {
  if (progress >= 100) {
    setTimeout(() => {
      reduxDisaptch(updateDiscoveryProgress({ searchId: id, progress }));
      reduxDisaptch(loadExplore(listId));
      dispatch(updateRecentlyFinishedRun(id, 'discoveries'));
    }, 5000);
  } else {
    reduxDisaptch(updateDiscoveryProgress({ searchId: id, progress }));
  }
};

function PusherRunProgressListener({ id, recordId, recordType }) {
  const [message, setMessage] = useState(null);
  const pusher = usePusher();
  const reduxDisaptch = useDispatch();
  const companyListId = useSelector((reduxState) => {
    if (recordType !== 'discovery') return null;
    return getCompanyListIdByDiscoveryId(reduxState, id);
  });

  useEffect(() => {
    if (!pusher) {
      return;
    }
    const channelName = getRunProgressChannelName(recordId);
    const channel = pusher.subscribe(channelName);
    channel.bind('progress', (data) => {
      // Method to be dispatched on trigger.
      if (data.message) {
        setMessage(data.message);
      }
      if (id) {
        const progress = 100 * data.progress;
        switch (recordType) {
          case 'company':
            processProgressCompany(id, progress);
            break;
          case 'discovery':
            processProgressDiscovery(id, progress, reduxDisaptch, companyListId);
            break;
          default:
            break;
        }
      }
    });
    // return will be called upon Component Unmount
    return () => {
      pusher.unsubscribe(channelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return message ? <Typography variant="paragraph">{message}</Typography> : null;
}

PusherRunProgressListener.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordType: PropTypes.oneOf(['company', 'discovery'])
};

export default PusherRunProgressListener;
