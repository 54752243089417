import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { React, useState } from 'react';

import AdminTabOrgLogo from './AdminTabOrgLogo';
import { CreateNewOrganizationDialog } from './CreateNewOrganizationDialog';

function OrganizationsTab({ organizations, hidden }) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="row" gap="16px" paddingBottom="8px">
        <Button onClick={() => setOpenDialog(true)} variant="outlined">
          Create new organization
        </Button>
        {openDialog && (
          <CreateNewOrganizationDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}></CreateNewOrganizationDialog>
        )}
      </Stack>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {Object.keys(organizations)
          .sort((key1, key2) => organizations[key1].id - organizations[key2].id)
          .map((key) => {
            const organization = organizations[key];
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button size="small" variant="outlined" disabled sx={{ width: '60px' }}>
                  {organization?.id ? organization.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={organization?.logoUrl} />
                <Typography variant="paragraph">{organization.name}</Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default OrganizationsTab;

OrganizationsTab.propTypes = {
  organizations: PropTypes.object,
  hidden: PropTypes.bool
};
