/* eslint-disable max-lines */
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { updateFinancePeerAction } from 'actions/company_metas/update-finance-peer-action';
import { makeNewSearchRunAction } from 'actions/runs/make-new-search-run-action';
import IconButtonComponent from 'components/IconButtonComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { dispatch } from 'hooks/AppStateProvider';
import CrunchbaseIcon from 'icons/CrunchbaseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import GoToIcon from 'icons/GoToIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import MenuIcon from 'icons/MenuIcon';
import RerunIcon from 'icons/RerunIcon';
import RunningResearchIcon from 'icons/RunningResearchIcon';
import SourceIcon from 'icons/SourceIcon';
import WarningIcon from 'icons/WarningIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateMismatchCompaniesInList } from 'reducer/explore-slice';
import { formatTimestampRelative } from 'utils/time-utils';

import RenderCompanySource from './RenderCompanySource';
import { RenderEditName } from './RenderEditName';
import RenderNotFoundCompanyName from './RenderNotFoundCompanyNameConnector';

// eslint-disable-next-line max-lines-per-function
function RenderBulkCompanyName({
  value,
  color,
  variant,
  id,
  showCheckBox,
  isChecked,
  onCheck,
  canPeerFinance
}) {
  const reduxDispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { recordId, recordReady: isReady, recordTimeSubmitted: timestamp, hideMenu } = value;
  const hideRecordReady = value.hideRecordReady || false;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const runInsightResearch = () => {
    setAnchorEl(null);
    if (!value.bulkId && value.suggestion) {
      dispatch(makeNewSearchRunAction(value.suggestion));
    }
  };

  const runFinancePeer = () => {
    setAnchorEl(null);
    reduxDispatch(updateFinancePeerAction(value.companyMetaId, value.companyListId));
  };

  const showInsight = () => {
    setAnchorEl(null);
    if (!isReady) {
      navigate(COMPANY_INSIGHTS_PREFIX);
      return;
    }
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${recordId}/${OVERVIEW_TAB.path}`);
  };

  const deleteRow = () => {
    setAnchorEl(null);
    value.onDelete?.(value.suggestion.companyListMetaId);
  };

  const helper = (event) => {
    const targetElement = event.target;
    if (!showTooltip && targetElement.scrollWidth > targetElement.clientWidth) {
      setShowTooltip(true);
    } else if (showTooltip) {
      setShowTooltip(false);
    }
  };

  const openMismatchDialog = () => {
    const { companyListId } = value;
    if (companyListId) {
      reduxDispatch(updateMismatchCompaniesInList({ listId: companyListId }));
    }
  };

  const checkBoxStyle = showCheckBox ? { visibility: 'visible' } : null;

  const loadingName = value?.loadingName;
  if (loadingName) {
    return (
      <Typography
        variant={variant}
        color={color}
        textAlign="left"
        paddingLeft={'6px'}
        noWrap
        display="block">
        {loadingName}
      </Typography>
    );
  }

  if (value?.running) {
    return (
      <Stack
        width="100%"
        height="100%"
        direction="row"
        alignItems="center"
        paddingLeft="38px"
        backgroundColor="primary.primary50">
        <RunningResearchIcon />
        <RenderCompanySource value={value.parameters} color="white" />
      </Stack>
    );
  }
  if (value?.enterNewCompany && value?.onEnterNewCompany) {
    return <RenderEditName onEnterNewCompany={value?.onEnterNewCompany}></RenderEditName>;
  }

  if (value?.notFound) {
    return <RenderNotFoundCompanyName value={value} />;
  }
  return (
    <Tooltip title={value.name || ''} arrow={true} open={showTooltip}>
      <Stack
        className="bulk-company-name"
        direction="row"
        gap="8px"
        alignItems="center"
        width="100%"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {value.allowCheckbox && (
          <FormControlLabel
            key={id}
            sx={{ width: '16px', height: '16px', padding: '0' }}
            control={
              <Checkbox
                onChange={(event) => {
                  onCheck(event.target.checked);
                  event.stopPropagation();
                }}
                checked={isChecked}
                style={checkBoxStyle}
              />
            }
          />
        )}
        {value.logoUrl && (
          <Avatar src={value.logoUrl} sx={{ width: 24, height: 24 }} variant="square" />
        )}
        <Typography
          flex={1}
          variant={value?.variant || variant}
          color={value?.color || color}
          textAlign="left"
          noWrap
          onMouseEnter={helper}
          onMouseLeave={() => setShowTooltip(false)}
          display="block">
          {value.name}
        </Typography>
        {value?.showAmbiguousWebsite && (
          <IconButtonComponent
            onClick={openMismatchDialog}
            tag={
              <Stack>
                <Typography>{"This company's data might not be accurate."}</Typography>
                <Typography>Click here to resolve the issue.</Typography>
              </Stack>
            }>
            <WarningIcon></WarningIcon>
          </IconButtonComponent>
        )}
        {recordId && !isHover && !hideRecordReady && (
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: isReady ? 'graphColors.graph2' : '#F3BA2F'
            }}
          />
        )}
        {value?.highlighted && (
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: 'primary.primary50'
            }}
          />
        )}
        {isHover && !hideMenu && (
          <IconButtonComponent onClick={handleClick} sx={{ padding: 0 }}>
            <MenuIcon />
          </IconButtonComponent>
        )}
        {!hideMenu && (
          <Menu
            id="bulk-record-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            textAlign="left"
            sx={{
              color: 'red',
              '&.MuiPaper-root': {
                color: 'yellow',
                backgroundColor: 'primary.white'
              }
            }}>
            {!recordId && (
              <MenuItem onClick={runInsightResearch} textAlign="left">
                <Stack direction="row" gap="8px" alignItems="center">
                  <RerunIcon /> Run Deep Dive
                </Stack>
              </MenuItem>
            )}
            {recordId && isReady && (
              <MenuItem onClick={showInsight} textAlign="left">
                <Stack direction="row" gap="8px" alignItems="center">
                  <GoToIcon /> See Deep Dive
                </Stack>
              </MenuItem>
            )}
            {recordId && !isReady && (
              <MenuItem textAlign="left">
                <Stack direction="row" gap="8px" alignItems="center">
                  Currently Running Deep Dive...
                </Stack>
              </MenuItem>
            )}
            {recordId && isReady && (
              <MenuItem onClick={runInsightResearch} textAlign="left">
                <Stack direction="row" gap="8px" alignItems="center">
                  <RerunIcon /> Rerun Deep Dive
                  <Typography color="greyColors.grey150">
                    ({formatTimestampRelative(timestamp)})
                  </Typography>
                </Stack>
              </MenuItem>
            )}
            {value.website && (
              <MenuItem textAlign="left">
                <Link
                  href={value.website}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  color="secondary.secondary1">
                  <Stack direction="row" alignItems="center" gap="8px">
                    <SourceIcon />
                    Go to website
                  </Stack>
                </Link>
              </MenuItem>
            )}
            {value.linkedinUrl && (
              <MenuItem textAlign="left">
                <Link
                  href={value.linkedinUrl}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  color="secondary.secondary1">
                  <Stack direction="row" alignItems="center" gap="8px">
                    <LinkedinIcon />
                    Go to Linkedin
                  </Stack>
                </Link>
              </MenuItem>
            )}
            {value.cbPermalink && (
              <MenuItem textAlign="left">
                <Link
                  href={`https://www.crunchbase.com/organization/${value.cbPermalink}`}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  color="secondary.secondary1">
                  <Stack direction="row" alignItems="center" gap="8px">
                    <CrunchbaseIcon />
                    Go to Crunchbase
                  </Stack>
                </Link>
              </MenuItem>
            )}
            {canPeerFinance && (
              <MenuItem onClick={runFinancePeer} textAlign="left">
                <Stack direction="row" gap="8px" alignItems="center">
                  <RerunIcon />
                  <Typography color="secondary.secondary1">Run Peer Finance</Typography>
                </Stack>
              </MenuItem>
            )}
            {value.onDelete && (
              <MenuItem onClick={deleteRow} textAlign="left">
                <Stack direction="row" alignItems="center">
                  <DeleteIcon /> Delete
                </Stack>
              </MenuItem>
            )}
          </Menu>
        )}
      </Stack>
    </Tooltip>
  );
}

RenderBulkCompanyName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.number,
  showCheckBox: PropTypes.bool,
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  canPeerFinance: PropTypes.bool
};

RenderBulkCompanyName.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};

export default React.memo(RenderBulkCompanyName);
