import { ICON_VARIANTS } from 'constants/icons';
import PropTypes from 'prop-types';
import React from 'react';

function LeadershipIcon({ variant }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect
        width="24"
        height="24"
        rx="4"
        fill={variant === ICON_VARIANTS.PRIMARY ? '#8ABA22' : 'white'}
      />
      <path
        d="M6 18.2102V16.6313C6 15.7938 6.31608 14.9906 6.8787 14.3984C7.44132 13.8062 8.20439 13.4735 9.00006 13.4735H9.37506M7.50003 7.15782C7.50003 7.99533 7.8161 8.79853 8.37872 9.39074C8.94134 9.98294 9.70442 10.3156 10.5001 10.3156C11.2957 10.3156 12.0588 9.98294 12.6214 9.39074C13.1841 8.79853 13.5001 7.99533 13.5001 7.15782C13.5001 6.32032 13.1841 5.51711 12.6214 4.9249C12.0588 4.3327 11.2957 4 10.5001 4C9.70442 4 8.94134 4.3327 8.37872 4.9249C7.8161 5.51711 7.50003 6.32032 7.50003 7.15782ZM14.8503 18.0657L13.2213 18.9641C13.173 18.9906 13.1186 19.0024 13.0642 18.9983C13.0099 18.9941 12.9577 18.9741 12.9135 18.9405C12.8693 18.907 12.8349 18.8611 12.814 18.8081C12.7931 18.7551 12.7866 18.6971 12.7953 18.6404L13.1066 16.7371L11.7888 15.3895C11.7495 15.3494 11.7216 15.2986 11.7084 15.2427C11.6952 15.1868 11.6972 15.1282 11.7142 15.0734C11.7312 15.0187 11.7624 14.9701 11.8044 14.9331C11.8464 14.8962 11.8973 14.8724 11.9515 14.8645L13.7726 14.5866L14.5871 12.8553C14.6114 12.8038 14.649 12.7604 14.6955 12.73C14.742 12.6997 14.7956 12.6836 14.8503 12.6836C14.9051 12.6836 14.9587 12.6997 15.0052 12.73C15.0517 12.7604 15.0892 12.8038 15.1136 12.8553L15.9281 14.5866L17.7491 14.8645C17.8032 14.8727 17.854 14.8966 17.8957 14.9336C17.9375 14.9706 17.9686 15.0191 17.9856 15.0737C18.0025 15.1283 18.0046 15.1869 17.9916 15.2427C17.9785 15.2985 17.9509 15.3493 17.9119 15.3895L16.5941 16.7371L16.9046 18.6397C16.9139 18.6964 16.908 18.7547 16.8874 18.8081C16.8668 18.8614 16.8324 18.9076 16.7881 18.9414C16.7438 18.9752 16.6914 18.9952 16.6368 18.9992C16.5822 19.0033 16.5277 18.9911 16.4794 18.9641L14.8503 18.0657Z"
        stroke={variant === ICON_VARIANTS.PRIMARY ? 'white' : '#8ABA22'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LeadershipIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_VARIANTS))
};

LeadershipIcon.defaultProps = {
  variant: ICON_VARIANTS.PRIMARY
};

export default LeadershipIcon;
