import { format, formatDistanceToNowStrict, isBefore, subDays } from 'date-fns';

import { toTitleCase } from './string-utils';

export const formatTimestampRelative = (timestamp) => {
  const dt = new Date(timestamp);
  const dtDateOnly = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
  return toTitleCase(formatDistanceToNowStrict(dtDateOnly, { addSuffix: true }));
};

export const getUtcTimeDiffFromNowInSeconds = (utcStr) => {
  const now = new Date();
  const date = new Date(utcStr);
  const utcDate = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);
  return (now.valueOf() - utcDate.valueOf()) / 1000;
};

export const checkIsOld = (timestamp) => isBefore(new Date(timestamp), subDays(new Date(), 7));

export const formatTimestamp = (timestamp, formatCode = 'dd.MM.yyyy, hh:mma') => {
  const dt = new Date(timestamp);
  return format(dt, formatCode);
};

export const getCurrentSortableTimestamp = () => {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
};
