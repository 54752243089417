/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  uploads: {}
};

export const filesSlice = createSlice({
  name: 'files',
  initialState: initialSliceState,
  reducers: {
    startUploadFile: (sliceState, { payload }) => {
      const { id, file } = payload;
      sliceState.uploads[id] = file;
    },
    addUploadFileProgress: (sliceState, { payload }) => {
      const { id, progress } = payload;
      sliceState.uploads[id].progress = progress;
    },
    fileUploadEnded: (sliceState, { payload }) => {
      const { id, isSucceed } = payload;
      if (sliceState.uploads[id]) {
        sliceState.uploads[id].finished = true;
        sliceState.uploads[id].isSucceed = isSucceed;
      }
    }
  }
});

export const { startUploadFile, addUploadFileProgress, fileUploadEnded } = filesSlice.actions;

export default filesSlice.reducer;
