import BaseClient from './BaseClient';

const URL_PREFIX = 'company_list_metas';

export default class CompanyListMetasClient extends BaseClient {
  fetchCompany(companyListMetaId) {
    return this.get(`${URL_PREFIX}/${companyListMetaId}`);
  }

  deleteCompany(companyListMetaId) {
    return this.httpDelete(`${URL_PREFIX}/${companyListMetaId}`);
  }

  updateCustomColumn(companyListMetaId, custom_column_id, value) {
    return this.put(`${URL_PREFIX}/${companyListMetaId}/custom_column`, {
      id: custom_column_id,
      value
    });
  }

  updateBulkOfCustomColumnValues(data) {
    return this.put(`${URL_PREFIX}/bulk_update_custom_columns`, data);
  }

  uploadFile(companyListMetaId, data) {
    return this.post(`${URL_PREFIX}/${companyListMetaId}/upload_file`, data, 'files');
  }

  downloadFile(companyListMetaId, fileId) {
    return this.get(`${URL_PREFIX}/${companyListMetaId}/download_file/${fileId}`, 'files');
  }

  deleteFile(companyListMetaId, fileId) {
    return this.httpDelete(`${URL_PREFIX}/${companyListMetaId}/delete_file/${fileId}`);
  }
}
