import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

export default function SelectAddToListMenu({
  anchorEl,
  open,
  onClose,
  onNewList,
  onSelectAddToList
}) {
  return (
    <Menu
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}>
      <MenuItem
        onClick={(event) => {
          onNewList(event);
          onClose();
        }}>
        Create New List
      </MenuItem>
      <MenuItem
        onClick={() => {
          onSelectAddToList();
          onClose();
        }}>
        Add To Existing List
      </MenuItem>
    </Menu>
  );
}
SelectAddToListMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  onNewList: PropTypes.func,
  onSelectAddToList: PropTypes.func
};
