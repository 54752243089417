import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { determineEventTickFormatter, getTimelineColorByIndex } from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';
import EventTimelinePieceComponent from './EventTimelinePieceComponent';

/* eslint-disable id-length */
export default function EventTimelineComponent({ title, timelines, zAxisName, mode, ...args }) {
  const datasets = useMemo(() => {
    return timelines.map((timeline, outerIndex) => ({
      name: timeline.name,
      data: timeline.dates.map((date, index) => ({
        date: new Date(date).getTime(),
        y: 0,
        [zAxisName]: timeline.values[index],
        sublabel: timeline.sublabels[index],
        link: timeline.urls[index],
        label: timeline.labels[index],
        highlight: timeline?.highlights ? timeline.highlights[index] : false,
        color: timeline?.colors ? timeline.colors[index] : null,
        fill: getTimelineColorByIndex(outerIndex)
      }))
    }));
  }, [timelines, zAxisName]);

  function getMinMax(arr) {
    if (!arr) {
      return null;
    }
    let [minV] = arr;
    let [maxV] = arr;
    arr.forEach((item) => {
      if (item < minV) minV = item;
      if (item > maxV) maxV = item;
    });
    return [minV, maxV];
  }

  const domain = useMemo(() => {
    let dates = [];
    timelines.forEach((timeline) => {
      dates = [...dates, ...timeline.dates];
    });
    return getMinMax(dates).map((date) => {
      return new Date(date).getTime();
    });
  }, [timelines]);

  const heightParams = mode === 'monitoring' ? { height: '100%' } : {};
  const tickFormatter = determineEventTickFormatter(...domain);
  return (
    <RechartsBox title={title} mode={mode} responsive={false} {...heightParams}>
      {datasets.map((timeline, index) => {
        return (
          <EventTimelinePieceComponent
            key={index}
            data={timeline.data}
            dataKeyX="date"
            dataKeyY="y"
            yAxisName={timeline.name}
            fill={getTimelineColorByIndex(index)}
            domain={domain}
            tick={index + 1 === datasets.length}
            tickFormatter={tickFormatter}
            mode={mode}
            {...args}
          />
        );
      })}
    </RechartsBox>
  );
}

EventTimelineComponent.propTypes = {
  title: PropTypes.string,
  timelines: PropTypes.array,
  zAxisName: PropTypes.string,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint', 'monitoring']).isRequired
};
