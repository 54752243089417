import { Button, Typography } from '@mui/material';
import { handleErrorAction } from 'actions/users/handle-error-action';
import { dispatch } from 'hooks/AppStateProvider';
import React from 'react';

import './ErrorBoundaryComponent.scss';

/* eslint-disable react/prop-types */
export function wrapWithError(WrappedComponent) {
  return class ErrorBoundaryComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, errorInfo);
      dispatch(handleErrorAction(error, errorInfo, this.props));
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div className="error-boundary-component">
            <Typography variant="h2">Something went wrong.</Typography>
            <Button variant="contained" onClick={() => window.location.reload()}>
              refresh
            </Button>
          </div>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
