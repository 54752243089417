import { Stack, Typography } from '@mui/material';
import CustomAvatarGroup from 'components/CustomAvatarGroup';
import CustomAvatar from 'components/tile/CustomAvatar';
import PropTypes from 'prop-types';
import React from 'react';

function RenderTeamOption(props) {
  const thisTeamUsers = props?.teams
    ?.find((team) => team.name === props.option)
    ?.users?.map((user) => ({ ...user, name: user.username }));
  return (
    <li {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack direction="row" gap="8px" alignItems="center">
          <CustomAvatar name={props.option} size={24} org={true} />
          <Typography variant="paragraph" color="greyColors.grey300">
            {props.option}
          </Typography>
        </Stack>
        {thisTeamUsers && <CustomAvatarGroup entities={thisTeamUsers} />}
      </Stack>
    </li>
  );
}

RenderTeamOption.propTypes = {
  teams: PropTypes.object,
  option: PropTypes.string
};

export default RenderTeamOption;
