import { ACTIONS } from 'constants/actions';

function updateOpinionState(runId, insightId, opinion) {
  return { type: ACTIONS.ADD_OPINION, runId, insightId, opinion };
}

export function loadOpinion(runId, insightId) {
  return (_state, dispatch, apiClient) => {
    apiClient?.opinions?.fetchOpinion(runId, insightId)?.then((res) => {
      const opinion = res.ok && res.data ? res.data : null;
      if (opinion) {
        dispatch(updateOpinionState(runId, insightId, opinion));
      }
    });
  };
}
