export const MinimizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M14.5 10C14.5 10.2761 14.7239 10.5 15 10.5L19.5 10.5C19.7761 10.5 20 10.2761 20 10C20 9.72386 19.7761 9.5 19.5 9.5L15.5 9.5L15.5 5.5C15.5 5.22386 15.2761 5 15 5C14.7239 5 14.5 5.22386 14.5 5.5L14.5 10ZM21.3536 4.35355C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645C21.1583 3.45118 20.8417 3.45118 20.6464 3.64645L21.3536 4.35355ZM15.3536 10.3536L21.3536 4.35355L20.6464 3.64645L14.6464 9.64645L15.3536 10.3536Z"
        fill="#0F2345"
      />
      <path
        d="M10.5 15C10.5 14.7239 10.2761 14.5 10 14.5L5.5 14.5C5.22386 14.5 5 14.7239 5 15C5 15.2761 5.22386 15.5 5.5 15.5L9.5 15.5L9.5 19.5C9.5 19.7761 9.72386 20 10 20C10.2761 20 10.5 19.7761 10.5 19.5L10.5 15ZM3.64645 20.6464C3.45118 20.8417 3.45118 21.1583 3.64645 21.3536C3.84171 21.5488 4.15829 21.5488 4.35355 21.3536L3.64645 20.6464ZM9.64645 14.6464L3.64645 20.6464L4.35355 21.3536L10.3536 15.3536L9.64645 14.6464Z"
        fill="#0F2345"
      />
    </svg>
  );
};
