import { createSlice } from '@reduxjs/toolkit';
import { insightTypeToTitle } from 'utils/insights-utils';

const initialSliceState = {
  uiSettings: {},
  dynamicCards: {},
  insightsColumns: {}
};

export const deepDiveSlice = createSlice({
  name: 'deep-dive',
  initialState: initialSliceState,
  reducers: {
    updateUiSettingsPublicMode: (sliceState, { payload }) => {
      const { isPublicMode } = payload;
      sliceState.uiSettings.isPublicMode = isPublicMode;
    },
    addAllDeepDiveDynamicCards: (sliceState, { payload }) => {
      const { deepDiveId, cards } = payload;
      sliceState.dynamicCards[deepDiveId] = cards;
    },
    addDeepDiveDynamicCard: (sliceState, { payload }) => {
      const { deepDiveId, card } = payload;
      if (sliceState.dynamicCards[deepDiveId]) {
        sliceState.dynamicCards[deepDiveId][card.id] = card;
      }
    },
    deleteDeepDiveDynamicCard: (sliceState, { payload }) => {
      const { deepDiveId, cardId } = payload;
      if (sliceState.dynamicCards[deepDiveId]?.[cardId]) {
        delete sliceState.dynamicCards[deepDiveId][cardId];
      }
    },
    addAllInsightsAsColumns: (sliceState, { payload }) => {
      const { deepDiveId, insights } = payload;

      sliceState.insightsColumns[deepDiveId] = insights?.reduce((columns, insightId) => {
        columns[insightId] = {
          id: insightId,
          name: insightTypeToTitle(insightId),
          columnType: 'deep_dive'
        };
        return columns;
      }, {});
    }
  }
});

export const {
  updateUiSettingsPublicMode,
  addAllDeepDiveDynamicCards,
  addDeepDiveDynamicCard,
  deleteDeepDiveDynamicCard,
  addAllInsightsAsColumns
} = deepDiveSlice.actions;

export default deepDiveSlice.reducer;
