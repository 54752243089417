export const getPublicShare = (state) => state.publicShareLinks;
export const getPublicShareLinks = (state) => getPublicShare(state).links;
export const getPublicShareLinksIds = (state) => getPublicShare(state).linkIds;
export const getPublicShareLinksUiSettings = (state) => getPublicShare(state).uiSettings;
export const getRunPublicShareLinks = (state, runId) => getPublicShareLinks(state)[runId];
export const getRunPublicShareLinksData = (state, runId, linkId) =>
  getRunPublicShareLinks(state, runId)[linkId];
export const getPublicLinkIdData = (state, linkId) => getPublicShareLinksIds(state)[linkId];
export const getPublicShareLinksUiSettingsByRunId = (state, runId) =>
  getPublicShareLinksUiSettings(state)[runId];
export const getPublicShareLinksUiSettingsByLinkId = (state, linkId) =>
  getPublicShareLinksUiSettings(state)[linkId];
export const getSearchIdByPublicLinkId = (state, linkId) =>
  getPublicLinkIdData(state, linkId)?.searchId;
export const getIsLoadingPublicLinksByRunId = (state, runId) =>
  getPublicShareLinksUiSettingsByRunId(state, runId)?.isLoading;
export const getIsPublicLinksAddingInProgressByRunId = (state, runId) =>
  getPublicShareLinksUiSettingsByRunId(state, runId)?.inAddProgress;
export const getIsLoadingPublicLinksDataByLinkId = (state, linkId) =>
  getPublicShareLinksUiSettingsByLinkId(state, linkId)?.isLoadingPublicLinkData;
