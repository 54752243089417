import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { deleteFileFromExploreRowAction } from 'actions/explore/delete-file-from-discovery-row-action';
import { downloadFileFromExploreRowAction } from 'actions/explore/download-file-from-discovery-row-action';
import { uploadFileToExploreRowAction } from 'actions/explore/upload-file-to-discovery-row-action';
import IconButtonComponent from 'components/IconButtonComponent';
import { TOAST_TYPES } from 'constants/toasts';
import AddFileIcon from 'icons/AddFileIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DownloadIcon from 'icons/DownloadIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openChooseFile } from 'utils/download-utils';

export default function RenderFiles({ value }) {
  const { addToast } = useAppUtils();
  const dispatch = useDispatch();
  const [isColumnHover, setIsColumnHover] = useState(false);
  const [openDownloadsAnchorEl, setOpenDownloadsAnchorEl] = useState(null);
  const openDownloads = Boolean(openDownloadsAnchorEl);

  if (!value) {
    return null;
  }

  const { companyListId, companyListMetaId, files } = value;
  const handleOpenDownloads = (event) => {
    setOpenDownloadsAnchorEl(event.currentTarget);
  };

  const handleCloseDownloads = () => {
    setOpenDownloadsAnchorEl(null);
  };

  const uploadFile = (file) => {
    if (file.size > 20 * 1000 * 1000) {
      addToast(
        `File size ${file.size / 1000 / 1000}MB is too big, file size could not be above 20MB`,
        TOAST_TYPES.ERROR
      );
      return;
    }

    dispatch(uploadFileToExploreRowAction(companyListId, companyListMetaId, file));
  };

  const uploadFiles = (filesList) => {
    Array.from(filesList).forEach((file) => uploadFile(file));
  };

  const handleDownloadFile = (fileInfo) => {
    addToast('Starting download the file', TOAST_TYPES.INFO);
    dispatch(
      downloadFileFromExploreRowAction(companyListMetaId, fileInfo, (isSucced) => {
        if (isSucced) {
          addToast('finished download the file', TOAST_TYPES.SUCCESS);
        } else {
          addToast('failed to download the file', TOAST_TYPES.ERROR);
        }
      })
    );
    handleCloseDownloads();
  };

  const handleDeleteFile = (event, fileInfo) => {
    event.stopPropagation();
    addToast('Deleting the file', TOAST_TYPES.INFO);
    dispatch(deleteFileFromExploreRowAction(companyListId, companyListMetaId, fileInfo));
    handleCloseDownloads();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      flex={1}
      height="100%"
      onMouseEnter={() => setIsColumnHover(true)}
      onMouseLeave={() => setIsColumnHover(false)}>
      {files && (
        <Tooltip title="Add Files" arrow={false}>
          <Button
            variant="text"
            onClick={() => openChooseFile(uploadFiles, true)}
            sx={{
              padding: '8px',
              minWidth: 'unset',
              visibility: isColumnHover ? 'visible' : 'hidden'
            }}>
            +
          </Button>
        </Tooltip>
      )}
      {files && (
        <Button
          variant="text"
          onClick={handleOpenDownloads}
          sx={{ padding: '4px', minWidth: 'unset' }}>{`${Object.keys(files).length} files`}</Button>
      )}
      {!files && isColumnHover && (
        <IconButtonComponent tag="Add Files" onClick={() => openChooseFile(uploadFiles, true)}>
          <AddFileIcon />
        </IconButtonComponent>
      )}
      {files && (
        <Menu anchorEl={openDownloadsAnchorEl} open={openDownloads} onClose={handleCloseDownloads}>
          {Object.keys(files).map((fileId) => (
            <MenuItem
              key={fileId}
              onClick={() => handleDownloadFile(files[fileId])}
              sx={{ gap: '64px' }}>
              <Stack direction="row" alignItems="center">
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText>{files[fileId].file_name}</ListItemText>
              </Stack>
              <IconButtonComponent
                onClick={(event) => handleDeleteFile(event, files[fileId])}
                sx={{ marginLeft: 'auto' }}>
                <DeleteIcon />
              </IconButtonComponent>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Stack>
  );
}

RenderFiles.propTypes = {
  value: PropTypes.object
};
