import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 300;
const nodeHeight = 100;

function nodesFromPlan(plan, nodeType) {
  const nodes = plan.map((step) => {
    const { step_id, objective, function_use } = step;
    return {
      id: step_id,
      data: {
        label: objective,
        functionUse: function_use,
        stepId: step_id
      },
      type: nodeType
    };
  });
  return [...nodes, { id: 'end', data: { label: 'End' }, type: nodeType }];
}

function edgesFromPlan(plan) {
  const edges = [];
  plan.forEach((step) => {
    const { step_id, if_failed, if_successful } = step;
    if (if_successful === if_failed) {
      edges.push({
        id: `${step_id}_to_${if_successful}`,
        source: step_id,
        target: if_successful,
        type: 'condition',
        data: { label: 'if yes', condition: true }
      });
    } else {
      edges.push({
        id: `${step_id}_to_${if_failed}`,
        source: step_id,
        target: if_failed,
        type: 'condition',
        data: { label: 'if no', condition: false }
      });
      edges.push({
        id: `${step_id}_to_${if_successful}`,
        source: step_id,
        target: if_successful,
        type: 'condition',
        data: { label: 'if yes', condition: true }
      });
    }
  });
  return edges;
}

/* eslint-disable id-length */
const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2
    };

    return node;
  });

  return { nodes, edges };
};

export function extractNodesAndEdges(plan, nodeType) {
  const nodes = nodesFromPlan(plan, nodeType);
  const edges = edgesFromPlan(plan);
  return getLayoutedElements(nodes, edges);
}
