import PropTypes from 'prop-types';
import React from 'react';

export default function EditIcon({ useDefaultColor }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.7497 7.8366L16.189 5.26286C16.0198 5.09451 15.7908 5 15.5521 5C15.3134 5 15.0844 5.09451 14.9152 5.26286L5.83736 14.3277L5.00853 17.9047C4.97994 18.0355 4.98092 18.171 5.0114 18.3013C5.04188 18.4316 5.10109 18.5535 5.18471 18.658C5.26833 18.7626 5.37423 18.8471 5.4947 18.9054C5.61516 18.9638 5.74714 18.9945 5.88099 18.9953C5.94335 19.0016 6.0062 19.0016 6.06856 18.9953L9.68488 18.1664L18.7497 9.11038C18.918 8.94117 19.0125 8.71219 19.0125 8.47349C19.0125 8.2348 18.918 8.00581 18.7497 7.8366ZM9.24866 17.3812L5.85918 18.0923L6.6313 14.7682L13.4233 8.00237L16.0407 10.6197L9.24866 17.3812ZM16.6252 9.9872L14.0079 7.36984L15.526 5.86049L18.0997 8.47785L16.6252 9.9872Z"
        fill={useDefaultColor ? '#0B1C38' : 'currentColor'}
      />
    </svg>
  );
}

EditIcon.propTypes = {
  useDefaultColor: PropTypes.bool
};

EditIcon.defaultProps = {
  useDefaultColor: false
};
