import React from 'react';

export default function ShareIcon({ ...args }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...args}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9481 8.42657C17.9481 7.45953 17.1632 6.67559 16.1949 6.67559C15.2267 6.67559 14.4418 7.45953 14.4418 8.42657C14.4418 9.39361 15.2267 10.1776 16.1949 10.1776C17.1632 10.1776 17.9481 9.39361 17.9481 8.42657ZM19 8.42657C19 6.87931 17.7441 5.625 16.1949 5.625C14.6457 5.625 13.3899 6.87931 13.3899 8.42657C13.3899 8.68737 13.4255 8.93984 13.4923 9.17939L10.0624 10.9616C9.5515 10.2711 8.73064 9.82343 7.80507 9.82343C6.25587 9.82343 5 11.0777 5 12.625C5 14.1723 6.25587 15.4266 7.80507 15.4266C8.66628 15.4266 9.43685 15.0389 9.95141 14.4288L13.4494 16.2464C13.4104 16.4326 13.3899 16.6256 13.3899 16.8234C13.3899 18.3707 14.6457 19.625 16.1949 19.625C17.7441 19.625 19 18.3707 19 16.8234C19 15.2762 17.7441 14.0219 16.1949 14.0219C15.2193 14.0219 14.36 14.5193 13.8574 15.2742L10.4665 13.5123C10.5597 13.2334 10.6101 12.9351 10.6101 12.625C10.6101 12.3776 10.578 12.1377 10.5178 11.9092L13.9604 10.1204C14.4727 10.7935 15.283 11.2281 16.1949 11.2281C17.7441 11.2281 19 9.97384 19 8.42657ZM7.80507 14.376C8.77332 14.376 9.55824 13.592 9.55824 12.625C9.55824 11.658 8.77332 10.874 7.80507 10.874C6.83682 10.874 6.0519 11.658 6.0519 12.625C6.0519 13.592 6.83682 14.376 7.80507 14.376ZM16.1949 18.5744C17.1632 18.5744 17.9481 17.7905 17.9481 16.8234C17.9481 15.8564 17.1632 15.0724 16.1949 15.0724C15.2267 15.0724 14.4418 15.8564 14.4418 16.8234C14.4418 17.7905 15.2267 18.5744 16.1949 18.5744Z"
        fill="currentColor"
      />
    </svg>
  );
}
