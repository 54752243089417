import { Box, Link, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { extractLinkedinName, limitLength } from 'utils/string-utils';

import LinkedinButton from './LinkedinButton';

export default function LinkedinButtonVerbose({ linkedinUrl }) {
  if (!linkedinUrl) {
    return null;
  }
  const name = extractLinkedinName(linkedinUrl);
  if (!name || name.length < 3) {
    return null;
  }
  return (
    <Box onClick={(event) => event.stopPropagation()}>
      <LinkedinButton linkedinUrl={linkedinUrl} />
      <Link href={linkedinUrl} underline="none" target="_blank" rel="noreferrer">
        <Tooltip title={name.slice(3)}>
          <Typography variant="paragraphBold" color="primary.primary2">
            {limitLength(name, 20)}
          </Typography>
        </Tooltip>
      </Link>
    </Box>
  );
}

LinkedinButtonVerbose.propTypes = { linkedinUrl: PropTypes.string };
