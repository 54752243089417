import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';
import { addExploreDomainKnowledgeResource } from 'reducer/explore-slice';
import { addUploadFileProgress, fileUploadEnded, startUploadFile } from 'reducer/files';
import { addFolderSupportingResource } from 'reducer/folders-slice';
import { v4 as uuidv4 } from 'uuid';

export function addDiscoveryDomainKnowledgeResourceAction({
  resourceName,
  resourceType,
  data,
  listId,
  folderId,
  onFinish
}) {
  return (dispatch, _getState, apiClient) => {
    if (resourceType === 'files') {
      const uploadId = uuidv4();
      const { name, size } = data;
      dispatch(
        startUploadFile({
          id: uploadId,
          file: { name, size, progress: 0 }
        })
      );
      const body = {
        supporting_resource_type: DOMAIN_KNOWLEDGE_RESOURCE_TYPE.FILES,
        name
      };
      if (listId) {
        body.company_list_id = listId;
      }
      if (folderId) {
        body.folder_id = folderId;
      }
      apiClient.filesUploaderManager.uploadFile(
        uploadId,
        data,
        `supporting_resources/files`,
        (res, error, wholeFileUploaded) => {
          const isSucceed = !error && res?.status === 200;
          const isFinishedUploadFile = error || wholeFileUploaded;
          if (isFinishedUploadFile) {
            if (isSucceed) {
              if (listId) {
                dispatch(addExploreDomainKnowledgeResource({ listId, resource: res.data }));
              }
              if (folderId) {
                dispatch(addFolderSupportingResource({ folderId, resource: res.data }));
              }
            }
            dispatch(fileUploadEnded({ id: uploadId, isSucceed: isSucceed }));
            onFinish?.(isSucceed);
          }
        },
        (progress) =>
          dispatch(
            addUploadFileProgress({
              id: uploadId,
              progress
            })
          ),
        body
      );
    } else {
      const resource = {
        supporting_resource_type: resourceType,
        name: resourceName,
        data,
        company_list_id: listId || null,
        folder_id: folderId || null
      };
      apiClient.supportingResources.submitSupportingResource(resource).then((res) => {
        if (res.ok && onFinish) {
          onFinish?.(res.data);
          if (listId) {
            dispatch(addExploreDomainKnowledgeResource({ listId, resource: res.data }));
          }
          if (folderId) {
            dispatch(addFolderSupportingResource({ folderId, resource: res.data }));
          }
        }
      });
    }
  };
}
