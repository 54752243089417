import { Box, IconButton, Stack } from '@mui/material';
import { updateExploreCompanyIsCheckedByCompanyListMetaId } from 'actions/explore-table-ui/update-discovery-ui-state-by-company-meta-id';
import RenderBulkCompanyName from 'components/elements/table/RenderBulkCompanyNameConnector';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

function SelectedCompanyInList({ company }) {
  const [isHover, setIsHover] = useState(false);
  const dispatch = useDispatch();

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  const onRemove = () => {
    dispatch(
      updateExploreCompanyIsCheckedByCompanyListMetaId(
        company.companyListId,
        company.companyListMetaId,
        false
      )
    );
  };
  return (
    <Stack
      direction="row"
      padding="8px 24px"
      alignItems="center"
      width="100%"
      gap="8px"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ backgroundColor: isHover ? 'background.bg50' : 'default' }}>
      <Box width="70%">
        <RenderBulkCompanyName value={company}></RenderBulkCompanyName>
      </Box>
      <Box display="flex">
        {isHover && (
          <IconButton onClick={onRemove} sx={{ marginLeft: 'auto', color: 'black', padding: 0 }}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
}

SelectedCompanyInList.propTypes = {
  company: PropTypes.object
};

export default SelectedCompanyInList;
