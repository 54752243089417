import {
  deleteCompaniesFromListByIds,
  replaceCompanyInList,
  replaceExploreMismatchCompany
} from 'reducer/explore-slice';

import { sendAmbiguousWebsiteEvent } from './add-companies-to-list-action';

function replaceCompanyInMismatched(oldCompanyListMetaId, newCompanyMeta, listId) {
  return (dispatch) => {
    dispatch(
      replaceExploreMismatchCompany({
        oldCompanyListMetaId,
        newCompanyMeta,
        listId
      })
    );
  };
}

function replaceToNotFoundCompanyInMismatched(oldCompanyListMetaId, notFoundName, listId) {
  return (dispatch) => {
    dispatch(
      replaceExploreMismatchCompany({
        oldCompanyListMetaId,
        notFoundName,
        listId
      })
    );
  };
}
function replaceToFailedCompanyInMismatched(oldCompanyListMetaId, failedName, listId) {
  return (dispatch) => {
    dispatch(
      replaceExploreMismatchCompany({
        oldCompanyListMetaId,
        failedName,
        listId
      })
    );
  };
}

export function resubmitMismatchedCompanyAction(listId, idToDelete, newName) {
  return (dispatch, _getState, apiClient) => {
    // first delete the existing company and then add the new one
    apiClient.companyListMetas.deleteCompany(idToDelete).then((res1) => {
      if (res1.ok) {
        apiClient.companiesLists
          .addCompanies(listId, [newName])
          .then((res2) => {
            if (res2.ok && res2.data) {
              const { companies_added, not_found, ambiguous_website_list_mata_ids } = res2.data;
              if (companies_added?.length) {
                dispatch(replaceCompanyInMismatched(idToDelete, companies_added[0], listId));
                dispatch(
                  replaceCompanyInList({
                    oldCompanyListMetaId: idToDelete,
                    newCompanyMeta: companies_added[0],
                    listId
                  })
                );
              }
              if (not_found?.length) {
                dispatch(replaceToNotFoundCompanyInMismatched(idToDelete, newName, listId));
                dispatch(deleteCompaniesFromListByIds({ listId, ids: [idToDelete] }));
              }
              if (ambiguous_website_list_mata_ids?.length) {
                dispatch(
                  sendAmbiguousWebsiteEvent(companies_added, ambiguous_website_list_mata_ids)
                );
              }
            }
          })
          .catch(() => {
            dispatch(replaceToFailedCompanyInMismatched(idToDelete, newName, listId));
            dispatch(deleteCompaniesFromListByIds({ listId, ids: [idToDelete] }));
          });
      }
    });
  };
}
