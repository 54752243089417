import PropTypes from 'prop-types';
import React from 'react';
import { Dot } from 'recharts';

/* eslint-disable id-length */
function ArtScatterShape({ cx, cy, fill }) {
  return <Dot cx={cx} cy={cy} r={2.7} fill={fill} />;
}

ArtScatterShape.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  fill: PropTypes.string
};

export default ArtScatterShape;
