import PropTypes from 'prop-types';
import React from 'react';

export default function DislikeIcon({ selected, disabled }) {
  if (selected || disabled) {
    const color = disabled ? '#D6DCED' : '#579BFC';
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M20.3689 14.6416C20.3689 14.9177 20.145 15.1416 19.8689 15.1416H16.4844V5.58313H19.8689C20.145 5.58313 20.3689 5.80699 20.3689 6.08313V14.6416Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M8.46875 5.58313L16.5154 5.58313L16.5154 15.4708L13.3883 19.515C13.199 19.7599 12.9068 19.9033 12.5972 19.9033H11.3959C10.6598 19.9033 10.176 19.1349 10.4941 18.4711L11.9485 15.4364C12.1394 15.0382 11.8491 14.5771 11.4074 14.5771H6.47651C5.68189 14.5771 5.05326 13.9045 5.1069 13.1117C5.16069 12.3168 5.3214 11.5328 5.58469 10.7809L7.05303 6.58741C7.26365 5.98588 7.83141 5.58313 8.46875 5.58313Z"
          stroke={color}
        />
      </svg>
    );
  }
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.698 13.8984C19.698 14.1746 19.4741 14.3984 19.198 14.3984H15.8135V4.83997H19.198C19.4741 4.83997 19.698 5.06382 19.698 5.33997V13.8984Z"
        stroke="#0B1C38"
      />
      <path
        d="M7.79785 4.83996L15.8445 4.83996L15.8445 14.7277L12.7174 18.7718C12.5281 19.0168 12.2359 19.1602 11.9263 19.1602H10.725C9.98888 19.1602 9.50505 18.3918 9.82319 17.728L11.2776 14.6933C11.4685 14.295 11.1782 13.834 10.7365 13.834H5.80561C5.01099 13.834 4.38236 13.1614 4.43601 12.3685C4.48979 11.5737 4.6505 10.7897 4.91379 10.0377L6.38213 5.84425C6.59276 5.24271 7.16051 4.83996 7.79785 4.83996Z"
        stroke="#0B1C38"
      />
    </svg>
  );
}

DislikeIcon.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};
