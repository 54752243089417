import { ACTIONS } from 'constants/actions';

function updateOpinionState(runId, insightId, opinion) {
  return { type: ACTIONS.ADD_OPINION, runId, insightId, opinion };
}

export function makeOpinion(runId, insightId, opinion) {
  return (_state, dispatch, apiClient) => {
    apiClient.opinions.createOpinion(runId, insightId, opinion).then((res) => {
      const dbOpinion = res.ok && res.data ? res.data : null;
      if (dbOpinion) {
        dispatch(updateOpinionState(runId, insightId, dbOpinion));
      }
    });
  };
}
