import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  notifications: {}
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialSliceState,
  reducers: {
    addAllNotifications: (sliceState, { payload }) => {
      const { notifications } = payload;
      sliceState.notifications = notifications;
    },
    setDismissNotifications: (sliceState, { payload }) => {
      const { notificationIds, dismissed } = payload;
      for (const notificationId of notificationIds) {
        if (sliceState.notifications[notificationId]) {
          sliceState.notifications[notificationId].dismissed = dismissed;
        }
      }
    },
    addNotification: (sliceState, { payload }) => {
      const { notification } = payload;
      sliceState.notifications[notification.id] = notification;
    }
  }
});

export const { addAllNotifications, setDismissNotifications, addNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
