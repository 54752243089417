import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import SelectableButton from 'components/SelectableButton';
import { useAppState } from 'hooks/state-context';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { getAllIndustries, getAllPartners } from 'selectors/companyLists';
import { appTheme } from 'theme';

export const PARTNER_TO_LOGO = Object.freeze({
  SAP: 'https://images.crunchbase.com/image/upload/t_cb-default-original/v1488420037/xyqlcefn45ijfegxxih8.png',
  Snowflake:
    'https://images.crunchbase.com/image/upload/t_cb-default-original/ftaz3bneeq9qhgnllf2h',
  Salesforce:
    'https://images.crunchbase.com/image/upload/t_cb-default-original/v1488354350/yx91yyljvcuu5j8agjqq.png',
  Servicenow:
    'https://play-lh.googleusercontent.com/HdfHZ5jnfMM1Ep7XpPaVdFIVSRx82wKlRC_qmnHx9H1E4aWNp4WKoOcH0x95NAnuYg'
  // Microsoft:
  // 'https://images.crunchbase.com/image/upload/t_cb-default-original/v1501613147/ypg7mliwmzomf9fdrkvz.png'
});

function SetDiscoveryByPartnership({
  filters,
  industries,
  setIndustries,
  partners,
  setPartners,
  industriesMaxWidth
}) {
  const { state } = useAppState();
  const [openSelect, setOpenSelect] = useState(false);
  const [hoverLabel, setHoverLabel] = useState();

  const allIndustries = getAllIndustries(state);
  const allPartners = getAllPartners(state);

  const allIndustriesList = useMemo(() => {
    const allList = [
      ...new Set(
        Object.values(allIndustries).reduce(
          (acc, partner) =>
            acc.concat(Object.values(partner).reduce((res, indes) => res.concat(indes), [])),
          []
        )
      )
    ];
    return allList.sort((ind1, ind2) => ind1.localeCompare(ind2));
  }, [allIndustries]);

  const filteredIndustries = useMemo(() => {
    const selectedHQs = filters?.hq_location_continent || [];
    const localIndustries = new Set();
    Object.keys(allIndustries)
      .filter((partnerDb) => partners?.find((partner2) => partner2 === partnerDb))
      .forEach((partnerDb) =>
        Object.keys(allIndustries[partnerDb])
          .filter((regionIndustries) => selectedHQs.find((region2) => region2 === regionIndustries))
          .forEach((regionIndustries) =>
            Object.values(allIndustries[partnerDb][regionIndustries]).forEach((industry) =>
              localIndustries.add(industry)
            )
          )
      );
    return localIndustries;
  }, [allIndustries, filters, partners]);

  useEffect(() => {
    if (filteredIndustries?.size) {
      if (industries?.length > 0) {
        let selectionWasFiltered = false;
        const values = industries.filter((industry) => {
          if (filteredIndustries.has(industry)) {
            return true;
          }
          selectionWasFiltered = true;
          return false;
        });
        if (selectionWasFiltered) {
          setIndustries(values);
        }
      }
    }
  }, [filteredIndustries, industries, setIndustries]);

  useEffect(() => {
    if (allPartners && !partners) {
      setPartners(allPartners);
    }
  }, [allPartners, partners, setPartners]);

  const setIsSelectedPartner = (partner) => {
    if (partners.find((partner2) => partner2 === partner)) {
      setPartners(partners.filter((partner2) => partner2 !== partner));
    } else {
      setPartners([...partners, partner]);
    }
  };

  const handleDisableSelected = (event, label) => {
    if (!filteredIndustries.has(label)) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap="16px" width="100%">
      {partners && (
        <Stack direction="row" gap="8px">
          {allPartners.map((partner) => {
            return (
              <SelectableButton
                key={partner}
                tooltip={partner}
                isSelected={Boolean(partners.find((partner2) => partner2 === partner))}
                onClick={() => setIsSelectedPartner(partner)}
                allowOnly={false}>
                <Box
                  sx={{ width: '24px', height: '24px' }}
                  component="img"
                  src={PARTNER_TO_LOGO[partner]}
                />
              </SelectableButton>
            );
          })}
        </Stack>
      )}
      {industries && (
        <Box width="100%">
          <Box width="80%" maxWidth={`${industriesMaxWidth}px`}>
            <FormControl fullWidth size="small">
              <InputLabel size="small">Select Industry</InputLabel>
              <Select
                size="small"
                value={industries?.[0] ? industries?.[0] : ''}
                renderValue={(selected) => (
                  <Typography
                    variant="paragraphMedium"
                    sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {selected}
                  </Typography>
                )}
                label="Select Industry"
                open={openSelect}
                onOpen={() => setOpenSelect(true)}
                onClose={() => setOpenSelect(false)}
                onChange={(event) => setIndustries([event.target.value])}
                sx={{
                  '.MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }
                }}>
                {allIndustriesList.map((label, index) => (
                  <MenuItem
                    value={label}
                    key={index}
                    disabled={!filteredIndustries.has(label)}
                    sx={{
                      backgroundColor: filteredIndustries.has(label)
                        ? 'transparent'
                        : 'background.bg75',
                      '&.Mui-disabled': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '4px',
                        pointerEvents: 'auto',
                        backgroundColor: 'background.bg75',
                        opacity: 1,
                        color: 'greyColors.grey150'
                      }
                    }}
                    onClickCapture={(event) => handleDisableSelected(event, label)}>
                    {label}
                    {!filteredIndustries.has(label) && (
                      <Tooltip
                        title="Unavailable within your selections"
                        open={hoverLabel === label}>
                        <Stack
                          onMouseEnter={() => setHoverLabel(label)}
                          onMouseLeave={() => setHoverLabel(null)}>
                          <ToastIcons
                            type="info"
                            size="16"
                            mainColor={appTheme.palette.secondary.secondary1}
                            backgroundColor="transparent"
                          />
                        </Stack>
                      </Tooltip>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

SetDiscoveryByPartnership.propTypes = {
  filters: PropTypes.object,
  industries: PropTypes.array,
  setIndustries: PropTypes.func,
  partners: PropTypes.array,
  setPartners: PropTypes.func,
  industriesMaxWidth: PropTypes.number
};

SetDiscoveryByPartnership.defaultProps = {
  industriesMaxWidth: 300
};

export default SetDiscoveryByPartnership;
