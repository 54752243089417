import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { ACTIONS } from 'constants/actions';

export function updateRunProgress(runId, progress) {
  return { type: ACTIONS.UPDATE_RUN_PROGRESS, runId, progress };
}

export function monitorResearchProgressAction(searchId) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.runs.fetchRunProgress(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { progress } = res.data;
          dispatch(updateRunProgress(searchId, progress));
          if (progress >= 100) {
            clearInterval(tickerId);
            dispatch(updateRecentlyFinishedRun(searchId, 'runs'));
          }
        }
      });
    };
    tickerId = setInterval(ticker, 15000);
    ticker();
  };
}
function updateRunStatuses(runId, statuses) {
  return { type: ACTIONS.UPDATE_RUN_STATUSES, runId, statuses };
}

export function monitorResearchStatusesAction(searchId) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.runs.getRunStatuses(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { status, statuses } = res.data;
          if (status !== 'WAITING') {
            clearInterval(tickerId);
            return;
          }

          dispatch(updateRunStatuses(searchId, statuses));
        }
      });
    };
    tickerId = setInterval(ticker, 20000);
    ticker();
  };
}
