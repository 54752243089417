import { Box, Card, Chip, Divider, Stack } from '@mui/material';
import { loadCardData } from 'actions/runs/load-card-data-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import InsightTextComponent from 'components/InsightTextComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import html2canvas from 'html2canvas';
import CardActionsDividerIcon from 'icons/CardActionsDividerIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCardByRecordIdAndInsightId } from 'selectors/cards';
import { insightTypeToIcon } from 'utils/insights-utils';

import { CardTemplate } from './CardTemplate';
import OpinionComponent from './OpinionComponent';

function createCanvasPromiseFromHTMLElement(element) {
  return new Promise((resolve, reject) => {
    if (!(element && element.parentElement)) {
      resolve('');
    }
    html2canvas(element, {
      scale: 1
    })
      .then((canvas) => resolve(canvas.toDataURL('image/png')))

      .catch((error) => {
        reject(error);
      });
  });
}

function CardComponent({ recordId, insightId, sectionId, mode, hidden }) {
  const { state } = useAppState();
  const [cardFilters, setCardFilters] = useState();
  const [elements, setElements] = useState([]);
  const card = getCardByRecordIdAndInsightId(state, recordId, insightId);
  const { insight } = card;
  const templateId = card?.template_id;
  const message = insight?.message;

  useEffect(() => {
    dispatch(loadCardData(recordId, insightId));
  }, [recordId, insightId]);

  useEffect(() => {
    if (!card.elements) return;
    setElements(card.elements);
  }, [card.elements]);

  const copyCardUrlToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${COMPANY_INSIGHTS_PREFIX}/${recordId}/cards/${insightId}`
    );
  };

  const Icon = insightTypeToIcon(insightId);

  const printRef = React.useRef();

  function toPptxClickHandler() {
    const c_element = printRef.current;
    const element = c_element.getElementsByClassName('cardPptxWrapper').item(0);
    return createCanvasPromiseFromHTMLElement(element);
  }
  card.toPptxClickHandler = toPptxClickHandler;

  return hidden ? (
    <Box ref={printRef}>
      <CardTemplate
        elements={elements}
        templateId={templateId}
        optionalFilters={card.filters}
        cardFilters={cardFilters}
        setCardFilters={setCardFilters}
        insightId={insightId}
        recordId={recordId}
        hidden
      />
    </Box>
  ) : (
    <Card
      sx={{
        width: '100%',
        height: mode === 'normal' ? '100%' : 'fit-content',
        overflow: mode === 'normal' ? 'auto' : 'hidden'
      }}
      ref={printRef}
      id={sectionId}>
      <Box
        sx={{
          padding: { xs: '16px', md: '32px' },
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflow: 'auto'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '16px',
            alignItems: 'center'
          }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: { xs: '100%', md: 'fit-content' } }}>
            <Icon />
            <Stack direction="row" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
              <OpinionComponent insightId={insightId} recordId={recordId}></OpinionComponent>
              <CardActionsDividerIcon />
              <IconButtonComponent
                tag="Share"
                variant="secondary"
                onClick={copyCardUrlToClipboard}
                message="The card link was copied to your clipboard.">
                <ShareIcon />
              </IconButtonComponent>
            </Stack>
          </Stack>
          <InsightTextComponent
            text={message}
            type={mode === 'normal' ? null : insightId}
            mode={mode === 'normal' ? 'modal' : 'tab'}
            sx={{ flex: 1, textAlign: 'left' }}
          />
          <Stack sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'row' } }}>
            <OpinionComponent insightId={insightId} recordId={recordId}></OpinionComponent>
            <CardActionsDividerIcon />
            <IconButtonComponent
              tag="Share"
              variant="secondary"
              onClick={copyCardUrlToClipboard}
              message="The card link was copied to your clipboard.">
              <ShareIcon />
            </IconButtonComponent>
          </Stack>
        </Box>
        {elements?.length ? (
          <React.Fragment>
            <Divider sx={{ borderColor: 'greyColors.grey100' }} />
            {cardFilters && (
              <Chip
                label="Reset Filtering"
                variant="outlined"
                onClick={() => setCardFilters(null)}
                sx={{ width: '150px', float: 'right', borderRadius: 20 }}
              />
            )}
            <CardTemplate
              elements={elements}
              templateId={templateId}
              optionalFilters={card.filters}
              cardFilters={cardFilters}
              setCardFilters={setCardFilters}
              insightId={insightId}
              recordId={recordId}
            />
          </React.Fragment>
        ) : null}
      </Box>
    </Card>
  );
}

CardComponent.propTypes = {
  sectionId: PropTypes.string,
  sectionTitle: PropTypes.string,
  recordId: PropTypes.string.isRequired,
  insightId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['normal', 'stretch']).isRequired,
  hidden: PropTypes.bool
};

export default wrapWithError(CardComponent);
