import { ACTIONS } from 'constants/actions';

export function updateCardData(runId, insightId, card) {
  return { type: ACTIONS.ADD_RUN_CARD, runId, insightId, card };
}

export function loadCardData(runId, insightId) {
  return (_state, dispatch, apiClient) => {
    apiClient?.cards?.fetchCardData(runId, insightId)?.then((res) => {
      if (res.ok) {
        const card = res.data;
        dispatch(updateCardData(runId, insightId, card));
      }
    });
  };
}
