export function normilzeElements(elements) {
  if (!elements) return elements;

  return Object.keys(elements).reduce((acc, elementKey) => {
    const element = elements[elementKey];
    if (elementKey === 'LeadershipOverviewGroup') {
      const leadershipElement = { ...element };
      const columns = leadershipElement?.data?.columns;
      if (columns) {
        columns.forEach((column) => {
          if (
            column.id === 'notable_companies' ||
            column.id === 'notable_schools' ||
            column.id === 'investors_trust_data' ||
            column.id === 'proficiencies_data' ||
            column.id === 'competency_signals' ||
            column.id === 'longest_company' ||
            column.id === 'last_company' ||
            column.id === 'additional_institutions' ||
            column.id === 'sections'
          ) {
            column.values = column.values.map((value) => {
              try {
                return JSON.parse(value);
              } catch {
                return [];
              }
            });
          }
        });
      }
      acc[elementKey] = leadershipElement;
    } else {
      acc[elementKey] = element;
    }
    return acc;
  }, {});
}
