import ArtTopbarComponent from 'components/ArtTopbarComponent';
import { ROUTES_PATHS } from 'constants/app-routes';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ArtScreen from 'screens/ArtScreen';

function ArtRouter() {
  return (
    <Router>
      <ArtTopbarComponent />
      <Routes>
        <Route path={ROUTES_PATHS.MAIN_PATH} element={<ArtScreen />} />
        <Route path={ROUTES_PATHS.ART_PATH} element={<ArtScreen />} />
      </Routes>
    </Router>
  );
}

export default ArtRouter;
