import { updateNameInExploreList } from 'reducer/explore-slice';
import { getLastDiscoveryIdByListId } from 'selectors/discoveries';

import { loadExplore } from './load-explore-action';

export function changeDiscoveryNameAction(listId, newName, onError) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const discoveryId = getLastDiscoveryIdByListId(state, listId);
    apiClient.discoveries
      .changeDiscoveryName(discoveryId, newName)
      .then((res) => {
        if (res.ok) {
          dispatch(updateNameInExploreList({ listId, newName }));
        } else {
          onError?.('Failed to update list name');
          dispatch(loadExplore(listId, null, false));
        }
      })
      .catch(() => {
        onError?.('Failed to update list name');
        dispatch(loadExplore(listId, null, false));
      });
  };
}
