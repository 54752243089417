import {
  addCompanyAtIndex,
  deleteCompaniesFromListByIds,
  removeNotFoundCompanyFromList
} from 'reducer/explore-slice';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';

function deleteExploreCompaniesById(listId, companiesIdsToDelete) {
  return (dispatch) => {
    dispatch(deleteCompaniesFromListByIds({ listId, ids: companiesIdsToDelete }));
  };
}

function restoreDeletedCompany(listId, company, index) {
  return (dispatch) => {
    dispatch(addCompanyAtIndex({ listId, company, index }));
  };
}

export function removeNotFoundCompany(listId, companyName) {
  return (dispatch) => {
    dispatch(removeNotFoundCompanyFromList({ listId, companyName }));
  };
}

export function deleteCompaniesListAction(listId, companiesIdsToDeleteOrNull, onError) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    let companiesIdsToDelete = companiesIdsToDeleteOrNull;
    if (!companiesIdsToDeleteOrNull) {
      const selectedCompaniesIds = Object.keys(
        getExploreTableUICheckedCompaniesByListId(state, listId)
      );
      if (selectedCompaniesIds) {
        companiesIdsToDelete = selectedCompaniesIds
          .filter((company) => company)
          .map((id) => parseInt(id, 10));
      }
      dispatch(clearExploreTableUIState({ listId }));
    }
    const allCompanies = getCompanyMetasByListId(state, listId);
    const companiesIdsToDeleteSet = new Set(companiesIdsToDelete);
    const companiesForRestore = allCompanies.reduce((acc, company, index) => {
      if (companiesIdsToDeleteSet.has(company.company_list_meta_id)) {
        acc[company.company_list_meta_id] = { company, index };
      }
      return acc;
    }, {});
    dispatch(deleteExploreCompaniesById(listId, companiesIdsToDelete));
    companiesIdsToDelete.forEach((id) => {
      apiClient.companyListMetas
        .deleteCompany(id)
        .then((res) => {
          if (!res.ok) {
            onError?.(companiesForRestore[id].company.name);
            dispatch(
              restoreDeletedCompany(
                listId,
                companiesForRestore[id].company,
                companiesForRestore[id].index
              )
            );
          }
        })
        .catch(() => {
          onError?.(companiesForRestore[id].company.name);
          dispatch(
            restoreDeletedCompany(
              listId,
              companiesForRestore[id].company,
              companiesForRestore[id].index
            )
          );
        });
    });
  };
}
