import { get, httpDelete, post, put } from './base-client';

export default class BaseClient {
  constructor(token, setErrorConfig) {
    this.token = token;
    this.setErrorConfig = setErrorConfig;
  }

  get(path, contentType) {
    return get(path, this.token, this.setErrorConfig, contentType);
  }

  put(path, data) {
    return put(path, this.token, data || {}, this.setErrorConfig);
  }

  post(path, data, contentType, token = this.token) {
    return post(path, token, data || {}, this.setErrorConfig, contentType);
  }

  httpDelete(path) {
    return httpDelete(path, this.token, this.setErrorConfig);
  }
}
