import PropTypes from 'prop-types';
import React from 'react';

export default function LikeIcon({ selected, disabled }) {
  if (selected || disabled) {
    const color = disabled ? '#D6DCED' : '#579BFC';
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M4.97266 10.8447C4.97266 10.5686 5.19651 10.3447 5.47266 10.3447H8.85719V19.9032H5.47266C5.19651 19.9032 4.97266 19.6793 4.97266 19.4032V10.8447Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M16.8728 19.9032H8.82617V10.0155L11.9532 5.97131C12.1426 5.7264 12.4347 5.58301 12.7443 5.58301H13.9457C14.6818 5.58301 15.1656 6.35141 14.8475 7.0152L13.393 10.0499C13.2022 10.4482 13.4924 10.9092 13.9341 10.9092H18.865C19.6597 10.9092 20.2883 11.5818 20.2346 12.3746C20.1809 13.1695 20.0202 13.9535 19.7569 14.7054L18.2885 18.8989C18.0779 19.5004 17.5101 19.9032 16.8728 19.9032Z"
          stroke={color}
        />
      </svg>
    );
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.30176 10.1016C4.30176 9.82542 4.52562 9.60156 4.80176 9.60156H8.18629V19.16H4.80176C4.52562 19.16 4.30176 18.9362 4.30176 18.66V10.1016Z"
        stroke="#0B1C38"
      />
      <path
        d="M16.2019 19.16H8.15527V9.27232L11.2823 5.22815C11.4717 4.98324 11.7638 4.83984 12.0734 4.83984H13.2748C14.0109 4.83984 14.4947 5.60825 14.1766 6.27204L12.7221 9.30672C12.5313 9.705 12.8216 10.166 13.2632 10.166H18.1941C18.9888 10.166 19.6174 10.8386 19.5638 11.6315C19.51 12.4263 19.3493 13.2103 19.086 13.9623L17.6176 18.1558C17.407 18.7573 16.8392 19.16 16.2019 19.16Z"
        stroke="#0B1C38"
      />
    </svg>
  );
}

LikeIcon.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};
