import BaseClient from './BaseClient';

const URL_PREFIX = 'runs';

export default class RunsClient extends BaseClient {
  fetchAllRuns({ num = 50, all = false }) {
    return this.get(`${URL_PREFIX}?N=${num}&all=${all ? '1' : '0'}`);
  }

  getRunStatuses(runId) {
    return this.get(`${URL_PREFIX}/${runId}`);
  }

  submitRun(suggestion) {
    return this.post(URL_PREFIX, suggestion);
  }

  deleteRun(runId) {
    return this.httpDelete(`${URL_PREFIX}/${runId}`);
  }

  fetchOverviewData(runId) {
    return this.get(`${URL_PREFIX}/${runId}/overview`);
  }

  fetchSummary(runId) {
    return this.get(`${URL_PREFIX}/${runId}/summary`);
  }

  fetchRunProgress(runId) {
    return this.get(`${URL_PREFIX}/${runId}/progress`);
  }

  fetchIntermediate(runId, service) {
    return this.get(`${URL_PREFIX}/${runId}/${service}/intermediate`);
  }

  copyRunToUsers(runId, payload) {
    return this.post(`${URL_PREFIX}/${runId}/copy`, payload);
  }

  toPptxAll(runId, data) {
    return this.post(`${URL_PREFIX}/${runId}/to_pptx_all`, data);
  }

  fetchDynamicCards(runId) {
    return this.get(`${URL_PREFIX}/${runId}/dynamic_cards`);
  }

  updateRunExtraData(runId) {
    return this.put(`${URL_PREFIX}/${runId}/company_extra_data`);
  }

  addDynamicCard(runId, payload) {
    return this.post(`${URL_PREFIX}/${runId}/dynamic_card`, payload);
  }

  deleteDynamicCard(runId, cardId) {
    return this.httpDelete(`${URL_PREFIX}/${runId}/dynamic_card/${cardId}`);
  }
}
