import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { addPublicShareLink, updateUiSettingsAddingInProgress } from 'reducer/puclic-shares';

export function addNewPublicShareLinkAction(runId) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateUiSettingsAddingInProgress({ runId, inAddProgress: true }));
    apiClient.publicLinks
      .createPublicLink(runId)
      .then((res) => {
        if (res.ok && res.data) {
          const { public_link: publicLink } = res.data;

          dispatch(addPublicShareLink({ runId, publicShareLink: publicLink }));
          dispatch(
            sendUserEvent(USER_EVENTS.PUBLIC_LINK_SUBMITTED, {
              searchId: runId,
              publicLink
            })
          );
        }
      })
      .finally(() => {
        dispatch(updateUiSettingsAddingInProgress({ runId, inAddProgress: false }));
      });
  };
}
