import React from 'react';

export const NewListIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 6C9.22386 6 9 5.77614 9 5.5V4.5H7C6.17157 4.5 5.5 5.17157 5.5 6V18C5.5 18.8284 6.17157 19.5 7 19.5H10C10.2761 19.5 10.5 19.7239 10.5 20C10.5 20.2761 10.2761 20.5 10 20.5H7C5.61929 20.5 4.5 19.3807 4.5 18V6C4.5 4.61929 5.61929 3.5 7 3.5H9C9 3.22386 9.22386 3 9.5 3C9.77614 3 10 3.22386 10 3.5H14C14 3.22386 14.2239 3 14.5 3C14.7761 3 15 3.22386 15 3.5H17C18.3807 3.5 19.5 4.61929 19.5 6V11.5C19.5 11.7761 19.2761 12 19 12C18.7239 12 18.5 11.7761 18.5 11.5V6C18.5 5.17157 17.8284 4.5 17 4.5H15V5.5C15 5.77614 14.7761 6 14.5 6C14.2239 6 14 5.77614 14 5.5V4.5H10V5.5C10 5.77614 9.77614 6 9.5 6ZM15.5 9C15.7761 9 16 8.77614 16 8.5C16 8.22386 15.7761 8 15.5 8H8.5C8.22386 8 8 8.22386 8 8.5C8 8.77614 8.22386 9 8.5 9H15.5ZM16 11.5C16 11.7761 15.7761 12 15.5 12H8.5C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H15.5C15.7761 11 16 11.2239 16 11.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0489 14.4747L18.1094 13.4142L19.5236 14.8284L13.6801 20.6719L11.5588 21.379L12.2659 19.2577L16.3418 15.1818L17.7559 16.5959L18.463 15.8888L17.0489 14.4747ZM20.5842 14.4749L18.4629 12.3536C18.2677 12.1583 17.9511 12.1583 17.7558 12.3536L11.4753 18.6341C11.4204 18.6889 11.3791 18.7559 11.3545 18.8295L10.2939 22.0115C10.1636 22.4024 10.5355 22.7742 10.9263 22.6439L14.1083 21.5833C14.1819 21.5587 14.2489 21.5174 14.3038 21.4625L20.5842 15.182C20.7795 14.9867 20.7795 14.6701 20.5842 14.4749Z"
        fill="currentColor"
      />
    </svg>
  );
};
