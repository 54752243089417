import { loadAllOrganizations } from './load-all-organizations-action';

export function makeNewOrganizationAction(payload, onError, onSuccess) {
  const { name, logoUrl } = payload;
  return (_state, dispatch, apiClient) => {
    apiClient.organizations
      .submitOrganization({ name, logo_url: logoUrl })
      .then((res) => {
        if (res.ok && res.data) {
          onSuccess?.('Organization was created successfully!');
          dispatch(loadAllOrganizations());
        } else {
          onError?.(res?.message || res?.data?.message || 'Failed to create organization');
        }
      })
      .catch((res) => {
        onError?.(res?.message || res?.data?.message || 'Failed to create organization');
      });
  };
}
