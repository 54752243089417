import { Box, Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import CloseIcon from 'icons/CloseIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';

export function ErrorDialog({ onClose, text }) {
  return (
    <Stack
      border={1}
      borderRadius={1}
      borderColor="indicator.negative"
      padding="16px"
      alignItems="flex-start"
      direction="row"
      gap="8px"
      sx={{
        position: 'fixed',
        width: '400px',
        bottom: '5%',
        right: '24px',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0px 0px 2px rgba(15, 35, 69, 0.6), 2px 3px 3px rgba(15, 35, 69, 0.15)'
      }}>
      <Box>
        <ToastIcons type={'error'} />
      </Box>
      <Stack>
        <Typography variant="paragraph" textAlign="left">
          {text}
        </Typography>
      </Stack>
      <IconButtonComponent onClick={onClose}>
        <CloseIcon></CloseIcon>
      </IconButtonComponent>
    </Stack>
  );
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func,
  text: PropTypes.string
};
