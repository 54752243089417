import { ACTIONS } from 'constants/actions';

export function updateAllAdminCompanyLists(lists) {
  return { type: ACTIONS.UPDATE_ALL_ADMIN_COMPANY_LISTS, lists };
}

export function loadAllAdminListsAction() {
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists.getAllAdminCompaniesLists().then((res) => {
      if (res.ok && res.data) {
        dispatch(updateAllAdminCompanyLists(res.data.company_lists));
      }
    });
  };
}
