import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export function MultipleChoiceComponent({ title, labels, onChange, all }) {
  const [checkedStatusSet, setCheckedStatusSet] = useState(all ? new Set(labels) : new Set());

  const handleSingleChange = (event, index) => {
    const newCheckStatus = new Set(checkedStatusSet);
    if (event.target.checked) {
      newCheckStatus.add(labels[index]);
    } else {
      newCheckStatus.delete(labels[index]);
    }
    setCheckedStatusSet(newCheckStatus);
    onChange?.(Array.from(newCheckStatus));
  };

  const handleAllChange = (event) => {
    let newCheckedStatusSet = null;
    if (event.target.checked) {
      newCheckedStatusSet = new Set(labels);
    } else {
      newCheckedStatusSet = new Set();
    }
    setCheckedStatusSet(newCheckedStatusSet);
    onChange?.(Array.from(newCheckedStatusSet));
  };
  return (
    <Stack direction="column">
      <Typography variant="paragraphBold" color="secondary.secondary6">
        {title}
      </Typography>
      {labels.map((label, index) => (
        <FormControlLabel
          key={`${label}-${index}`}
          label={
            <Typography variant="paragraph" color="greyColors.grey300">
              {label}
            </Typography>
          }
          control={
            <Checkbox
              checked={checkedStatusSet.has(labels[index])}
              onChange={(event) => handleSingleChange(event, index)}
              sx={{
                '&.Mui-checked': {
                  color: 'secondary.secondary6'
                }
              }}
            />
          }
        />
      ))}
      {all && (
        <FormControlLabel
          label={<Typography variant="paragraph">all</Typography>}
          control={
            <Checkbox
              checked={checkedStatusSet.size === labels.length}
              indeterminate={checkedStatusSet.size > 0 && checkedStatusSet.size < labels.length}
              onChange={handleAllChange}
              sx={{
                '&.Mui-checked': {
                  color: 'secondary.secondary6'
                }
              }}
            />
          }
        />
      )}
    </Stack>
  );
}

MultipleChoiceComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  onChange: PropTypes.func,
  all: PropTypes.bool
};
