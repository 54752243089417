import { Avatar, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { stringToCodeInt, toTitleCase } from 'utils/string-utils';

function CustomAvatar({ name, pictureUrl, org, size, noTooltip }) {
  const displayName = name ? toTitleCase(name) : '?';
  if (noTooltip) {
    return (
      <Avatar
        alt={displayName}
        src={pictureUrl}
        variant={org ? 'rounded' : 'round'}
        sx={{
          backgroundColor: `graphColors.graph${((stringToCodeInt(displayName) + 3) % 12) + 1}`,
          width: size,
          height: size,
          fontSize: size ? size / 2 + 2 : null
        }}>
        {pictureUrl ? null : displayName.charAt(0)}
      </Avatar>
    );
  }
  return (
    <Tooltip title={name} placement="top">
      <Avatar
        alt={displayName}
        src={pictureUrl}
        variant={org ? 'rounded' : 'round'}
        sx={{
          backgroundColor: `graphColors.graph${((stringToCodeInt(displayName) + 3) % 12) + 1}`,
          width: size,
          height: size,
          fontSize: size ? size / 2 + 2 : null
        }}>
        {pictureUrl ? null : displayName.charAt(0)}
      </Avatar>
    </Tooltip>
  );
}

CustomAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  org: PropTypes.bool,
  size: PropTypes.number,
  noTooltip: PropTypes.bool
};

export default CustomAvatar;
