import { TOAST_TYPES } from 'constants/toasts';

import { addUserData } from './add-user-data-action';

export function changeUsernameAction(username, addToast) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .changeUsername(username)
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(addUserData(res.data));
          addToast('Username updated', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Error changing username', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Error changing username', TOAST_TYPES.ERROR);
      });
  };
}
