import { TOAST_TYPES } from 'constants/toasts';
import { deleteTeam } from 'reducer/teams-slice';

export function deleteTeamAction(teamId, addToast) {
  return (dispatch, _getState, apiClient) => {
    apiClient.teams
      .deleteTeam(teamId)
      .then((res) => {
        if (res.ok) {
          dispatch(deleteTeam({ teamId }));
          addToast('Team deleted', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to delete team', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to delete team', TOAST_TYPES.ERROR);
      });
  };
}
