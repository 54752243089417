import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PersonItemComponent from 'components/PersonItemComponent';
import PropTypes from 'prop-types';

export default function LeadershipOverviewComponent({
  columns,
  numberOfPeopleToShow,
  onClick,
  isMinified
}) {
  if (columns.length < 1) {
    return <Typography variant="h3">No table data found.</Typography>;
  }
  const people = columns[0].values.map((__, index) =>
    columns.reduce((obj, col) => ({ ...obj, [col.id]: col.values[index] }), { id: index })
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={5}>
        {people.slice(0, numberOfPeopleToShow).map((person, index) => {
          return (
            <Grid item key={index} xs={12} md={isMinified ? 4 : 3}>
              <PersonItemComponent person={person} onClick={onClick} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

LeadershipOverviewComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  numberOfPeopleToShow: PropTypes.number,
  onClick: PropTypes.func,
  isMinified: PropTypes.bool
};
