import { TOAST_TYPES } from 'constants/toasts';

import { addUserData } from './add-user-data-action';

export function changePictureAction(picture, addToast) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .changePicture(picture)
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(addUserData(res.data));
          addToast('Picture updated', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Error changing picture', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Error changing picture', TOAST_TYPES.ERROR);
      });
  };
}
