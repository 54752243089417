import { setIsListMonitoredAction } from 'actions/company_lists/set-is-monitored-action';
import { loadExplore } from 'actions/explore/load-explore-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { FREQUENCY_TO_DAYS, SCHEDULE_FREQUENCIES, SCHEDULE_TYPE } from 'constants/schedules';
import { USER_EVENTS } from 'constants/userEvents';

import { getSchedulesAction } from './get-schedules-action';

export function getScheduleDates(jobs, frequency) {
  return Array(jobs.length)
    .fill(1)
    .map(() => {
      const start_date = new Date();
      // 3am - 5am UTC time
      const startHour = 3;
      const timeframe = 2;

      const hour = Math.floor(startHour + Math.random() * timeframe);
      const min = Math.floor(0 + Math.random() * 60);
      const sec = Math.floor(0 + Math.random() * 60);

      start_date.setUTCHours(hour, min, sec);
      start_date.setDate(start_date.getDate() + FREQUENCY_TO_DAYS[frequency]);

      return start_date;
    })
    .sort();
}

export function createSchedulesByRecordsAction(listId, record_ids, frequency) {
  return async (dispatch, getState, apiClient) => {
    if (record_ids?.length === 0) {
      return;
    }
    dispatch(
      sendUserEvent(USER_EVENTS.SUBMIT_SCHEDULES, {
        listId,
        record_ids,
        frequency,
        type: SCHEDULE_TYPE.DISCOVERY
      })
    );
    dispatch(setIsListMonitoredAction(listId, true));

    const dates = getScheduleDates(record_ids, frequency);

    const sortedRecords = Array.from(record_ids).sort();

    const payloads = [];
    for (let index = 0; index < record_ids.length; index++) {
      const record_id = sortedRecords[index];
      const body = {
        company_list_id: listId,
        record_id: record_id,
        frequency: frequency,
        start: dates[index],
        monitoring_type: SCHEDULE_TYPE.DISCOVERY
      };
      if (frequency === SCHEDULE_FREQUENCIES.MONTHLY) {
        const end = new Date(dates[index]);
        end.setMonth(end.getMonth() + 3);
        body.end = end;
      } else if (frequency === SCHEDULE_FREQUENCIES.DAILY) {
        const end = new Date(dates[index]);
        end.setDate(end.getDate() + 14);
        body.end = end;
      }
      payloads.push(body);
    }

    const schedulesPromises = payloads.map((body) => {
      return new Promise((resolve) => {
        apiClient.schedules
          .createSchedule(body)
          .then(() => {
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    });

    await Promise.all(schedulesPromises);
    dispatch(loadExplore(listId, null, false));
    dispatch(getSchedulesAction());
  };
}
