import { Button, Stack, Typography } from '@mui/material';
import { toggleUserActive } from 'actions/users/toggle-user-active-action';
import { updateUserIsLimitedAction } from 'actions/users/update-user-is-limited-action';
import { updateUserIsReadOnlyAction } from 'actions/users/update-user-is-read-only-action';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useState } from 'react';

import AdminTabOrgLogo from './AdminTabOrgLogo';
import { CreateNewUserDialog } from './CreateNewUserDialog';
import DeleteUserDialog from './DeleteUserDialog';
import UpdateUserOrganizationDialog from './UpdateUserOrganizationDialog';
import { ViewUser } from './ViewUser';

function UsersTab({ users, hidden, isLoading, setIsLoading, filter }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [viewUserId, setViewUserId] = useState(null);
  const [viewUserEmail, setViewUserEmail] = useState(null);
  const [openUserId, setOpenUserId] = useState(-1);
  const [orgOpenUserId, setOrgOpenUserId] = useState(-1);

  const toggleActive = (userId) => {
    setIsLoading(true);
    dispatch(toggleUserActive(userId));
    setIsLoading(false);
  };

  const handleClickOpen = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenUserId(userId);
  };
  const handleOrgClickOpen = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    setOrgOpenUserId(userId);
  };

  const handleClose = () => {
    setOpenUserId(-1);
    setOrgOpenUserId(-1);
  };

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        <Stack direction="row" gap="16px" paddingBottom="8px">
          <Button onClick={() => setOpenDialog(true)} variant="outlined">
            Create new user
          </Button>
          {openDialog && (
            <CreateNewUserDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}></CreateNewUserDialog>
          )}
        </Stack>
        <ViewUser
          userId={viewUserId}
          setUserId={setViewUserId}
          userEmail={viewUserEmail}
          isAdmin={true}
          users={users}></ViewUser>
        {Object.keys(users)
          .filter((key) => {
            return filter ? filter(users, key) : true;
          })
          .sort((key1, key2) => new Date(users[key2].signDate) - new Date(users[key1].signDate))
          .map((key) => {
            const user = users[key];
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => {
                    setViewUserId(user.id);
                    setViewUserEmail(user.email);
                  }}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {user?.id ? user.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={user.organizationLogoUrl} />
                <Typography width="240px" variant="paragraph">
                  {user.email}
                </Typography>

                {user.isAdmin || user.isDev ? (
                  <Button size="small" disabled={true} sx={{ margin: 'auto', width: '100px' }}>
                    {user?.isAdmin && 'ADMIN'}
                    {user.isAdmin && user.isDev && ', '}
                    {user?.isDev && 'DEV'}
                  </Button>
                ) : (
                  <>
                    <Button
                      size="small"
                      onClick={(event) => handleOrgClickOpen(event, user.id)}
                      disabled={isLoading}
                      color="warning"
                      variant="outlined"
                      sx={{ marginLeft: 'auto', width: '100px' }}>
                      Change Org
                    </Button>
                    <Button
                      size="small"
                      onClick={() => toggleActive(key)}
                      disabled={isLoading}
                      color={user?.isActive ? 'error' : 'success'}
                      variant="contained"
                      sx={{ marginLeft: 'auto', width: '100px' }}>
                      {user?.isActive ? 'Deactivate' : 'Activate'}
                    </Button>
                    <Button
                      size="small"
                      onClick={(event) => handleClickOpen(event, user.id)}
                      disabled={isLoading}
                      color={'error'}
                      variant="contained"
                      sx={{ marginLeft: 'auto', width: '100px' }}>
                      Delete User
                    </Button>
                    <UpdateUserOrganizationDialog
                      open={orgOpenUserId === user.id}
                      user={user}
                      onClose={handleClose}
                    />
                    <DeleteUserDialog
                      open={openUserId === user.id}
                      user={user}
                      onClose={handleClose}
                    />
                  </>
                )}
                <Stack direction="row" alignItems="center" gap="4px">
                  <Button
                    onClick={() => dispatch(updateUserIsLimitedAction(user.id, !user.isLimited))}
                    size="small"
                    sx={{ marginLeft: 'auto', width: '120px' }}
                    variant={user.isLimited ? 'contained' : 'outlined'}>
                    {user.isLimited ? 'Make Regular' : 'Make Limited'}
                  </Button>
                  <Button
                    onClick={() => dispatch(updateUserIsReadOnlyAction(user.id, !user.isBasic))}
                    size="small"
                    sx={{ marginLeft: 'auto', width: '140px' }}
                    variant={user.isBasic ? 'contained' : 'outlined'}>
                    {user.isBasic ? 'Make Read Write' : 'Make Read Only'}
                  </Button>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default UsersTab;

UsersTab.propTypes = {
  users: PropTypes.object,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  filter: PropTypes.func
};
