import { loadExplore } from 'actions/explore/load-explore-action';
import { loadAllFoldersAction } from 'actions/folders/load-all-folders-action';
import { loadAllPersons } from 'actions/persons/load-all-persons-action';
import { loadAllRuns } from 'actions/runs/load-all-runs-action';
import { RESOURCE_TYPES, RESOURCE_TYPE_TO_RESOURCE_ID_TYPE } from 'constants/resources';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';

export function updateResourcePermissionsAction(
  id,
  resourceType,
  user_ids,
  organization_ids,
  team_ids,
  addToast
) {
  const resourceIdType = RESOURCE_TYPE_TO_RESOURCE_ID_TYPE[resourceType];
  return (dispatch, _getState, apiClient) => {
    apiClient.permissions
      .updatePermissionsByResourceId(id, resourceIdType, { user_ids, organization_ids, team_ids })
      .then((res) => {
        if (res.ok) {
          switch (resourceType) {
            case RESOURCE_TYPES.LIST:
              dispatch(loadExplore(id, null, false));
              break;
            case RESOURCE_TYPES.DEEP_DIVE:
              oldDispatch(loadAllRuns());
              break;
            case RESOURCE_TYPES.PERSON:
              oldDispatch(loadAllPersons());
              break;
            case RESOURCE_TYPES.FOLDER:
              dispatch(loadAllFoldersAction());
              break;
            default:
              break;
          }
          addToast('Successfully updated permissions', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to update permissions!', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to update permissions!', TOAST_TYPES.ERROR);
      });
  };
}
