import { AvatarGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import CustomAvatar from './tile/CustomAvatar';

function CustomAvatarGroup({ entities, size, max }) {
  return (
    <AvatarGroup
      max={max}
      sx={{
        '& .MuiAvatar-root': { width: size, height: size, fontSize: size / 2 + 2 }
      }}>
      {entities.map((entity, index) => (
        <CustomAvatar
          key={index}
          name={entity.name}
          pictureUrl={entity?.picture}
          org={entity.org}
        />
      ))}
    </AvatarGroup>
  );
}

CustomAvatarGroup.propTypes = {
  entities: PropTypes.array,
  size: PropTypes.number,
  max: PropTypes.number
};

CustomAvatarGroup.defaultProps = {
  size: 24,
  max: 4
};

export default CustomAvatarGroup;
