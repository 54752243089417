import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Button, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { dismissNotificationAction } from 'actions/notifications/dismiss-notification-action';
import { loadUserNotificationsAction } from 'actions/notifications/load-user-notifications-action';
import { ROUTES_PATHS } from 'constants/app-routes';
import ExploreIcon from 'icons/ExploreIcon';
import SettingsIcon from 'icons/SettingsIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllExploreLists } from 'selectors/explore';
import { getAllNotifications } from 'selectors/notifications';
import { getObjectLength } from 'utils/objects-utils';
import { formatTimestampRelative } from 'utils/time-utils';

import IconButtonComponent from './IconButtonComponent';

function NotificationsComponent() {
  const notifications = useSelector(getAllNotifications);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const explores = useSelector(getAllExploreLists);

  const newNotifications = useMemo(() => {
    return (
      notifications &&
      Object.values(notifications).filter((notification) => !notification.dismissed)
    );
  }, [notifications]);

  const notificationToListName = (notification) => {
    return explores?.[notification.company_list_id]?.name;
  };

  useEffect(() => {
    dispatch(loadUserNotificationsAction());
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dismissAll = () => {
    dispatch(
      dismissNotificationAction(
        Object.values(newNotifications).map((notification) => notification.id),
        true
      )
    );
    handleClose();
  };

  return (
    <div>
      <IconButtonComponent sx={{ color: 'white' }} onClick={handleClick}>
        <Badge badgeContent={getObjectLength(newNotifications)} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButtonComponent>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack direction="row" alignItems="center">
          <Button
            onClick={() => {
              navigate(ROUTES_PATHS.MONITORINGS_PATH);
              handleClose();
            }}
            startIcon={<SettingsIcon />}
            sx={{ padding: '8px 16px' }}>
            Manage Monitors
          </Button>
          <Button onClick={dismissAll} sx={{ marginLeft: 'auto', paddingRight: '16px' }}>
            Dismiss All
          </Button>
        </Stack>
        {notifications &&
          Object.values(notifications)
            .sort((n1, n2) => new Date(n2.time_submitted) - new Date(n1.time_submitted))
            .map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  handleClose();
                  navigate(notification?.redirect);
                }}
                sx={{
                  width: '350px',
                  backgroundColor: notification.dismissed ? 'auto' : 'background.bg100'
                }}>
                <ListItemIcon>
                  <ExploreIcon />
                </ListItemIcon>
                <Stack width="100%">
                  <Stack direction="row" width="100%">
                    {notification.notification_type === 'MONITORING' ? (
                      <Typography
                        variant="paragraphBold"
                        noWrap={false}
                        sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                        {notificationToListName(notification)}
                      </Typography>
                    ) : (
                      <Typography
                        variant="paragraph"
                        noWrap={false}
                        sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                        {notification?.msg}
                      </Typography>
                    )}
                    <Typography
                      variant="paragraph"
                      noWrap={false}
                      fontSize={12}
                      marginLeft="auto"
                      sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {formatTimestampRelative(notification.time_submitted)}
                    </Typography>
                  </Stack>
                  {notification.count_discovered && (
                    <Typography
                      variant="paragraph"
                      noWrap={false}
                      sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {`${notification.count_discovered} new findings for iteration #${notification.run_iteration}`}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
}

NotificationsComponent.propTypes = {};

export default NotificationsComponent;
