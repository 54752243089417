import { Avatar, Button, Link, Menu, Stack, Typography } from '@mui/material';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { appTheme } from 'theme';
import { formatTimestampRelative } from 'utils/time-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderLeftLeadership({ value, color }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = value?.value?.left_leaderships;

  const finalValue = useMemo(() => {
    return data?.length;
  }, [data]);

  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (!finalValue) return null;

  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          gap="8px"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Avatar sx={{ width: '20px', height: '20px' }} />
          <Typography variant="paragraph" color={color} noWrap display="block">
            {finalValue}
          </Typography>
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PaperProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}
        MenuListProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}>
        <Stack
          sx={{
            backgroundColor: 'secondary.secondary1',
            color: 'white',
            padding: '4px'
          }}>
          {data &&
            Object.values(data).map((leader, index) => (
              <Stack
                key={index}
                borderBottom={data?.length > 1 ? '1px solid' : null}
                borderColor="greyColors.grey300">
                <Stack direction="row" gap="8px" padding="8px" alignItems="center">
                  <Avatar sx={{ width: 20, height: 20 }} />
                  <Typography variant="paragraphSemiBold" color="white">
                    {leader.full_name}
                  </Typography>
                  {leader.linkedin_url && (
                    <Link
                      minHeight={0}
                      href={leader.linkedin_url}
                      underline="none"
                      target="_blank"
                      rel="noopener"
                      color="white"
                      padding="2px">
                      <GoToIcon />
                    </Link>
                  )}
                </Stack>
                <Typography variant="paragraph" color="white" padding="8px">
                  <strong>{formatTimestampRelative(leader.leave_date)}</strong>: left position{' '}
                  <strong>{leader.past_title_in_target}</strong> to become{' '}
                  <strong>{leader.current_title}</strong> at{' '}
                  <strong>{leader.current_company}</strong>
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Menu>
    </Stack>
  );
}

RenderLeftLeadership.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderLeftLeadership.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
