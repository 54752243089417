import { Button, Stack } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import ViewButton from 'components/ViewButton';
import TableColumnsFilterComponent from 'components/elements/table/filters/TableColumnsFilterComponent';
import TableScreeningFiltersComponent from 'components/elements/table/filters/TableScreeningFiltersComponent';
import ListMonitoringDialog from 'components/monitoring/ListMonitoringDialog';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import DataEnrichmentIcon from 'icons/DataEnrichmentIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getUserIsLimited } from 'selectors/user';
import { isEmpty } from 'utils/objects-utils';

import ColumnsPlatterDialog from './filters/columns/ColumnsPlatterDialog';

function TableComponentToolbar({
  listId,
  viewId,
  csvOptions,
  allRows,
  filteredRowsNum,
  onNewList
}) {
  const [openColumnsPlatter, setOpenColumnsPlatter] = useState(false);
  const isUserTrial = useOldStateSelector(getUserIsLimited);

  return (
    <GridToolbarContainer sx={{ marginBottom: '16px' }}>
      <Stack sx={{ width: '100%' }} gap="4px">
        <Stack direction="row" alignItems="center" gap="8px">
          {listId && (
            <Stack direction="row" alignItems="center" gap="8px">
              <TableScreeningFiltersComponent
                listId={listId}
                viewId={viewId}
                allRows={allRows}
                filteredRowsNum={filteredRowsNum}
                onNewList={onNewList}
              />
              <TableColumnsFilterComponent listId={listId} viewId={viewId} />
            </Stack>
          )}
          <GridToolbarExport
            csvOptions={csvOptions}
            onClick={() => {
              dispatch(
                sendExploreEvent(USER_EVENTS.LIST_PRESS_EXPORT, listId, 'Exported list to CSV', {
                  listId
                })
              );
            }}
          />
          <ViewButton listId={listId} viewId={viewId} toolTipPlacement="right" />
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="16px"
            width="fit-content"
            alignItems="center"
            marginLeft="auto">
            {!isUserTrial && (
              <ListMonitoringDialog companyListId={listId} isListEmpty={isEmpty(allRows)} />
            )}
            {listId && (
              <Button
                variant="contained"
                startIcon={<DataEnrichmentIcon />}
                onClick={() => {
                  setOpenColumnsPlatter(true);
                }}>
                Add Columns
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
      {listId && (
        <ColumnsPlatterDialog
          open={openColumnsPlatter}
          onClose={() => setOpenColumnsPlatter(false)}
          companyListId={listId}
        />
      )}
    </GridToolbarContainer>
  );
}

TableComponentToolbar.propTypes = {
  listId: PropTypes.number,
  viewId: PropTypes.string,
  onToBenchmark: PropTypes.func,
  onAddNewRow: PropTypes.func,
  onDeepDive: PropTypes.func,
  csvOptions: PropTypes.object,
  numSelected: PropTypes.number,
  isEmpty: PropTypes.bool,
  noRunsLeft: PropTypes.bool,
  haveSelectedCompanies: PropTypes.bool,
  allRows: PropTypes.array,
  filteredRowsNum: PropTypes.number,
  onNewList: PropTypes.func
};

export default React.memo(TableComponentToolbar);
