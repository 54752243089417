import React from 'react';

export default function TechTeamIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M6.40281 32.9596H6.38281C6.23281 32.9496 6.11281 32.8196 6.13281 32.6696L6.30281 30.1696C6.30281 30.0196 6.42281 29.9096 6.59281 29.9196C6.74281 29.9296 6.86281 30.0596 6.84281 30.2096L6.67281 32.7096C6.67281 32.8496 6.54281 32.9696 6.40281 32.9696V32.9596Z"
        fill="#374EF4"
      />
      <path
        d="M9.08281 30.6296H9.06281L6.56281 30.4596C6.41281 30.4496 6.29281 30.3196 6.31281 30.1696C6.31281 30.0196 6.43281 29.9096 6.60281 29.9196L9.10281 30.0896C9.25281 30.0996 9.37281 30.2296 9.35281 30.3796C9.35281 30.5196 9.22281 30.6396 9.08281 30.6396V30.6296Z"
        fill="#374EF4"
      />
      <path
        d="M20.5928 37.5796C19.3228 37.5796 18.0528 37.4396 16.7828 37.1596C12.8228 36.2796 9.27281 34.0496 6.77281 30.8496C6.68281 30.7296 6.70282 30.5596 6.82281 30.4596C6.94281 30.3696 7.11281 30.3896 7.21281 30.5096C9.63281 33.5996 13.0728 35.7696 16.9028 36.6196C21.3228 37.5996 25.8628 36.7896 29.6828 34.3596C33.5028 31.9196 36.1428 28.1496 37.1128 23.7296C37.8028 20.6196 37.6028 17.3996 36.5428 14.4196C36.4928 14.2796 36.5628 14.1196 36.7128 14.0696C36.8528 14.0196 37.0128 14.0896 37.0628 14.2396C38.1528 17.3196 38.3628 20.6496 37.6528 23.8596C36.6428 28.4196 33.9228 32.3196 29.9728 34.8396C27.1228 36.6596 23.8928 37.5896 20.5928 37.5896V37.5796Z"
        fill="#374EF4"
      />
      <path
        d="M33.8928 10.0796H33.8728C33.7228 10.0696 33.6028 9.93957 33.6228 9.78957L33.7928 7.28957C33.8028 7.13957 33.9128 7.03957 34.0828 7.03957C34.2328 7.04957 34.3528 7.17957 34.3328 7.32957L34.1628 9.82957C34.1628 9.96957 34.0328 10.0896 33.8928 10.0896V10.0796Z"
        fill="#374EF4"
      />
      <path
        d="M33.8928 10.0796H33.8728L31.3728 9.89957C31.2228 9.88957 31.1028 9.75957 31.1228 9.60957C31.1228 9.45957 31.2428 9.34957 31.4128 9.35957L33.9128 9.52957C34.0628 9.53957 34.1828 9.66957 34.1628 9.81957C34.1628 9.95957 34.0328 10.0796 33.8928 10.0796Z"
        fill="#374EF4"
      />
      <path
        d="M3.66281 25.9396C3.55281 25.9396 3.44281 25.8696 3.40281 25.7596C2.31281 22.6796 2.10281 19.3496 2.81281 16.1396C3.82281 11.5796 6.54281 7.67957 10.4928 5.15957C14.4328 2.64957 19.1228 1.81957 23.6828 2.82957C27.6428 3.70957 31.1928 5.94957 33.6928 9.13957C33.7828 9.25957 33.7628 9.42957 33.6428 9.52957C33.5228 9.61957 33.3528 9.59957 33.2528 9.47957C30.8328 6.38957 27.3928 4.21957 23.5628 3.36957C19.1428 2.38957 14.6028 3.19957 10.7828 5.62957C6.96281 8.06957 4.32281 11.8396 3.35281 16.2596C2.66281 19.3696 2.86281 22.5896 3.92281 25.5696C3.97281 25.7096 3.90281 25.8696 3.75281 25.9196C3.72281 25.9296 3.69281 25.9396 3.66281 25.9396Z"
        fill="#374EF4"
      />
      <path
        d="M12.5628 12.9395C13.8128 12.9395 14.8228 13.9295 14.8228 15.1795C14.8228 16.4195 13.8228 17.4295 12.5828 17.4395C11.3528 17.4495 10.3228 16.4395 10.3228 15.1995C10.3228 13.9595 11.3228 12.9495 12.5628 12.9395Z"
        fill="#9A2FC5"
      />
      <path
        d="M19.3128 26.6595C19.3128 25.4595 19.3528 24.6695 19.3028 23.4795C19.2328 21.9195 18.5328 20.4195 17.2528 19.5195C15.9728 18.6195 14.4828 18.5695 13.0528 18.6595C12.3828 18.6995 11.7228 18.8495 11.1228 19.1495C9.31277 20.0495 8.39277 21.5295 8.31277 23.5195C8.27277 24.5495 8.29277 25.1895 8.31277 26.2195C8.31277 26.4395 8.49277 26.6195 8.72277 26.6195L19.3328 26.6595H19.3128Z"
        fill="#9A2FC5"
      />
      <path
        d="M27.2328 12.9395C28.4828 12.9395 29.4928 13.9295 29.4928 15.1795C29.4928 16.4195 28.4928 17.4295 27.2528 17.4395C26.0228 17.4495 24.9928 16.4395 24.9928 15.1995C24.9928 13.9595 25.9928 12.9495 27.2328 12.9395Z"
        fill="#374EF4"
      />
      <path
        d="M32.6228 26.0295C32.6228 25.0495 32.6528 24.3395 32.6028 23.2595C32.5328 21.7195 31.8128 20.2295 30.5428 19.3495C29.2728 18.4695 27.7828 18.4095 26.3528 18.4895C25.6828 18.5295 25.0228 18.6795 24.4228 18.9795C22.6128 19.8795 21.6928 21.3595 21.6128 23.3495C21.5728 24.3795 21.5928 24.9595 21.6128 25.9995C21.6128 26.2195 21.7928 26.3995 22.0228 26.3995L32.2228 26.4395C32.4528 26.4395 32.6328 26.2595 32.6328 26.0295H32.6228Z"
        fill="#374EF4"
      />
      <path
        d="M27.7428 26.2196C27.7428 25.1696 27.7728 24.4196 27.7228 23.2696C27.6528 21.6296 26.9328 20.0396 25.6628 19.0996C24.3928 18.1596 22.9028 18.0896 21.4728 18.1896C20.8028 18.2296 20.1428 18.3896 19.5428 18.7096C17.7328 19.6696 16.8128 21.2496 16.7328 23.3696C16.6928 24.4696 16.7128 25.0896 16.7328 26.1896C16.7328 26.4296 16.9128 26.6196 17.1428 26.6196L27.3428 26.6596C27.5728 26.6596 27.7528 26.4596 27.7528 26.2196H27.7428Z"
        fill="white"
      />
      <path
        d="M26.5228 27.3095H14.0828C13.8428 27.3095 13.6528 27.1295 13.6528 26.8995V23.2295C13.6528 19.9295 16.4928 17.2595 19.9828 17.2595H20.6228C24.2128 17.2595 26.9028 20.1395 26.9528 23.4395C26.9728 24.5895 26.9528 25.7495 26.9528 26.8995C26.9528 27.1195 26.7628 27.3095 26.5228 27.3095Z"
        fill="#8EBCFC"
      />
      <path
        d="M26.5228 27.5795H14.0828C13.6928 27.5795 13.3728 27.2695 13.3728 26.8995V23.2295C13.3728 19.7895 16.3328 16.9895 19.9828 16.9895H20.6228C24.2128 16.9895 27.1728 19.8795 27.2328 23.4395C27.2428 24.2095 27.2328 24.9795 27.2328 25.7495V26.8995C27.2328 27.2795 26.9128 27.5795 26.5228 27.5795ZM19.9828 17.5295C16.6428 17.5295 13.9228 20.0795 13.9228 23.2195V26.8895C13.9228 26.9595 13.9928 27.0195 14.0828 27.0195H26.5128C26.5928 27.0195 26.6728 26.9595 26.6728 26.8895V25.7295C26.6728 24.9595 26.6828 24.1995 26.6728 23.4295C26.6228 20.1695 23.9128 17.5195 20.6128 17.5195H19.9728L19.9828 17.5295Z"
        fill="#8EBCFC"
      />
      <path
        d="M25.7328 27.9794L13.1228 27.9194C12.8928 27.9194 12.7028 27.7394 12.7028 27.5094V23.8394C12.7028 22.3994 13.7128 20.7194 14.7128 19.6994C16.0028 18.3894 17.5328 17.9494 19.4328 17.8594H20.0428C23.4828 17.8594 26.1028 20.8094 26.1528 24.0994C26.1728 25.2494 26.1528 26.4094 26.1528 27.5594C26.1528 27.7894 25.9628 27.9694 25.7328 27.9694V27.9794Z"
        fill="white"
      />
      <path
        d="M23.6728 12.0495C23.6728 14.1295 21.9828 15.8195 19.9128 15.8195C17.8328 15.8195 16.1528 14.1295 16.1528 12.0495C16.1528 9.97952 17.8328 8.29952 19.9028 8.29952C21.9928 8.29952 23.6728 9.96952 23.6728 12.0495Z"
        fill="white"
      />
      <path
        d="M19.9128 16.0895C18.8328 16.0895 17.8228 15.6695 17.0528 14.8995C16.2828 14.1295 15.8728 13.1195 15.8728 12.0395C15.8728 9.81952 17.6828 8.01952 19.9028 8.00952C20.9828 8.00952 21.9928 8.42952 22.7528 9.17952C23.5128 9.93952 23.9428 10.9495 23.9428 12.0295C23.9428 13.1095 23.5228 14.1195 22.7628 14.8895C22.0028 15.6595 20.9828 16.0795 19.9028 16.0795L19.9128 16.0895ZM19.9128 8.56952C17.9928 8.56952 16.4328 10.1295 16.4328 12.0495C16.4328 12.9795 16.7928 13.8595 17.4528 14.5195C18.1128 15.1795 18.9928 15.5495 19.9228 15.5495C20.8528 15.5495 21.7328 15.1895 22.3928 14.5295C23.0528 13.8695 23.4128 12.9895 23.4128 12.0595C23.4128 11.1295 23.0528 10.2495 22.3928 9.59952C21.7328 8.94952 20.8628 8.58952 19.9228 8.58952L19.9128 8.56952Z"
        fill="#374EF4"
      />
      <path
        d="M25.4128 28.0494C25.4128 26.8994 25.4328 25.7394 25.4128 24.5894C25.3628 21.2994 22.8328 18.4094 19.4428 18.4094H18.8428C15.5428 18.4094 12.8728 21.0794 12.8728 24.3794V28.0494H25.4128Z"
        fill="white"
      />
      <path
        d="M25.4128 28.3294H12.8828C12.7328 28.3294 12.6028 28.2094 12.6028 28.0494V24.3794C12.6028 20.9394 15.4028 18.1394 18.8428 18.1394H19.4428C22.8328 18.1394 25.6328 21.0294 25.6828 24.5894C25.6828 25.3594 25.6828 26.1294 25.6828 26.8994V28.0494C25.6828 28.1994 25.5528 28.3294 25.4028 28.3294H25.4128ZM13.1528 27.7794H25.1328V26.8994C25.1328 26.1294 25.1328 25.3694 25.1328 24.5994C25.0828 21.3394 22.5328 18.6894 19.4428 18.6894H18.8428C15.7028 18.6894 13.1528 21.2394 13.1528 24.3794V27.7794Z"
        fill="#374EF4"
      />
    </svg>
  );
}
