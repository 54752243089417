import { Stack } from '@mui/material';
import LoaderComponent from 'components/LoaderComponent';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getFinalPredictions,
  getPredictionComponents,
  getSimilarArtworks,
  getTargetArtwork
} from 'selectors/art';

import ArtBarChart from './ArtBarChart';
import ArtScatter from './ArtScatter';
import TargetArtwork from './TargetArtwork';

function PredictPanel({ isPredictLoading, onPredict }) {
  const finalPredictions = useSelector(getFinalPredictions);
  const targetArtwork = useSelector(getTargetArtwork);
  const similarArtworks = useSelector(getSimilarArtworks);
  const predictionComponents = useSelector(getPredictionComponents);

  const [showPrediction, setShowPrediction] = React.useState(false);
  if (isPredictLoading) {
    return <LoaderComponent />;
  }

  if (!targetArtwork || !finalPredictions) {
    return null;
  }

  const onRePredict = (removedPeers) => {
    const localTargetArtwork = { ...targetArtwork };
    delete localTargetArtwork?.transactions;
    onPredict(
      -1,
      localTargetArtwork,
      similarArtworks.filter((artwork, index) => !removedPeers[index])
    );
  };
  return (
    <Stack>
      <Stack direction="column" gap="16px">
        <Stack>
          <TargetArtwork artwork={targetArtwork} />
        </Stack>
        <Stack>
          <ArtScatter
            finalPredictions={finalPredictions}
            similarArtworks={similarArtworks}
            onRePredict={onRePredict}
            showPrediction={showPrediction}
            setShowPrediction={setShowPrediction}
          />
        </Stack>
        {showPrediction && (
          <Stack>
            <ArtScatter predictionComponents={predictionComponents} />
          </Stack>
        )}
        <Stack>
          <ArtBarChart finalPredictions={finalPredictions} />
        </Stack>
      </Stack>
    </Stack>
  );
}

PredictPanel.propTypes = {
  isPredictLoading: PropTypes.bool,
  onPredict: PropTypes.func
};

export default PredictPanel;
