import { getAuth0Token } from './client-api';

const HTTP_METHOD = Object.freeze({
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE'
});

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

async function request(path, method, _token, data, setErrorConfig, contentType = 'json') {
  const fullUrl = `${BASE_URL}/${path}`;
  const headers = { 'Content-Type': 'application/json' };

  const newToken = await getAuth0Token();
  if (newToken) {
    headers.Authorization = `Bearer ${newToken}`;
  }

  if (contentType === 'files') {
    delete headers['Content-Type'];
  }

  const options = { method, headers };
  if (data) {
    if (contentType === 'files') {
      options.body = data;
    } else {
      options.body = JSON.stringify(data);
    }
  }

  if (contentType === 'files' && method === HTTP_METHOD.GET) {
    return fetch(fullUrl, options).then((res) => res.blob());
  }

  return fetch(fullUrl, options).then((res) =>
    res.json().then((body) => {
      if (setErrorConfig && res.status !== 200) {
        setErrorConfig({ status: res.status, statusText: res.statusText, message: body.message });
      }
      return { ok: res.ok, status: res.status, statusText: res.statusText, data: body };
    })
  );
}

export function get(path, token, setErrorConfig, contentType) {
  return request(path, HTTP_METHOD.GET, token, null, setErrorConfig, contentType);
}

export function put(path, token, data, setErrorConfig) {
  return request(path, HTTP_METHOD.PUT, token, data, setErrorConfig);
}

export function post(path, token, data, setErrorConfig, contentType) {
  return request(path, HTTP_METHOD.POST, token, data, setErrorConfig, contentType);
}

export function httpDelete(path, token, setErrorConfig) {
  return request(path, HTTP_METHOD.DELETE, token, setErrorConfig);
}
