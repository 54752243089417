import PropTypes from 'prop-types';
import React from 'react';

import RenderStr from './RenderStr';

export default function RenderDescription({ value, color, variant }) {
  return <RenderStr value={value} color={color} variant={variant} />;
}

RenderDescription.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderDescription.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
