import PropTypes from 'prop-types';

export default function ArrowIcon({ rotation, color }) {
  const style = rotation ? { transform: `rotate(${rotation}deg)` } : null;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5956 15.3584L17.6562 14.2977L13.0602 9.70166L13.0603 9.70154L11.9996 8.64088L11.9995 8.641L11.9994 8.64088L10.9387 9.70154L10.9389 9.70166L6.34277 14.2977L7.40343 15.3584L11.9995 10.7623L16.5956 15.3584Z"
        fill={color || '#0F2345'}
      />
    </svg>
  );
}

ArrowIcon.propTypes = {
  rotation: PropTypes.number,
  color: PropTypes.string
};
