import { updateColumnsHistoryInList } from 'reducer/explore-slice';

export function getCompanyListColumnHistoryAction(company_list_id, data_type) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companyExtraData
      .getCompanyListColumnHistory(company_list_id, data_type)
      .then((res) => {
        if (res.ok && res.data) {
          const { companies_history } = res.data;
          dispatch(
            updateColumnsHistoryInList({ listId: company_list_id, data_type, companies_history })
          );
        }
      });
  };
}
