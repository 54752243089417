import { ClickAwayListener, TextField } from '@mui/material';
import { updateDiscoveryCustomColumnValueAction } from 'actions/explore/update-discovery-custom-column-value-action';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export function RenderCustomNumber({ value: payload }) {
  const [number, setNumber] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (payload?.value) {
      setNumber(parseFloat(payload?.value));
    }
  }, [payload?.value]);
  const onValueChange = (event) => {
    setNumber(event.target.value);
  };

  const updateColumn = () => {
    if (!number && !payload?.value) {
      return;
    }
    if (number !== payload?.value) {
      dispatch(
        updateDiscoveryCustomColumnValueAction(
          payload?.listId,
          payload?.companyListMetaId,
          payload?.columnId,
          number
        )
      );
    }
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      updateColumn();
    }
    event.stopPropagation();
  };
  return (
    <ClickAwayListener onClickAway={updateColumn}>
      <TextField
        size="medium"
        fullWidth
        onChange={onValueChange}
        onKeyDown={onDownKey}
        value={number}
        variant="standard"
        sx={{ borderRadius: '4px' }}
        InputProps={{
          type: 'number',
          disableUnderline: true,
          inputProps: {
            style: { textAlign: 'center' }
          }
        }}
      />
    </ClickAwayListener>
  );
}

RenderCustomNumber.propTypes = {
  value: PropTypes.number
};
