import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable id-length */
function StackedBarTooltipContent({ label, maxWidth, children }) {
  return (
    <Stack
      direction="column"
      gap="8px"
      sx={{
        padding: '10px',
        textAlign: 'left',
        maxWidth,
        color: 'greyColors.grey100',
        borderRadius: '2px',
        backgroundColor: 'secondary.secondary1'
      }}>
      {label && (
        <Typography color="white" variant="paragraphMedium">
          {label}
        </Typography>
      )}
      <Typography variant="paragraph">{children}</Typography>
    </Stack>
  );
}

StackedBarTooltipContent.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.number
};

export default StackedBarTooltipContent;
