import { translateOrganizationForState } from 'actions/runs/load-all-runs-action';
import { ACTIONS } from 'constants/actions';
import { RESEARCH_TYPES } from 'constants/researches';

import { loadPersonCard } from './load-person-card-action';
import { monitorPersonProgressAction } from './monitor-person-progress-action';

function updateResourceLoaded() {
  return { type: ACTIONS.UPDATE_RESOURCE_LOADED, resourceType: RESEARCH_TYPES.LEADERSHIP.id };
}

function updatePersonsData(data) {
  return { type: ACTIONS.ADD_PERSONS_DATA, data };
}

export function translatePersonRecordForState(personRecord) {
  return {
    id: personRecord.id,
    recordId: personRecord.id,
    name: personRecord.name,
    statuses: personRecord.statuses,
    timeSubmitted: personRecord.time_submitted,
    parameters: personRecord.parameters,
    progress: personRecord.progress,
    ready: personRecord.ready,
    card: personRecord.card,
    owner: personRecord?.user?.email,
    ownerId: personRecord?.user?.id,
    currentCompanyLogo: personRecord?.parameters?.current_company_logo_url,
    currentCompanyName: personRecord?.parameters?.current_company_name,
    currentRole: personRecord?.parameters?.role,
    linkedinUrl: personRecord?.parameters?.linkedin_url,
    imageUrl: personRecord?.parameters?.person_image_url,
    facebookUrl: personRecord?.parameters?.facebook_url,
    twitterUrl: personRecord?.parameters?.twitter_url,
    user: personRecord?.user,
    collaboratorsUsers: personRecord.collaborators_users,
    collaboratorsOrgs: personRecord.collaborators_orgs,
    collaboratorsTeams: personRecord.collaborators_teams,
    ...translateOrganizationForState(personRecord?.user?.organization)
  };
}

export function loadAllPersons(parameters = { num: 50, all: false }) {
  return (_state, dispatch, apiClient) => {
    apiClient.persons.fetchAllPersons(parameters).then((res) => {
      if (res.ok && res.data) {
        const { records: personRecords } = res.data;
        const personsData = personRecords.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translatePersonRecordForState(data) };
        }, {});
        dispatch(updatePersonsData(personsData));
        personRecords.forEach((record) => {
          if (record.ready) {
            if (!parameters.all) {
              dispatch(loadPersonCard(record.id));
            }
          } else {
            dispatch(monitorPersonProgressAction(record.id));
          }
        });
      }
      dispatch(updateResourceLoaded());
    });
  };
}
