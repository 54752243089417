import { updateAllExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { replaceNotFoundCompanyInList } from 'reducer/explore-slice';
import {
  removeExploreCompaniesLoading,
  updateExploreCompaniesLoading
} from 'reducer/explore-table-ui';

import { sendAmbiguousWebsiteEvent } from './add-companies-to-list-action';

export function replaceCompanyInListAction(listId, oldName, newName) {
  const loadingName = [oldName];
  return (dispatch, _getState, apiClient) => {
    dispatch(updateExploreCompaniesLoading({ names: [loadingName], listId }));

    apiClient.companiesLists
      .addCompanies(listId, [newName])
      .then((res) => {
        if (res.ok && res.data) {
          const { companies_added, ambiguous_website_list_mata_ids } = res.data;
          dispatch(removeExploreCompaniesLoading({ names: [loadingName], listId }));
          if (companies_added?.length) {
            dispatch(
              replaceNotFoundCompanyInList({
                oldName,
                companyMeta: companies_added[0],
                listId
              })
            );
            dispatch(updateAllExtraDataForCompaniesInListAction(listId));
          }

          if (ambiguous_website_list_mata_ids?.length) {
            dispatch(sendAmbiguousWebsiteEvent(companies_added, ambiguous_website_list_mata_ids));
          }
        }
      })
      .catch(() => {
        dispatch(removeExploreCompaniesLoading({ names: [loadingName], listId }));
      });
  };
}
