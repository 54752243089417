import PropTypes from 'prop-types';

import RenderMultipleLogos from './RenderMultipleLogos';
import TableRowLoading from './TableRowLoading';

export default function RenderLeaders({ value }) {
  if (!value) return null;
  if (value?.loading) {
    return <TableRowLoading />;
  }

  const data = value
    ?.filter((leader) => leader)
    ?.map((leader) => {
      return {
        logo_url: leader.person_image_url,
        text: `${leader.full_name}, ${leader.role}`,
        link_url: leader.linkedin_url || leader.facebook_url || leader.twitter_url,
        show_also_if_empty: true
      };
    });

  return <RenderMultipleLogos value={data} />;
}

RenderLeaders.propTypes = {
  value: PropTypes.array
};
