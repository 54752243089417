import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { RESOURCE_TYPES } from 'constants/resources';
import { TILE_TYPE } from 'constants/tiles';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDiscoveryReadyById, getDiscoveryTimestampById } from 'selectors/discoveries';
import { getPersonReadyById, getPersonTimestampById } from 'selectors/persons';
import { getRunSearchStatusById, getRunTimestampById } from 'selectors/runs';
import { getUserHasRunsLeft } from 'selectors/user';
import { appTheme } from 'theme';
import { checkIsOld, formatTimestamp, formatTimestampRelative } from 'utils/time-utils';

import RerunButton from '../RerunButton';
import SharedStack from './SharedStack';

function RerunStack({ type, id, isOnHover, companyListId, showRerun, folderId }) {
  const { state } = useAppState();
  const userHasRunsLeft = getUserHasRunsLeft(state);
  const isRerunable = type === TILE_TYPE.PERSON || type === TILE_TYPE.RUN;
  const isDiscovery = type === TILE_TYPE.DISCOVERY;
  const isDiscoveryReady = useSelector((reduxState) => {
    if (!isDiscovery) return false;
    return getDiscoveryReadyById(reduxState, id);
  });
  const discoveryTimestamp = useSelector((reduxState) => {
    if (!isDiscovery) return null;
    return getDiscoveryTimestampById(reduxState, id);
  });

  const determineStatus = () => {
    switch (type) {
      case TILE_TYPE.RUN:
        return getRunSearchStatusById(state, id);
      case TILE_TYPE.PERSON:
        return getPersonReadyById(state, id) ? 'ready' : 'running';
      case TILE_TYPE.DISCOVERY:
        return isDiscoveryReady ? 'ready' : 'running';
      default:
        return null;
    }
  };

  const determineTimestamp = () => {
    switch (type) {
      case TILE_TYPE.RUN:
        return getRunTimestampById(state, id);
      case TILE_TYPE.PERSON:
        return getPersonTimestampById(state, id);
      case TILE_TYPE.DISCOVERY:
        return discoveryTimestamp;
      default:
        return null;
    }
  };

  const [timestampIsOnHover, setTimestampIsOnHover] = useState(false);
  const status = determineStatus();
  const timestamp = determineTimestamp();
  const isOld = checkIsOld(timestamp);

  const determineVisibility = () => {
    if (isOld) {
      return 'visible';
    }
    if (isOnHover) {
      return 'visible';
    }
    return 'hidden';
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={isOld ? '8px' : '0px'}
      sx={{ width: '100%' }}>
      {isRerunable && userHasRunsLeft && showRerun && (
        <Box onClick={(event) => event.stopPropagation()} paddingX="16px">
          <RerunButton
            id={id}
            type={type}
            sx={{ padding: 0, visibility: determineVisibility() }}
            status={status}
            color={appTheme.palette.primary.primary3_50}
            variant="secondary"
            companyListId={companyListId}
          />
        </Box>
      )}
      {type === TILE_TYPE.PERSON && (
        <SharedStack searchId={id} folderId={folderId} resourceType={RESOURCE_TYPES.PERSON} />
      )}
      {type === TILE_TYPE.RUN && (
        <SharedStack searchId={id} folderId={folderId} resourceType={RESOURCE_TYPES.DEEP_DIVE} />
      )}
      {type === TILE_TYPE.DISCOVERY && (
        <SharedStack id={companyListId} folderId={folderId} resourceType={RESOURCE_TYPES.LIST} />
      )}
      {timestamp && (
        <Tooltip title={formatTimestamp(timestamp)} open={timestampIsOnHover}>
          <Typography
            onMouseEnter={() => setTimestampIsOnHover(true)}
            onMouseLeave={() => setTimestampIsOnHover(false)}
            variant="paragraphMedium"
            sx={{
              color: 'greyColors.grey300',
              padding: '6px 8px 6px 8px',
              borderRadius: '2px',
              backgroundColor: isOld ? 'background.bg100' : null
            }}>
            {formatTimestampRelative(timestamp)}
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );
}

RerunStack.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf([...Object.values(TILE_TYPE), 'new_suggestions', 'existing_suggestions']),
  isOnHover: PropTypes.bool,
  companyListId: PropTypes.number,
  showRerun: PropTypes.bool,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

RerunStack.defaultProps = {
  companyListId: null,
  showRerun: true
};

export default wrapWithError(RerunStack);
