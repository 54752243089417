import { Box } from '@mui/material';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import InsightsGroupComponent from 'components/InsightsGroupComponent';
import CardComponent from 'components/card/CardComponent';
import PeopleCardComponent from 'components/card/people/PeopleCardComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { insightTypeToTitle } from 'utils/insights-utils';
import { groupSort, groupToColor, groupToTitle } from 'utils/overviewGroup-utils';

import DeepDiveDynamicCardsGroupComponent from './deep-dive/DeepDiveDynamicCardsGroupComponent';

function OverviewComponent({
  recordId,
  insightsbyGroups,
  elementsByGroups,
  isMinified,
  dynamicCards
}) {
  const [open, setOpen] = useState(false);
  const [insightId, setInsightId] = useState();
  const [insightTitle, setInsightTitle] = useState();
  const [groupTitle, setGroupTitle] = useState();
  const [isPerson, setIsPerson] = useState();

  const showModalCard = (insight, title, person) => {
    setInsightId(insight);
    setGroupTitle(groupToTitle(title));
    setInsightTitle(insightTypeToTitle(insight));
    setIsPerson(person);
    setOpen(true);
    if (person) {
      dispatch(
        sendUserEvent(USER_EVENTS.DEEP_DIVE_CARD_OPEN, {
          insightId: 'LeadershipInsight',
          searchId: recordId,
          person: insight
        })
      );
    } else {
      dispatch(
        sendUserEvent(USER_EVENTS.DEEP_DIVE_CARD_OPEN, {
          insightId: insight,
          searchId: recordId
        })
      );
    }
  };

  const closeModal = () => setOpen(false);
  return (
    <Box id="overview-component" sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {dynamicCards.length > 0 && (
        <DeepDiveDynamicCardsGroupComponent deepDiveId={recordId} cards={dynamicCards} />
      )}
      {Object.keys({ ...insightsbyGroups, ...elementsByGroups })
        .filter((groupType) => {
          const points = insightsbyGroups?.[groupType];
          const element = elementsByGroups?.[groupType];
          return (points ? points.length > 0 : false) || element?.data;
        })
        .sort(groupSort)
        .map((groupType) => {
          return (
            <InsightsGroupComponent
              key={groupType}
              recordId={recordId}
              groupType={groupType}
              title={groupToTitle(groupType)}
              color={groupToColor(groupType)}
              points={insightsbyGroups?.[groupType]}
              element={elementsByGroups?.[groupType]}
              onOpenInsight={(insight) =>
                showModalCard(insight, groupType, groupType === 'LeadershipOverviewGroup')
              }
              isMinified={isMinified}
            />
          );
        })}
      <ModalComponent open={open} onClose={closeModal} title={`${groupTitle}: ${insightTitle}`}>
        {insightId &&
          (isPerson ? (
            <PeopleCardComponent recordId={recordId} insightId={insightId} mode="normal" />
          ) : (
            <CardComponent recordId={recordId} insightId={insightId} mode="normal" />
          ))}
      </ModalComponent>
    </Box>
  );
}

OverviewComponent.propTypes = {
  recordId: PropTypes.string,
  insightsbyGroups: PropTypes.object,
  elementsByGroups: PropTypes.object,
  isMinified: PropTypes.bool,
  dynamicCards: PropTypes.array
};
OverviewComponent.defaultProps = {
  dynamicCards: []
};

export default OverviewComponent;
