/* eslint-disable prefer-named-capture-group */
import { Typography } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import PropTypes from 'prop-types';

function markdownToText(markdown) {
  // Remove some of markdown formatting
  const text = markdown
    // Remove unordered lists
    .replace(/(?:-|\*|\+)\s(.*?)(?:\n|$)/u, '$1\n')
    // Remove ordered lists
    .replace(/\d+\.\s(.*?)(?:\n|$)/u, '$1\n')
    // Remove links
    .replace(/\[(.*?)\]\(.*?\)/u, '$1')
    // Remove inline code
    .replace(/`([^`]+)`/u, '$1');

  return text.trim();
}

/* eslint-disable id-length */
function CustomMarkdownCell({ body }) {
  return (
    <MuiMarkdown
      overrides={{
        h1: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        h2: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        h3: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        h4: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        h5: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        h6: {
          component: Typography,
          props: {
            variant: 'paragraphBold'
          }
        },
        p: {
          component: Typography,
          props: {
            variant: 'paragraph'
          }
        },
        a: {
          component: Typography,
          props: {
            variant: 'paragraphSemiBold',
            color: 'primary.primary50'
          }
        }
      }}>
      {markdownToText(body)}
    </MuiMarkdown>
  );
}

CustomMarkdownCell.propTypes = {
  body: PropTypes.string
};

export default CustomMarkdownCell;
