import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateClientId } from 'reducer/general-slice';
import { v4 as uuidv4 } from 'uuid';

export function ClientIdProvider({ children }) {
  const newClientId = uuidv4();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateClientId({ clientId: newClientId }));
  }, [dispatch, newClientId]);

  return <React.Fragment>{children}</React.Fragment>;
}

ClientIdProvider.propTypes = {
  children: PropTypes.any
};
