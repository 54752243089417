import React from 'react';

export default function ExtendIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H17C17.5523 5 18 5.44772 18 6V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V6C6 5.44772 6.44772 5 7 5ZM5 6C5 4.89543 5.89543 4 7 4H17C18.1046 4 19 4.89543 19 6V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V6ZM16 7.5C16 7.77614 15.7761 8 15.5 8H8.5C8.22386 8 8 7.77614 8 7.5C8 7.22386 8.22386 7 8.5 7H15.5C15.7761 7 16 7.22386 16 7.5ZM16 10.5C16 10.7761 15.7761 11 15.5 11H11.5C11.2239 11 11 10.7761 11 10.5C11 10.2239 11.2239 10 11.5 10H15.5C15.7761 10 16 10.2239 16 10.5ZM15.5 14C15.7761 14 16 13.7761 16 13.5C16 13.2239 15.7761 13 15.5 13H11.5C11.2239 13 11 13.2239 11 13.5C11 13.7761 11.2239 14 11.5 14H15.5ZM16 16.5C16 16.7761 15.7761 17 15.5 17H11.5C11.2239 17 11 16.7761 11 16.5C11 16.2239 11.2239 16 11.5 16H15.5C15.7761 16 16 16.2239 16 16.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.5C10 10.7761 9.77614 11 9.5 11H8.5C8.22386 11 8 10.7761 8 10.5C8 10.2239 8.22386 10 8.5 10H9.5C9.77614 10 10 10.2239 10 10.5ZM10 13.5C10 13.7761 9.77614 14 9.5 14H8.5C8.22386 14 8 13.7761 8 13.5C8 13.2239 8.22386 13 8.5 13H9.5C9.77614 13 10 13.2239 10 13.5ZM9.5 17C9.77614 17 10 16.7761 10 16.5C10 16.2239 9.77614 16 9.5 16H8.5C8.22386 16 8 16.2239 8 16.5C8 16.7761 8.22386 17 8.5 17H9.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
