import { Button, Stack, Switch, Typography } from '@mui/material';
import { loadTeamsAction } from 'actions/teams/load-teams-action';
import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import TeamCard from 'components/teams/TeamCard';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeams } from 'selectors/teams';
import { getUserOrganizationId, getUserOrganizationUsers } from 'selectors/user';
import { v4 as uuidv4 } from 'uuid';

export default function TeamScreen() {
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const [showMine, setShowMine] = useState(false);
  const [newTeams, setNewTeams] = useState([]);
  const teams = useSelector(getTeams);
  const canTeamManagement = Boolean(useOldStateSelector(getUserOrganizationId));
  const orgUsers = getUserOrganizationUsers(state);
  const teamNames = Object.values(teams).map((team) => team.name);
  const filter = (team) => {
    if (showMine) {
      return team.member;
    }
    return true;
  };

  const addNewTeam = () => {
    const newId = uuidv4();
    setNewTeams((prev) => [
      ...prev,
      {
        id: newId,
        name: `New Team ${prev.length + 2 + (Object.values(teams)?.length || 0)}`,
        users: []
      }
    ]);
  };

  const removeNewTeam = (id) => {
    setNewTeams((prev) => prev.filter((team) => team.id !== id));
  };

  useEffect(() => {
    reduxDispatch(loadTeamsAction());
    dispatch(loadUserOrganizationUsersAction());
  }, [reduxDispatch]);

  if (!canTeamManagement) {
    return (
      <Stack
        sx={{
          padding: '32px',
          minHeight: 'calc(100vh - 50px)',
          backgroundColor: 'background.bg100'
        }}
        gap="32px"
        alignItems="center">
        <Typography variant="h1">Teams</Typography>
        <Typography variant="paragraph" color="greyColors.grey300">
          This page is in development.
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack
      sx={{
        padding: '32px',
        minHeight: 'calc(100vh - 50px)',
        backgroundColor: 'background.bg100'
      }}
      gap="32px"
      alignItems="center">
      <Typography variant="h1">Teams</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="16px">
        <Typography variant="paragraph" color="greyColors.grey300">
          Only show my teams
        </Typography>
        <Switch
          checked={showMine}
          onChange={() => {
            setShowMine(!showMine);
          }}
        />
      </Stack>
      <Stack gap="16px" sx={{ width: '500px' }}>
        {teams &&
          Object.values(teams)
            .filter(filter)
            .map((team) => (
              <TeamCard key={team.id} team={team} orgUsers={orgUsers} teamNames={teamNames} />
            ))}
        {newTeams &&
          newTeams.map((team) => (
            <TeamCard
              key={team.id}
              team={team}
              orgUsers={orgUsers}
              teamNames={teamNames}
              onCancel={() => removeNewTeam(team.id)}
            />
          ))}
        <Button
          gap="16px"
          alignItems="center"
          justifyContent="center"
          onClick={addNewTeam}
          sx={{
            border: '1px dashed',
            borderRadius: '4px',
            paddingX: '16px',
            paddingY: '8px',
            height: '32px',
            borderColor: 'greyColors.grey200'
          }}>
          <Typography variant="paragraph" color="greyColors.grey300">
            Create New Team
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
