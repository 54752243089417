import { Button, Stack, Typography } from '@mui/material';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import DislikeIcon from 'icons/DislikeIcon';
import LikeIcon from 'icons/LikeIcon';
import PropTypes from 'prop-types';
import { React } from 'react';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import TextTooltip from '../TextTooltip';

const OpinionValue = Object.freeze({
  LIKE: 1,
  NEUTRAL: 0,
  DISLIKE: -1
});

function OpinionsTab({ opinions, hidden, filter }) {
  const navigate = useNavigate();

  const onClickOpinion = (search, insight) => {
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${search}/cards/${insight}`);
  };
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="5px" sx={{ textAlign: 'left' }}>
        {Object.keys(opinions)
          .filter((key) => {
            return filter ? filter(opinions, key) : true;
          })
          .sort(
            (key1, key2) =>
              new Date(opinions[key2].time_submitted).getTime() -
              new Date(opinions[key1].time_submitted).getTime()
          )
          .map((key) => {
            const opinion = opinions[key];
            if (opinion?.id === -1) return null;
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="left"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickOpinion(opinion.record_id, opinion.insight_id)}
                  variant="contained"
                  sx={{
                    width: '60px'
                  }}>
                  <Typography
                    variant="paragraph"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap">
                    {opinion?.id ? opinion.id : key}
                  </Typography>
                </Button>
                {opinion?.value === OpinionValue.DISLIKE ? (
                  <DislikeIcon selected={true} />
                ) : (
                  <LikeIcon selected={opinion?.value === OpinionValue.LIKE} />
                )}
                <Typography variant="paragraph">
                  {opinion?.time_submitted ? formatTimestamp(opinion.time_submitted) : 'NA'}
                </Typography>
                <TextTooltip text={opinion?.company} width="100px"></TextTooltip>
                <TextTooltip text={opinion?.insight_id} width="150px"></TextTooltip>
                <TextTooltip
                  text={opinion?.comment ? opinion.comment : 'no comment yet'}
                  width="300px"></TextTooltip>
                <Typography variant="paragraph" sx={{ marginLeft: 'auto' }}>
                  {opinion.user_email}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default OpinionsTab;

OpinionsTab.propTypes = {
  opinions: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func
};
