import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import InsightTextComponent from 'components/InsightTextComponent';
import ElementComponent from 'components/elements/ElementComponent';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { appTheme } from 'theme';

const TEMPLATE_MAPPINGS = Object.freeze({
  OneColumn: [12],
  TwoColumn: [6, 6],
  ThreeColumn: [4, 4, 4],
  TwoOneColumn: [6, 6, 12],
  TwoColumnLeft: [8, 4],
  TwoColumnRight: [4, 8],
  ThreeOneColumn: [4, 4, 4, 12]
});

const useDivider = (template, index) => {
  const aggregate = template.slice(0, index + 1).reduce((agg, cur) => agg + cur, 0);
  return aggregate % 12 !== 0;
};

const DIVIDER_PLACEMENT = {};

const NO_DIVIDER_ELEMENTS = ['EventTimelineElement'];

const NO_TITLE_ELEMENTS = ['TeamChartElement'];

Object.keys(TEMPLATE_MAPPINGS).forEach((templateId) => {
  DIVIDER_PLACEMENT[templateId] = TEMPLATE_MAPPINGS[templateId].map((col, index) =>
    useDivider(TEMPLATE_MAPPINGS[templateId], index)
  );
});

export function CardTemplate({
  templateId,
  elements,
  optionalFilters,
  cardFilters,
  setCardFilters,
  insightId,
  recordId
}) {
  const { tabPath } = useParams();
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const widths = TEMPLATE_MAPPINGS[templateId] || [12];
  const dividers = DIVIDER_PLACEMENT[templateId] || [false];
  const actualElements = useMemo(() => elements?.filter((element) => element), [elements]);
  const sx = tabPath === 'allCards' ? { width: 1450, height: 700 } : {};

  const shouldHaveSubHeaderMinHeight = useMemo(() => {
    if (templateId !== 'TwoOneColumn' && templateId !== 'ThreeOneColumn') return false;
    if (actualElements?.filter((element) => element.insight?.message)?.length <= 0) return false;
    return true;
  }, [templateId, actualElements]);

  function innerElement() {
    return (
      <div className="card-inner" id={`card-inner-${insightId}`}>
        <Grid container spacing={4} sx={sx}>
          {actualElements &&
            actualElements.map((element, index) => (
              <React.Fragment key={`fragment-${index}`}>
                <Grid
                  item
                  key={`grid-item-${index}`}
                  xs={12}
                  md={widths[index % widths.length]}
                  sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      flex: 1,
                      width: '100%',
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0'
                    }}>
                    {!NO_TITLE_ELEMENTS.includes(element.element_type) && (
                      <React.Fragment>
                        <Typography variant="paragraphBold" color="secondary.secondary6">
                          {element.data.name}
                        </Typography>
                        <InsightTextComponent
                          text={element.insight?.message}
                          sx={{
                            minHeight:
                              !shouldHaveSubHeaderMinHeight || index === actualElements?.length - 1
                                ? 'inherit'
                                : '40px'
                          }}
                        />
                      </React.Fragment>
                    )}
                    <ElementComponent
                      element={element}
                      showTitle={false}
                      mode="card"
                      templateId={templateId}
                      optionalFilters={optionalFilters}
                      cardFilters={cardFilters}
                      setCardFilters={setCardFilters}
                      recordId={recordId}
                    />
                  </Box>

                  {dividers[index % dividers.length] &&
                    !NO_DIVIDER_ELEMENTS.includes(element.element_type) && (
                      <Divider
                        key={`divider-${index}`}
                        orientation="vertical"
                        variant="middle"
                        sx={{
                          display: mediumViewport ? 'initial' : 'none',
                          borderColor: 'greyColors.grey100'
                        }}
                        flexItem
                      />
                    )}
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </div>
    );
  }

  const card = innerElement();

  return card;
}

CardTemplate.propTypes = {
  templateId: PropTypes.string,
  elements: PropTypes.array,
  optionalFilters: PropTypes.array,
  cardFilters: PropTypes.array,
  setCardFilters: PropTypes.func,
  hidden: PropTypes.bool,
  insightId: PropTypes.string
};
