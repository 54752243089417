import React from 'react';

export default function FinanceStatusIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none">
      <path
        d="M29.0428 2.47095H9.72277C9.37277 2.47095 9.09277 2.75095 9.09277 3.10095V32.0709H33.0628C33.4128 32.0709 33.6928 31.7909 33.6928 31.4409V7.12095C33.6928 6.87095 33.5928 6.63095 33.4128 6.45095L29.7128 2.75095C29.5328 2.57095 29.2928 2.47095 29.0428 2.47095Z"
        fill="#8EBCFC"
      />
      <path
        d="M27.5427 3.55102H7.99268V33.151H32.5927V8.60102C32.5927 8.40102 32.5127 8.21102 32.3727 8.07102L28.0627 3.76102C27.9227 3.62102 27.7327 3.54102 27.5327 3.54102L27.5427 3.55102Z"
        fill="white"
      />
      <path
        d="M26.7428 34.091H30.8428C31.1928 34.091 31.4728 33.811 31.4728 33.461V9.50096H27.1028C26.7528 9.50096 26.4728 9.22096 26.4728 8.87096V4.50096H7.49277C7.14277 4.50096 6.86277 4.78096 6.86277 5.13096V33.471C6.86277 33.821 7.14277 34.101 7.49277 34.101H11.5928"
        fill="white"
      />
      <path
        d="M30.8428 34.371H26.7428C26.5928 34.371 26.4628 34.251 26.4628 34.091C26.4628 33.931 26.5828 33.811 26.7428 33.811H30.8428C31.0328 33.811 31.1928 33.651 31.1928 33.461V9.78096H27.0928C26.5928 9.78096 26.1928 9.37096 26.1928 8.88096V4.77096H7.49277C7.30277 4.77096 7.14277 4.93096 7.14277 5.12096V33.461C7.14277 33.651 7.30277 33.811 7.49277 33.811H11.5928C11.7428 33.811 11.8728 33.931 11.8728 34.091C11.8728 34.251 11.7528 34.371 11.5928 34.371H7.49277C6.99277 34.371 6.59277 33.961 6.59277 33.471V5.13096C6.59277 4.63096 6.99277 4.23096 7.49277 4.23096H26.4628C26.6128 4.23096 26.7428 4.35096 26.7428 4.51096V8.89096C26.7428 9.08096 26.9028 9.24096 27.0928 9.24096H31.4728C31.6228 9.24096 31.7528 9.36096 31.7528 9.52096V33.491C31.7528 33.991 31.3428 34.391 30.8528 34.391L30.8428 34.371Z"
        fill="#374EF4"
      />
      <path
        d="M31.4727 9.50096L26.4727 4.50096V9.00096C26.4727 9.28096 26.6927 9.50096 26.9727 9.50096H31.4727Z"
        fill="#86AD28"
      />
      <path
        d="M31.4727 9.78096H26.9727C26.5427 9.78096 26.1927 9.43096 26.1927 9.00096V4.50096C26.1927 4.39096 26.2627 4.29096 26.3627 4.25096C26.4627 4.21096 26.5827 4.23096 26.6627 4.31096L31.6627 9.31096C31.7427 9.39096 31.7627 9.51096 31.7227 9.61096C31.6827 9.71096 31.5827 9.78096 31.4727 9.78096ZM26.7427 5.17096V9.01096C26.7427 9.13096 26.8427 9.23096 26.9627 9.23096H30.8027L26.7427 5.17096Z"
        fill="#86AD28"
      />
      <path
        d="M27.1427 12.2512H11.1927C11.0427 12.2512 10.9127 12.1312 10.9127 11.9712C10.9127 11.8112 11.0327 11.6912 11.1927 11.6912H27.1427C27.2927 11.6912 27.4227 11.8112 27.4227 11.9712C27.4227 12.1312 27.3027 12.2512 27.1427 12.2512Z"
        fill="#374EF4"
      />
      <path
        d="M15.1227 15.2012H11.1927C11.0427 15.2012 10.9127 15.0812 10.9127 14.9212C10.9127 14.7612 11.0327 14.6412 11.1927 14.6412H15.1227C15.2727 14.6412 15.4027 14.7612 15.4027 14.9212C15.4027 15.0812 15.2827 15.2012 15.1227 15.2012Z"
        fill="#374EF4"
      />
      <path
        d="M27.1427 15.2012H16.9527C16.8027 15.2012 16.6727 15.0812 16.6727 14.9212C16.6727 14.7612 16.7927 14.6412 16.9527 14.6412H27.1427C27.2927 14.6412 27.4227 14.7612 27.4227 14.9212C27.4227 15.0812 27.3027 15.2012 27.1427 15.2012Z"
        fill="#374EF4"
      />
      <path
        d="M27.2428 17.3909H11.0928C10.8166 17.3909 10.5928 17.6147 10.5928 17.8909V19.3809C10.5928 19.657 10.8166 19.8809 11.0928 19.8809H27.2428C27.5189 19.8809 27.7428 19.657 27.7428 19.3809V17.8909C27.7428 17.6147 27.5189 17.3909 27.2428 17.3909Z"
        fill="#86AD28"
      />
      <path
        d="M24.6627 22.3611H13.5527C13.2655 22.3611 13.0327 22.5939 13.0327 22.8811V36.8111C13.0327 37.0982 13.2655 37.3311 13.5527 37.3311H24.6627C24.9499 37.3311 25.1827 37.0982 25.1827 36.8111V22.8811C25.1827 22.5939 24.9499 22.3611 24.6627 22.3611Z"
        fill="#374EF4"
      />
      <path
        d="M24.6627 37.6111H13.5527C13.1127 37.6111 12.7527 37.2511 12.7527 36.8111V22.8811C12.7527 22.4411 13.1127 22.0811 13.5527 22.0811H24.6627C25.1027 22.0811 25.4627 22.4411 25.4627 22.8811V36.8111C25.4627 37.2511 25.1027 37.6111 24.6627 37.6111ZM13.5527 22.6411C13.4127 22.6411 13.3027 22.7511 13.3027 22.8911V36.8211C13.3027 36.9611 13.4127 37.0711 13.5527 37.0711H24.6627C24.8027 37.0711 24.9127 36.9611 24.9127 36.8211V22.8911C24.9127 22.7511 24.8027 22.6411 24.6627 22.6411H13.5527Z"
        fill="#374EF4"
      />
      <path
        d="M22.8727 24.051H15.3327C15.2498 24.051 15.1827 24.1182 15.1827 24.201V25.841C15.1827 25.9238 15.2498 25.991 15.3327 25.991H22.8727C22.9555 25.991 23.0227 25.9238 23.0227 25.841V24.201C23.0227 24.1182 22.9555 24.051 22.8727 24.051Z"
        fill="white"
      />
      <path
        d="M22.8727 26.271H15.3327C15.0927 26.271 14.9027 26.081 14.9027 25.841V24.201C14.9027 23.961 15.0927 23.771 15.3327 23.771H22.8727C23.1027 23.771 23.3027 23.961 23.3027 24.201V25.841C23.3027 26.081 23.1127 26.271 22.8727 26.271ZM15.4527 25.721H22.7427V24.331H15.4527V25.721Z"
        fill="white"
      />
      <path
        d="M16.5027 29.121H14.6527C14.5027 29.121 14.3727 29.001 14.3727 28.841C14.3727 28.681 14.4927 28.561 14.6527 28.561H16.5027C16.6527 28.561 16.7827 28.681 16.7827 28.841C16.7827 29.001 16.6627 29.121 16.5027 29.121Z"
        fill="white"
      />
      <path
        d="M20.0327 29.121H18.1827C18.0327 29.121 17.9027 29.001 17.9027 28.841C17.9027 28.681 18.0227 28.561 18.1827 28.561H20.0327C20.1827 28.561 20.3127 28.681 20.3127 28.841C20.3127 29.001 20.1927 29.121 20.0327 29.121Z"
        fill="white"
      />
      <path
        d="M23.5527 29.121H21.7027C21.5527 29.121 21.4227 29.001 21.4227 28.841C21.4227 28.681 21.5427 28.561 21.7027 28.561H23.5527C23.7027 28.561 23.8327 28.681 23.8327 28.841C23.8327 29.001 23.7127 29.121 23.5527 29.121Z"
        fill="white"
      />
      <path
        d="M16.5027 31.731H14.6527C14.5027 31.731 14.3727 31.611 14.3727 31.451C14.3727 31.291 14.4927 31.171 14.6527 31.171H16.5027C16.6527 31.171 16.7827 31.291 16.7827 31.451C16.7827 31.611 16.6627 31.731 16.5027 31.731Z"
        fill="white"
      />
      <path
        d="M20.0327 31.731H18.1827C18.0327 31.731 17.9027 31.611 17.9027 31.451C17.9027 31.291 18.0227 31.171 18.1827 31.171H20.0327C20.1827 31.171 20.3127 31.291 20.3127 31.451C20.3127 31.611 20.1927 31.731 20.0327 31.731Z"
        fill="white"
      />
      <path
        d="M23.5527 31.731H21.7027C21.5527 31.731 21.4227 31.611 21.4227 31.451C21.4227 31.291 21.5427 31.171 21.7027 31.171H23.5527C23.7027 31.171 23.8327 31.291 23.8327 31.451C23.8327 31.611 23.7127 31.731 23.5527 31.731Z"
        fill="white"
      />
      <path
        d="M16.5027 34.341H14.6527C14.5027 34.341 14.3727 34.221 14.3727 34.061C14.3727 33.901 14.4927 33.781 14.6527 33.781H16.5027C16.6527 33.781 16.7827 33.901 16.7827 34.061C16.7827 34.221 16.6627 34.341 16.5027 34.341Z"
        fill="white"
      />
      <path
        d="M20.0327 34.341H18.1827C18.0327 34.341 17.9027 34.221 17.9027 34.061C17.9027 33.901 18.0227 33.781 18.1827 33.781H20.0327C20.1827 33.781 20.3127 33.901 20.3127 34.061C20.3127 34.221 20.1927 34.341 20.0327 34.341Z"
        fill="white"
      />
      <path
        d="M23.5527 34.341H21.7027C21.5527 34.341 21.4227 34.221 21.4227 34.061C21.4227 33.901 21.5427 33.781 21.7027 33.781H23.5527C23.7027 33.781 23.8327 33.901 23.8327 34.061C23.8327 34.221 23.7127 34.341 23.5527 34.341Z"
        fill="white"
      />
    </svg>
  );
}
