import { loadFolderByIdAction } from 'actions/folders/load-folder-by-id-action';
import { increaseUserSubmissionsCountInState } from 'actions/users/add-user-data-action';
import { loadUserAction } from 'actions/users/load-user-action';
import { USER_EVENTS } from 'constants/userEvents';
import { getPersonParametersById } from 'selectors/persons';
import { getUserHasRunsLeft } from 'selectors/user';

import { sendUserEvent } from '../users/send-user-event-action';
import { loadAllPersons } from './load-all-persons-action';

export function makeNewPersonsSearchAction(payload, folderId, reduxDispatch) {
  return (state, dispatch, apiClient) => {
    const hasRunsLeft = getUserHasRunsLeft(state);
    if (!hasRunsLeft) return;
    dispatch(increaseUserSubmissionsCountInState());

    apiClient.persons
      .submitPerson({ ...payload, folder_id: folderId })
      .then(() => {
        dispatch(loadAllPersons());
        if (folderId && reduxDispatch) {
          reduxDispatch(loadFolderByIdAction(folderId));
        }
      })
      .catch(() => {
        dispatch(loadUserAction());
        dispatch(loadAllPersons());
      });
    dispatch(sendUserEvent(USER_EVENTS.PERSON_SUBMITTED, { person: payload.name }));
  };
}

export function makeRerunPersonsSearchAction(searchId) {
  return (state, dispatch, apiClient) => {
    const hasRunsLeft = getUserHasRunsLeft(state);
    if (!hasRunsLeft) return;
    dispatch(increaseUserSubmissionsCountInState());

    const parameters = getPersonParametersById(state, searchId);
    apiClient.persons
      .submitPerson(parameters)
      .then(() => {
        dispatch(loadAllPersons());
      })
      .catch(() => {
        dispatch(loadUserAction());
        dispatch(loadAllPersons());
      });
    dispatch(sendUserEvent(USER_EVENTS.PERSON_RERUN_SUBMITTED, { person: parameters.name }));
  };
}
