import { useAppState } from 'hooks/state-context';
import { useEffect, useState } from 'react';

export function useOldStateSelector(selector) {
  const { state } = useAppState();
  const [selectorValue, setSelectorValue] = useState(selector(state));
  const value = selector(state);
  useEffect(() => setSelectorValue(value), [value]);
  return selectorValue;
}
