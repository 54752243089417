import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getExploreCompaniesLoadingByListId } from 'selectors/explore-table-ui';

import RenderNotFoundCompanyName from './RenderNotFoundCompanyName';

export default function RenderNotFoundCompanyNameConnector({ value }) {
  const companiesLoading = useSelector((state) =>
    getExploreCompaniesLoadingByListId(state, value.companyListId)
  );
  const isLoading = Boolean(companiesLoading?.[value.name]);

  return <RenderNotFoundCompanyName value={value} isLoading={isLoading} />;
}

RenderNotFoundCompanyNameConnector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.number
};
