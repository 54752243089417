import { makeNewClustersAction } from 'actions/clusters/make-new-clusters-action';
import { makeRerunPersonsSearchAction } from 'actions/persons/make-new-persons-search-action';
import { makeNewSearchRerunAction } from 'actions/runs/make-new-search-run-action';
import { TILE_TYPE } from 'constants/tiles';
import { dispatch } from 'hooks/AppStateProvider';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';

import IconButtonComponent from './IconButtonComponent';

const RerunButton = ({ id, type, status, color, variant, sx, companyListId, discoveryId }) => {
  const rerunJob = (event) => {
    event.stopPropagation();
    if (type === TILE_TYPE.RUN) {
      dispatch(makeNewSearchRerunAction(id));
    } else if (type === TILE_TYPE.PERSON) {
      dispatch(makeRerunPersonsSearchAction(id));
    } else if (type === TILE_TYPE.TAGGING && companyListId && discoveryId) {
      dispatch(makeNewClustersAction(companyListId, discoveryId));
    }
  };
  return (
    <IconButtonComponent
      tag="Rerun"
      sx={{ ...sx, color }}
      variant={variant}
      disabled={status === 'running'}
      onClick={rerunJob}>
      <RerunIcon />
    </IconButtonComponent>
  );
};

RerunButton.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  companyListId: PropTypes.number,
  discoveryId: PropTypes.string
};

RerunButton.defaultProps = {
  variant: 'primary',
  companyListId: null,
  discoveryId: null
};

export default RerunButton;
