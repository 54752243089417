import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { generateDisableActionMessage, getActionDescription } from 'constants/discovery';
import { EXCEEDED_LIMIT_TEXT } from 'constants/tiles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

export const DisableListActionTooltip = ({
  children,
  actionType,
  actionDisabled,
  disabled,
  isEmpty,
  showDescription,
  noRunsLeft,
  tag
}) => {
  const [hoverOnButton, setHoverOnButton] = useState(false);
  const title = useMemo(() => {
    if (noRunsLeft) {
      return EXCEEDED_LIMIT_TEXT;
    }
    if (showDescription && (!actionDisabled || isEmpty)) {
      return getActionDescription(actionType);
    }
    if (!showDescription && tag && (!actionDisabled || isEmpty)) {
      return tag;
    }
    return generateDisableActionMessage(actionType);
  }, [noRunsLeft, showDescription, actionDisabled, isEmpty, actionType, tag]);

  useEffect(() => {
    setHoverOnButton(false);
  }, [disabled]);

  return (
    <Tooltip
      open={
        Boolean(actionDisabled || isEmpty || showDescription || noRunsLeft || tag) &&
        hoverOnButton &&
        !disabled
      }
      title={title}
      arrow>
      <Box
        onPointerEnter={() => setHoverOnButton(true)}
        onPointerLeave={() => setHoverOnButton(false)}>
        {children}
      </Box>
    </Tooltip>
  );
};

DisableListActionTooltip.propTypes = {
  disabled: PropTypes.bool,
  actionType: PropTypes.string,
  children: PropTypes.object,
  isEmpty: PropTypes.bool,
  showDescription: PropTypes.bool,
  noRunsLeft: PropTypes.bool,
  tag: PropTypes.string,
  actionDisabled: PropTypes.bool
};

DisableListActionTooltip.defaultProps = {
  isEmpty: false,
  showDescription: false,
  tag: null,
  disabled: false
};
