import { TOAST_TYPES } from 'constants/toasts';
import { updatePredicted } from 'reducer/art-slice';

export function predictArtAction(
  id,
  artistName,
  artwork,
  similar_artworks,
  addToast,
  setLoading,
  onPredictSuccess
) {
  return (dispatch, _getState, apiClient) => {
    setLoading(id);
    apiClient.art
      .predictArt(artistName, artwork, similar_artworks)
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updatePredicted({ data: res.data.data }));
          addToast('Prediction Succeeded', TOAST_TYPES.SUCCESS);
          onPredictSuccess();
        } else {
          addToast('Prediction Failed', TOAST_TYPES.ERROR);
        }
        setLoading(null);
      })
      .catch(() => {
        addToast('Prediction Failed', TOAST_TYPES.ERROR);
        setLoading(null);
      });
  };
}
