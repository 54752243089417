import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  clientId: null
};

export const generalSlice = createSlice({
  name: 'general',
  initialState: initialSliceState,
  reducers: {
    updateClientId: (sliceState, { payload }) => {
      const { clientId } = payload;
      sliceState.clientId = clientId;
    }
  }
});

export const { updateClientId } = generalSlice.actions;

export default generalSlice.reducer;
