import CustomAvatarGroup from 'components/CustomAvatarGroup';
import { RESOURCE_TYPES } from 'constants/resources';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getCompanyListCollaboratorsOrgs,
  getCompanyListCollaboratorsTeams,
  getCompanyListCollaboratorsUsers,
  getCompanyListOwner
} from 'selectors/explore';
import { getFolderCollaborateInfo } from 'selectors/folders';
import {
  getPersonCollaboratorsOrgs,
  getPersonCollaboratorsTeams,
  getPersonCollaboratorsUsers,
  getPersonOwner
} from 'selectors/persons';
import {
  getRunCollaboratorsOrgs,
  getRunCollaboratorsTeams,
  getRunCollaboratorsUsers,
  getRunOwner
} from 'selectors/runs';

function useAllCollaborators(
  collaboratorsUsers,
  collaboratorsOrgs,
  collaboratorsTeams,
  owner,
  folderId
) {
  const {
    resourceUsers: folderUsers,
    resourceOrgs: folderOrgs,
    resourceTeams: folderTeams,
    resourceOwner: folderOwner
  } = useSelector((reduxState) => getFolderCollaborateInfo(reduxState, folderId));
  const collaborators = useMemo(() => {
    const allCollaboratorsUsers = [];
    const allCollaboratorsOrgs = [];
    const allCollaboratorsTeams = [];
    if (collaboratorsUsers?.length > 0) {
      allCollaboratorsUsers.push(...collaboratorsUsers);
    }
    if (folderUsers?.length > 0) {
      allCollaboratorsUsers.push(...folderUsers);
    }
    if (
      folderOwner &&
      owner &&
      folderOwner.id !== owner.id &&
      !allCollaboratorsUsers.find((user) => user.id === folderOwner.id)
    ) {
      allCollaboratorsUsers.push(folderOwner);
    }
    if (collaboratorsOrgs?.length > 0) {
      allCollaboratorsOrgs.push(...collaboratorsOrgs);
    }
    if (folderOrgs?.length > 0) {
      allCollaboratorsOrgs.push(...folderOrgs);
    }
    if (collaboratorsTeams?.length > 0) {
      allCollaboratorsTeams.push(...collaboratorsTeams);
    }
    if (folderTeams?.length > 0) {
      allCollaboratorsTeams.push(...folderTeams);
    }
    return { allCollaboratorsUsers, allCollaboratorsOrgs, allCollaboratorsTeams };
  }, [
    collaboratorsUsers,
    collaboratorsOrgs,
    collaboratorsTeams,
    owner,
    folderUsers,
    folderOrgs,
    folderTeams,
    folderOwner
  ]);
  return collaborators;
}

function SharedStackInner({
  collaboratorsUsers,
  collaboratorsOrgs,
  collaboratorsTeams,
  owner,
  ...props
}) {
  const tags = [
    ...(collaboratorsOrgs?.map((org) => ({
      name: org.name,
      picture: org.logo_url,
      org: true
    })) || []),
    ...(collaboratorsTeams?.map((team) => ({
      name: team.name,
      org: true
    })) || []),
    ...(owner ? [{ name: owner.username, picture: owner.picture }] : []),
    ...(collaboratorsUsers?.map((user) => ({ name: user.username, picture: user.picture })) || [])
  ];
  if (!tags.length) {
    return null;
  }
  if (tags.length === 1) {
    return null;
  }
  return <CustomAvatarGroup entities={tags} {...props} />;
}

function ListSharedStackConnector({ id, folderId, ...props }) {
  const collaboratorsUsers = useSelector((state) => getCompanyListCollaboratorsUsers(state, id));
  const collaboratorsOrgs = useSelector((state) => getCompanyListCollaboratorsOrgs(state, id));
  const collaboratorsTeams = useSelector((state) => getCompanyListCollaboratorsTeams(state, id));
  const owner = useSelector((state) => getCompanyListOwner(state, id));
  const { allCollaboratorsUsers, allCollaboratorsOrgs, allCollaboratorsTeams } =
    useAllCollaborators(collaboratorsUsers, collaboratorsOrgs, collaboratorsTeams, owner, folderId);

  return (
    <SharedStackInner
      collaboratorsOrgs={allCollaboratorsOrgs}
      collaboratorsTeams={allCollaboratorsTeams}
      collaboratorsUsers={allCollaboratorsUsers}
      owner={owner}
      {...props}
    />
  );
}

function FolderSharedStackConnector({ id, ...props }) {
  const { resourceUsers, resourceOrgs, resourceTeams, resourceOwner } = useSelector((reduxState) =>
    getFolderCollaborateInfo(reduxState, id)
  );
  return (
    <SharedStackInner
      collaboratorsOrgs={resourceOrgs}
      collaboratorsTeams={resourceTeams}
      collaboratorsUsers={resourceUsers}
      owner={resourceOwner}
      {...props}
    />
  );
}

function DeepDiveSharedStackConnector({ searchId, folderId, ...props }) {
  const { state } = useAppState();
  const collaboratorsUsers = getRunCollaboratorsUsers(state, searchId);
  const collaboratorsOrgs = getRunCollaboratorsOrgs(state, searchId);
  const collaboratorsTeams = getRunCollaboratorsTeams(state, searchId);
  const owner = getRunOwner(state, searchId);
  const { allCollaboratorsUsers, allCollaboratorsOrgs, allCollaboratorsTeams } =
    useAllCollaborators(collaboratorsUsers, collaboratorsOrgs, collaboratorsTeams, owner, folderId);
  return (
    <SharedStackInner
      collaboratorsOrgs={allCollaboratorsOrgs}
      collaboratorsTeams={allCollaboratorsTeams}
      collaboratorsUsers={allCollaboratorsUsers}
      owner={owner}
      {...props}
    />
  );
}
function PersonSharedStackConnector({ searchId, folderId, ...props }) {
  const { state } = useAppState();
  const collaboratorsUsers = getPersonCollaboratorsUsers(state, searchId);
  const collaboratorsOrgs = getPersonCollaboratorsOrgs(state, searchId);
  const collaboratorsTeams = getPersonCollaboratorsTeams(state, searchId);
  const owner = getPersonOwner(state, searchId);
  const { allCollaboratorsUsers, allCollaboratorsOrgs, allCollaboratorsTeams } =
    useAllCollaborators(collaboratorsUsers, collaboratorsOrgs, collaboratorsTeams, owner, folderId);
  return (
    <SharedStackInner
      collaboratorsOrgs={allCollaboratorsOrgs}
      collaboratorsTeams={allCollaboratorsTeams}
      collaboratorsUsers={allCollaboratorsUsers}
      owner={owner}
      {...props}
    />
  );
}

function SharedStack({ id, resourceType, size, searchId, folderId }) {
  switch (resourceType) {
    case RESOURCE_TYPES.LIST:
      return <ListSharedStackConnector id={id} size={size} folderId={folderId} />;
    case RESOURCE_TYPES.FOLDER:
      return <FolderSharedStackConnector id={id} size={size} />;
    case RESOURCE_TYPES.DEEP_DIVE:
      return <DeepDiveSharedStackConnector size={size} searchId={searchId} folderId={folderId} />;
    case RESOURCE_TYPES.PERSON:
      return <PersonSharedStackConnector size={size} searchId={searchId} folderId={folderId} />;
    default:
      return null;
  }
}

SharedStack.propTypes = {
  id: PropTypes.number,
  resourceType: PropTypes.string,
  size: PropTypes.number,
  searchId: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

SharedStack.defaultProps = {
  size: 24
};

SharedStackInner.propTypes = {
  collaboratorsOrgs: PropTypes.array,
  collaboratorsTeams: PropTypes.array,
  collaboratorsUsers: PropTypes.array,
  owner: PropTypes.object
};

ListSharedStackConnector.propTypes = {
  id: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

DeepDiveSharedStackConnector.propTypes = {
  searchId: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

PersonSharedStackConnector.propTypes = {
  searchId: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

FolderSharedStackConnector.propTypes = {
  id: PropTypes.number
};

export default SharedStack;
