import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function RenderVariableHeader({ colDef }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip title={`${colDef.headerName} - ${colDef.description}`} arrow={true} open={showTooltip}>
      <Typography
        variant="paragraphSemiBold"
        color="greyColors.grey300"
        paddingLeft={colDef.field === 'company_name' ? '32px' : 0}
        noWrap
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        display="block">
        {colDef.headerName}
      </Typography>
    </Tooltip>
  );
}

RenderVariableHeader.propTypes = {
  colDef: PropTypes.object
};
