import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';

import { addExploreFile } from './upload-file-to-discovery-row-action';

export function deleteFileFromExploreRowAction(listId, companyListMetaId, fileInfo) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const rows = getCompanyMetasMergedRowsMapByListId(state, listId);
    const companyListFiles = rows[companyListMetaId]?.files;
    if (companyListFiles) {
      if (Object.keys(companyListFiles).length > 1) {
        const newFiles = { ...companyListFiles };
        delete newFiles[fileInfo.id];
        dispatch(addExploreFile(listId, companyListMetaId, newFiles));
      } else {
        dispatch(addExploreFile(listId, companyListMetaId, null));
      }
    }
    apiClient.companyListMetas.deleteFile(companyListMetaId, fileInfo.id);
  };
}
