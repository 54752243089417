import BaseClient from './BaseClient';

const URL_PREFIX = 'notifications';

export default class NotificationsClient extends BaseClient {
  fetchUserNotifications() {
    return this.get(`${URL_PREFIX}?get_discovery_data=true`);
  }

  fetchNotification(notificationId) {
    return this.get(`${URL_PREFIX}/${notificationId}`);
  }

  dismissNotifications(notificationIds, dismissed) {
    const payload = { notification_ids: notificationIds, to: dismissed };
    return this.put(`${URL_PREFIX}/set_dismissed`, payload);
  }
}
