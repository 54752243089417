import { loadExplore } from 'actions/explore/load-explore-action';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';

import { sendUserEvent } from '../users/send-user-event-action';
import {
  monitorClustersProgressAction,
  monitorClustersStatusesAction
} from './monitor-clusters-progress-action';

export function makeNewClustersAction(company_list_id) {
  return (reduxDispatch, _getState, apiClient) => {
    apiClient.tagging.submitClustersRun(company_list_id).then((res) => {
      reduxDispatch(loadExplore(company_list_id, null, false));
      if (res.ok && res.data) {
        const { record_id: recordId } = res.data;
        reduxDispatch(monitorClustersProgressAction(recordId));
        dispatch(monitorClustersStatusesAction(recordId));

        dispatch(
          sendUserEvent(USER_EVENTS.TAGGING_SUBMITTED, {
            listId: company_list_id,
            recordId
          })
        );
      }
    });
  };
}
