import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BaseTooltipComponent from '../BaseTooltipComponent';

function ClustersScatterTooltipContent({ payload }) {
  if (!payload.length) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <BaseTooltipComponent label={payload[0]?.payload?.label}>
      {payload[2]?.name && payload[2]?.payload?.size && (
        <Typography variant="paragraph">
          {payload[2].name}: {payload[2].payload.size}
        </Typography>
      )}
      {/* {payload[0]?.payload?.company_hashes && (
        <Typography variant="paragraph">{payload[0].payload.company_hashes.join(', ')}</Typography>
      )} */}
      {/* <br></br>
      {payload[0]?.payload?.company_hashes && (
        <Typography variant="paragraph">{payload[0].payload.company_hashes.length}</Typography>
      )} */}
    </BaseTooltipComponent>
  );
}

ClustersScatterTooltipContent.propTypes = {
  payload: PropTypes.array
};

export default ClustersScatterTooltipContent;
