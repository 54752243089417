/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  found: {},
  predicted: {},
  artists: [],
  originalPredicted: {}
};

export const artSlice = createSlice({
  name: 'art',
  initialState: initialSliceState,
  reducers: {
    updateFound: (sliceState, { payload }) => {
      const { artworks } = payload;
      sliceState.found = artworks.reduce((acc, artwork, id) => ({ ...acc, [id]: artwork }), {});
    },
    updatePredicted: (sliceState, { payload }) => {
      const { data } = payload;
      sliceState.predicted = data;
    },
    updateArtists: (sliceState, { payload }) => {
      const { artists } = payload;
      sliceState.artists = artists;
    },
    clearArtState: (sliceState) => {
      sliceState.found = {};
      sliceState.predicted = {};
    },
    updateOriginalPredicted: (sliceState, { payload }) => {
      const { data } = payload;
      sliceState.originalPredicted = data;
    }
  }
});

export const {
  updateFound,
  updatePredicted,
  updateArtists,
  clearArtState,
  updateOriginalPredicted
} = artSlice.actions;

export default artSlice.reducer;
