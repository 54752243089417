import { Tab, Tabs } from '@mui/material';
import ClustersViewIcon from 'icons/ClustersViewIcon';
import DomainKnowledgeIcon from 'icons/DomainKnowledgeIcon';
import ExtendIcon from 'icons/ExtendIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { VIEW_TYPES } from 'screens/DiscoveryRunBaseScreen';
import { appTheme } from 'theme';

const determineTabs = () => {
  const tabs = [
    { name: 'List', icon: <ExtendIcon /> },
    { name: 'Cluster', icon: <ClustersViewIcon /> },
    { name: 'Domain Knowledge', icon: <DomainKnowledgeIcon /> }
  ];
  return tabs;
};

export function DiscoveryViewTabs({ setSelectedView }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = determineTabs();

  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        setSelectedView(VIEW_TYPES.TABLE);
        break;
      case 1:
        setSelectedView(VIEW_TYPES.CLUSTERS);
        break;
      case 2:
        setSelectedView(VIEW_TYPES.DOMAIN_KNOWLEDGE);
        break;

      default:
        break;
    }
  }, [activeTabIndex, setSelectedView]);
  return (
    <Tabs
      value={activeTabIndex}
      textColor="secondary"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'greyColors.grey100',
        minHeight: '10px'
      }}
      TabIndicatorProps={{
        style: { backgroundColor: appTheme.palette.primary.primary50, height: '4px' }
      }}
      onChange={(_event, newTabIndex) => setActiveTabIndex(newTabIndex)}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          color="primary"
          sx={{
            gap: '8px',
            '&:hover': {
              color: appTheme.palette.primary.primary25
            },

            '&.Mui-selected': {
              color: appTheme.palette.primary.primary50
            },
            minHeight: '10px'
          }}
          iconPosition="start"
          icon={tab.icon}
          label={tab.name}
        />
      ))}
    </Tabs>
  );
}

DiscoveryViewTabs.propTypes = {
  setSelectedView: PropTypes.func
};
