import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { MonitoredListComponent } from 'screens/MonitoringsScreen';

function MonitoringSchedulesTab({ hidden, schedules }) {
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      {Object.keys(schedules)
        .sort((listId1, listId2) => listId2 - listId1)
        .map((listId) => (
          <MonitoredListComponent
            key={listId}
            listId={listId}
            schedules={schedules[listId]}
            isAdminScreen={true}
          />
        ))}
    </Stack>
  );
}

export default MonitoringSchedulesTab;

MonitoringSchedulesTab.propTypes = {
  schedules: PropTypes.object,
  hidden: PropTypes.bool
};
