import { ACTIONS } from 'constants/actions';

import { translatePersonRecordForState } from './load-all-persons-action';

function updatePersonData(personId, data) {
  return { type: ACTIONS.UPDATE_PERSON_DATA, personId, data };
}

export function loadPersonByIdAction(personId) {
  return (_state, dispatch, apiClient) => {
    apiClient.persons.fetchPersonData(personId).then((res) => {
      if (res.ok) {
        dispatch(updatePersonData(personId, translatePersonRecordForState(res.data)));
      }
    });
  };
}
