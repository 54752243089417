import BaseClient from './BaseClient';

const URL_PREFIX = 'folders';

export default class FoldersClient extends BaseClient {
  fetchFolders() {
    return this.get(URL_PREFIX);
  }

  fetchFolder(folderId) {
    return this.get(`${URL_PREFIX}/${folderId}`);
  }

  addFolder(payload) {
    return this.post(URL_PREFIX, payload);
  }

  renameFolder(folderId, folderName) {
    const payload = { name: folderName };
    return this.put(`${URL_PREFIX}/${folderId}/rename`, payload);
  }

  deleteFolder(folderId) {
    return this.httpDelete(`${URL_PREFIX}/${folderId}`);
  }
}
