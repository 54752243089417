import { updateExploreTableColumnWidth } from 'reducer/explore-table-ui';
import { getExploreUiColumnWidthByListId } from 'selectors/explore-table-ui';

export default function updateDiscoveryColumnResizeAction(listId, columnId, width) {
  return (dispatch, getState) => {
    const state = getState();
    const currentWidth = getExploreUiColumnWidthByListId(state, listId, columnId);
    if (currentWidth !== width) {
      dispatch(updateExploreTableColumnWidth({ listId, columnId, width }));
    }
  };
}
