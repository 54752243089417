import BaseClient from './BaseClient';

const URL_PREFIX = 'autocomplete';

export default class AutocompleteClient extends BaseClient {
  submitAutocomplete(searchTerm) {
    return this.post(URL_PREFIX, { searchTerm });
  }

  submitAutocompleteSuggestions(searchTerm) {
    return this.post(`${URL_PREFIX}-suggestions`, { searchTerm });
  }
}
