import PropTypes from 'prop-types';
import { appTheme } from 'theme';

export default function CustomAnalyticsIcon({ disabled, color }) {
  const actualColor = disabled ? appTheme.palette.greyColors.grey200 : color;
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M20.25 12.875V17.375"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15.125H22.5"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.625V9.125"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 6.875H9.75"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 18.125V21.125"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 19.625H17.25"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 8.375L16.5 11.375"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0305 4.84451L3.96978 17.9052C3.67689 18.1981 3.67689 18.673 3.96978 18.9659L5.9088 20.9049C6.2017 21.1978 6.67657 21.1978 6.96946 20.9049L20.0302 7.84419C20.3231 7.5513 20.3231 7.07643 20.0302 6.78353L18.0911 4.84451C17.7983 4.55162 17.3234 4.55162 17.0305 4.84451Z"
        stroke={actualColor}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CustomAnalyticsIcon.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string
};

CustomAnalyticsIcon.defaultProps = {
  color: 'white'
};
