import { loadTeamsAction } from 'actions/teams/load-teams-action';
import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import { dispatch } from 'hooks/AppStateProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserOrganizationId } from 'selectors/user';

function OrganizationContextFetcher() {
  const [hasCalledTeams, setHasCalledTeams] = useState(false);
  const [hasCalledOrgUsers, setHasCalledOrgUsers] = useState(false);
  const organizationId = useOldStateSelector(getUserOrganizationId);
  const reduxDispatch = useDispatch();
  useEffect(() => {
    if (!organizationId) {
      return;
    }
    if (!hasCalledTeams) {
      setHasCalledTeams(true);
      reduxDispatch(loadTeamsAction());
    }
    if (!hasCalledOrgUsers) {
      setHasCalledOrgUsers(true);
      dispatch(loadUserOrganizationUsersAction());
    }
  }, [
    organizationId,
    hasCalledTeams,
    hasCalledOrgUsers,
    setHasCalledTeams,
    setHasCalledOrgUsers,
    reduxDispatch
  ]);
  return null;
}

export default OrganizationContextFetcher;
