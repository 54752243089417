import { Stack } from '@mui/material';
import { loadExplore } from 'actions/explore/load-explore-action';
import CustomAnalyticsThread from 'components/discovery/CustomAnalyticsThread';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { getExploreListByListId } from 'selectors/explore';

function CustomAnalyticsModal({ response, parameters, open, onClose, listId, title }) {
  const dispatch = useDispatch();
  const list = useSelector((state) => getExploreListByListId(state, listId));
  const companies = useSelector((state) => getCompanyMetasMergedRowsMapByListId(state, listId));

  const post = {
    ...response,
    ...parameters,
    query: parameters?.question,
    responseType: parameters?.response_type
  };
  useEffect(() => {
    if (listId && !list) {
      dispatch(loadExplore(listId));
    }
  }, [listId, list, dispatch]);

  return (
    <ModalComponent title={title} open={open} onClose={onClose} maxWidth={1000}>
      <Stack sx={{ padding: '16px' }}>
        {list && companies && open ? (
          <CustomAnalyticsThread
            post={post}
            listId={listId}
            companies={companies}
            showToolCallHistory
          />
        ) : (
          'Loading...'
        )}
      </Stack>
    </ModalComponent>
  );
}

CustomAnalyticsModal.propTypes = {
  response: PropTypes.object,
  parameters: PropTypes.object,
  listId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default CustomAnalyticsModal;
