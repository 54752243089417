import React from 'react';

export default function FinanceRiskIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none">
      <path
        d="M10.0902 20.4259C13.7961 20.4259 16.8002 17.4217 16.8002 13.7159C16.8002 10.01 13.7961 7.00586 10.0902 7.00586C6.38442 7.00586 3.38025 10.01 3.38025 13.7159C3.38025 17.4217 6.38442 20.4259 10.0902 20.4259Z"
        fill="#86AD28"
      />
      <path
        d="M10.0802 19.4159C9.07025 19.4159 8.04025 19.1459 7.11025 18.5759C4.43025 16.9359 3.58025 13.4159 5.22025 10.7359C6.86025 8.05586 10.3802 7.20586 13.0602 8.84586C14.3602 9.63586 15.2703 10.8959 15.6302 12.3759C15.9902 13.8559 15.7502 15.3859 14.9502 16.6859C13.8702 18.4459 12.0002 19.4159 10.0702 19.4159H10.0802ZM5.70025 11.0259C4.22025 13.4459 4.98025 16.6259 7.41025 18.1159C9.83025 19.5959 13.0103 18.8359 14.5002 16.4059C15.2203 15.2359 15.4402 13.8459 15.1202 12.5059C14.8003 11.1659 13.9703 10.0359 12.8003 9.31586C10.3702 7.83586 7.20025 8.59586 5.71025 11.0259H5.70025Z"
        fill="white"
      />
      <path
        d="M6.2203 20.3757C6.2203 20.3757 6.1203 20.3657 6.0803 20.3357C5.9503 20.2557 5.9103 20.0857 5.9903 19.9557L7.3103 17.8057C7.3903 17.6757 7.5603 17.6357 7.6903 17.7157C7.8203 17.7957 7.8603 17.9657 7.7803 18.0957L6.4603 20.2457C6.4103 20.3257 6.3203 20.3757 6.2303 20.3757H6.2203Z"
        fill="#86AD28"
      />
      <path
        d="M12.7903 9.99578C12.7903 9.99578 12.6903 9.98578 12.6503 9.95578C12.5203 9.87578 12.4803 9.70578 12.5603 9.57578L13.8803 7.42578C13.9603 7.29578 14.1303 7.25578 14.2603 7.33578C14.3903 7.41578 14.4303 7.58578 14.3503 7.71578L13.0303 9.86578C12.9803 9.94578 12.8903 9.99578 12.8003 9.99578H12.7903Z"
        fill="#86AD28"
      />
      <path
        d="M5.93026 10.9957C5.93026 10.9957 5.83026 10.9857 5.79026 10.9557L3.64026 9.63574C3.51026 9.55574 3.47026 9.38574 3.55026 9.25574C3.63026 9.12574 3.80026 9.08574 3.93026 9.16574L6.08026 10.4857C6.21026 10.5657 6.25026 10.7357 6.17026 10.8657C6.12026 10.9457 6.03026 10.9957 5.94026 10.9957H5.93026Z"
        fill="#86AD28"
      />
      <path
        d="M10.0902 15.4661C11.0567 15.4661 11.8402 14.6826 11.8402 13.7161C11.8402 12.7496 11.0567 11.9661 10.0902 11.9661C9.12371 11.9661 8.34021 12.7496 8.34021 13.7161C8.34021 14.6826 9.12371 15.4661 10.0902 15.4661Z"
        fill="white"
      />
      <path
        d="M20.9602 4.93599C21.1902 4.53599 21.7702 4.53599 22.0002 4.93599L30.1402 19.026L38.2802 33.116C38.5102 33.516 38.2202 34.016 37.7602 34.016H5.2102C4.7502 34.016 4.4602 33.516 4.6902 33.116L12.8302 19.026L20.9602 4.93599Z"
        fill="#8EBCFC"
      />
      <path
        d="M19.1403 5.54585C19.3703 5.14585 19.9503 5.14585 20.1803 5.54585L28.3203 19.6359L36.4603 33.7259C36.6903 34.1259 36.4003 34.6258 35.9403 34.6258H3.39026C2.93026 34.6258 2.64026 34.1259 2.87026 33.7259L11.0103 19.6359L19.1403 5.54585Z"
        fill="white"
      />
      <path
        d="M18.3302 6.07595C18.5602 5.67595 19.1402 5.67595 19.3702 6.07595L35.6502 34.2559C35.8802 34.6559 35.5902 35.1559 35.1302 35.1559H2.5802C2.1202 35.1559 1.8302 34.6559 2.0602 34.2559L18.3302 6.07595Z"
        fill="white"
      />
      <path
        d="M35.1302 35.4459H2.5802C2.2602 35.4459 1.9802 35.2859 1.8202 35.0059C1.6602 34.7359 1.6602 34.4059 1.8202 34.1259L18.1002 5.94595C18.4202 5.39595 19.3002 5.39595 19.6202 5.94595L35.8902 34.1259C36.0502 34.4059 36.0502 34.7259 35.8902 35.0059C35.7302 35.2759 35.4502 35.4459 35.1302 35.4459ZM18.8502 6.05595C18.7902 6.05595 18.6502 6.07595 18.5702 6.21595L2.3002 34.3959C2.2202 34.5459 2.2702 34.6759 2.3002 34.7259C2.3302 34.7759 2.4102 34.8859 2.5802 34.8859H35.1302C35.3002 34.8859 35.3802 34.7759 35.4102 34.7259C35.4402 34.6759 35.4902 34.5459 35.4102 34.3959L19.1402 6.21595C19.0602 6.06595 18.9102 6.05595 18.8602 6.05595H18.8502Z"
        fill="#374EF4"
      />
      <path
        d="M18.3502 13.5459C18.5802 13.1559 19.1402 13.1559 19.3702 13.5459L28.7702 29.8059C29.0002 30.1959 28.7102 30.6859 28.2602 30.6859H9.46023C9.01023 30.6859 8.73023 30.1959 8.95023 29.8059L18.3502 13.5459Z"
        fill="#374EF4"
      />
      <path
        d="M19.3403 24.4559C19.1203 24.9059 18.5903 24.9059 18.3703 24.4559L17.0703 21.7759C16.8503 21.3259 17.1203 20.7759 17.5503 20.7759H20.1403C20.5703 20.7759 20.8403 21.3359 20.6203 21.7759L19.3203 24.4559H19.3403Z"
        fill="white"
      />
      <path
        d="M18.8503 27.7359C19.4302 27.7359 19.9003 27.2658 19.9003 26.6859C19.9003 26.106 19.4302 25.6359 18.8503 25.6359C18.2704 25.6359 17.8003 26.106 17.8003 26.6859C17.8003 27.2658 18.2704 27.7359 18.8503 27.7359Z"
        fill="white"
      />
    </svg>
  );
}
