import React from 'react';

export default function BigSearchIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8558 13.8923C20.8558 10.0517 17.7405 6.93827 13.8975 6.93827C10.0545 6.93827 6.93911 10.0517 6.93911 13.8923C6.93911 17.7329 10.0545 20.8463 13.8975 20.8463C17.7405 20.8463 20.8558 17.7329 20.8558 13.8923ZM22.4616 13.8923C22.4616 9.16541 18.6273 5.3335 13.8975 5.3335C9.16763 5.3335 5.33334 9.16541 5.33334 13.8923C5.33334 18.6192 9.16763 22.4511 13.8975 22.4511C15.6992 22.4511 17.3709 21.8951 18.7503 20.9454C18.7601 20.9544 18.7701 20.9633 18.7805 20.972L25.3477 26.4791C25.6874 26.7639 26.1938 26.7197 26.4788 26.3802C26.7638 26.0407 26.7195 25.5346 26.3798 25.2498L19.9986 19.8986C21.5217 18.3535 22.4616 16.2326 22.4616 13.8923Z"
        fill="#0F2345"
      />
    </svg>
  );
}
