import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function PieChartLegendComponent({
  payload,
  setHoverItem,
  clickItemIndex,
  setClickItemIndex,
  mode
}) {
  const [hoverIndex, setHoverIndex] = useState();

  const enterHover = (index) => {
    setHoverItem(index);
    setHoverIndex(index);
  };

  const leaveHover = () => {
    setHoverItem(null);
    setHoverIndex(null);
  };

  const onClickItem = (index) => {
    if (setClickItemIndex) {
      setClickItemIndex(index);
    }
  };

  const determineVariant = (index) => {
    if (clickItemIndex !== -1) {
      return clickItemIndex === index ? 'paragraphBold' : 'paragraph';
    }
    return hoverIndex === index ? 'paragraphBold' : 'paragraph';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
      {payload.map((entry, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => onClickItem(index)}
          onMouseEnter={() => enterHover(index)}
          onMouseLeave={leaveHover}>
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              backgroundColor: entry.color,
              borderRadius: '50%'
            }}
          />
          <Typography
            variant={determineVariant(index)}
            color="greyColors.grey200"
            sx={{
              flex: 1,
              textAlign: 'left',
              fontSize: mode === 'powerpoint' ? '20px' : null
            }}>{`${entry.payload.name}`}</Typography>
          <Typography
            variant={determineVariant(index)}
            color="secondary.secondary1"
            sx={{
              fontSize: mode === 'powerpoint' ? '20px' : null
            }}>{`${entry.payload.percentage < 1 ? '<1' : entry.payload.percentage}%`}</Typography>
        </Box>
      ))}
    </Box>
  );
}

PieChartLegendComponent.propTypes = {
  payload: PropTypes.array,
  setHoverItem: PropTypes.func,
  clickItemIndex: PropTypes.number,
  setClickItemIndex: PropTypes.func,
  overrideColor: PropTypes.string,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint'])
};
