import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ForbiddenResourceScreen from 'screens/ForbiddenResourceScreen';

export const OnErrorContext = createContext();

const ERROR_VALUES = Object.freeze({
  FORBIDDEN_RESOURCE: 'forbiddenResource'
});

export function useOnError() {
  return useContext(OnErrorContext);
}

export function OnErrorProvider({ children }) {
  const [onErrorConfig, setOnErrorConfig] = useState(null);
  const [location, setLocation] = useState(null);
  const currentLocation = useLocation();

  useEffect(() => {
    if (!location) {
      setLocation(currentLocation);
    } else if (location !== currentLocation) {
      setLocation(currentLocation);
      setOnErrorConfig(null);
    }
  }, [location, setLocation, currentLocation, setOnErrorConfig]);

  const forbiddenResource = useCallback((id, resourceType) => {
    if (id && resourceType) {
      dispatch(sendUserEvent(USER_EVENTS.FORBIDDEN_RESOURCE, { id, resourceType }));
    }
    setOnErrorConfig({
      value: ERROR_VALUES.FORBIDDEN_RESOURCE,
      title: 'Access Denied',
      message: 'You do not have permission to view this resource.',
      buttonUrl: '/',
      buttonText: 'Go to Home Page'
    });
  }, []);

  let contents = children;
  switch (onErrorConfig?.value) {
    case ERROR_VALUES.FORBIDDEN_RESOURCE:
      contents = <ForbiddenResourceScreen {...onErrorConfig} />;
      break;
    default:
      break;
  }

  return (
    <OnErrorContext.Provider value={{ forbiddenResource }}>{contents}</OnErrorContext.Provider>
  );
}

OnErrorProvider.propTypes = {
  children: PropTypes.any
};
