export function isEmpty(object) {
  if (!object) return true;
  if (
    typeof object === 'object' &&
    !object?.length &&
    !Object.keys?.(object)?.length &&
    !object?.size
  ) {
    return true;
  }
  return false;
}

export function getObjectLength(object) {
  if (isEmpty(object)) return 0;
  return object?.length || Object.keys?.(object)?.length || object?.size || 0;
}
