import BaseClient from './BaseClient';

const URL_PREFIX = 'company_metas';

export default class CompanyMetasClient extends BaseClient {
  fetchCompanyMetas(company_list_id, company_meta_ids) {
    return this.put(`${URL_PREFIX}`, { company_list_id, company_meta_ids });
  }

  updateIgnoreAmbiguous(companyMetaId, ignoreAmbiguous) {
    return this.put(`${URL_PREFIX}/${companyMetaId}/ignore-ambiguous`, {
      ignore_ambiguous: ignoreAmbiguous
    });
  }

  updateFinancePeer(companyMetaId) {
    return this.put(`${URL_PREFIX}/${companyMetaId}/update-finance-peer`, {});
  }

  updateFinancePeerInclusion(companyMetaId, included) {
    return this.put(`${URL_PREFIX}/${companyMetaId}/update-finance-peer/inclusion`, {
      ids: included
    });
  }
}
