import BaseClient from './BaseClient';

const URL_PREFIX = 'arts';

export default class ArtClient extends BaseClient {
  getArtists() {
    return this.get(`${URL_PREFIX}/artists`);
  }

  findArt(artistName, artworkTitle, externalOnly) {
    return this.post(`${URL_PREFIX}/disambiguate`, {
      artist_name: artistName,
      title: artworkTitle,
      external_only: externalOnly
    });
  }

  predictArt(artist_name, artwork, similar_artworks) {
    return this.post(`${URL_PREFIX}/predict`, {
      artist_name,
      artwork,
      similar_artworks
    });
  }
}
