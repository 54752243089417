import { Box } from '@mui/material';
import { updateDiscoveryCustomColumnValueAction } from 'actions/explore/update-discovery-custom-column-value-action';
import CloseIcon from 'icons/CloseIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

export function RenderCustomBoolean({ value: payload }) {
  const [value, setValue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const val = payload?.value?.length === 0 ? null : payload?.value;
    setValue(val);
  }, [payload?.value]);

  const updateColumn = (newVal) => {
    dispatch(
      updateDiscoveryCustomColumnValueAction(
        payload?.listId,
        payload?.companyListMetaId,
        payload?.columnId,
        newVal
      )
    );
  };

  const onValueChange = () => {
    let newVal = null;
    if (value === true) {
      newVal = false;
    }
    if (value === false) {
      newVal = null;
    }
    if (value === null) {
      newVal = true;
    }
    setValue(newVal);
    updateColumn(newVal);
  };

  return (
    <Box
      onClick={onValueChange}
      sx={{ width: '100%', cursor: 'pointer' }}
      display="flex"
      justifyContent="center">
      {value === false && (
        <CloseIcon color={appTheme.palette.primary.primary3_50} strokeWidth="2.5" />
      )}
      {value === true && <ToastIcons type="success" size={36} contained={false} />}
      {value === null && <Box sx={{ width: '50px', height: '50px' }}></Box>}
    </Box>
  );
}

RenderCustomBoolean.propTypes = {
  value: PropTypes.bool
};
