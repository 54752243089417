import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import TopMentionsElement from './TopMentionsElement';

export default function CustomElement({ type, data, cardFilters }) {
  const Element = useMemo(() => {
    switch (type) {
      case 'top_mentions':
        return <TopMentionsElement data={data} cardFilters={cardFilters} />;
      default:
        return <React.Fragment></React.Fragment>;
    }
  }, [type, data, cardFilters]);
  return Element;
}

CustomElement.propTypes = {
  type: PropTypes.string,
  cardFilters: PropTypes.array
};
