import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography
} from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import CompanyActionsComponent from 'components/summary/company/CompanyActionsComponent';
import CompanyDetailsComponent from 'components/summary/company/CompanyDetailsComponent';
import SummaryCompanyDescriptionComponent from 'components/summary/company/SummaryCompanyDescriptionComponent';
import { useAppState } from 'hooks/state-context';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRunCompanyLogoUrlById, getRunCompanyNameById } from 'selectors/runs';
import { appTheme } from 'theme';

const SCROLL_THRESHOLD = 150;

function MobileCompanySummaryComponent({ recordId, insightsbyGroups, elementsByGroups }) {
  const { state } = useAppState();
  const name = getRunCompanyNameById(state, recordId);
  const logoUrl = getRunCompanyLogoUrlById(state, recordId);
  const [expendDetails, setExpendDetails] = useState(false);

  return (
    <Stack
      direction="row"
      gap="16px"
      sx={{
        display: {
          xs: 'flex',
          md: 'none'
        },
        backgroundColor: 'primary.primary1',
        position: top === 0 ? 'initial' : 'sticky',
        top: '50px',
        zIndex: 'overviewSummary'
      }}>
      <Accordion
        expanded={expendDetails}
        onChange={() => setExpendDetails(!expendDetails)}
        sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ArrowIcon color={appTheme.palette.primary.white} rotation={180} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: 'primary.primary1' }}>
          <Typography
            variant="h1"
            color="primary.white"
            textAlign="left"
            sx={{ flex: 1, textAlign: 'left' }}>
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'primary.primary1' }}>
          <Stack
            direction="column"
            gap="16px"
            sx={{
              flex: 1,
              gap: '16px',
              margin: '16px 16px 0 0'
            }}>
            <Stack direction="row" gap="16px" alignItems="center">
              <Box
                component="img"
                src={logoUrl}
                sx={{
                  width: '40px',
                  height: '40px'
                }}
              />
              <CompanyActionsComponent
                recordId={recordId}
                insightsbyGroups={insightsbyGroups}
                elementsByGroups={elementsByGroups}
              />
            </Stack>
            <CompanyDetailsComponent recordId={recordId} />
            <SummaryCompanyDescriptionComponent
              recordId={recordId}
              scrollThreshold={SCROLL_THRESHOLD}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

MobileCompanySummaryComponent.propTypes = {
  recordId: PropTypes.string,
  backTag: PropTypes.string,
  backHref: PropTypes.string,
  addBottomPadding: PropTypes.bool,
  insightsbyGroups: PropTypes.object,
  elementsByGroups: PropTypes.object
};

export default wrapWithError(MobileCompanySummaryComponent);
