import { ACTIONS } from 'constants/actions';

export function updateCurrentPersonsAutcomplete(term, status, suggestions) {
  return { type: ACTIONS.UPDATE_PERSONS_SEARCH_AUTOCOMPLETE, term, status, suggestions };
}

export function clearCurrentPersonsAutocomplete(term) {
  return { type: ACTIONS.REMOVE_PERSONS_SEARCH_AUTOCOMPLETE, term };
}

export function makeNewPersonsAutocompleteAction(term) {
  return (_state, dispatch, apiClient) => {
    apiClient.persons.submitAutocomplete(term).then((res) => {
      const status = res.ok ? 'success' : 'failure';
      dispatch(updateCurrentPersonsAutcomplete(term, status, res.data.suggestions));
    });
  };
}
