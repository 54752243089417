import { updateExploreCompanyIsCheckedByCompanyListMetaId } from 'actions/explore-table-ui/update-discovery-ui-state-by-company-meta-id';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';
import { getUserFeatureFlags } from 'selectors/user';

import RenderBulkCompanyName from './RenderBulkCompanyName';

export default function RenderBulkCompanyNameConnector({ value, ...args }) {
  const dispatch = useDispatch();
  const { companyListMetaId, companyListId } = value;
  const selectedCompanies = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, companyListId)
  );
  const { state } = useAppState();
  const canPeerFinance =
    getUserFeatureFlags(state)?.includes('peer_finance') && value?.allowPeerFinance;

  const showCheckBox = selectedCompanies && Object.keys(selectedCompanies).length > 0;
  const isChecked = Boolean(selectedCompanies?.[companyListMetaId]);
  const onCheck = useCallback(
    (checked) => {
      dispatch(
        updateExploreCompanyIsCheckedByCompanyListMetaId(companyListId, companyListMetaId, checked)
      );
    },
    [companyListMetaId, companyListId, dispatch]
  );
  return (
    <RenderBulkCompanyName
      value={value}
      showCheckBox={showCheckBox}
      isChecked={isChecked}
      onCheck={onCheck}
      canPeerFinance={canPeerFinance}
      {...args}
    />
  );
}

RenderBulkCompanyNameConnector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
