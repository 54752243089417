import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BaseTooltipComponent from '../BaseTooltipComponent';

/* eslint-disable id-length */
function StackedBarTooltipContent({ payload }) {
  if (!payload || payload.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <BaseTooltipComponent
      label={
        payload[0].payload?.benchmarkLabel?.top_peer
          ? `${payload[0].payload.label} - ${payload[0].payload.benchmarkLabel.tooltip}`
          : payload[0].payload.label
      }>
      {payload.map((entry, index) => (
        <Box
          key={index}
          sx={{ display: 'flex', gap: '16px', alignItems: 'center', cursor: 'pointer' }}>
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              backgroundColor: entry.color,
              borderRadius: '50%'
            }}
          />
          <Typography variant="paragraph" sx={{ flex: 1, textAlign: 'left' }}>
            {entry.name}
          </Typography>
          <Typography variant="paragraphSemibold">{entry.value}</Typography>
        </Box>
      ))}
    </BaseTooltipComponent>
  );
}

StackedBarTooltipContent.propTypes = {
  payload: PropTypes.array
};

export default StackedBarTooltipContent;
