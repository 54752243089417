import BaseClient from './BaseClient';

const URL_PREFIX = 'discoveries';

export default class DiscoveriesClient extends BaseClient {
  submitDiscovery(payload) {
    return this.post(URL_PREFIX, payload);
  }

  getDiscoveryStatuses(discoveryId) {
    return this.get(`${URL_PREFIX}/${discoveryId}/statuses`);
  }

  fetchDiscoveryProgress(discoveryId) {
    return this.get(`${URL_PREFIX}/${discoveryId}/progress`);
  }

  changeDiscoveryName(discoveryId, newName) {
    const payload = { name: newName };
    return this.put(`${URL_PREFIX}/${discoveryId}/change_name`, payload);
  }

  getDiscoverySuggestions(userPrompt) {
    const payload = { prompt: userPrompt };
    return this.post(`${URL_PREFIX}/suggestions`, payload);
  }
}
