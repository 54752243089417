import { Typography } from '@mui/material';
import { deletePersonDataAction } from 'actions/persons/delete-person-data-action';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import { FOLDERS_PREFIX } from 'constants/app-routes';
import { TILE_TYPE } from 'constants/tiles';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFirstParentFolderIdOfResource } from 'selectors/folders';
import {
  getPersonNameById,
  getPersonProgressById,
  getPersonRecordIdById,
  getPersonTimestampById
} from 'selectors/persons';

import FinishedRunningSearch from '../FinishedRunningSearch';

export default function RunningSearchAction({ id, mode, recentlyFinished }) {
  const { state } = useAppState();
  const navigate = useNavigate();
  const searchTimestamp = getPersonTimestampById(state, id);
  const progress = getPersonProgressById(state, id) || 0;
  const progressExistInState = Boolean(getPersonProgressById(state, id));
  const name = getPersonNameById(state, id);
  const recordId = getPersonRecordIdById(state, id);
  const folderId = useSelector((reduxState) =>
    getFirstParentFolderIdOfResource(reduxState, TILE_TYPE.PERSON, recordId)
  );
  const handleCancel = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(deletePersonDataAction(id));
  };
  const onSeeResults = () => {
    if (folderId) {
      navigate(`${FOLDERS_PREFIX}/${folderId}?personId=${id}`);
    } else {
      navigate(`?personId=${id}`);
    }
  };

  if (mode === 'monitor' && recentlyFinished) {
    return <FinishedRunningSearch name={name} runId={id} onSeeResults={onSeeResults} />;
  }

  return (
    <RunningSearchActionBase
      {...{ progress, progressExistInState, searchTimestamp, handleCancel, mode }}>
      {mode === 'tile' ? (
        <Typography variant="tiny" color="greyColors.grey300" textAlign="left" flex={1}>
          Running Person Research
        </Typography>
      ) : (
        <Typography variant="paragraphBold" color="primary.primary100" textAlign="left" flex={1}>
          {name}
        </Typography>
      )}
    </RunningSearchActionBase>
  );
}

RunningSearchAction.propTypes = {
  id: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(['tile', 'monitor']),
  recentlyFinished: PropTypes.bool
};

RunningSearchAction.defaultProps = {
  mode: 'tile'
};
