import PropTypes from 'prop-types';

import MultipleSelectionFilerComponent from './components/MultipleSelectionFilerComponent';

function IterationColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <MultipleSelectionFilerComponent
      columnId="company_source"
      rows={rows}
      rowMap={(data) =>
        `${data?.title}${data?.iteration > 0 ? ' #' : ''}${
          data?.iteration > 0 ? data?.iteration : ''
        }`
      }
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

IterationColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default IterationColumnFilter;
