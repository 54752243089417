import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const BASIC_TIER_TITLE = 'This is a premium feature';

const BASIC_TIER_TEXT =
  'This feature is only available to premium users. Please contact the Mathlabs team and upgrade your account to access this feature.';

function HookErrorDialog({ open, onClose, errorConfig }) {
  const isBasicError = errorConfig?.status === 402;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title">
        {isBasicError ? BASIC_TIER_TITLE : errorConfig?.statusText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isBasicError ? BASIC_TIER_TEXT : errorConfig?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

HookErrorDialog.propTypes = {
  open: PropTypes.bool,
  errorConfig: PropTypes.object,
  onClose: PropTypes.func
};

export default memo(HookErrorDialog);
