import { Button, Stack, Typography } from '@mui/material';
import DotsAnimation from 'components/DotsAnimation';
import UserAvatar from 'components/UserAvatar';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import AssistantWandAvatarIcon from 'icons/AssistantWandAvatarIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'utils/objects-utils';

import CustomAnalyticsFeedMessageComponent from './CustomAnalyticsFeedMessageComponent';
import DiscoverySuggestionsComponent from './DiscoverySuggestionsComponent';
import PromptDisplayComponent from './PromptDisplayComponent';

function CustomAnalyticsThread({
  post,
  listId,
  companyMetaId,
  companies,
  enrichButtonText,
  setEnrichPostId,
  setDomainKnowledgePostId,
  shouldShowAddToDomainKnowledge,
  showToolCallHistory,
  deepDiveId,
  setPromptConfig,
  placement
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const answerType = post.message?.answer_type || post.messages?.[0]?.message?.answer_type;
  const selectedCompaniesIds = post.body?.company_meta_ids;
  const plan = post?.plan;
  const suggestions = post?.suggestions;

  const companiesByMetaId = useMemo(() => {
    if (isEmpty(companies)) {
      return companies;
    }
    return Object.values(companies).reduce((acc, company) => {
      return {
        ...acc,
        [company.companyMetaId]: company
      };
    }, {});
  }, [companies]);

  const selectedCompanies = useMemo(() => {
    if (!listId || !companiesByMetaId || !selectedCompaniesIds) {
      return null;
    }
    const fullSelectedCompanies = selectedCompaniesIds
      ?.map((company_meta_ids) => companiesByMetaId[company_meta_ids])
      .filter((company) => company);
    return fullSelectedCompanies?.reduce((acc, company) => {
      return {
        ...acc,
        [company.companyListMetaId]: {
          id: company.companyListMetaId,
          name: company.name,
          companyMetaId: company.companyMetaId,
          logoUrl: company.logo_url
        }
      };
    }, {});
  }, [selectedCompaniesIds, companiesByMetaId, listId]);

  const filterRow = (row) => {
    if (!post || !selectedCompaniesIds || !row || !row.company_meta_id) {
      return true;
    }
    return selectedCompaniesIds.includes(row.company_meta_id);
  };

  const handleCollapseClick = useCallback(() => setIsCollapsed((prevValue) => !prevValue), []);

  const renderEnrichButtons = (renderedPost) =>
    renderedPost.postId &&
    renderedPost.responseType !== 'DISCOVERY' && (
      <Stack
        direction="row"
        gap="8px"
        justifyContent="flex-end"
        sx={{ marginLeft: 'auto', paddingX: '8px', paddingBottom: '8px' }}>
        {renderedPost.postId &&
          renderedPost.status === '100%' &&
          renderedPost.query.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`) && (
            <Button
              variant="outlined"
              sx={{ alignSelf: 'flex-start', height: '32px' }}
              onClick={() => setEnrichPostId(renderedPost.postId)}>
              {enrichButtonText}
            </Button>
          )}
        {renderedPost.postId && shouldShowAddToDomainKnowledge && (
          <Button
            variant="outlined"
            sx={{ alignSelf: 'flex-start', height: '32px' }}
            onClick={() => setDomainKnowledgePostId(renderedPost.postId)}>
            Add to domain knowledge
          </Button>
        )}
      </Stack>
    );

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="row" alignItems="center" gap="16px">
        <UserAvatar width={32} height={32} />
        <PromptDisplayComponent
          listId={listId}
          companyMetaId={companyMetaId}
          prompt={post.query}
          answerType={answerType}
          selectedCompanies={selectedCompanies}
          plan={plan}
          deepDiveId={deepDiveId}
          isCollapsed={isCollapsed}
          handleCollapseClick={handleCollapseClick}
        />
      </Stack>
      {!isCollapsed && (
        <Stack direction="row" gap="16px" sx={{ boxSizing: 'border-box', width: '100%' }}>
          <Stack
            sx={{
              minWidth: 32,
              height: 32,
              backgroundColor: 'primary.primary50',
              color: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 16
            }}>
            <AssistantWandAvatarIcon />
          </Stack>
          <Stack
            direction="column"
            sx={{
              flex: 1,
              border: '1px solid',
              borderColor: 'greyColors.grey50',
              borderRadius: '4px',
              padding: '8px',
              gap: '16px',
              maxWidth: 'calc(100% - 64px)'
            }}>
            {post.postId && post.status !== '100%' && (
              <Stack gap="16px">
                <Stack direction="row" alignItems="center" gap="16px">
                  {!post.message?.answer && !post.messages && <DotsAnimation />}
                  <Typography variant="paragraphSemiBold" color="primary.primary50">
                    {post.status || '0%'}
                  </Typography>
                  <Typography textAlign="left" color="primary.primary50">
                    {post.statusMessage}
                  </Typography>
                </Stack>
              </Stack>
            )}
            {post.responseType !== 'MULTIVALUE' && post.message?.answer && (
              <CustomAnalyticsFeedMessageComponent
                listId={listId}
                companyMetaId={companyMetaId}
                body={post.message?.answer}
                followUpQuestions={post.message?.follow_up_questions}
                sources={post.message?.sources}
                reasoning={post.message?.reasoning}
                answerType={post.message?.answer_type}
                toolCallHistory={showToolCallHistory ? post.message?.tool_call_history : null}
                responseType={post?.responseType}
                placement={placement}
                deepDiveId={deepDiveId}
              />
            )}
            {post.messages && (
              <Stack gap="8px">
                {post.messages
                  ?.filter((row) => companiesByMetaId[row.company_meta_id])
                  ?.filter((row) => filterRow(row))
                  ?.map((row) => (
                    <CustomAnalyticsFeedMessageComponent
                      listId={listId}
                      companyMetaId={companyMetaId}
                      key={row.company_meta_id}
                      title={
                        companiesByMetaId[row.company_meta_id].cb_name ||
                        companiesByMetaId[row.company_meta_id].name
                      }
                      companyLogo={companiesByMetaId[row.company_meta_id].logo_url}
                      body={row.message?.answer}
                      followUpQuestions={row.message?.follow_up_questions}
                      sources={row.message?.sources}
                      reasoning={row.message?.reasoning}
                      answerType={row.message?.answer_type}
                      toolCallHistory={showToolCallHistory ? row.message?.tool_call_history : null}
                      isLoading={row.loading}
                      placement={placement}
                      deepDiveId={deepDiveId}
                    />
                  ))}
              </Stack>
            )}

            {companyMetaId && post.messages?.[0]?.message?.answer && (
              <CustomAnalyticsFeedMessageComponent
                listId={listId}
                companyMetaId={companyMetaId}
                body={post.messages[0].message.answer}
                followUpQuestions={post.messages[0].message?.follow_up_questions}
                sources={post.messages[0].message?.sources}
                reasoning={post.messages[0].message?.reasoning}
                answerType={post.messages[0].message?.answer_type}
                toolCallHistory={
                  showToolCallHistory ? post.messages[0].message?.tool_call_history : null
                }
                responseType={post.messages[0].message?.responseType}
                placement={placement}
                deepDiveId={deepDiveId}
              />
            )}

            {post.image && (
              <img style={{ maxWidth: '100%' }} src={`data:image/png;base64,${post.image}`} />
            )}
            {renderEnrichButtons(post)}
          </Stack>
        </Stack>
      )}
      {isCollapsed && renderEnrichButtons(post)}
      {!isEmpty(suggestions) && (
        <DiscoverySuggestionsComponent
          suggestions={suggestions}
          setPromptConfig={setPromptConfig}
        />
      )}
    </Stack>
  );
}

CustomAnalyticsThread.propTypes = {
  post: PropTypes.object.isRequired,
  listId: PropTypes.number.isRequired,
  thread: PropTypes.object,
  companies: PropTypes.object,
  enrichButtonText: PropTypes.string,
  setEnrichPostId: PropTypes.func,
  setDomainKnowledgePostId: PropTypes.func,
  shouldShowAddToDomainKnowledge: PropTypes.bool,
  showToolCallHistory: PropTypes.bool,
  companyMetaId: PropTypes.number,
  deepDiveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPromptConfig: PropTypes.func,
  placement: PropTypes.oneOf(Object.values(CUSTOM_ANALYTICS_PLACEMENT))
};

export default CustomAnalyticsThread;
