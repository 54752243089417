/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  teams: {}
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState: initialSliceState,
  reducers: {
    updateTeams: (sliceState, { payload }) => {
      const { teams } = payload;
      sliceState.teams = teams.reduce((acc, team) => ({ ...acc, [team.id]: team }), {});
    },
    updateTeam: (sliceState, { payload }) => {
      const { team } = payload;
      sliceState.teams[team.id] = team;
    },
    deleteTeam: (sliceState, { payload }) => {
      const { teamId } = payload;
      delete sliceState.teams[teamId];
    }
  }
});

export const { updateTeams, updateTeam, deleteTeam } = teamsSlice.actions;

export default teamsSlice.reducer;
