import { addUserData } from './add-user-data-action';

export function loadUserAction() {
  return (_state, dispatch, apiClient) => {
    apiClient.users.getUser().then((res) => {
      if (res.ok && res.data) {
        dispatch(addUserData(res.data));
      }
    });
  };
}
