import { parseNewLines } from './string-utils';

export type AnswerType = 'text' | 'number' | 'boolean' | 'links';

export type PromptConfig = {
  forEachCompany: boolean;
  searchTheWeb: boolean;
  column?: string;
  useColumn: boolean;
  columns: string[];
  useColumns: boolean;
  resource?: string;
  useDomainKnowledge: boolean;
  question: string;
  answerType: AnswerType;
  useGpt4?: boolean;
  usePlanner?: boolean;
  temperature: number;
};

type LinkObject = {
  title?: string;
  link?: string;
  url?: string;
  score?: number;
};

const linkObjectToMarkdown = (linkObject: LinkObject) => {
  return `[${linkObject.title || linkObject.link || linkObject.url}](${
    linkObject.link || linkObject.url
  })`;
};

const scoreSort = (item1: LinkObject, item2: LinkObject) => {
  if (item1?.score && item2?.score) {
    return item2.score - item1.score;
  }
  return 0;
};

export const formatAnswerBody = (body: string, answerType: AnswerType | undefined) => {
  switch (answerType) {
    case 'links':
      try {
        return JSON.parse(body)
          .sort(scoreSort)
          .map(
            (link: string | LinkObject, index: number) =>
              `${index + 1}. ${typeof link === 'string' ? link : linkObjectToMarkdown(link)}`
          )
          .join('\n');
      } catch {
        return parseNewLines(body);
      }
    default:
      break;
  }
  return parseNewLines(body);
};

export const getCustomAnalyticsMetaColumnId = (columnId: string) => `${columnId}-meta`;
