import { createTheme } from '@mui/material';

const appFontFamily = ['"Inter"', 'sans-serif'].join(',');

/* eslint-disable max-lines */
export const appTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#39397B',
      darker: '#053e85',
      primary0: '#A38DF8',
      primary25: '#846ED9',
      primary50: '#584CA7',
      primary75: '#39397B',
      primary100: '#2A2A59',
      primary150: '#0B1C38',
      primary200: '#09162D',
      primary1: '#0B1C38',
      primary2: '#0096B7',
      primary3_50: '#579BFC',
      primary3_100: '#4680D2',
      primary3_150: '#3564A8',
      white: '#ffffff'
    },
    secondary: {
      main: '#ffffff',
      secondary1: '#0F2345',
      secondary2: '#0013C3',
      secondary3: '#99E0FF',
      secondary3_50: '#99E0FF',
      secondary3_100: '#00B1FF',
      secondary4: '#6B7393',
      secondary5_50: '#7F82A4',
      secondary5_100: '#5C5E76',
      secondary6: '#000B75',
      secondary9: '#5F71F6'
    },
    background: {
      main: '#000000',
      bg50: '#F8F9FF',
      bg75: '#F4F5FD',
      bg100: '#E7EBF6',
      bg150: '#D6DCED',
      bg200: '#B8C0D9',
      bg250: '#838EB1'
    },
    sentiment: {
      red: '#F20530',
      orange: '#FF8811',
      yellow: '#F3BA2F',
      lightGreen: '#78E324',
      darkGreen: '#04BD00'
    },
    greyColors: {
      grey50: '#E7EBF1',
      grey100: '#D7DCE4',
      grey150: '#A5AEBD',
      grey200: '#848FA2',
      grey250: '#637188',
      grey300: '#42526E'
    },
    graphColors: {
      graph1: '#579BFC',
      graph2: '#374EF4',
      graph3: '#807EF1',
      graph4: '#7E3B8A',
      graph5: '#E2445C',
      graph6: '#9CD326',
      graph7: '#24A3A0',
      graph8: '#87CAB9',
      graph9: '#FFD549',
      graph10: '#C45CD6',
      graph11: '#EF8B86',
      graph12: '#536E15'
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff'
    },
    indicator: {
      positive: '#04BD00',
      negative: '#F20530',
      info: '#374EF4'
    },
    coding: {
      coding1: '#B5186D',
      coding2: '#9A2FC5',
      coding3: '#0072C0',
      coding4: '#0593A2',
      coding5: '#FF8811'
    }
  },
  typography: {
    fontFamily: appFontFamily,
    h1: {
      fontSize: 24,
      lineHeight: '29px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    h2: {
      fontSize: 20,
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    h3: {
      fontSize: 20,
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    h4: {
      fontSize: 16,
      lineHeight: '19.36px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    paragraph: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: appFontFamily
    },
    paragraphMedium: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    paragraphSemiBold: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    },
    paragraphBold: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    tiny: {
      fontSize: 12,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: appFontFamily
    },
    tinyBold: {
      fontSize: 12,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: appFontFamily
    },
    largeNumber: {
      fontSize: 48,
      lineHeight: '58px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontFamily: appFontFamily
    }
  },
  zIndex: {
    topDialog: 1301,
    statusMonitor: 101,
    topBar: 100,
    searchBar: 99,
    overviewTabs: 99,
    overviewSummary: 98
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.primary25,
          height: '4px'
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: ({ theme }) => ({
          color: theme.palette.primary.white,
          marginLeft: '8px',
          marginRight: '8px',
          paddingLeft: '8px',
          paddingRight: '8px',
          textTransform: 'none',
          ...theme.typography.paragraphMedium,
          '&:hover': {
            color: theme.palette.primary.primary25
          },
          '&.Mui-selected': {
            color: theme.palette.primary.white
          }
        }),
        textColorSecondary: ({ theme }) => ({
          color: theme.palette.greyColors.grey300,
          textTransform: 'none',
          ...theme.typography.paragraph,
          '&:hover': {
            color: theme.palette.secondary.secondary3_100
          },
          '&.Mui-selected': {
            color: theme.palette.greyColors.grey300
          }
        })
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          whiteSpace: 'nowrap',
          textTransform: 'none',
          backgroundColor: 'white',
          width: '100px',
          padding: '4px',
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderLeft: '1px solid',
            borderColor: theme.palette.greyColors.grey100
          },
          '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.primary.primary50,
            color: theme.palette.primary.white,
            borderColor: theme.palette.primary.primary50
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        },
        containedPrimary: ({ theme }) => ({
          ...theme.typography.paragraphMedium,
          backgroundColor: theme.palette.primary.primary50,
          '&:hover': {
            backgroundColor: theme.palette.primary.primary75
          },
          '&.Mui-pressed': {
            backgroundColor: theme.palette.primary.primary100
          }
        }),
        textPrimary: ({ theme }) => ({
          ...theme.typography.paragraphBold,
          color: theme.palette.primary.primary50,
          '&:hover': {
            color: theme.palette.primary.primary75
          },
          '&.Mui-pressed': {
            color: theme.palette.primary.primary100
          }
        }),
        outlinedPrimary: ({ theme }) => ({
          ...theme.typography.paragraphBold,
          color: theme.palette.primary.primary50,
          '&:hover': {
            color: theme.palette.primary.primary75
          },
          '&.Mui-pressed': {
            color: theme.palette.primary.primary100
          }
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px'
        },
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.white,
          '&:hover': {
            backgroundColor: theme.palette.secondary.secondary5_50
          },
          '&.Mui-disabled': {
            color: theme.palette.secondary.secondary5_50
          }
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.palette.secondary.secondary1,
          '&:hover': {
            backgroundColor: theme.palette.background.bg75
          },
          '&.Mui-disabled': {
            color: theme.palette.background.bg150
          }
        })
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.secondary1,
          padding: '8px',
          marginTop: '4px'
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          '&.MuiMenu-paper': {
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.secondary.secondary1
          }
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          ...theme.typography.paragraphMedium,
          backgroundColor: theme.palette.secondary.secondary1,
          padding: '8px',
          marginTop: '4px'
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.secondary.secondary1
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.bg100,
          borderRadius: '4px',
          color: theme.palette.greyColors.grey300
        }),
        outlinedPrimary: ({ theme }) => ({
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: theme.palette.primary.primary3_50,
          borderRadius: '4px',
          border: '2px solid',
          fontWeight: 'bold',
          color: theme.palette.primary.primary3_50
        })
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-clearIndicator': {
            visibility: 'visible'
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '.MuiDataGrid-main': {
            border: `1px solid ${theme.palette.greyColors.grey100}`,
            borderRadius: '4px 4px 4px 4px',
            color: theme.palette.greyColors.grey300,
            backgroundColor: 'white'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-footerContainer': {
            border: 'none',
            justifyContent: 'center'
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.bg100,
            font: {
              family: theme.typography.fontFamily,
              color: theme.palette.greyColors.grey100,
              size: theme.typography.paragraphSemiBold.fontSize,
              weight: theme.typography.paragraphSemiBold.fontWeight
            }
          }
        })
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.greyColors.grey150,
          ...theme.typography.paragraph,
          '&.Mui-selected': {
            ...theme.typography.paragraphMedium,
            color: theme.palette.secondary.secondary1,
            backgroundColor: 'rgba(0,0,0,0)'
          }
        })
      }
    }
  }
});
