import React from 'react';

export default function HeadcountIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M13.7201 8.1281C13.7201 9.1981 12.8501 10.0781 11.7701 10.0781C10.6901 10.0781 9.82007 9.2081 9.82007 8.1281C9.82007 7.0481 10.6901 6.1781 11.7701 6.1781C12.8501 6.1781 13.7201 7.0481 13.7201 8.1281Z"
        fill="#8EBCFC"
      />
      <path
        d="M7.92004 14.4881C7.92004 12.2181 9.87004 10.3981 12.19 10.6181C14.21 10.8081 15.7 12.6281 15.7 14.6581V14.8281C15.7 14.9781 15.58 15.0981 15.43 15.0981H7.92004V14.4881Z"
        fill="#8EBCFC"
      />
      <path
        d="M6.45008 8.43811C4.87008 8.43811 3.58008 7.14811 3.58008 5.56811C3.58008 3.98811 4.86008 2.68811 6.45008 2.68811C8.04008 2.68811 9.32008 3.97811 9.32008 5.55811C9.32008 7.13811 8.03008 8.42811 6.45008 8.42811V8.43811Z"
        fill="#5F71F6"
      />
      <path
        d="M1.30002 15.1381H11.68C11.84 15.1381 11.96 15.0181 11.96 14.8681V14.0581C11.96 11.0381 9.50002 8.58812 6.49002 8.58812C3.48002 8.58812 1.02002 11.0381 1.02002 14.0581V14.8681C1.02002 15.0181 1.15002 15.1381 1.30002 15.1381Z"
        fill="#5F71F6"
      />
    </svg>
  );
}
