import PropTypes from 'prop-types';
import { appTheme } from 'theme';

import AssistantWandIcon from './AssistantWandIcon';

export default function AssistantWandAvatarIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={appTheme.palette.primary.primary50} />
      <AssistantWandIcon size={size} color={'white'} />
    </svg>
  );
}

AssistantWandAvatarIcon.propTypes = {
  size: PropTypes.number
};

AssistantWandAvatarIcon.defaultProps = {
  size: 24
};
