import React from 'react';

export default function LocationIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M8.49994 14.6699C11.0184 14.6699 13.0599 14.1103 13.0599 13.4199C13.0599 12.7296 11.0184 12.1699 8.49994 12.1699C5.98152 12.1699 3.93994 12.7296 3.93994 13.4199C3.93994 14.1103 5.98152 14.6699 8.49994 14.6699Z"
        fill="#8EBCFC"
      />
      <path
        d="M8.49994 1.32996C7.35994 1.32996 6.25994 1.78996 5.44994 2.58996C4.64994 3.39996 4.18994 4.49996 4.18994 5.63996C4.18994 6.56996 4.48994 7.47996 5.05994 8.22996C5.05994 8.22996 5.17994 8.37996 5.19994 8.40996L8.50994 13.62L11.8099 8.40996L11.9499 8.22996C12.5099 7.48996 12.8199 6.57996 12.8199 5.63996C12.8199 4.49996 12.3599 3.39996 11.5599 2.58996C10.7399 1.78996 9.63994 1.32996 8.49994 1.32996ZM8.49994 7.20996C8.18994 7.20996 7.88994 7.11996 7.62994 6.94996C7.36994 6.77996 7.16994 6.52996 7.04994 6.24996C6.92994 5.95996 6.89994 5.64996 6.95994 5.33996C7.01994 5.03996 7.16994 4.75996 7.38994 4.53996C7.60994 4.31996 7.88994 4.16996 8.18994 4.10996C8.48994 4.04996 8.80994 4.07996 9.09994 4.19996C9.38994 4.31996 9.62994 4.51996 9.79994 4.77996C9.96994 5.03996 10.0599 5.33996 10.0599 5.64996C10.0599 6.06996 9.88994 6.45996 9.59994 6.75996C9.30994 7.05996 8.90994 7.21996 8.48994 7.21996L8.49994 7.20996Z"
        fill="#5F71F6"
      />
    </svg>
  );
}
