import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import { getThreadKey } from 'reducer/custom-analytics';
import { mapMetaIdToListMetaId } from 'selectors/companyMetas';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';

import { updateBulkOfCustomColumnValuesAction } from './bulk-update-custom-columns-values';

export function updateColumnCustomAnalyticsPostAction(listId, postId, existColumnId, onFinish) {
  const getColumnValue = (data, recordId) => {
    return {
      answer: data.message.answer,
      answer_type: data.message.answer_type,
      reasoning: data.message.reasoning,
      sources: data.message.sources ? data.message.sources : null,
      custom_analytics_record_id: recordId,
      company_meta_id: data.company_meta_id
    };
  };
  return (dispatch, getState) => {
    const state = getState();
    const threadKey = getThreadKey(listId);
    const post = getCustomAnalyticsThreadSpecificPostById(state, threadKey, postId);
    const metaIdToListMetaId = mapMetaIdToListMetaId(state, listId);

    dispatch(editDiscoveryCustomColumnInfoAction(listId, existColumnId, post.body));
    const customColumnValues = post.messages.map((row) => ({
      company_list_meta_id: metaIdToListMetaId[row.company_meta_id],
      value: getColumnValue(row, post.recordId)
    }));

    dispatch(
      updateBulkOfCustomColumnValuesAction(listId, existColumnId, customColumnValues, onFinish)
    );
  };
}
