import { Box, Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import MultiSelectComponent from 'components/MultiSelectComponent';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

function FiltersComponent({ title, labels, onChange, checked }) {
  const [checkedStatusSet, setCheckedStatusSet] = useState(checked);

  useEffect(() => {
    setCheckedStatusSet(checked);
  }, [checked, labels]);

  const allLabels = useMemo(
    () => labels.filter((label) => Boolean(label)).map((label) => ({ id: label, name: label })),
    [labels]
  );

  const handleSelect = (newLabels) => {
    setCheckedStatusSet(newLabels);
    onChange?.(newLabels);
  };

  const determineRequiredMessage = () => {
    if (!labels?.length) {
      return 'Please select a broader scope of partner database or location';
    }
    return 'Please select at least one value';
  };

  return (
    <Stack>
      <Box>
        <Stack gap="16px" sx={{ padding: '8px 16px' }}>
          <Typography variant="paragraphSemiBold" color="greyColors.grey300">
            {title}
          </Typography>
          <MultiSelectComponent
            maxWidth={400}
            selectedValues={checkedStatusSet?.filter((value) => value)}
            allValues={allLabels}
            onValueChange={handleSelect}
            allTag="All"
          />
          {checkedStatusSet?.length === 0 && (
            <Typography variant="paragraph" sx={{ color: 'sentiment.red', marginLeft: 'auto' }}>
              {determineRequiredMessage()}
            </Typography>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

FiltersComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  onChange: PropTypes.func,
  checked: PropTypes.array,
  required: PropTypes.bool
};

export default wrapWithError(FiltersComponent);
