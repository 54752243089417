import { translateNonMathlabsRecordForState } from 'actions/dev_and_or_admin/load-all-non-mathlabs-searches';
import { ACTIONS } from 'constants/actions';

import { translatePersonRecordForState } from '../persons/load-all-persons-action';
import { translateRecordForState } from '../runs/load-all-runs-action';

function addUserSearches(runs, discoveries, persons) {
  return { type: ACTIONS.ADD_USER_SEARCHES, runs, discoveries, persons };
}

export function loadAllUserSearchesAction(userId) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.fetchAllUserSearches(userId).then((res) => {
      if (res.ok && res.data) {
        const runsData = res.data?.runs?.records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateRecordForState(data) };
        }, {});
        const discoveriesData = res.data?.discoveries?.records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateNonMathlabsRecordForState(data) };
        }, {});
        const personsData = res.data?.persons?.records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translatePersonRecordForState(data) };
        }, {});
        dispatch(addUserSearches(runsData, discoveriesData, personsData));
      }
    });
  };
}
