import { updateCustomAnalytics } from 'reducer/admin-slice';

export function loadAllAdminCustomAnalytics(excludeMathlabs = true) {
  return (dispatch, _getState, apiClient) => {
    apiClient.customAnalytics.fetchAdminCustomAnalytics(excludeMathlabs).then((res) => {
      if (res.ok && res.data.records) {
        dispatch(updateCustomAnalytics({ records: res.data.records }));
      }
    });
  };
}
