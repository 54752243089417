/* eslint-disable max-lines */

/* eslint-disable max-lines-per-function */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { getCompanyListColumnHistoryAction } from 'actions/company_extra_data/get-company-list-column-history-action';
import {
  updateCustomAnalyticsExtraDataForCompaniesInListAction,
  updateExtraDataByTypeForCompaniesInListAction
} from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { deleteDiscoveryCustomColumnNameAction } from 'actions/explore/delete-discovery-custom-column-name-action';
import { editDiscoveryCustomColumnInfoAction } from 'actions/explore/edit-discovery-custom-column-info-action';
import { editDiscoveryCustomColumnNameAction } from 'actions/explore/edit-discovery-custom-column-name-action';
import { createListFromColumnAction } from 'actions/llm_queries/check-values-contains-names-action';
import { createSchedulesByCustomAnalyticsColumnsAction } from 'actions/schedules/create-column-schedule-action';
import { deleteSchedulesByColumnsAction } from 'actions/schedules/delete-schedule-action';
import PromptDisplayComponent from 'components/discovery/PromptDisplayComponent';
import { SCHEDULE_FREQUENCIES } from 'constants/schedules';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CopyIcon from 'icons/CopyIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import InfoIcon from 'icons/InfoIcon';
import MonitoringIcon from 'icons/MonitoringIcon';
import OutlinedInfoIcon from 'icons/OutlinedInfoIcon';
import RetryIcon from 'icons/RetryIcon';
import SearchIcon from 'icons/SearchIcon';
import VisibilityOffIcon from 'icons/VisibilityOffIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateExploreHiddenColumns } from 'reducer/explore-slice';
import { getUserFeatureFlags, getUserIsDev } from 'selectors/user';

function CustomColumnMenu({ currentColumn, ...args }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [newName, setNewName] = useState(false);
  const dispatch = useDispatch();
  const { field: columnId, headerName: columnName, extraParams } = currentColumn;
  const { listId, info, allowEditHeader } = extraParams;
  const isDev = useOldStateSelector(getUserIsDev);
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const handleClose = () => setOpenDialog(false);
  const handleDeleteClose = () => setOpenDeleteDialog(false);
  const handleSettingsClose = () => setOpenSettingsDialog(false);
  const { addToast } = useAppUtils();

  const handleRename = () => {
    dispatch(editDiscoveryCustomColumnNameAction(listId, columnId, newName));
    handleClose();
  };

  const handleDelete = () => {
    dispatch(deleteDiscoveryCustomColumnNameAction(listId, columnId));
    handleClose();
  };

  const handleCopyContent = () => {
    navigator.clipboard.writeText(info?.question || info);
    handleSettingsClose();
  };
  const handleRefreshData = (event, recompute) => {
    if (
      currentColumn.type === 'predefined_custom_analytics' ||
      currentColumn.type === 'custom_analytics'
    ) {
      dispatch(
        updateCustomAnalyticsExtraDataForCompaniesInListAction(
          listId,
          currentColumn.field,
          recompute
        )
      );
    }
    if (currentColumn.type === 'extra_data_column' || currentColumn.field === 'revenues') {
      dispatch(
        updateExtraDataByTypeForCompaniesInListAction(
          listId,
          currentColumn.field.toUpperCase(),
          recompute
        )
      );
    }
    args.hideMenu(event);
    handleClose();
  };

  const handleHideColumn = (event) => {
    searchParams.append('hidden_columns', columnId);
    dispatch(updateExploreHiddenColumns({ listId, columnsIds: [columnId], isHidden: true }));
    setSearchParams(searchParams);
    args.hideMenu(event);
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };

  const createNewListFromColumn = (event) => {
    dispatch(createListFromColumnAction(listId, currentColumn, onError));
    args.hideMenu(event);
    handleClose();
  };

  const toggleColumnAutoFill = (event) => {
    if (currentColumn.type === 'custom_analytics') {
      const oldInfo = currentColumn?.extraParams?.info || {};
      const newInfo = { ...oldInfo, auto_fill: !oldInfo.auto_fill };
      dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
      if (newInfo.auto_fill) {
        dispatch(
          updateCustomAnalyticsExtraDataForCompaniesInListAction(listId, currentColumn.field)
        );
      }
    }

    args.hideMenu(event);
    handleClose();
  };

  const toggleColumnMonitor = (event) => {
    if (currentColumn.type === 'custom_analytics') {
      const oldInfo = currentColumn?.extraParams?.info || {};
      const newMonitor = !oldInfo.monitor;
      const newInfo = { ...oldInfo, monitor: newMonitor };
      dispatch(editDiscoveryCustomColumnInfoAction(listId, currentColumn.field, newInfo));
      if (newMonitor) {
        dispatch(
          createSchedulesByCustomAnalyticsColumnsAction(
            listId,
            [currentColumn.field],
            SCHEDULE_FREQUENCIES.DAILY
          )
        );
      } else {
        dispatch(deleteSchedulesByColumnsAction(listId, [currentColumn.field]));
      }
    }

    args.hideMenu(event);
    handleClose();
  };

  const addLastDataColumn = () => {
    dispatch(getCompanyListColumnHistoryAction(listId, currentColumn.field));
    args.hideMenu(event);
    handleClose();
  };

  return (
    <Stack direction="column" gap="8px" pagging="16px">
      {info && (
        <MenuItem onClick={() => setOpenSettingsDialog(true)}>
          <ListItemIcon>
            <OutlinedInfoIcon />
          </ListItemIcon>
          <ListItemText>Query</ListItemText>
        </MenuItem>
      )}
      {allowEditHeader && (
        <MenuItem onClick={() => setOpenDialog(true)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
      )}
      {allowEditHeader && (
        <MenuItem onClick={() => setOpenDeleteDialog(true)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      )}
      {(currentColumn?.type === 'predefined_custom_analytics' ||
        currentColumn?.type === 'extra_data_column' ||
        (featureFlags?.includes('column_monitoring') &&
          currentColumn?.type === 'custom_analytics') ||
        currentColumn.field === 'revenues') && (
        <MenuItem onClick={(event) => handleRefreshData(event, true)}>
          <ListItemIcon>
            <RetryIcon color="currentColor" />
          </ListItemIcon>
          <ListItemText>Refresh</ListItemText>
        </MenuItem>
      )}

      {isDev && currentColumn.field === 'revenues' && (
        <MenuItem onClick={(event) => handleRefreshData(event, false)}>
          <ListItemIcon>
            <RetryIcon color="currentColor" />
          </ListItemIcon>
          <ListItemText>Fill Column</ListItemText>
        </MenuItem>
      )}

      <MenuItem onClick={handleHideColumn}>
        <ListItemIcon>
          <VisibilityOffIcon />
        </ListItemIcon>
        <ListItemText>Hide Column</ListItemText>
      </MenuItem>

      {(currentColumn?.type?.includes('custom_analytics') ||
        currentColumn?.type === 'custom_str' ||
        currentColumn.type === 'basic_data_column') && (
        <MenuItem onClick={createNewListFromColumn}>
          <Tooltip title="Will extract names and websites of companies if the column contains any.">
            <ListItemIcon>
              <InfoIcon contained={false} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Create New Company List From Column</ListItemText>
        </MenuItem>
      )}

      {currentColumn?.type === 'custom_analytics' && (
        <MenuItem onClick={(event) => toggleColumnAutoFill(event, true)}>
          <ListItemIcon>
            <RetryIcon color="currentColor" />
          </ListItemIcon>
          <ListItemText>{`${
            currentColumn?.extraParams?.info?.auto_fill ? 'Stop A' : 'A'
          }uto fill new companies`}</ListItemText>
        </MenuItem>
      )}
      {featureFlags?.includes('column_monitoring') && currentColumn?.type === 'custom_analytics' && (
        <MenuItem onClick={(event) => toggleColumnMonitor(event)}>
          <ListItemIcon>
            <MonitoringIcon color="currentColor" />
          </ListItemIcon>
          <ListItemText>{`${
            currentColumn?.extraParams?.info?.monitor ? 'Stop' : 'Start'
          } column monitoring`}</ListItemText>
        </MenuItem>
      )}
      {featureFlags?.includes('column_monitoring') && currentColumn?.type === 'custom_analytics' && (
        <MenuItem onClick={addLastDataColumn}>
          <ListItemIcon>
            <SearchIcon color="currentColor" />
          </ListItemIcon>
          <ListItemText>See Previous values</ListItemText>
        </MenuItem>
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Rename column name</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Set the new column name
          </DialogContentText>
          <Input onChange={(event) => setNewName(event.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={handleRename} autoFocus>
            Change
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete column &quot;{columnName}&quot;</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete column &quot;{columnName}&quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSettingsDialog}
        onClose={handleSettingsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Column Query</DialogTitle>
        <DialogContent>
          <PromptDisplayComponent listId={listId} prompt={info} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCopyContent}
            startIcon={<CopyIcon color="white" />}>
            Copy Content
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

CustomColumnMenu.propTypes = {
  currentColumn: PropTypes.object,
  onError: PropTypes.func
};

export default CustomColumnMenu;
