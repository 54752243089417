import { Input } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

export function RenderEditName({ onEnterNewCompany }) {
  const [name, setName] = useState('');

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      onEnterNewCompany(name);
      return;
    }
    event.stopPropagation();
  };
  return (
    <Input
      sx={{ paddingLeft: '16px', width: '100%' }}
      disableUnderline={true}
      placeholder="Type a Company Name"
      value={name}
      onChange={(event) => setName(event.target.value)}
      onKeyDown={onDownKey}></Input>
  );
}

RenderEditName.propTypes = {
  onEnterNewCompany: PropTypes.func
};
