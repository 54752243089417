import { Box, Button, Stack, Typography } from '@mui/material';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { hideDiscoveryRunningBarInTable } from 'reducer/explore-slice';
import { getDiscoveryProgressMessageById } from 'selectors/discoveries';

export default function RenderRunningRow({ value }) {
  const dispatch = useDispatch();
  const progressMessage = useSelector((state) =>
    getDiscoveryProgressMessageById(state, value?.discoveryId)
  );

  const onHideRunningBar = () => {
    dispatch(hideDiscoveryRunningBarInTable({ discoveryId: value.discoveryId }));
  };
  if (!value?.running) {
    return null;
  }

  return (
    <Stack
      width="100%"
      height="100%"
      padding={0}
      direction="row"
      justifyContent="flex-start"
      backgroundColor="primary.primary50"
      alignItems="center">
      <Box width="500px" color="white" padding="24px">
        <RunningSearchActionBase
          progress={value.progress}
          searchTimestamp={value.timeSubmitted}
          progressExistInState={true}
          timeColor="greyColors.grey100"
        />
      </Box>
      <Box width="500px" color="white" padding="24px" display="flex" justifyContent="flex-start">
        {progressMessage && <Typography variant="paragraph">{progressMessage}</Typography>}
      </Box>
      <Button sx={{ color: 'white' }} onClick={onHideRunningBar}>
        Hide
      </Button>
    </Stack>
  );
}

RenderRunningRow.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
};
