import BaseClient from './BaseClient';

const URL_PREFIX = 'explore';

export default class ExploreClient extends BaseClient {
  fetchAllExploreLists({ num = 50, all = false, record_only = false }) {
    return this.get(
      `${URL_PREFIX}?N=${num}&all=${all ? '1' : '0'}&record_only=${record_only ? '1' : '0'}`,
      this.token
    );
  }

  fetchExploreList(listId) {
    return this.get(`${URL_PREFIX}/${listId}`);
  }

  getSemanticSearch(listId, searchTerm) {
    return this.get(
      `${URL_PREFIX}/${listId}/semantic_search?search_query=${searchTerm}`,
      this.token
    );
  }
}
