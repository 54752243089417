import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { addExploreCustomColumn } from 'reducer/explore-slice';
import { v4 as uuidv4 } from 'uuid';

export function addExploreCustomColumnAction(
  listId,
  columnName,
  info,
  type,
  customAnalyticsAnswerType,
  onCreated,
  id
) {
  return (dispatch, _getState, apiClient) => {
    const columnId = id || uuidv4();
    const columnType = type || 'custom_str';
    const columnSetting = {
      id: columnId,
      name: columnName || 'Text',
      type: columnType,
      custom_analytics_answer_type: customAnalyticsAnswerType
    };
    if (info) {
      columnSetting.info = info;
    }
    dispatch(
      sendExploreEvent(
        USER_EVENTS.CUSTOM_COLUMN_SUBMISSION,
        listId,
        `Added custom column ${columnName}`,
        {
          listId,
          columnName,
          columnType,
          id,
          ...info
        }
      )
    );
    apiClient.companiesLists.addCustomColumn(listId, columnSetting).then((res) => {
      if (res.ok && onCreated) {
        onCreated?.(columnSetting);
      }
    });
    dispatch(addExploreCustomColumn({ listId, columnSetting }));
  };
}
