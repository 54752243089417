import React from 'react';

export default function AniversaryIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <g clipPath="url(#clip0_27_2006)">
        <path
          d="M2.8877 8.48229C2.8877 8.20229 3.1077 7.98229 3.3877 7.98229H12.8877C13.1677 7.98229 13.3877 8.20229 13.3877 8.48229V10.3723H2.8877V8.48229Z"
          fill="#8EBCFC"
        />
        <path
          d="M9.33763 6.64228V7.98229H6.94763V6.64228C6.94763 6.47228 7.08763 6.34229 7.24763 6.34229C8.23763 6.34229 8.26763 6.34229 9.02763 6.34229C9.19763 6.34229 9.32763 6.47228 9.32763 6.64228H9.33763Z"
          fill="#8EBCFC"
        />
        <path
          d="M8.13751 5.63224C7.81751 5.63224 7.51751 5.50224 7.29751 5.28224C7.07751 5.06224 6.94751 4.75224 6.94751 4.44224C6.94751 4.00224 7.34751 3.28224 8.13751 2.29224C8.93751 3.28224 9.32751 4.00224 9.32751 4.44224C9.32751 4.76224 9.19751 5.06224 8.97751 5.28224C8.75751 5.50224 8.44751 5.63224 8.13751 5.63224Z"
          fill="#5F71F6"
        />
        <path
          d="M1.45752 10.8623C1.45752 10.5823 1.67752 10.3623 1.95752 10.3623H14.3275C14.6075 10.3623 14.8275 10.5823 14.8275 10.8623V15.1123C14.8275 15.3923 14.6075 15.6123 14.3275 15.6123H1.95752C1.67752 15.6123 1.45752 15.3923 1.45752 15.1123V10.8623Z"
          fill="#5F71F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_2006">
          <rect width="13.37" height="13.32" fill="white" transform="translate(1.45752 2.29224)" />
        </clipPath>
      </defs>
    </svg>
  );
}
