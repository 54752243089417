import { Avatar, Checkbox, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import SelectedChip from 'components/SelectedChip';
import ShowMoreContainerComponent from 'components/ShowMoreContainerComponent';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExploreListSelectedCompanies,
  updateExploreListCompaniesIsChecked
} from 'reducer/explore-table-ui';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';

const selectStyle = {
  minWidth: '140px',
  width: 'auto',
  height: '32px',
  textAlign: 'left',
  backgroundColor: 'primary.white',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'greyColors.grey100'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'greyColors.grey100'
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      minWidth: 200,
      maxWidth: 300
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 100
  }
};

function CompaniesMultiSelectComponent({
  listId,
  maxWidth,
  placeholder,
  allCompanies,
  handleChange
}) {
  const dispatch = useDispatch();
  const selectedCompanies = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );

  const companies = useMemo(() => {
    if (!allCompanies) return [];
    if (allCompanies instanceof Map) return Array.from(allCompanies.values());
    return Object.values(allCompanies);
  }, [allCompanies]);
  const selectedCompaniesList = useMemo(() => {
    return selectedCompanies
      ? Object.keys(selectedCompanies).map((company) => {
          const companyMetaListId = parseInt(company, 10);
          return {
            id: allCompanies.get(companyMetaListId).id,
            name: allCompanies.get(companyMetaListId).name
          };
        })
      : [];
  }, [selectedCompanies, allCompanies]);

  const isAllSelected =
    selectedCompanies && allCompanies.size === Object.keys(selectedCompanies).length;
  const isNoneSelected = !selectedCompanies || Object.keys(selectedCompanies).length <= 0;

  const handleCompaniesChange = (event, child) => {
    const companyListMetaId = child.props.value;
    const checked = !selectedCompanies?.[companyListMetaId];

    if (companyListMetaId === 'all') {
      const newSelectedComapnies = isAllSelected ? [] : Array.from(allCompanies.keys());
      dispatch(
        setExploreListSelectedCompanies({ listId, selectedComapnies: newSelectedComapnies })
      );
    } else {
      dispatch(updateExploreListCompaniesIsChecked({ listId, companyListMetaId, checked }));
    }

    handleChange?.(event.target.value);
  };

  return (
    <FormControl>
      <Select
        value={selectedCompaniesList}
        defaultValue="Select someone"
        multiple
        displayEmpty
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (isAllSelected || isNoneSelected) {
            return (
              <Typography variant="paragraphMedium" color="greyColors.grey300">
                {isAllSelected ? 'All Companies' : placeholder}
              </Typography>
            );
          }
          return (
            <ShowMoreContainerComponent
              sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 0.5 }}
              maxWidth={maxWidth}>
              {selected.map((company) => (
                <SelectedChip
                  key={company.id}
                  value={company.id}
                  label={company.name}
                  handleCloseClick={() =>
                    dispatch(
                      updateExploreListCompaniesIsChecked({
                        listId,
                        companyListMetaId: company.id,
                        checked: false
                      })
                    )
                  }
                />
              ))}
            </ShowMoreContainerComponent>
          );
        }}
        sx={selectStyle}
        onChange={handleCompaniesChange}>
        {companies.length > 0 && (
          <MenuItem
            value="all"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px'
            }}>
            <Checkbox checked={isAllSelected} />
            <Typography variant="paragraphMedium" color="greyColors.grey300">
              All Companies
            </Typography>
          </MenuItem>
        )}
        {companies.map((company) => (
          <MenuItem
            key={company.id}
            value={company.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px'
            }}>
            <Stack direction="row" gap="4px" alignItems="center">
              <Checkbox checked={Boolean(selectedCompanies?.[company.id])} />
              {company.logoUrl && (
                <Avatar src={company.logoUrl} sx={{ width: 24, height: 24 }} variant="square" />
              )}
              <Typography variant="paragraphMedium" color="greyColors.grey300">
                {company.name}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CompaniesMultiSelectComponent.propTypes = {
  listId: PropTypes.number,
  maxWidth: PropTypes.number,
  placeholder: PropTypes.string,
  allCompanies: PropTypes.object,
  handleChange: PropTypes.func
};

CompaniesMultiSelectComponent.defaultProps = {
  placeholder: ''
};

export default CompaniesMultiSelectComponent;
