import { FOLDER_RESOURCE_TYPE, RESEARCH_TYPES } from 'constants/researches';

export const getOldResourcesLoaded = (state) => state.loadedResources;
export const getResourcesSlice = (state) => state.resources;
export const getReduxResourcesLoaded = (state) => getResourcesSlice(state).loadedResources;
export const getIsDeepDivesLoaded = (state) =>
  getOldResourcesLoaded(state)[RESEARCH_TYPES.DEEP_DIVE.id];
export const getIsLeadershipLoaded = (state) =>
  getOldResourcesLoaded(state)[RESEARCH_TYPES.LEADERSHIP.id];
export const getIsExploresLoaded = (state) =>
  getReduxResourcesLoaded(state)[RESEARCH_TYPES.EXPLORE.id];
export const getIsFoldersLoaded = (state) => getReduxResourcesLoaded(state)[FOLDER_RESOURCE_TYPE];
export const getCurrentOpenResourceName = (state) =>
  getResourcesSlice(state).currentOpenResourceName;
export const getCurrentOpenResourceType = (state) =>
  getResourcesSlice(state).currentOpenResourceType;
