import { updateAllExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { addDiscoveryColumnsFromCsvAction } from 'actions/explore/add-discovery-columns-from-csv-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { addCompaniesToList } from 'reducer/explore-slice';
import {
  removeExploreCompaniesLoading,
  updateExploreCompaniesLoading
} from 'reducer/explore-table-ui';
import { getExploreCsvColumnsToUploadByListId } from 'selectors/explore-table-ui';

const BULK_SIZE = 20;

export function sendAmbiguousWebsiteEvent(companies, ambiguous_website_list_mata_ids) {
  return (dispatch) => {
    const ambiguousIds = new Set(ambiguous_website_list_mata_ids);
    companies.forEach((company) => {
      if (ambiguousIds.has(company.company_list_meta_id)) {
        const { cb_permalink, company_website, linkedin_url, name } = company.parameters;
        dispatch(
          sendUserEvent(USER_EVENTS.AUTOCOMPLETE_AMBIGUOUS_WEBSITE, {
            term: name || company.name,
            suggestion_type: 'new_suggestions',
            cb_permalink,
            company_website,
            linkedin_url
          })
        );
      }
    });
  };
}

export function addCompaniesToListAction(listId, search_terms, onEvent) {
  return async (dispatch, getState, apiClient) => {
    const start = Date.now();
    dispatch(updateExploreCompaniesLoading({ names: search_terms, listId }));
    const companiesAdded = [];
    const companiesNotFound = [];
    const companiesAlreadyExist = [];
    const errors = [];
    /* eslint-disable no-await-in-loop */
    for (let index = 0; index < search_terms.length; index += BULK_SIZE) {
      const bulk = search_terms.slice(index, index + BULK_SIZE);
      try {
        const res = await apiClient.companiesLists.addCompanies(listId, bulk);
        if (res.ok && res.data) {
          const { companies_added, not_found, already_exist, ambiguous_website_list_mata_ids } =
            res.data;
          if (companies_added?.length) {
            companiesAdded.push(...companies_added);
            dispatch(
              addCompaniesToList({
                listId,
                companyMetas: companies_added
              })
            );
          }
          if (not_found?.length) {
            companiesNotFound.push(...not_found);
          }
          if (already_exist?.length) {
            companiesAlreadyExist.push(...already_exist);
          }
          if (ambiguous_website_list_mata_ids?.length) {
            dispatch(sendAmbiguousWebsiteEvent(companies_added, ambiguous_website_list_mata_ids));
          }
        }
      } catch (exception) {
        errors.push(index);
      }
    }

    if (search_terms.length > 1) {
      const end = Date.now();
      dispatch(
        sendUserEvent(USER_EVENTS.CSV_UPLOAD_TIME, {
          num_companies: search_terms.length,
          time_in_sec: (end - start) / 1000
        })
      );
    }
    if (companiesNotFound.length) {
      dispatch(addCompaniesToList({ listId, notFound: companiesNotFound }));
    }
    if (companiesAdded.length) {
      dispatch(updateAllExtraDataForCompaniesInListAction(listId));
    }

    if (companiesAlreadyExist.length && search_terms.length === 1 && onEvent) {
      onEvent(`${search_terms[0]} already exists on this list`, TOAST_TYPES.ERROR);
    }
    dispatch(removeExploreCompaniesLoading({ names: search_terms, listId }));
    const csvColumnsToUpload = getExploreCsvColumnsToUploadByListId(getState(), listId);
    if (csvColumnsToUpload) {
      dispatch(addDiscoveryColumnsFromCsvAction(listId, csvColumnsToUpload));
    }

    if (errors.length) {
      const failedSearchTerms = errors.map((index) => search_terms[index]).join(', ');
      onEvent?.(
        `Failed to add companies: ${failedSearchTerms}, because we couldn't find companies with those names.`,
        TOAST_TYPES.ERROR
      );
    }
  };
}
