const GROUP_TITLE_MAPPING = Object.freeze({
  overview: 'Overview',
  IPTechOverviewGroup: 'Capability',
  GrowthSignalsOverviewGroup: 'Growth Signals',
  HealthRiskOverviewGroup: 'Risk and Health',
  LeadershipOverviewGroup: 'Leadership',
  B2DOverviewGroup: 'Voice of Customer',
  TeamOverviewGroup: 'Team'
});

export const GROUP_ORDER_MAPPING = Object.freeze({
  TeamOverviewGroup: 0,
  GrowthSignalsOverviewGroup: 1,
  B2DOverviewGroup: 2,
  HealthRiskOverviewGroup: 3,
  IPTechOverviewGroup: 4,
  LeadershipOverviewGroup: 5
});

export const groupSort = (item1, item2) => {
  return GROUP_ORDER_MAPPING[item1] - GROUP_ORDER_MAPPING[item2];
};

const numToCodingColor = (num) => `coding.coding${num}`;

const GROUP_COLOR_MAPPING = Object.freeze({
  IPTechOverviewGroup: numToCodingColor(2),
  GrowthSignalsOverviewGroup: numToCodingColor(4),
  HealthRiskOverviewGroup: numToCodingColor(3),
  LeadershipOverviewGroup: numToCodingColor(1),
  B2DOverviewGroup: numToCodingColor(5)
});

export function groupToTitle(group) {
  return group in GROUP_TITLE_MAPPING ? GROUP_TITLE_MAPPING[group] : group;
}

export function groupToColor(group) {
  return group in GROUP_COLOR_MAPPING ? GROUP_COLOR_MAPPING[group] : numToCodingColor(5);
}
