import { loadCompaniesAndUpdateColumns } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { handleCustomAnalyticsDiscoverySearchProgress } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { loadExplore } from 'actions/explore/load-explore-action';
import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { COMPANY_EXTRA_DATA_TYPES } from 'constants/company-extra-data';
import { dispatch } from 'hooks/AppStateProvider';
import { usePusher } from 'hooks/PusherProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiscoveryProgress } from 'reducer/explore-slice';
import { getDiscoveriesIdsByListId } from 'selectors/explore';
import { getClientId } from 'selectors/general';
import { getListChannelName } from 'utils/pusher-utils';

const processProgressDiscovery = (
  searchId,
  progress,
  message,
  reduxDisaptch,
  listId,
  knownIds,
  setKnownIds
) => {
  if (progress >= 100) {
    setTimeout(() => {
      reduxDisaptch(updateDiscoveryProgress({ searchId, progress }));
      reduxDisaptch(loadExplore(listId));
      dispatch(updateRecentlyFinishedRun(searchId, 'discoveries'));
    }, 1000);
  } else {
    if (!knownIds.includes(`${searchId}`)) {
      setKnownIds((prev) => [...prev, `${searchId}`]);
      reduxDisaptch(loadExplore(listId, null, false));
    }
    reduxDisaptch(updateDiscoveryProgress({ searchId, progress, message }));
  }
  reduxDisaptch(handleCustomAnalyticsDiscoverySearchProgress(listId, searchId, progress, message));
};

function PusherListListener({ listId }) {
  const pusher = usePusher();
  const reduxDisaptch = useDispatch();
  const [knownIds, setKnownIds] = useState([]);
  const [listChannel, setChannel] = useState(null);

  const discoveryIds = useSelector((state) => getDiscoveriesIdsByListId(state, listId));
  const currectClientId = useSelector(getClientId);

  useEffect(() => {
    if (discoveryIds && discoveryIds.length > 0) {
      setKnownIds(discoveryIds);
    }
  }, [discoveryIds]);

  useEffect(() => {
    if (!pusher) {
      return;
    }
    const channelName = getListChannelName(listId);
    const channel = pusher.subscribe(channelName);
    setChannel(channel);

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [listId, pusher]);

  useEffect(() => {
    if (listChannel && listChannel.bind) {
      listChannel.unbind('progress');
      listChannel.bind('progress', (data) => {
        const { record_id, progress: floatProgress, message } = data;
        if (record_id) {
          const progress = 100 * floatProgress;
          processProgressDiscovery(
            record_id,
            progress,
            message,
            reduxDisaptch,
            listId,
            knownIds,
            setKnownIds
          );
        }
      });
    }
  }, [listChannel, knownIds, listId, setKnownIds, reduxDisaptch]);

  useEffect(() => {
    if (!listChannel || !currectClientId) {
      return;
    }
    listChannel.bind('company_extra_data', (data) => {
      const { data_type, updated_company_meta_ids, client_id } = data;
      if (
        updated_company_meta_ids?.length &&
        (client_id !== currectClientId || data_type === COMPANY_EXTRA_DATA_TYPES.REVENUES)
      ) {
        reduxDisaptch(loadCompaniesAndUpdateColumns(listId, data_type, updated_company_meta_ids));
      }
    });
  }, [listChannel, currectClientId, reduxDisaptch, listId]);

  return null;
}

PusherListListener.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PusherListListener;
