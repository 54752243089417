import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getElementByRecordIdAndGroupType } from 'selectors/elements';

import PeopleCardInnerComponent from './PeopleCardInnerComponent';

function PeopleCardComponent({ recordId, insightId, mode }) {
  const { state } = useAppState();
  const element = getElementByRecordIdAndGroupType(state, recordId, 'LeadershipOverviewGroup');

  const idToIndex = useMemo(() => {
    if (!element.data.columns?.[0]) return;

    return element.data.columns[0].values.reduce((acc, value, index) => {
      acc[value] = index;
      return acc;
    }, {});
  }, [element.data.columns]);

  const personData = useMemo(() => {
    const rawPersonData = Object.fromEntries(
      Object.values(element.data.columns).map((value) => [
        value.name,
        value.values[idToIndex[insightId]]
      ])
    );
    return rawPersonData;
  }, [element, insightId, idToIndex]);

  return <PeopleCardInnerComponent personData={personData} mode={mode} />;
}

PeopleCardComponent.propTypes = {
  recordId: PropTypes.string,
  insightId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['normal', 'stretch']).isRequired
};

export default wrapWithError(PeopleCardComponent);
