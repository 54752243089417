import { ICON_VARIANTS } from 'constants/icons';
import PropTypes from 'prop-types';
import React from 'react';

function DeepDiveIcon({ variant }) {
  let rectFill = 'transparent';
  if (variant === ICON_VARIANTS.PRIMARY) {
    rectFill = '#2388FF';
  } else if (variant === ICON_VARIANTS.SECONDARY) {
    rectFill = 'white';
  }
  const pathFill = variant === ICON_VARIANTS.SECONDARY ? '#2388FF' : 'white';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_6102_12972)">
        <rect width="24" height="24" rx="4" fill={rectFill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.88892 5H17.1111C17.6634 5 18.1111 5.44771 18.1111 6V18C18.1111 18.5523 17.6634 19 17.1111 19H6.88892C6.33663 19 5.88892 18.5523 5.88892 18V6C5.88892 5.44772 6.33663 5 6.88892 5ZM17.1111 4H6.88892C5.78435 4 4.88892 4.89543 4.88892 6V18C4.88892 19.1046 5.78435 20 6.88892 20H17.1111C18.2157 20 19.1111 19.1046 19.1111 18V6C19.1111 4.89543 18.2157 4 17.1111 4ZM15.4934 10.6739C15.4706 10.6028 15.4449 10.5325 15.4163 10.4633C15.2816 10.1381 15.0841 9.84259 14.8352 9.5937C14.5863 9.3448 14.2909 9.14737 13.9657 9.01267C13.8964 8.984 13.8262 8.95828 13.755 8.93557V10.6739H15.4934ZM16.4268 11.2447C16.4362 11.3794 16.3259 11.489 16.1908 11.489H13.1845C13.0494 11.489 12.94 11.3795 12.94 11.2445V8.23811C12.94 8.10307 13.0496 7.99271 13.1843 8.00215C13.5597 8.02845 13.9289 8.11525 14.2776 8.25967C14.7017 8.43533 15.087 8.69279 15.4116 9.01737C15.7361 9.34195 15.9936 9.72727 16.1693 10.1514C16.3137 10.5 16.4005 10.8693 16.4268 11.2447ZM11.1249 13.3017V12.4866V9.93324C10.8874 10.009 10.6604 10.118 10.4508 10.258C10.0101 10.5525 9.66652 10.9712 9.46365 11.4609C9.26079 11.9507 9.20771 12.4896 9.31113 13.0095C9.41455 13.5295 9.66983 14.0071 10.0447 14.3819C10.4195 14.7568 10.8971 15.012 11.417 15.1155C11.937 15.2189 12.4759 15.1658 12.9657 14.9629C13.4554 14.7601 13.874 14.4165 14.1686 13.9758C14.3086 13.7662 14.4176 13.5392 14.4934 13.3017H11.94H11.1249ZM12.755 12.4866H15.1908C15.3259 12.4866 15.4362 12.5963 15.4268 12.731C15.3844 13.3363 15.1849 13.9217 14.8463 14.4286C14.4622 15.0034 13.9163 15.4514 13.2776 15.7159C12.6389 15.9805 11.9361 16.0497 11.258 15.9149C10.58 15.78 9.95719 15.4471 9.46835 14.9582C8.97952 14.4694 8.64661 13.8466 8.51174 13.1686C8.37687 12.4905 8.44609 11.7877 8.71065 11.149C8.97521 10.5103 9.42322 9.96442 9.99803 9.58034C10.5049 9.24166 11.0903 9.04222 11.6956 8.99981C11.8303 8.99037 11.94 9.10074 11.94 9.23578V11.6716V12.4866H12.755Z"
          fill={pathFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_6102_12972">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

DeepDiveIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_VARIANTS))
};

DeepDiveIcon.defaultProps = {
  variant: ICON_VARIANTS.PRIMARY
};

export default DeepDiveIcon;
