import { loadExplore } from 'actions/explore/load-explore-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { SCHEDULE_TYPE } from 'constants/schedules';
import { USER_EVENTS } from 'constants/userEvents';
import { getCompanyListSchedulesById } from 'selectors/schedules';

import { getSchedulesAction } from './get-schedules-action';

export function deleteSchedulesAction(listId, scheduleIds) {
  return async (dispatch, _getState, apiClient) => {
    dispatch(sendUserEvent(USER_EVENTS.DELETE_MONITORING_SCHEDULES, { listId, scheduleIds }));

    const deleteSchedulesPromises = scheduleIds.map((scheduleId) => {
      return new Promise((resolve) => {
        apiClient.schedules
          .deleteSchedule(scheduleId)
          .then(() => {
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    });

    await Promise.all(deleteSchedulesPromises);
    dispatch(getSchedulesAction());
    dispatch(loadExplore(listId, null, false));
    dispatch(getSchedulesAction());
  };
}

export function deleteSchedulesByRecordsAction(listId, recordIds) {
  return (dispatch, getState) => {
    const state = getState();
    const schedules = getCompanyListSchedulesById(state, Number(listId));
    const scheduleIds = schedules
      .filter((schedule) => schedule.monitoring_type === SCHEDULE_TYPE.DISCOVERY)
      .filter((schedule) => recordIds.includes(schedule.record_id))
      .map((schedule) => schedule.id);
    dispatch(deleteSchedulesAction(listId, scheduleIds));
  };
}

export function deleteSchedulesByColumnsAction(listId, columnIds) {
  return (dispatch, getState) => {
    const state = getState();
    const schedules = getCompanyListSchedulesById(state, Number(listId));
    const scheduleIds = schedules
      .filter((schedule) => schedule.monitoring_type === SCHEDULE_TYPE.CUSTOM_ANALLYTICS_COLUMN)
      .filter((schedule) => columnIds.includes(schedule.parameters.data_type))
      .map((schedule) => schedule.id);
    dispatch(deleteSchedulesAction(listId, scheduleIds));
  };
}
