export default function DataEnrichmentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_5637_10771)">
        <path
          d="M17.5588 19.5C18.2141 19.5 18.8425 19.7634 19.3058 20.2322C19.7691 20.7011 20.0294 21.337 20.0294 22C20.0294 21.337 20.2897 20.7011 20.753 20.2322C21.2164 19.7634 21.8448 19.5 22.5 19.5C21.8448 19.5 21.2164 19.2366 20.753 18.7678C20.2897 18.2989 20.0294 17.663 20.0294 17C20.0294 17.663 19.7691 18.2989 19.3058 18.7678C18.8425 19.2366 18.2141 19.5 17.5588 19.5ZM17.5588 4.5C18.2141 4.5 18.8425 4.76339 19.3058 5.23223C19.7691 5.70107 20.0294 6.33696 20.0294 7C20.0294 6.33696 20.2897 5.70107 20.753 5.23223C21.2164 4.76339 21.8448 4.5 22.5 4.5C21.8448 4.5 21.2164 4.23661 20.753 3.76777C20.2897 3.29893 20.0294 2.66304 20.0294 2C20.0294 2.66304 19.7691 3.29893 19.3058 3.76777C18.8425 4.23661 18.2141 4.5 17.5588 4.5ZM8.91176 19.5C8.91176 17.5109 9.69264 15.6032 11.0826 14.1967C12.4726 12.7902 14.3578 12 16.3235 12C14.3578 12 12.4726 11.2098 11.0826 9.8033C9.69264 8.39678 8.91176 6.48912 8.91176 4.5C8.91176 6.48912 8.13088 8.39678 6.74091 9.8033C5.35093 11.2098 3.46572 12 1.5 12C3.46572 12 5.35093 12.7902 6.74091 14.1967C8.13088 15.6032 8.91176 17.5109 8.91176 19.5Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5637_10771">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
