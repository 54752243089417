import { createSelector } from 'reselect';

export const getCurrentDiscoveryClusterLabel = (state) => state.currentDiscoveryClusterLabel;
export const getDiscoveriesClusters = (state) => state.discoveriesClusters;
export const getDiscoveryClustersByClustersId = (state, clustersId) => {
  return clustersId ? getDiscoveriesClusters(state)[clustersId] : null;
};

export const getClustersReadyById = (state, id) =>
  getDiscoveryClustersByClustersId(state, id).ready;
export const getClustersProgressById = (state, clustersId) =>
  getDiscoveryClustersByClustersId(state, clustersId).progress;
export const getClustersStatusesById = (state, clustersId) =>
  getDiscoveryClustersByClustersId(state, clustersId).statuses;
export const getClustersFailedById = (state, clustersId) => {
  const statuses = getClustersStatusesById(state, clustersId);
  if (!statuses) {
    return null;
  }
  const failed = Object.keys(statuses).filter(
    (service) => statuses[service] === 'FAILED' || statuses[service] === 'SUBMISSION_FAILED'
  );
  return failed.length > 0;
};

export const getCurrentlyRunningClusters = (state) =>
  createSelector(getDiscoveriesClusters, (clusters) => {
    return Object.keys(clusters).reduce((acc, id) => {
      if (!getClustersReadyById(state, id)) {
        acc[id] = clusters[id];
      }
      return acc;
    }, {});
  });
