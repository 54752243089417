export default function ClustersViewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <g clipPath="url(#clip0_1730_59576)">
        <rect x="3" y="3.5" width="4" height="4" rx="0.5" fill="currentColor" />
        <rect x="9" y="3.5" width="4" height="4" rx="0.5" fill="currentColor" />
        <rect x="3" y="9.5" width="4" height="4" rx="0.5" fill="currentColor" />
        <rect x="9" y="9.5" width="4" height="4" rx="0.5" fill="currentColor" />
      </g>
      <rect x="0.5" y="1" width="15" height="15" rx="1.5" stroke="currentColor" />
      <defs>
        <clipPath id="clip0_1730_59576">
          <rect
            width="10.6667"
            height="10.6667"
            fill="currentColor"
            transform="translate(2.66663 3.16669)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
