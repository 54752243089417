import { addAllDeepDiveDynamicCards } from 'reducer/deep-dive';

export function loadRunDynamicCardsAction(runId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.runs.fetchDynamicCards(runId).then((res) => {
      if (res.ok && res.data) {
        const { company_extra_data_list: cards } = res.data;
        dispatch(
          addAllDeepDiveDynamicCards({
            deepDiveId: runId,
            cards: cards.reduce((allCards, card) => {
              allCards[card.data_type] = { id: card.data_type, data: card.data };
              return allCards;
            }, {})
          })
        );
      }
    });
  };
}
