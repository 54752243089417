import { Box, Chip, Grid, Typography } from '@mui/material';
import InsightTextComponent from 'components/InsightTextComponent';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { getGraphColorByIndex } from 'utils/charts-utils';

import PieChartComponent from '../pie/PieChartComponent';
import TableComponent from '../table/TableComponent';

export default function TeamChartComponent({ data, mode }) {
  const [customFilter, setCustomFilter] = useState({
    L1:
      data.pie.data.default_tier1 in data.pie.data.data2
        ? data.pie.data.default_tier1
        : data.pie.data.data1.labels[0]
  });

  const { data1, data2 } = data.pie.data;

  const indexL1 = data1.labels.indexOf(customFilter?.L1);
  const colorL1 = getGraphColorByIndex(indexL1);

  const setL1Filter = useCallback(
    (newValue) => {
      if (newValue) {
        if (newValue === 'Other') {
          setCustomFilter({
            L1: 'Other',
            L2: data2.Other.labels[0],
            OtherLabels: data2.Other.labels
          });
        } else {
          setCustomFilter({ L1: newValue });
        }
      }
    },
    [data2?.Other?.labels]
  );

  const setL2Filter = useCallback((newValue) => {
    setCustomFilter((oldFilter) => ({ ...oldFilter, L2: newValue }));
  }, []);

  const restoreDefaultFilter = () => setCustomFilter({ L1: data.pie.data.default_tier1 });

  if (mode === 'powerpoint') {
    return (
      <React.Fragment>
        <PieChartComponent
          values={data1.values}
          labels={data1.labels}
          showAsDonut={true}
          showLegend={true}
          setClickItem={setL1Filter}
          selected={customFilter?.L1}
          mode={mode}
        />
        <PieChartComponent
          values={data2[customFilter.L1]?.values}
          labels={data2[customFilter.L1]?.labels}
          showAsDonut={true}
          showLegend={true}
          setClickItem={setL2Filter}
          overrideColor={colorL1}
          selected={customFilter.L2}
          mode={mode}
        />
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={4} sx={{ minHeight: 400 }}>
      <Grid item xs={12} md={8} sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1, width: '100%', textAlign: 'left' }}>
          <Box sx={{ display: 'flex', width: '100%', textAlign: 'left', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="paragraphBold"
                color="secondary.secondary6"
                sx={{ marginBottom: 8 }}>
                Organisational Breakdown
              </Typography>
              <InsightTextComponent sx={{ marginBottom: 24 }} text={data?.pie?.data?.description} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Chip
                label="Reset Filtering"
                variant="outlined"
                onClick={restoreDefaultFilter}
                sx={{ float: 'right', borderRadius: 20 }}
              />
            </Box>
          </Box>
          <Grid container spacing={4} sx={{ margin: 'auto' }}>
            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <PieChartComponent
                values={data1.values}
                labels={data1.labels}
                showAsDonut={true}
                showLegend={true}
                setClickItem={setL1Filter}
                selected={customFilter?.L1}
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <PieChartComponent
                values={data2[customFilter.L1]?.values}
                labels={data2[customFilter.L1]?.labels}
                showAsDonut={true}
                showLegend={true}
                setClickItem={setL2Filter}
                overrideColor={colorL1}
                selected={customFilter.L2}
                mode={mode}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1, width: '100%', textAlign: 'left' }}>
          <Typography
            variant="paragraphBold"
            color="secondary.secondary6"
            sx={{ marginBottom: 10 }}>
            Sample Profiles{customFilter.L2 && ` for ${customFilter.L2}`}
            {customFilter.L1 && ` in ${customFilter.L1}`}
          </Typography>
          <InsightTextComponent
            sx={{ marginBottom: 24 }}
            text={
              data.table?.insight?.message ||
              'Select departments and/or roles from the pie chart legends to change focus.'
            }
          />
          <TableComponent
            elementId={data.table.element_id}
            title={data.table.data.name}
            columns={data.table.data.columns}
            customFilter={customFilter}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

TeamChartComponent.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint'])
};
