import { createSlice } from '@reduxjs/toolkit';
import { TILE_TYPE_TO_ID_KEY } from 'constants/tiles';

const initialSliceState = {
  folders: {},
  currentFolderId: null
};

export const foldersSlice = createSlice({
  name: 'folders',
  initialState: initialSliceState,
  reducers: {
    addAllFolders: (sliceState, { payload }) => {
      const { folders } = payload;
      sliceState.folders = folders;
    },
    addNewFolder: (sliceState, { payload }) => {
      const { folder } = payload;
      sliceState.folders[folder.id] = folder;
    },
    updateFolder: (sliceState, { payload }) => {
      const { tmpFolderId, folder } = payload;

      if (sliceState.folders[tmpFolderId]) {
        delete sliceState.folders[tmpFolderId];
      }
      sliceState.folders[folder.id] = folder;
    },
    renameFolder: (sliceState, { payload }) => {
      const { folderId, folderName } = payload;
      if (sliceState.folders[folderId]) {
        sliceState.folders[folderId].name = folderName;
      }
    },
    removeFolder: (sliceState, { payload }) => {
      const { folderId } = payload;
      delete sliceState.folders[folderId];
    },
    updateCurrentFolderId: (sliceState, { payload }) => {
      const { folderId } = payload;
      sliceState.currentFolderId = folderId;
    },
    deleteFolderResource: (sliceState, { payload }) => {
      const { currentFolderId, id, type } = payload;
      if (sliceState.folders[currentFolderId]?.folder_resources) {
        const idKey = TILE_TYPE_TO_ID_KEY[type];
        sliceState.folders[currentFolderId].folder_resources = sliceState.folders[
          currentFolderId
        ].folder_resources.filter((resource) => {
          return resource[idKey] !== id;
        });
      }
    },
    addFolderSupportingResource: (sliceState, { payload }) => {
      const { folderId, resource } = payload;
      if (!sliceState.folders[folderId]) return;

      if (!sliceState.folders[folderId]?.supporting_resources) {
        sliceState.folders[folderId].supporting_resources = [];
      }
      sliceState.folders[folderId].supporting_resources.push(resource);
    },
    deleteFolderSupportingResource: (sliceState, { payload }) => {
      const { folderId, resourceId } = payload;
      if (!sliceState.folders[folderId]?.supporting_resources) return;
      sliceState.folders[folderId].supporting_resources = sliceState.folders[
        folderId
      ].supporting_resources.filter((resource) => resource.id !== resourceId);
    },
    moveFolderResource: (sliceState, { payload }) => {
      const { id, type, currentFolderId, newFolderId, undo } = payload;
      const fromId = undo ? newFolderId : currentFolderId;
      const toId = undo ? currentFolderId : newFolderId;
      const idKey = TILE_TYPE_TO_ID_KEY[type];
      if (fromId && sliceState.folders?.[fromId]?.folder_resources) {
        sliceState.folders[fromId].folder_resources = sliceState.folders[
          fromId
        ].folder_resources.filter((resource) => {
          return resource[idKey] !== id;
        });
      }
      if (toId) {
        if (!sliceState.folders[toId].folder_resources) {
          sliceState.folders[toId].folder_resources = [];
        }
        sliceState.folders[toId].folder_resources.push({
          [idKey]: id,
          folder_id: toId,
          last_updated: new Date().toISOString()
        });
      }
    }
  }
});

export const {
  addAllFolders,
  addNewFolder,
  updateFolder,
  renameFolder,
  deleteFolderResource,
  removeFolder,
  updateCurrentFolderId,
  moveFolderResource,
  addFolderSupportingResource
} = foldersSlice.actions;

export default foldersSlice.reducer;
