import React from 'react';

export default function CommercialRiskIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0904 20.4259C13.7962 20.4259 16.8004 17.4217 16.8004 13.7159C16.8004 10.01 13.7962 7.00586 10.0904 7.00586C6.38454 7.00586 3.38037 10.01 3.38037 13.7159C3.38037 17.4217 6.38454 20.4259 10.0904 20.4259Z"
        fill="#86AD28"
      />
      <path
        d="M10.0804 19.4159C9.07037 19.4159 8.04037 19.1459 7.11037 18.5759C4.43037 16.9359 3.58037 13.4159 5.22037 10.7359C6.86037 8.05586 10.3804 7.20586 13.0604 8.84586C14.3604 9.63586 15.2704 10.8959 15.6304 12.3759C15.9904 13.8559 15.7504 15.3859 14.9504 16.6859C13.8704 18.4459 12.0004 19.4159 10.0704 19.4159H10.0804ZM5.70037 11.0259C4.22037 13.4459 4.98037 16.6259 7.41037 18.1159C9.83037 19.5959 13.0104 18.8359 14.5004 16.4059C15.2204 15.2359 15.4404 13.8459 15.1204 12.5059C14.8004 11.1659 13.9704 10.0359 12.8004 9.31586C10.3704 7.83586 7.20037 8.59586 5.71037 11.0259H5.70037Z"
        fill="white"
      />
      <path
        d="M6.22042 20.3757C6.22042 20.3757 6.12042 20.3657 6.08042 20.3357C5.95042 20.2557 5.91042 20.0857 5.99042 19.9557L7.31042 17.8057C7.39042 17.6757 7.56042 17.6357 7.69042 17.7157C7.82042 17.7957 7.86042 17.9657 7.78042 18.0957L6.46042 20.2457C6.41042 20.3257 6.32042 20.3757 6.23042 20.3757H6.22042Z"
        fill="#86AD28"
      />
      <path
        d="M12.7904 9.99578C12.7904 9.99578 12.6904 9.98578 12.6504 9.95578C12.5204 9.87578 12.4804 9.70578 12.5604 9.57578L13.8804 7.42578C13.9604 7.29578 14.1304 7.25578 14.2604 7.33578C14.3904 7.41578 14.4304 7.58578 14.3504 7.71578L13.0304 9.86578C12.9804 9.94578 12.8904 9.99578 12.8004 9.99578H12.7904Z"
        fill="#86AD28"
      />
      <path
        d="M5.93039 10.9957C5.93039 10.9957 5.83039 10.9857 5.79039 10.9557L3.64039 9.63574C3.51039 9.55574 3.47038 9.38574 3.55038 9.25574C3.63038 9.12574 3.80038 9.08574 3.93038 9.16574L6.08039 10.4857C6.21039 10.5657 6.25038 10.7357 6.17038 10.8657C6.12038 10.9457 6.03038 10.9957 5.94038 10.9957H5.93039Z"
        fill="#86AD28"
      />
      <path
        d="M10.0903 15.4661C11.0568 15.4661 11.8403 14.6826 11.8403 13.7161C11.8403 12.7496 11.0568 11.9661 10.0903 11.9661C9.12383 11.9661 8.34033 12.7496 8.34033 13.7161C8.34033 14.6826 9.12383 15.4661 10.0903 15.4661Z"
        fill="white"
      />
      <path
        d="M20.9602 4.93599C21.1902 4.53599 21.7702 4.53599 22.0002 4.93599L30.1402 19.026L38.2802 33.116C38.5102 33.516 38.2202 34.016 37.7602 34.016H5.2102C4.7502 34.016 4.4602 33.516 4.6902 33.116L12.8302 19.026L20.9602 4.93599Z"
        fill="#8EBCFC"
      />
      <path
        d="M19.1401 5.54585C19.3701 5.14585 19.9501 5.14585 20.1801 5.54585L28.3201 19.6359L36.4601 33.7259C36.6901 34.1259 36.4001 34.6258 35.9401 34.6258H3.39013C2.93013 34.6258 2.64013 34.1259 2.87013 33.7259L11.0101 19.6359L19.1401 5.54585Z"
        fill="white"
      />
      <path
        d="M18.3302 6.07595C18.5602 5.67595 19.1402 5.67595 19.3702 6.07595L35.6502 34.2559C35.8802 34.6559 35.5902 35.1559 35.1302 35.1559H2.5802C2.1202 35.1559 1.8302 34.6559 2.0602 34.2559L18.3302 6.07595Z"
        fill="white"
      />
      <path
        d="M35.1302 35.4459H2.5802C2.2602 35.4459 1.9802 35.2859 1.8202 35.0059C1.6602 34.7359 1.6602 34.4059 1.8202 34.1259L18.1002 5.94595C18.4202 5.39595 19.3002 5.39595 19.6202 5.94595L35.8902 34.1259C36.0502 34.4059 36.0502 34.7259 35.8902 35.0059C35.7302 35.2759 35.4502 35.4459 35.1302 35.4459ZM18.8502 6.05595C18.7902 6.05595 18.6502 6.07595 18.5702 6.21595L2.3002 34.3959C2.2202 34.5459 2.2702 34.6759 2.3002 34.7259C2.3302 34.7759 2.4102 34.8859 2.5802 34.8859H35.1302C35.3002 34.8859 35.3802 34.7759 35.4102 34.7259C35.4402 34.6759 35.4902 34.5459 35.4102 34.3959L19.1402 6.21595C19.0602 6.06595 18.9102 6.05595 18.8602 6.05595H18.8502Z"
        fill="#374EF4"
      />
      <path
        d="M18.3502 13.5459C18.5802 13.1559 19.1402 13.1559 19.3702 13.5459L28.7702 29.8059C29.0002 30.1959 28.7102 30.6859 28.2602 30.6859H9.46023C9.01023 30.6859 8.73023 30.1959 8.95023 29.8059L18.3502 13.5459Z"
        fill="#374EF4"
      />
      <path
        d="M19.3403 24.4559C19.1203 24.9059 18.5903 24.9059 18.3703 24.4559L17.0703 21.7759C16.8503 21.3259 17.1203 20.7759 17.5503 20.7759H20.1403C20.5703 20.7759 20.8403 21.3359 20.6203 21.7759L19.3203 24.4559H19.3403Z"
        fill="white"
      />
      <path
        d="M18.8503 27.7359C19.4302 27.7359 19.9003 27.2658 19.9003 26.6859C19.9003 26.106 19.4302 25.6359 18.8503 25.6359C18.2704 25.6359 17.8003 26.106 17.8003 26.6859C17.8003 27.2658 18.2704 27.7359 18.8503 27.7359Z"
        fill="white"
      />
    </svg>
  );
}
