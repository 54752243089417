import React from 'react';

function OpenFolderIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.68425 18.5L8.00599 12.1291C8.06615 11.964 8.17385 11.8216 8.31469 11.7211C8.45554 11.6206 8.62283 11.5667 8.79422 11.5667H19.1583M5.68425 18.5H17.4959C17.8877 18.4999 18.2673 18.3592 18.5691 18.1021C18.871 17.8451 19.0764 17.4878 19.1498 17.0917L19.9886 12.5755C20.0086 12.4514 20.0022 12.3243 19.9697 12.203C19.9372 12.0817 19.8795 11.9692 19.8004 11.8732C19.7214 11.7772 19.6231 11.7 19.5122 11.6471C19.4013 11.5942 19.2805 11.5667 19.1583 11.5667M5.68425 18.5C5.23756 18.5 4.80916 18.3174 4.49331 17.9923C4.17745 17.6673 4 17.2264 4 16.7667V7.23333C4 6.77362 4.17745 6.33274 4.49331 6.00768C4.80916 5.68262 5.23756 5.5 5.68425 5.5H9.05275L11.5791 8.1H17.474C17.9207 8.1 18.3491 8.28262 18.665 8.60768C18.9808 8.93274 19.1583 9.37362 19.1583 9.83333V11.5667"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OpenFolderIcon;
