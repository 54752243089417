import SchemaIcon from '@mui/icons-material/Schema';
import { Box, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import IconButtonComponent from 'components/IconButtonComponent';
import CAPlanChart from 'components/custom-analytics/CAPlanChart';
import ModalComponent from 'components/modal/ModalComponent';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import CopyIcon from 'icons/CopyIcon';
import { PlanIcon } from 'icons/PlanIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey, updatePromptClipboard } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getDeepDiveDynamicCardsByDeepDiveId,
  getDeepDiveInsightsColumnsByDeepDiveId
} from 'selectors/deep-dive';
import { getListCustomColumnsByListId, getListDomainKnowledgeByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserIsDev } from 'selectors/user';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import {
  getCompaniesAndColumns,
  getDeepDiveDynamicCardsMetadata,
  promptConfigToDisplayPrefix,
  promptToPromptConfig
} from 'utils/prompts-utils';

import { ANSWER_TYPES } from './PromptInputComponent';

function PromptDisplayComponent({
  listId,
  companyMetaId,
  prompt,
  mode,
  answerType,
  selectedCompanies,
  plan,
  deepDiveId,
  isCollapsed,
  handleCollapseClick
}) {
  const { addToast } = useAppUtils();
  const [planOpen, setPlanOpen] = useState(false);
  const handlePlanClose = () => setPlanOpen(false);
  const handlePlan = (event) => {
    setPlanOpen(true);
    event.stopPropagation();
    event.preventDefault();
  };

  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const isDev = useOldStateSelector(getUserIsDev);
  const folderId = useSelector(getCurrentFolderId);
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );
  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );
  const deepDiveDynamicCards = useSelector((state) =>
    getDeepDiveDynamicCardsByDeepDiveId(state, deepDiveId)
  );
  const deepDiveInsights = useSelector((state) =>
    getDeepDiveInsightsColumnsByDeepDiveId(state, deepDiveId)
  );
  const dispatch = useDispatch();
  const question = prompt?.question || prompt;
  const answer_type = prompt?.answer_type || answerType;
  const model = prompt?.model;

  const placement = listId
    ? CUSTOM_ANALYTICS_PLACEMENT.EXPLORE
    : CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE;
  const promptConfig = useMemo(
    () => promptToPromptConfig(question, answer_type, selectedCompanies, model),
    [question, answer_type, selectedCompanies, model]
  );
  const { columns } = useMemo(() => {
    if (listId) {
      return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
    }
    if (deepDiveDynamicCards) {
      const deepDiveCards = {
        ...getDeepDiveDynamicCardsMetadata(deepDiveDynamicCards),
        ...deepDiveInsights
      };

      return { columns: deepDiveCards };
    }
    return { columns: {} };
  }, [rows, customColumns, listId, columnsSettings, isDev, deepDiveDynamicCards, deepDiveInsights]);

  const promptDisplayPrefix = useMemo(() => {
    const localConfig = { ...promptConfig };
    return promptConfigToDisplayPrefix(localConfig, columns, domainKnowledge, placement);
  }, [promptConfig, columns, domainKnowledge, placement]);

  const handleCopyClick = () => {
    const threadKey = getThreadKey(listId, companyMetaId);
    dispatch(updatePromptClipboard({ threadKey, promptConfig }));
    addToast('Question settings copied to current search', TOAST_TYPES.SUCCESS);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      sx={{
        border: mode === 'plain' ? 'none' : '1px solid',
        borderColor: 'greyColors.grey50',
        borderRadius: '4px',
        padding: '8px',
        flex: 1
      }}>
      <Stack direction="row" alignItems="center" gap="4px" flexWrap="wrap">
        {promptDisplayPrefix && (
          <Typography textAlign="left" variant="paragraphMedium">
            {promptDisplayPrefix}
          </Typography>
        )}
        <Typography textAlign="left" variant="paragraph">
          {promptConfig.question}
        </Typography>
      </Stack>
      {mode !== 'plain' && (
        <Stack direction="row" gap="8px" alignItems="center">
          {answer_type && answer_type !== 'text' && (
            <Box sx={{ padding: 0 }}>{ANSWER_TYPES[promptConfig.answerType]?.icon}</Box>
          )}
          {isDev && plan && plan.steps.length > 0 && (
            <IconButtonComponent sx={{ padding: 0 }} tag="Plan" onClick={handlePlan}>
              {<SchemaIcon />}
            </IconButtonComponent>
          )}
          {!promptConfig?.useDiscover && (
            <IconButtonComponent
              onClick={handleCopyClick}
              sx={{ padding: 0 }}
              tag="Use as starting point for new question">
              <CopyIcon />
            </IconButtonComponent>
          )}
          <IconButtonComponent
            onClick={handleCollapseClick}
            tag={isCollapsed ? 'Show answer' : 'Hide answer'}
            sx={{ transform: isCollapsed ? 'rotate(90deg)' : 'rotate(180deg)' }}>
            <ArrowIcon />
          </IconButtonComponent>
        </Stack>
      )}{' '}
      {isDev && plan && (
        <ModalComponent
          title={promptConfig.question}
          titleIcon={<PlanIcon />}
          open={planOpen}
          onClose={handlePlanClose}>
          <Stack padding="16px">
            <Typography variant="paragraph">
              Time: {Math.round(plan.time_taken_to_generate)}s. Cost: $
              {Math.round(plan.cost * 10000) / 10000}.
            </Typography>
            <CAPlanChart planw={plan.steps} />
          </Stack>
        </ModalComponent>
      )}
    </Stack>
  );
}

PromptDisplayComponent.propTypes = {
  listId: PropTypes.number,
  prompt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mode: PropTypes.string,
  answerType: PropTypes.string,
  selectedCompanies: PropTypes.array,
  plan: PropTypes.object,
  companyMetaId: PropTypes.number,
  deepDiveId: PropTypes.number,
  isCollapsed: PropTypes.bool,
  handleCollapseClick: PropTypes.func
};

export default PromptDisplayComponent;
