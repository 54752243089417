import { makeNewDiscoveryAction } from 'actions/discoveries/make-new-discovery-action';
import { getDiscoverySuggestionsAction } from 'actions/explore/get-discovery-suggestions-action';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import { DISCOVERY_TYPES, DISCOVERY_TYPES_TITLES } from 'constants/discovery';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostStatusToThread,
  getThreadKey
} from 'reducer/custom-analytics';
import {
  getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId,
  getCustomAnalyticsThreadIdById
} from 'selectors/custom-analytics';
import { getExploreTableSelectedCompanies } from 'selectors/explore-table-ui';

export function handleCustomAnalyticsDiscoverySearchProgress(listId, searchId, progress, message) {
  const threadKey = getThreadKey(listId);

  return (dispatch, getState) => {
    const state = getState();
    const postId = getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId(
      state,
      threadKey,
      searchId
    );
    if (!postId) {
      return;
    }

    if (progress) {
      dispatch(
        addPostStatusToThread({
          threadKey,
          postId,
          status: `${Math.round(progress)}%`,
          statusMessage: message || 'Discovery in progress'
        })
      );
    }

    if (progress >= 100) {
      const threadId = getCustomAnalyticsThreadIdById(state, threadKey);

      dispatch(
        addPostAnswerToThread({
          threadKey,
          threadId,
          postId,
          message: { answer: { listId, searchId } },
          responseType: 'DISCOVERY'
        })
      );
    }
  };
}

export function createNewDiscoveryInCustomAnalyticsThreadAction(
  listId,
  discoverMethod,
  filters,
  searchTerm,
  url
) {
  return (dispatch, getState) => {
    const postId = new Date().getTime();
    const query = `${PROMPT_PREFIX.DISCOVER.title} by ${DISCOVERY_TYPES_TITLES[discoverMethod]}`;
    const params = {};
    if (discoverMethod === DISCOVERY_TYPES.TEXT) {
      params.searchTerm = searchTerm;
    } else if (discoverMethod === DISCOVERY_TYPES.PEERS) {
      const companyListMetaIds = getExploreTableSelectedCompanies(getState(), listId).map(
        (suggestion) => suggestion.companyListMetaId
      );
      params.peers = companyListMetaIds;
    } else if (discoverMethod === DISCOVERY_TYPES.URL) {
      params.url = url;
    }
    const body = {
      question: query,
      discoverMethod,
      params
    };
    const threadKey = getThreadKey(listId);

    dispatch(
      makeNewDiscoveryAction(
        {
          company_list_id: listId,
          filters,
          trigger_service: true,
          user_search_term: searchTerm,
          url: url
        },
        (discoveryId) => {
          dispatch(addPostQueryToThread({ threadKey, postId, query, body, discoveryId }));
          if (searchTerm && discoverMethod === DISCOVERY_TYPES.TEXT) {
            dispatch(getDiscoverySuggestionsAction(threadKey, postId, searchTerm));
          }
        }
      )
    );
  };
}
