export const getCurrentSearchAutocomplete = (state) => state.currentSearchAutocomplete;
export const getCurrentSearchAutocompleteTerm = (state) => getCurrentSearchAutocomplete(state).term;
export const getCurrentSearchAutocompleteStatus = (state) =>
  getCurrentSearchAutocomplete(state).status;
export const getCurrentSearchAutocompleteSuggestions = (state) =>
  getCurrentSearchAutocomplete(state).suggestions;
export const getCurrentSearchAutocompleteSuggestionsByType = (state, type) => {
  switch (type) {
    case 'new_suggestions':
      return getCurrentSearchAutocompleteSuggestions(state).new_suggestions;
    case 'existing_suggestions':
      return getCurrentSearchAutocompleteSuggestions(state).existing_suggestions;
    default:
      return {};
  }
};
export const getCurrentSearchRun = (state) => state.currentSearchRun;
export const getCurrentSearchRunSuggestion = (state) => getCurrentSearchRun(state).suggestion;
export const getCurrentSearchRunStatus = (state) => getCurrentSearchRun(state).status;
