import { updateExploreView } from 'reducer/explore-slice';

export function loadExploreView(companyListId, viewId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companiesLists.getView(companyListId, viewId).then((res) => {
      if (res.ok && res.data) {
        dispatch(updateExploreView({ listId: companyListId, view: res.data }));
      }
    });
  };
}
