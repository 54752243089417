import { Box } from '@mui/material';
import LevelComponent from 'components/LevelComponent';
import PropTypes from 'prop-types';
import React from 'react';

export default function RenderLevel({ value }) {
  return (
    <Box sx={{ width: '100%' }}>
      <LevelComponent level={value} shouldAdjustLevel={false} />
    </Box>
  );
}

RenderLevel.propTypes = {
  value: PropTypes.number
};
