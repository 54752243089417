import HomePageCompanyTile from 'components/tile/home-page/HomePageCompanyTile';
import HomePageDiscoveryTile from 'components/tile/home-page/HomePageDiscoveryTile';
import HomePagePersonTile from 'components/tile/home-page/HomePagePersonTile';
import DeepDiveIcon from 'icons/DeepDiveIcon';
import ExploreIcon from 'icons/ExploreIcon';
import LeadershipIcon from 'icons/LeadershipIcon';

export const FOLDER_RESOURCE_TYPE = 'folders';
export const RESEARCH_TYPES = Object.freeze({
  EXPLORE: {
    id: 'explore',
    title: 'Explore',
    description:
      'Conduct custom analyses of company portfolios using GenAI to uncover high-potential investment opportunities and innovation themes.',
    Icon: ExploreIcon,
    Tile: HomePageDiscoveryTile,
    primaryColor: '#24A3A0'
  },
  DEEP_DIVE: {
    id: 'deep-dive',
    title: 'Deep Dive',
    description:
      'Generate comprehensive company deep dive reports analyzing organizational capabilities, user perception, technology assets, growth signals, and more.',
    Icon: DeepDiveIcon,
    Tile: HomePageCompanyTile,
    primaryColor: '#2388FF'
  },
  LEADERSHIP: {
    id: 'leadership',
    title: 'Leadership',
    description:
      'Evaluate the scaling experience, technical expertise, commercial capabilities, and public visibility of key leaders.',
    Icon: LeadershipIcon,
    Tile: HomePagePersonTile,
    primaryColor: '#8ABA22'
  },
  ALL: { id: 'all', title: 'All' }
});

export const researchIdToTypeMap = Object.freeze({
  [RESEARCH_TYPES.EXPLORE.id]: RESEARCH_TYPES.EXPLORE,
  [RESEARCH_TYPES.DEEP_DIVE.id]: RESEARCH_TYPES.DEEP_DIVE,
  [RESEARCH_TYPES.LEADERSHIP.id]: RESEARCH_TYPES.LEADERSHIP
});
