import MultipleSelectionFilerComponent from 'components/elements/table/filters/columns/components/MultipleSelectionFilerComponent';
import PropTypes from 'prop-types';

function FoundingRoundColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <MultipleSelectionFilerComponent
      columnId="last_equity_round_type"
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
      rowMap={(data) => data?.data}
    />
  );
}

FoundingRoundColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default FoundingRoundColumnFilter;
