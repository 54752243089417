import { ICON_VARIANTS } from 'constants/icons';
import PropTypes from 'prop-types';
import React from 'react';

function FolderIcon({ variant }) {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.77778 5.5H9.33333L12 8.1H18.2222C18.6937 8.1 19.1459 8.28262 19.4793 8.60768C19.8127 8.93274 20 9.37362 20 9.83333V16.7667C20 17.2264 19.8127 17.6673 19.4793 17.9923C19.1459 18.3174 18.6937 18.5 18.2222 18.5H5.77778C5.30628 18.5 4.8541 18.3174 4.5207 17.9923C4.1873 17.6673 4 17.2264 4 16.7667V7.23333C4 6.77362 4.1873 6.33274 4.5207 6.00768C4.8541 5.68262 5.30628 5.5 5.77778 5.5Z"
        fill={variant === ICON_VARIANTS.PRIMARY ? '#FFC95E' : 'transparent'}
        stroke={variant === ICON_VARIANTS.PRIMARY ? '#FFC95E' : 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FolderIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_VARIANTS))
};

FolderIcon.defaultProps = {
  variant: ICON_VARIANTS.PRIMARY
};

export default FolderIcon;
