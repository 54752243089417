import { Avatar } from '@mui/material';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import React from 'react';
import { getUser } from 'selectors/user';
import { stringToCodeInt, toTitleCase } from 'utils/string-utils';

function UserAvatar({ width, height }) {
  const user = useOldStateSelector(getUser);
  const name = user?.username || '';
  return (
    <Avatar
      alt={toTitleCase(name)}
      src={user?.picture}
      variant="round"
      sx={{
        width,
        height,
        backgroundColor: `graphColors.graph${((stringToCodeInt(name) + 3) % 12) + 1}`
      }}>
      {user?.picture ? null : toTitleCase(name).charAt(0)}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

UserAvatar.defaultProps = {
  width: 24,
  height: 24
};

export default UserAvatar;
