import React from 'react';

export default function Logo({ ...args }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...args}>
      <path d="M18 0L12 6.5025L24 19.5V0H18Z" fill="#C5CDFF" />
      <path d="M12 6.5025L6 0H0V24V19.5L12 6.5025Z" fill="url(#paint0_linear_7_9740)" />
      <path
        d="M0 19.4999V23.9999H24V19.4999L12 6.50244L0 19.4999Z"
        fill="url(#paint1_linear_7_9740)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7_9740"
          x1="-3.06"
          y1="3.6075"
          x2="7.02"
          y2="16.5675"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#584CA7" />
          <stop offset="1" stopColor="#584CA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7_9740"
          x1="-1.095"
          y1="20.5049"
          x2="37.305"
          y2="9.94494"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#584CA7" />
          <stop offset="1" stopColor="#D6DCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
