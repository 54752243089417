import { useDispatch, useSelector } from 'react-redux';
import { updateCustomAnalyticsOpen } from 'reducer/custom-analytics';
import { getCustomAnalyticsOpen } from 'selectors/custom-analytics';

export function useCustomAnalytics() {
  const customAnalyticsOpen = useSelector(getCustomAnalyticsOpen);
  const dispatch = useDispatch();
  const setCustomAnalyticsOpen = (open: boolean) =>
    dispatch(updateCustomAnalyticsOpen({ customAnalyticsOpen: open }));
  return [customAnalyticsOpen, setCustomAnalyticsOpen] as const;
}
