import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/objects-utils';

const initialSliceState = {
  extractedNames: null,
  extractedNamesLoading: false,
  extractedNamesTitle: null
};

export const llmQueriesSlice = createSlice({
  name: 'llm_queries',
  initialState: initialSliceState,
  reducers: {
    addExtractedNames: (sliceState, { payload }) => {
      const { names } = payload;
      sliceState.extractedNames = names;
    },
    upadteExtractedNamesLoading: (sliceState, { payload }) => {
      const { loading } = payload;
      sliceState.extractedNamesLoading = loading;
    },
    upadteExtractedNamesTitle: (sliceState, { payload }) => {
      const { title } = payload;
      sliceState.extractedNamesTitle = title;
    },
    deleteExtractedNames: (sliceState) => {
      sliceState.extractedNames = null;
      sliceState.extractedNamesLoading = false;
      sliceState.extractedNamesTitle = null;
    },
    removeExtractedName: (sliceState, { payload }) => {
      const { name } = payload;
      const newNames = sliceState.extractedNames.filter((originalName) => originalName !== name);
      if (isEmpty(newNames)) {
        sliceState.extractedNames = null;
        sliceState.extractedNamesLoading = false;
        sliceState.extractedNamesTitle = null;
      } else {
        sliceState.extractedNames = newNames;
      }
    }
  }
});

export const {
  addExtractedNames,
  deleteExtractedNames,
  upadteExtractedNamesLoading,
  upadteExtractedNamesTitle,
  removeExtractedName
} = llmQueriesSlice.actions;

export default llmQueriesSlice.reducer;
