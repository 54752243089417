import { Box, Button, Menu, Stack, Tooltip, Typography } from '@mui/material';
import { PARTNER_TO_LOGO } from 'components/discovery/SetDiscoveryByPartnership';
import MonitoringIcon from 'icons/MonitoringIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { appTheme } from 'theme';
import { formatTimestamp } from 'utils/time-utils';

import RenderMultipleLogos from './RenderMultipleLogos';
import RenderUrl from './RenderUrl';
import TableRowLoading from './TableRowLoading';

const DISCOVERY_TYPES = Object.freeze({
  SEARCH: 'discovery_via_search',
  PEERS: 'discovery_via_companies',
  PARTNERS: 'discovery_via_partnership',
  URL: 'discovery_via_url',
  DRY: 'dry_discovery'
});

export default function RenderCompanySource({ value, color }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const partnerNames =
    value?.discovery_data?.partners?.length > 0
      ? value?.discovery_data?.partners
      : value?.discovery_type === DISCOVERY_TYPES.PARTNERS && Object.keys(PARTNER_TO_LOGO);

  const loading = value?.isLoading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (!value?.discovery_type) return null;

  const colors = {
    titleColor: color,
    urlColor: value?.alwaysOpen ? null : 'primary.primary0',
    text: value?.alwaysOpen ? 'greyColors.grey300' : 'white'
  };

  const renderRunContent = (
    <Stack
      padding="8px"
      gap="16px"
      width={value?.alwaysOpen ? '100%' : '200px'}
      alignItems="flex-start">
      {value?.title && (
        <Typography variant="h4" color="white" noWrap display="block">
          {value.title}
        </Typography>
      )}
      {value.discovery_type === DISCOVERY_TYPES.SEARCH && (
        <Stack gap="8px" alignItems="flex-start">
          <Typography variant="paragraphSemiBold" color={colors.text}>
            Received Text:
          </Typography>
          <Typography variant="paragraph" color={colors.text} display="block">
            {value.discovery_data}
          </Typography>
        </Stack>
      )}
      {value.discovery_type === DISCOVERY_TYPES.PARTNERS && partnerNames && (
        <Stack gap="8px" alignItems="flex-start">
          <Typography variant="paragraphSemiBold" color={colors.text}>
            Received Partner{partnerNames === 1 ? '' : 's'}:
          </Typography>
          <RenderMultipleLogos
            value={partnerNames.map((partner) => {
              return { logo_url: PARTNER_TO_LOGO[partner], text: partner };
            })}
          />
        </Stack>
      )}
      {value.discovery_type === DISCOVERY_TYPES.PEERS && (
        <Stack gap="8px" alignItems="flex-start">
          <Typography variant="paragraphSemiBold" color={colors.text}>
            Received {value.discovery_data.length} compan
            {value.discovery_data.length === 1 ? 'y' : 'ies'}:
          </Typography>
          <RenderMultipleLogos value={value.discovery_data} />
        </Stack>
      )}
      {value.discovery_type === DISCOVERY_TYPES.PARTNERS && (
        <Typography variant="paragraph" color={colors.text} display="block">
          Industry: {value.discovery_data?.industry?.[0]}
        </Typography>
      )}
      {value.discovery_type === DISCOVERY_TYPES.URL && (
        <Stack gap="8px" alignItems="flex-start">
          <Typography variant="paragraphSemiBold" color={colors.text}>
            Received Url:
          </Typography>
          <RenderUrl
            value={{
              url: value.discovery_data,
              value: value.discovery_data,
              color: colors.urlColor
            }}
          />
        </Stack>
      )}
      {value?.discoveredCompanies && Boolean(value.discoveredCompanies.length) && (
        <Typography variant="paragraphSemiBold" color={colors.text}>
          Discovered {value.discoveredCompanies.length} new compan
          {value.discoveredCompanies.length === 1 ? 'y' : 'ies'}
          {value.discoveredCompanies.length ? ':' : ''}
        </Typography>
      )}
      {value?.discoveredCompanies && (
        <Stack direction="row" gap="8px" alignItems="center" justifyContent="flex-start">
          <RenderMultipleLogos value={value.discoveredCompanies?.slice(0, 8)} />
          {value?.discoveredCompanies.length > 8 && (
            <Tooltip
              title={
                <Stack gap="4px">
                  {value.discoveredCompanies
                    .slice(8)
                    .reduce((resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / 8);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [];
                      }
                      resultArray[chunkIndex].push(item);
                      return resultArray;
                    }, [])
                    .map((chunk, indedx) => (
                      <RenderMultipleLogos key={indedx} value={chunk} />
                    ))}
                </Stack>
              }>
              <Typography
                variant="paragraphBold"
                color="greyColors.grey300"
                sx={{
                  border: '1px solid',
                  borderColor: 'background.bg200',
                  borderRadius: '4px',
                  padding: '3px'
                }}>
                {`+${value?.discoveredCompanies.length - 8}`}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      )}
      {value.time_submitted && (
        <Typography variant="paragraph" color="white" noWrap display="block">
          Added at {formatTimestamp(value.time_submitted, 'dd.MM.yyyy')}
        </Typography>
      )}
      {value?.original_record_id && (
        <Typography variant="tiny" color="greyColors.grey50">
          * Copied from other list
        </Typography>
      )}
    </Stack>
  );

  return (
    <Stack width="100%" padding={0}>
      {value?.alwaysOpen ? (
        renderRunContent
      ) : (
        <Button
          onClick={handleClick}
          fullWidth
          disableRipple
          sx={{
            '&:hover': { backgroundColor: 'unset' }
          }}>
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            gap="8px"
            onMouseEnter={() => setShowTooltip(!showTooltip)}
            onMouseLeave={() => setShowTooltip(false)}>
            <Typography variant="paragraph" color={colors.titleColor} noWrap display="block">
              {value.title}{' '}
            </Typography>
            {value?.iteration > 0 && (
              <Typography variant="paragraph" color="primary.primary0" noWrap display="block">
                {value?.iteration ? `#${value?.iteration}` : ''}
              </Typography>
            )}
            <Box sx={{ color: color, marginLeft: 'auto' }}>
              {value?.is_monitor && <MonitoringIcon />}
            </Box>
          </Stack>
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PaperProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}
        MenuListProps={{
          style: { backgroundColor: appTheme.palette.secondary.secondary1 }
        }}>
        {renderRunContent}
      </Menu>
    </Stack>
  );
}

RenderCompanySource.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderCompanySource.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
