export const SPECIAL_KEYS = Object.freeze(['hidden_columns', 'description']);

export const searchParamsToJson = (searchParams) => {
  return [...searchParams.entries()].reduce((acc, [key, value]) => {
    if (acc[key]) {
      acc[key] = [...acc[key], value];
    } else {
      acc[key] = [value];
    }
    return acc;
  }, {});
};

export const combineViewAndUrlArrays = (view, searchParams, key) => {
  const viewValues = view?.[key] || [];
  const urlValues = searchParams.getAll(key) || [];
  return viewValues
    .filter((id) => !urlValues.includes(`!${id}`))
    .concat(urlValues.filter((id) => !id.startsWith('!')));
};

export const combineViewAndUrlFilters = (view, searchParams) => {
  const viewFilters = view?.filters ? { ...view.filters } : {};
  for (const key of searchParams.keys()) {
    if (key === 'description') {
      const data = searchParams.getAll(key);
      const [searchTerm, type] = data[0].split(',');
      viewFilters.description = {
        data: {
          searchTerm,
          type
        }
      };
    }
    if (!SPECIAL_KEYS.includes(key) && !key.startsWith('!')) {
      const data = searchParams.getAll(key);
      if (data?.length > 0) {
        viewFilters[key] = {
          data: data[0].split(',')
        };
      }
    }
    if (key.startsWith('!')) {
      delete viewFilters[key.slice(1)];
    }
  }
  return viewFilters;
};

export const combineViewAndUrl = (view, searchParams) => {
  const combinedView = view ? { ...view } : {};
  const hidden_columns = combineViewAndUrlArrays(view, searchParams, 'hidden_columns');
  const filters = combineViewAndUrlFilters(view, searchParams);
  if (hidden_columns.length > 0) {
    combinedView.hidden_columns = hidden_columns;
  }
  if (filters && Object.keys(filters).length > 0) {
    combinedView.filters = filters;
  }
  return combinedView;
};
