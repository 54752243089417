import React from 'react';

export const PlanIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.0976 6H7.07317C6.52089 6 6.07317 6.44772 6.07317 7V11C6.07317 11.5523 6.52089 12 7.07317 12H16.9268C17.4791 12 17.9268 12.4477 17.9268 13V17C17.9268 17.5523 17.4791 18 16.9268 18H11.3415M6 21H6.14634C7.8032 21 9.14634 19.6569 9.14634 18V18C9.14634 16.3431 7.8032 15 6.14634 15H6C4.34315 15 3 16.3431 3 18V18C3 19.6569 4.34314 21 6 21ZM16.2927 8.57143H20C20.5523 8.57143 21 8.12371 21 7.57143V4C21 3.44772 20.5523 3 20 3H16.2927C15.7404 3 15.2927 3.44772 15.2927 4V7.57143C15.2927 8.12371 15.7404 8.57143 16.2927 8.57143Z"
        stroke="#0F2345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
