import { ELEMENT_HEIGHTS } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Area, CartesianGrid, ComposedChart, Line, ReferenceLine, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import {
  determineAllowDecimals,
  determineDomain,
  determineHoverGradient,
  determineReferenceLines,
  determineStroke,
  determineTickFormatter,
  determineTicks,
  getLineColorByIndex,
  mkTicks,
  unixTimeToTick
} from 'utils/charts-utils';

import RechartsBox from '../RechartsBox';

/* eslint-disable id-length */
export default function TimeSeriesComponent({
  elementId,
  title,
  xAxisValues,
  yAxisValues,
  xAxisName,
  yAxisName,
  mode
}) {
  const [isHover, setIsHover] = useState(false);

  const data = useMemo(() => {
    return xAxisValues.map((xVal, index) => ({
      x: new Date(xVal).getTime(),
      y: yAxisValues[index]
    }));
  }, [xAxisValues, yAxisValues]);

  const colorBreakPoint = 0;
  const colorGreenPoint = 0.3;
  const colorRedPoint = -0.3;
  const max = Math.max(...yAxisValues);
  const min = Math.min(...yAxisValues);
  const xAxisValuesUnixTime = xAxisValues.map((val) => new Date(val).getTime());
  const xMin = Math.min(...xAxisValuesUnixTime);
  const xMax = Math.max(...xAxisValuesUnixTime);
  const colorBreakPointPercentage = `${(1 - (colorBreakPoint - min) / (max - min)) * 100}%`;
  const colorGreenPointPercentage = `${(1 - (colorGreenPoint - min) / (max - min)) * 100}%`;
  const colorRedPointPercentage = `${(1 - (colorRedPoint - min) / (max - min)) * 100}%`;
  const positivePercentage = (max / (max - min)) * 100;
  const negativePercentage = ((-1 * min) / (max - min)) * 100;
  const ticks = mkTicks(xMin, xMax, 5);
  const stroke = determineStroke(elementId, min, max);
  const hoverGradient = determineHoverGradient(elementId);
  return (
    <RechartsBox
      title={title}
      mode={mode}
      height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
      <ComposedChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.greyColors.grey100,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        margin={{
          top: 20,
          right: 40,
          bottom: 40,
          left: 40
        }}
        data={data}>
        <defs>
          {stroke && stroke === 'url(#colorPERatio)' && (
            <linearGradient id="colorPERatio" x1="0" y1="0" x2="0" y2="100%">
              <stop offset={`${positivePercentage}%`} stopColor="#04BD00" />
              <stop offset={`${negativePercentage}%`} stopColor="#F20530" />
            </linearGradient>
          )}
          {hoverGradient && hoverGradient === 'url(#pehover)' && (
            <linearGradient id="pehover" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={`${positivePercentage * 0.05}%`}
                stopColor="#04BD00"
                stopOpacity={0.3}
              />
              <stop offset={`${positivePercentage}%`} stopColor="#FFFFFF" stopOpacity={0.1} />
              <stop
                offset={`${negativePercentage * 0.05}%`}
                stopColor="#F20530"
                stopOpacity={0.05}
              />
            </linearGradient>
          )}
          <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={getLineColorByIndex(0)} stopOpacity={0.3} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorSentimentRBG" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset={colorGreenPointPercentage} stopColor="#04BD00" />
            <stop offset={colorBreakPointPercentage} stopColor="#637188" />
            <stop offset={colorRedPointPercentage} stopColor="#F20530" />
          </linearGradient>
          <linearGradient id="colorSentimentRB" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset={colorBreakPointPercentage} stopColor="#637188" />
            <stop offset={colorRedPointPercentage} stopColor="#F20530" />
          </linearGradient>
          <linearGradient id="colorSentimentBG" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset={colorGreenPointPercentage} stopColor="#04BD00" />
            <stop offset={colorBreakPointPercentage} stopColor="#637188" />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="x"
          name={xAxisName}
          type="number"
          axisLine={false}
          tickLine={false}
          dy={8}
          tickFormatter={(tick) => unixTimeToTick(tick, ticks)}
          domain={[xMin, xMax]}
          ticks={ticks}
          label={{
            value: xAxisName,
            position: 'bottom',
            fill: appTheme.palette.greyColors.grey250
          }}
          stroke={appTheme.palette.greyColors.grey250}
        />
        <YAxis
          type="number"
          dataKey="y"
          axisLine={false}
          tickLine={false}
          name={yAxisName}
          domain={determineDomain(elementId, 'y')}
          allowDecimals={determineAllowDecimals(elementId, 'y')}
          ticks={determineTicks(elementId, 'y', yAxisValues)}
          tickFormatter={determineTickFormatter(elementId, 'y')}
          label={{
            value: yAxisName,
            position: 'insideLeft',
            angle: -90,
            style: {
              textAnchor: 'middle'
            },
            fill: appTheme.palette.greyColors.grey250
          }}
          stroke={appTheme.palette.greyColors.grey250}
        />
        <CartesianGrid vertical={false} stroke={appTheme.palette.greyColors.grey50} />
        {determineReferenceLines(elementId) &&
          determineReferenceLines(elementId).map((line, index) => {
            if (line.axis === 'x') {
              return <ReferenceLine key={index} x={line.value} stroke={line.stroke} />;
            } else if (line.axis === 'y') {
              return <ReferenceLine key={index} y={line.value} stroke={line.stroke} />;
            }
            return null;
          })}
        <Line
          type="linear"
          dot={xAxisValues.length < 2}
          strokeWidth={3}
          stroke={stroke}
          dataKey="y"
          name={title}
          legendType="none"
          cursor="pointer"
        />
        <Area
          type="linear"
          dot={false}
          strokeWidth={0}
          dataKey="y"
          name={title}
          fillOpacity={isHover ? '1' : '0'}
          fill={hoverGradient ? hoverGradient : 'url(#color0)'}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
      </ComposedChart>
    </RechartsBox>
  );
}

TimeSeriesComponent.propTypes = {
  elementId: PropTypes.string,
  title: PropTypes.string,
  xAxisValues: PropTypes.array,
  yAxisValues: PropTypes.array,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
