import { loadExplore } from 'actions/explore/load-explore-action';
import { addNotification } from 'reducer/notifications-slice';

export function loadNotificationByIdAction(notificationId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.notifications.fetchNotification(notificationId).then((res) => {
      if (res.ok && res.data) {
        const notification = res.data;
        dispatch(addNotification({ notification: notification }));
        if (notification.company_list_id) {
          dispatch(loadExplore(notification.company_list_id, null, true));
        }
      }
    });
  };
}
