import { INSIGHTS_TYPES } from 'constants/insights';
import AlternativeMediaIcon from 'icons/AlternativeMediaIcon';
import CommercialComperitorAnalysisIcon from 'icons/CommercialComperitorAnalysisIcon';
import CommercialLeadershipIcon from 'icons/CommercialLeadershipIcon';
import CommercialRiskIcon from 'icons/CommercialRiskIcon';
import CommercialTeamIcon from 'icons/CommercialTeamIcon';
import CommercialTractionIcon from 'icons/CommercialTractionIcon';
import CompanyHealthIcon from 'icons/CompanyHealthIcon';
import FinanceGrowthIcon from 'icons/FinanceGrowthIcon';
import FinanceMarketBeliefIcon from 'icons/FinanceMarketBeliefIcon';
import FinanceRiskIcon from 'icons/FinanceRiskIcon';
import FinanceStatusIcon from 'icons/FinanceStatusIcon';
import FinanceTrustSignalIcon from 'icons/FinanceTrustSignalIcon';
import GitHubIcon from 'icons/GitHubIcon';
import KeyPPByCompanyRoleIcon from 'icons/KeyPPByCompanyRoleIcon';
import KeyProductPerceptionIcon from 'icons/KeyProductPerceptionIcon';
import MarketBeliefIcon from 'icons/MarketBeliefIcon';
import OperationalGrowthIcon from 'icons/OperationalGrowthIcon';
import ProductLaunchIcon from 'icons/ProductLaunchIcon';
import RecognitionIcon from 'icons/RecognitionIcon';
import RedditIcon from 'icons/RedditIcon';
import StackExchangeIcon from 'icons/StackExchangeIcon';
import StackRedditInfluencialUsersIcon from 'icons/StackRedditInfluencialUsersIcon';
import TechDominantIpIcon from 'icons/TechDominantIpIcon';
import TeachIPAreasIcon from 'icons/TechIPAreasIcon';
import TechInorganicIpIcon from 'icons/TechInorganicIpIcon';
import TechLeadershipIcon from 'icons/TechLeadershipIcon';
import TechPatentsIcon from 'icons/TechPatentsIcon';
import TechTeamIcon from 'icons/TechTeamIcon';
import TwitterInsightIcon from 'icons/TwitterInsightIcon';

import { groupSort } from './overviewGroup-utils';

export function groupInsights(insightsObj) {
  const groupedInsights = Object.values(insightsObj).reduce((acc, insight) => {
    if (!insight.insight_group) {
      return acc;
    }
    if (!acc[insight.insight_group]) {
      acc[insight.insight_group] = [];
    }
    acc[insight.insight_group].push(insight);
    return acc;
  }, {});
  const sortedGroupedInsights = {};
  Object.keys(groupedInsights)
    .sort(groupSort)
    .forEach((group) => {
      sortedGroupedInsights[group] = groupedInsights[group];
    });
  return sortedGroupedInsights;
}

export function insightTypeToIcon(type) {
  switch (type) {
    case INSIGHTS_TYPES.COMMERCIAL_TRACTION:
      return CommercialTractionIcon;
    case INSIGHTS_TYPES.RECOGNITION:
      return RecognitionIcon;
    case INSIGHTS_TYPES.COMMERCIAL_LEADERSHIP_TEAM:
      return CommercialLeadershipIcon;
    case INSIGHTS_TYPES.COMMERCIAL_TEAM:
      return CommercialTeamIcon;
    case INSIGHTS_TYPES.COMMERCIAL_RISK:
      return CommercialRiskIcon;
    case INSIGHTS_TYPES.COMPETITION:
      return CommercialComperitorAnalysisIcon;
    case INSIGHTS_TYPES.TECH_EXPANSION:
      return OperationalGrowthIcon;
    case INSIGHTS_TYPES.TECH_DOMINANT_IP:
      return TechDominantIpIcon;
    case INSIGHTS_TYPES.TECH_IP_PATENT_ASSETS:
      return TechPatentsIcon;
    case INSIGHTS_TYPES.TECH_TEAM:
      return TechTeamIcon;
    case INSIGHTS_TYPES.TECH_LEADERSHIP_TEAM:
      return TechLeadershipIcon;
    case INSIGHTS_TYPES.TECH_IP_PUBLICATION_ASSETS:
      return TechInorganicIpIcon;
    case INSIGHTS_TYPES.TECH_IP_AREAS:
      return TeachIPAreasIcon;
    case INSIGHTS_TYPES.PRODUCT_LAUNCHES:
      return ProductLaunchIcon;
    case INSIGHTS_TYPES.FINANCING_TRENDS:
      return FinanceMarketBeliefIcon;
    case INSIGHTS_TYPES.MARKET_BELIEF:
      return MarketBeliefIcon;
    case INSIGHTS_TYPES.FINANCING_STATUS:
      return FinanceStatusIcon;
    case INSIGHTS_TYPES.FINANCING_GROWTH:
      return FinanceGrowthIcon;
    case INSIGHTS_TYPES.FINANCING_OPERATIONAL_PERFORMANCE:
      return CompanyHealthIcon;
    case INSIGHTS_TYPES.FINANCING_INORGANIC_GROWTH:
      return FinanceGrowthIcon;
    case INSIGHTS_TYPES.IPO_LOCATION:
      return FinanceRiskIcon;
    case INSIGHTS_TYPES.INVESTORS:
      return FinanceStatusIcon;
    case INSIGHTS_TYPES.PRIVATE_FUNDING:
      return FinanceTrustSignalIcon;
    case INSIGHTS_TYPES.GITHUB:
      return GitHubIcon;
    case INSIGHTS_TYPES.GITHUB_USER:
      return GitHubIcon;
    case INSIGHTS_TYPES.REDDIT:
      return RedditIcon;
    case INSIGHTS_TYPES.REDDIT_USER:
      return RedditIcon;
    case INSIGHTS_TYPES.STACK:
      return StackExchangeIcon;
    case INSIGHTS_TYPES.STACK_USER:
      return StackExchangeIcon;
    case INSIGHTS_TYPES.TWITTER:
      return TwitterInsightIcon;
    case INSIGHTS_TYPES.TWITTER_EFFORT:
      return TwitterInsightIcon;
    case INSIGHTS_TYPES.MENTIONS:
      return AlternativeMediaIcon;
    case INSIGHTS_TYPES.USERS:
      return StackRedditInfluencialUsersIcon;
    case INSIGHTS_TYPES.PRODUCT_REVIEW_OVERVIEW:
      return KeyProductPerceptionIcon;
    case INSIGHTS_TYPES.G2_PRODUCT_REVIEW_SIZE_TYPE:
      return KeyPPByCompanyRoleIcon;
    case INSIGHTS_TYPES.GROWTH_SIGNALS_EXTRA_DATA:
      return FinanceGrowthIcon;
    default:
      return FinanceTrustSignalIcon;
  }
}

const INSIGHT_TYPE_TO_TITLE = Object.freeze({
  TechInsight: 'Tech Team',
  TeamLeadershipTechSummaryInsight: 'Tech Leadership',
  IPPatentSummaryInsight: 'Patents',
  IPBQPatentSummaryInsight: 'Big Query Patents',
  IPPublicationSummaryInsight: 'Publications',
  MNSInsight: 'Commercial Team',
  TeamLeadershipCommercialSummaryInsight: 'Commercial Leadership',
  CommercialTractionInsight: 'Commercial Traction',
  CompetitionInsightV2: 'Competition',
  RiskInsight: 'Risk Events',
  StatusInsight: 'Status',
  MarketBeliefTrends: 'Market-belief Signals',
  OperationalInsight: 'Health Signals',
  InvestorsInsight: 'Investor Trust Signals',
  InorganicInsight: 'Inorganic Growth',
  InorganicEventsInsight: 'Inorganic Growth Events -- Clone',
  TalentInsight: 'Talent Growth',
  HeadcountGrowthInsight: 'People Growth Trajectory',
  ResearchFocusSummaryInsight: 'Dominant IP Areas',
  ProductsInsight: 'Most Recognised Products and Services',
  PublicInvestorsInsight: 'Institutional Investor Signals',
  PrivateInvestorsInsight: 'Investor Trust Signals',
  GrowthInsight: 'Funding Growth',
  GrowthEventsInsight: 'Funding Milestones -- Events Sourced',
  OpenSourceApprovalInsight: 'GitHub Engagement',
  StackInsight: 'Stack Exchange Engagement',
  StackUsersInsight: 'Stack Exchange by User Segment',
  SigSepOpenSourceApprovalInsight: 'GitHub by User Segment',
  RecognitionsInsight: 'Awards & Recognition',
  ProductLaunchInsight: 'Product Launches',
  ExternalTechInsight: 'Technology Acquisition (External)',
  RedditInsight: 'Reddit Engagement',
  RedditUsersInsight: 'Reddit by User Segment',
  TwitterInsight: 'Twitter Engagement',
  TwitterEffortInsight: 'Twitter Effort',
  GoingConcernPublicInsight: 'Going Concern Risk (Public Company)',
  G2ProductReviewSizeTypeInsight: 'Key Products Perception by Company/Role',
  ProductReviewOverviewInsight: 'Key Products Perception',
  ProductLaunchBiotechInsight: 'IP Development',
  MentionsInsight: 'Alternative Social (Stack, Reddit)',
  YouTubeEffortInsight: 'General Social (YouTube)',
  UsersInsight: 'Stackexchange and Reddit Influential Users',
  ValueChainEventsInsight: 'Value Chain Events',
  ChatGPTCategoriserInsight: 'Events Beta: ChatGPT Events Pipeline',
  LegalAndRegulatoryEventsInsight: 'Legal and Regulatory Events',
  PersonnelEventsInsight: 'Personnel Events',
  CommercialEventsInsight: 'Commercial Events',
  OperationalEventsInsight: 'Operational Events',
  FinancialEventsInsight: 'Financial Events',
  SustainabilityEventsInsight: 'Sustainability Events',
  CapabilityInsight: 'Capabilities',
  CapabilitySummaryInsight: 'Capabilities Summary',
  IPTechSummaryInsight: 'IP-Tech Summary',
  VoiceOfCustomerOverviewInsight: 'Voice Of Customer Summary',
  GrowthSignalsOverviewInsight: 'Growth Signals Summary',
  RiskAndHealthOverviewInsight: 'Risk Event Summary',
  GrowthSignalsExtraDataInsight: 'Team and Revenue Growth',
  RevenuesExtraDataInsight: 'Revenue'
});

export function insightTypeToTitle(insightType) {
  return insightType in INSIGHT_TYPE_TO_TITLE ? INSIGHT_TYPE_TO_TITLE[insightType] : insightType;
}
