import LeadershipOverviewElement from 'components/LeadershipOverviewComponent';
import { MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React from 'react';

import AreaChartComponent from './bar/AreaChartComponent';
import BarChartComponent from './bar/BarChartComponent';
import MultiBarChartComponent from './bar/MultiBarChartComponent';
import StackedBarChartComponent from './bar/StackedBarChartComponent';
import B2DCompositeComponent from './composites/B2DCompositeElement';
import CompetitorsCompositeComponent from './composites/CompetitorsCompositeElement';
import CustomElement from './custom/CustomElement';
import PieChartComponent from './pie/PieChartComponent';
import ScatterPlotComponent from './scatter/ScatterPlotComponent';
import ScatterPlotLogoComponent from './scatter/ScatterPlotLogoComponent';
import MultiTableComponent from './table/MultiTableComponent';
import TableComponent from './table/TableComponent';
import TeamChartComponent from './teamchart/TeamChartComponent';
import EventTimelineComponent from './timeline/EventTimelineComponent';
import MultiTimeSeriesComponent from './timeseries/MultiTimeSeriesComponent';
import TimeSeriesComponent from './timeseries/TimeSeriesComponent';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function ElementComponent({
  element,
  mode,
  optionalFilters,
  cardFilters,
  setCardFilters,
  noTitle,
  ...args
}) {
  const { element_type, element_id, data } = element;

  function innerElement(elementType, parameters) {
    if (noTitle && data && data.name) {
      data.name = null;
    }
    const modeOrPP = parameters.powerpoint ? 'powerpoint' : mode;
    switch (elementType) {
      case 'TableElement': {
        return (
          <TableComponent
            elementId={element_id}
            title={data.name}
            columns={data.columns}
            cardFilters={cardFilters}
            usePagination={data?.usePagination}
            {...args}
          />
        );
      }
      case 'MultiTableElement': {
        return <MultiTableComponent tables={data.data.tables} {...args} />;
      }
      case 'PieChartElement': {
        return (
          <PieChartComponent
            elementId={element_id}
            title={data.name}
            values={data.data.values}
            labels={data.data.labels}
            showAsDonut={true}
            showLegend={true}
            mode={modeOrPP}
          />
        );
      }
      case 'BarChartElement': {
        return (
          <BarChartComponent
            title={data.name}
            values={data.data.values}
            labels={data.data.labels}
            xAxisName={data.x_axis_name}
            yAxisName={data.y_axis_name}
            xIsDate={data.x_is_date}
            yIsInt={data.y_is_int}
            vertical={data.vertical}
            showMultiColors={data.multi_colors}
            mode={modeOrPP}
          />
        );
      }
      case 'StackedBarChartElement': {
        return (
          <StackedBarChartComponent
            title={data.name}
            values={data.data.values}
            labels={data.data.labels}
            xSplitDate={data.x_split_date}
            yIsInt={data.y_is_int}
            benchmarkLabels={data.data.benchmarkLabels}
            xAxisName={data.data.x_axis_name || data.x_axis_name}
            yAxisName={data.data.y_axis_name}
            vertical={data.vertical}
            optionalFilters={optionalFilters}
            setCardFilters={setCardFilters}
            keyOrder={data.data.key_order}
            isStars={data.is_stars}
            mode={modeOrPP}
          />
        );
      }
      case 'MultiBarChartElement': {
        return (
          <MultiBarChartComponent
            title={data.name}
            values={data.data.values}
            labels={data.data.labels}
            yIsInt={data.y_is_int}
            benchmarkLabels={data.data.benchmarkLabels}
            xAxisName={data.data.x_axis_name || data.x_axis_name}
            yAxisName={data.data.y_axis_name}
            vertical={data.vertical}
            optionalFilters={optionalFilters}
            setCardFilters={setCardFilters}
            mode={modeOrPP}
          />
        );
      }
      case 'MultiAreaTimeSeriesElement': {
        return (
          <AreaChartComponent
            title={data.name}
            values={data.data.values}
            labels={data.data.labels}
            xAxisName={data.x_axis_name}
            yAxisName={data.y_axis_name}
            xIsDate={data.x_is_date}
            keyOrder={data.data.key_order}
            mode={modeOrPP}
          />
        );
      }
      case 'ScatterPlotElement': {
        return (
          <ScatterPlotComponent
            title={data.name}
            xAxisValues={data.data.x}
            yAxisValues={data.data.y}
            xAxisName={data.data.x_axis_name}
            yAxisName={data.data.y_axis_name}
            labels={data.data.labels}
            mode={modeOrPP}
          />
        );
      }
      case 'ScatterPlotLogoElement': {
        return (
          <ScatterPlotLogoComponent
            title={data.name}
            xAxisValues={data.data.x}
            yAxisValues={data.data.y}
            xAxisName={data.data.x_axis_name}
            yAxisName={data.data.y_axis_name}
            labels={data.data.labels}
            mode={modeOrPP}
            elementId={element_id}
          />
        );
      }
      case 'TimeSeriesElement': {
        return (
          <TimeSeriesComponent
            elementId={element_id}
            title={data.name}
            xAxisValues={data.data.dates}
            yAxisValues={data.data.values}
            xAxisName={data.x_axis_name}
            yAxisName={data.y_axis_name}
            mode={modeOrPP}
          />
        );
      }
      case 'EventTimelineElement': {
        return (
          <EventTimelineComponent
            title={data.name}
            timelines={data.data.timelines}
            zAxisName={data.data.z_axis_name}
            mode={modeOrPP}
          />
        );
      }
      case 'MultiTimeSeriesElement': {
        return (
          <MultiTimeSeriesComponent
            elementId={element_id}
            title={data.name}
            data={data.data}
            xAxisName={data.x_axis_name}
            yAxisName={data.y_axis_name}
            yAxisName2={data.y_axis_name2}
            mode={modeOrPP}
            isScatter={data.is_scatter}
            benchmarkLabels={data.benchmarkLabels}
          />
        );
      }
      case 'TeamChartElement': {
        return <TeamChartComponent data={data.data} mode={modeOrPP} />;
      }
      case 'LeadershipOverviewElement': {
        return <LeadershipOverviewElement columns={data.columns} {...args} />;
      }
      case 'CompetitorsCompositeElement': {
        return (
          <CompetitorsCompositeComponent
            title={data.name}
            landscape={data.data.landscape}
            table={data.data.table}
            mode={modeOrPP}
            elementId={element_id}
            recordId={args.recordId}
          />
        );
      }
      case 'B2DUsersCompositeElement': {
        return (
          <B2DCompositeComponent
            title={data.name}
            barchart={data.data.barchart}
            table={data.data.table}
            mode={modeOrPP}
            elementId={element_id}
          />
        );
      }
      case 'StackCompositeElement': {
        return (
          <B2DCompositeComponent
            title={data.name}
            barchart={data.data.barchart}
            table={data.data.table}
            mode={modeOrPP}
            elementId={element_id}
          />
        );
      }
      case 'RedditCompositeElement': {
        return (
          <B2DCompositeComponent
            title={data.name}
            barchart={data.data.barchart}
            table={data.data.table}
            mode={modeOrPP}
            elementId={element_id}
          />
        );
      }
      case 'CustomElement': {
        return <CustomElement type={data.type} data={data.data} cardFilters={cardFilters} />;
      }
      default: {
        return <React.Fragment></React.Fragment>;
      }
    }
  }
  const regularElement = innerElement(element_type, { powerpoint: mode === 'powerpoint' });
  return regularElement;
}

ElementComponent.propTypes = {
  element: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(MODES).isRequired,
  optionalFilters: PropTypes.array,
  cardFilters: PropTypes.array,
  setCardFilters: PropTypes.func
};

export default ElementComponent;
