import { ACTIONS } from 'constants/actions';

function updatePersonCard(personId, data) {
  return { type: ACTIONS.ADD_PERSON_CARD, personId, data };
}

export function loadPersonCard(personId) {
  return (_state, dispatch, apiClient) => {
    apiClient.persons.fetchPersonCard(personId).then((res) => {
      if (res.ok) {
        dispatch(updatePersonCard(personId, res.data?.elements[0]?.data?.data));
      }
    });
  };
}
