import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

function NonScrollBlockingDialog({ open, children }) {
  if (!open) return null;

  return ReactDOM.createPortal(<Box>{children}</Box>, document.getElementById('modal'));
}

NonScrollBlockingDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any
};

export default NonScrollBlockingDialog;
