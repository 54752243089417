import CategoryIcon from '@mui/icons-material/Category';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LinkIcon from '@mui/icons-material/Link';
import PinIcon from '@mui/icons-material/Pin';
import RttIcon from '@mui/icons-material/Rtt';
import { Button, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { loadAllCompaniesFiltersAction } from 'actions/company_lists/load-all-companies-filters-action';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { createNewPostInCustomAnalyticsThreadAsyncAction } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import IconButtonComponent from 'components/IconButtonComponent';
import MultiSelectComponent from 'components/MultiSelectComponent';
import SelectWithSearchComponent from 'components/SelectWithSearchComponent';
import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import { CUSTOM_ANALYTICS_PLACEMENT } from 'constants/custom-analytics';
import { DISCOVERY_TYPES } from 'constants/discovery';
import {
  DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
  DOMAIN_KNOWLEDGE_RESOURCE_TYPE
} from 'constants/domain-knowledge';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CustomAnalyticsSubmitIcon from 'icons/CustomAnalyticsSubmitIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getCustomAnalyticClipboardById,
  getIsCustomAnalyticsThreadProccessingById
} from 'selectors/custom-analytics';
import {
  getDeepDiveDynamicCardsByDeepDiveId,
  getDeepDiveInsightsColumnsByDeepDiveId
} from 'selectors/deep-dive';
import {
  getCompanyMetasByListId,
  getListCustomColumnsByListId,
  getListDomainKnowledgeByListId
} from 'selectors/explore';
import {
  getExploreFilteredCompanies,
  getExploreTableUICheckedCompaniesByListId,
  getExploreUiColumnsByListId
} from 'selectors/explore-table-ui';
import { getCurrentFolderId, getCurrentFolderSupportingResources } from 'selectors/folders';
import { getUserFeatureFlags, getUserIsDev } from 'selectors/user';
import { appTheme } from 'theme';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { getObjectLength, isEmpty } from 'utils/objects-utils';
import {
  determineErrorStatus,
  extractFirstActivePromptField,
  getCompaniesAndColumns,
  getDeepDiveDynamicCardsMetadata,
  getPromptFieldText,
  promptConfigToPrompt
} from 'utils/prompts-utils';

import CompaniesMultiSelectComponent from './CompaniesMultiSelectComponent';
import CustomAnalyticsTemplatesChips from './CustomAnalyticsTemplatesChips';
import CustomAnalyticsUseFilteredCompaniesDialog from './CustomAnalyticsUseFilteredCompaniesDialog';
import PromptDevMenu from './PromptDevMenu';
import SetDiscoveryByPartnership from './SetDiscoveryByPartnership';

export const ANSWER_TYPES = Object.freeze({
  text: { label: 'Text', icon: <RttIcon /> },
  number: { label: 'Number', icon: <PinIcon /> },
  boolean: { label: 'Yes/No', icon: <CheckBoxIcon /> },
  links: { label: 'Links', icon: <LinkIcon /> },
  category: { label: 'Category', icon: <CategoryIcon />, disabled: true }
});
const MAX_NUMBER_OF_COMPANIES = 500;

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function PromptInputComponent({
  listId,
  companyMetaId,
  onAddNewPost,
  placement,
  deepDiveId,
  promptConfig,
  setPromptConfig
}) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();
  const [anchorEl, setAnchorEl] = useState(false);
  const [filters, setFilters] = useState({});
  const [industries, setIndustries] = useState([]);
  const [partners, setPartners] = useState();
  const [showError, setShowError] = useState(false);
  const [submitOptions, setSubmitOptions] = useState(false);
  const folderId = useSelector(getCurrentFolderId);
  const threadKey = getThreadKey(listId, companyMetaId);
  const isProccessing = useSelector((reduxState) =>
    getIsCustomAnalyticsThreadProccessingById(reduxState, threadKey)
  );
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const deepDiveDynamicCards = useSelector((state) =>
    getDeepDiveDynamicCardsByDeepDiveId(state, deepDiveId)
  );
  const isDev = useOldStateSelector(getUserIsDev);
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const promptClipboard = useSelector((state) => getCustomAnalyticClipboardById(state, threadKey));
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );

  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );
  const selectedCompanies = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );
  const numberOfComapnies = useSelector((state) => getCompanyMetasByListId(state, listId))?.length;
  const showOnlyDiscover = numberOfComapnies <= 0;
  const firstActiveField = extractFirstActivePromptField(promptConfig);
  const deepDiveInsights = useSelector((state) =>
    getDeepDiveInsightsColumnsByDeepDiveId(state, deepDiveId)
  );
  const filteredCompanies = useSelector((state) => getExploreFilteredCompanies(state, listId));

  const { columns, companies } = useMemo(() => {
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE) {
      return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings, isDev);
    }
    if (placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && deepDiveDynamicCards) {
      const deepDiveCards = {
        ...getDeepDiveDynamicCardsMetadata(deepDiveDynamicCards),
        ...deepDiveInsights
      };

      return { columns: deepDiveCards, companies: new Map() };
    }
    return { columns: {}, companies: new Map() };
  }, [
    rows,
    customColumns,
    listId,
    columnsSettings,
    isDev,
    deepDiveDynamicCards,
    placement,
    deepDiveInsights
  ]);

  const numberOfSelectedCompanies = useMemo(() => {
    if (!selectedCompanies) return 0;

    return Object.keys(selectedCompanies).length;
  }, [selectedCompanies]);

  useEffect(() => {
    dispatch(loadAllCompaniesFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (isDev) {
      setPromptConfig((prev) => ({ ...prev, useGpt4: false }));
    }
  }, [featureFlags, isDev, setPromptConfig]);

  useEffect(() => {
    setPromptConfig((prevPromptConfig) => {
      const companiesIds = selectedCompanies ? Object.keys(selectedCompanies) : [];
      const haveAll = companiesIds.length === companies.size;
      let newCompanies = null;
      if (!haveAll && companiesIds.length > 0) {
        newCompanies = companiesIds.reduce((result, companyId) => {
          result[companyId] = companies.get(parseInt(companyId, 10));
          return result;
        }, {});
      }
      return { ...prevPromptConfig, companies: newCompanies };
    });
  }, [selectedCompanies, companies, setPromptConfig]);

  useEffect(() => {
    setPromptConfig((prevPromptConfig) => {
      return { ...prevPromptConfig, useDiscover: showOnlyDiscover };
    });
  }, [showOnlyDiscover, setPromptConfig]);

  useEffect(() => {
    if (companyMetaId) {
      setPromptConfig((prevPromptConfig) => {
        return { ...prevPromptConfig, forEachCompany: true };
      });
    }
  }, [companyMetaId, setPromptConfig]);

  const handleFormatMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColumnListChange = (event) => {
    const newSelectedColumn = event.target.value;
    setPromptConfig((prev) => ({ ...prev, column: newSelectedColumn }));
  };
  const handleColumnsListChange = (newColumns) => {
    setPromptConfig((prev) => ({
      ...prev,
      columns: newColumns
    }));
  };
  const handleResourceListChange = (event) => {
    const newSelectedResource = event.target.value;
    setPromptConfig((prev) => ({ ...prev, resource: newSelectedResource }));
  };

  const getPlaceholder = () => {
    if (promptConfig.useDiscover) {
      if (promptConfig.discoverMethod === DISCOVERY_TYPES.TEXT) {
        return 'Type a description that defines the companies you are looking for';
      } else if (promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS) {
        return 'select companies';
      } else if (promptConfig.discoverMethod === DISCOVERY_TYPES.URL) {
        return 'Type a url';
      }
    }
    return 'Ask me anything...';
  };

  const errorStatus = useMemo(
    () =>
      determineErrorStatus(
        promptConfig,
        numberOfSelectedCompanies,
        partners,
        industries,
        rows?.length
      ),
    [promptConfig, numberOfSelectedCompanies, partners, industries, rows]
  );

  const getIsInputDisabled = () => {
    if (promptConfig.useDiscover && promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS) {
      return true;
    }
    if (promptConfig.useColumns && (!promptConfig.columns || promptConfig.columns.length === 0)) {
      return true;
    }
    return isProccessing;
  };

  useEffect(() => {
    if (errorStatus.length <= 0) {
      setShowError(false);
    }
  }, [errorStatus]);

  useEffect(() => {
    if (promptClipboard) {
      setPromptConfig(promptClipboard);
    }
  }, [promptClipboard, setPromptConfig]);

  const handleSubmit = () => {
    if (errorStatus.length > 0) {
      setShowError(true);
      return;
    }

    if (promptConfig.useDiscover) {
      const isByPartners = promptConfig.discoverMethod === DISCOVERY_TYPES.PARTNERS;
      const joinedFilters = {
        ...filters,
        industry: isByPartners ? industries : [],
        partner_db_name: isByPartners ? partners : []
      };

      const searchTerm =
        promptConfig.discoverMethod === DISCOVERY_TYPES.TEXT ? promptConfig.question : null;
      const url =
        promptConfig.discoverMethod === DISCOVERY_TYPES.URL ? promptConfig.question : null;

      dispatch(
        createNewDiscoveryInCustomAnalyticsThreadAction(
          listId,
          promptConfig.discoverMethod,
          joinedFilters,
          searchTerm,
          url
        )
      );
    } else {
      const prompt = promptConfigToPrompt(promptConfig);
      setPromptConfig((prev) => ({ ...prev, companies: null }));
      dispatch(
        sendExploreEvent(
          USER_EVENTS.CUSTOM_ANALYTICS_SUBMISSION,
          listId,
          `Submitted CA with question "${promptConfig?.question}"`,
          {
            listId,
            companyMetaId,
            ...promptConfig
          }
        )
      );
      let inputSelectedCompanies = null;
      if (numberOfSelectedCompanies) {
        inputSelectedCompanies = Object.keys(selectedCompanies).map(
          (listMetaId) => companies.get(parseInt(listMetaId, 10)).companyMetaId
        );
      }
      if (companyMetaId) {
        inputSelectedCompanies = [companyMetaId];
      }
      const submitFun = (companiesList) =>
        dispatch(
          createNewPostInCustomAnalyticsThreadAsyncAction({
            listId,
            companyMetaId,
            query: prompt,
            useColumn: promptConfig.column,
            answerType: promptConfig.answerType,
            useGpt4: promptConfig.useGpt4,
            usePlanner: promptConfig.usePlanner,
            selectedCompanies: companiesList,
            temperature: promptConfig.temperature,
            folderId: folderId && folderId !== SHARED_WITH_ME_FOLDER_ID ? folderId : null,
            supportingResourceId: promptConfig.resource,
            columns: promptConfig.columns
          })
        );

      if (promptConfig.forEachCompany) {
        if (inputSelectedCompanies?.length > MAX_NUMBER_OF_COMPANIES) {
          addToast(
            `The maximum number of companies is ${MAX_NUMBER_OF_COMPANIES}. You have selected ${inputSelectedCompanies.length} companies. Please select no more than ${MAX_NUMBER_OF_COMPANIES} companies`,
            TOAST_TYPES.ERROR
          );
          return;
        }

        if (
          !inputSelectedCompanies?.length &&
          numberOfComapnies > MAX_NUMBER_OF_COMPANIES &&
          (filteredCompanies.length === 0 || filteredCompanies.length > MAX_NUMBER_OF_COMPANIES)
        ) {
          addToast(
            `The maximum number of companies is ${MAX_NUMBER_OF_COMPANIES}. We are showing ${
              filteredCompanies.length > 0 ? filteredCompanies.length : numberOfComapnies
            } companies. Please use screening to reduce selection to no more than ${MAX_NUMBER_OF_COMPANIES} companies`,
            TOAST_TYPES.ERROR
          );
          return;
        }

        if (
          !inputSelectedCompanies?.length &&
          numberOfComapnies <= MAX_NUMBER_OF_COMPANIES &&
          filteredCompanies.length > 0 &&
          filteredCompanies.length < numberOfComapnies
        ) {
          setSubmitOptions({
            filtered: () => {
              submitFun(
                filteredCompanies.map(
                  (listMetaId) => companies.get(parseInt(listMetaId, 10)).companyMetaId
                )
              );
              onAddNewPost();
            },
            all: () => {
              submitFun(null);
              onAddNewPost();
            }
          });
          return;
        }
        if (
          !inputSelectedCompanies?.length &&
          numberOfComapnies > MAX_NUMBER_OF_COMPANIES &&
          filteredCompanies.length > 0 &&
          filteredCompanies.length < numberOfComapnies
        ) {
          submitFun(
            filteredCompanies.map(
              (listMetaId) => companies.get(parseInt(listMetaId, 10)).companyMetaId
            )
          );
          onAddNewPost();
          return;
        }
      }
      submitFun(inputSelectedCompanies);
      onAddNewPost();
    }
    setPromptConfig((prev) => ({ ...prev, question: '', answerType: 'text' }));
  };

  const handleDiscoverMethodChange = useCallback(
    (newDiscoverMethod) => {
      setShowError(false);
      if (newDiscoverMethod === DISCOVERY_TYPES.PEERS) {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newDiscoverMethod, question: '' }));
      } else {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newDiscoverMethod }));
        dispatch(clearExploreTableUIState({ listId }));
      }
    },
    [listId, dispatch, setPromptConfig]
  );

  const domainKnowledgeOptions = [
    ...DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS,
    ...Object.values(domainKnowledge || {})
      .sort((item1, item2) => (item1.type < item2.type ? 1 : -1))
      .map((dk) => ({
        icon: RESOURCE_TYPE_TO_ICON[dk.type],
        ...dk
      }))
  ];
  return (
    <Stack
      justifyContent="center"
      gap="16px"
      sx={{
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: 'greyColors.grey50',
        borderRadius: '4px',
        margin: '16px',
        padding: '16px 16px 0 16px',
        paddingTop: '4px',
        boxShadow: '0px 1px 8px 0px #19213D14'
      }}>
      <CustomAnalyticsTemplatesChips
        promptConfig={promptConfig}
        setPromptConfig={setPromptConfig}
        columnIds={Object.keys(columns)}
        resourcesTypes={Object.values(DOMAIN_KNOWLEDGE_RESOURCE_TYPE)}
        domainKnowledge={domainKnowledge}
        showOnlyDiscover={showOnlyDiscover}
        filters={filters}
        setFilters={setFilters}
        handleDiscoverMethodChange={handleDiscoverMethodChange}
        placement={placement}
        featureFlags={featureFlags}
      />
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        gap="4px"
        sx={{
          border: '1px solid',
          borderColor: showError ? 'indicator.negative' : 'greyColors.grey100',
          borderRadius: '4px',
          padding: '4px 8px',
          backgroundColor: 'white',
          boxShadow: '0px 1px 5px 0px #1018281A',
          color: 'greyColors.grey250'
        }}>
        <Stack direction="row" gap="4px" alignItems="center" flex={1}>
          {promptConfig.forEachCompany && !companyMetaId && (
            <Stack direction="row" gap="4px" alignItems="center">
              <Typography variant="paragraphMedium">
                {isEmpty(promptConfig.companies)
                  ? getPromptFieldText('forEachCompany', firstActiveField)
                  : `For ${getObjectLength(promptConfig.companies)} selected compan${
                      getObjectLength(promptConfig.companies) === 1 ? 'y' : 'ies'
                    }`}
              </Typography>
            </Stack>
          )}
          {promptConfig.useColumn && !featureFlags?.includes('ca_use_multiple_columns') && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText(
                  placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE ? 'useColumn' : 'useCard',
                  firstActiveField
                )}
              </Typography>
              <SelectWithSearchComponent
                options={Object.values(columns)}
                value={promptConfig.column}
                searchPlaceHolder="Search for a column"
                onChange={handleColumnListChange}
              />
            </Stack>
          )}
          {promptConfig.useColumns && featureFlags?.includes('ca_use_multiple_columns') && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText('useColumns', firstActiveField)}
              </Typography>
              <MultiSelectComponent
                maxWidth={340}
                selectedValues={promptConfig.columns}
                allValues={Object.values(columns)}
                onValueChange={handleColumnsListChange}
                allTag="All"
                emptyTag="Select at least 1 column"
              />
            </Stack>
          )}
          {promptConfig.useDomainKnowledge && (
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography variant="paragraphMedium">
                {getPromptFieldText('useDomainKnowledge', firstActiveField)}
              </Typography>
              <SelectWithSearchComponent
                options={domainKnowledgeOptions}
                value={promptConfig.resource}
                searchPlaceHolder="Search a resource"
                onChange={handleResourceListChange}
              />
            </Stack>
          )}
          {promptConfig.useDiscover && listId && (
            <Stack direction="row" gap="8px" alignItems="center" flex={1}>
              {promptConfig.discoverMethod === DISCOVERY_TYPES.PEERS && (
                <CompaniesMultiSelectComponent
                  listId={listId}
                  maxWidth={420}
                  placeholder="Select Peers"
                  allCompanies={companies}
                />
              )}
              {promptConfig.discoverMethod === DISCOVERY_TYPES.PARTNERS && (
                <SetDiscoveryByPartnership
                  filters={filters}
                  industries={industries}
                  setIndustries={setIndustries}
                  partners={partners}
                  setPartners={setPartners}
                  industriesMaxWidth={420}
                />
              )}
            </Stack>
          )}
        </Stack>
        <TextField
          maxRows={2}
          multiline
          variant="standard"
          placeholder={getPlaceholder()}
          value={promptConfig.question}
          size="small"
          autoFocus
          disableUnderline={true}
          disabled={getIsInputDisabled()}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Stack direction="row" gap="16px" alignItems="center">
                <Stack direction="row" gap="4px" alignItems="center">
                  {isDev && (
                    <PromptDevMenu promptConfig={promptConfig} setPromptConfig={setPromptConfig} />
                  )}
                  <IconButtonComponent onClick={handleFormatMenuClick}>
                    {ANSWER_TYPES[promptConfig.answerType]?.icon || <RttIcon />}
                  </IconButtonComponent>
                </Stack>
                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}>
                  {Object.keys(ANSWER_TYPES).map((answerType, index) => (
                    <MenuItem
                      value={answerType}
                      disabled={ANSWER_TYPES[answerType]?.disabled}
                      key={index}
                      onClick={() => {
                        setPromptConfig((prev) => ({ ...prev, answerType }));
                        setAnchorEl(null);
                      }}>
                      <Stack
                        direction="row"
                        gap="16px"
                        alignItems="center"
                        sx={{ color: 'greyColors.grey250' }}>
                        {ANSWER_TYPES[answerType].icon}
                        <Typography>{ANSWER_TYPES[answerType].label}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  variant="contained"
                  sx={{
                    height: '100%',
                    width: '32px',
                    borderRadius: '4px',
                    color:
                      isProccessing || promptConfig.question.length <= 0
                        ? 'greyColors.grey100'
                        : 'primary.primary100'
                  }}
                  onClick={handleSubmit}
                  disabled={isProccessing}>
                  <CustomAnalyticsSubmitIcon />
                </Button>
              </Stack>
            ),
            sx: {
              width: '100%',
              color: 'greyColors.grey250',
              ...appTheme.typography.paragraph
            }
          }}
          sx={{
            flex: 1,
            minWidth: '100%',
            alignItems: 'center',
            ...appTheme.typography.paragraph
          }}
          onChange={(event) => {
            setShowError(false);
            setPromptConfig((prev) => ({ ...prev, question: event.target.value }));
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap="4px"
        alignSelf="flex-end"
        visibility={showError ? 'visible' : 'hidden'}
        height="20px">
        <ToastIcons type="error" size="16" />
        <Stack direction="row" gap="4px">
          {errorStatus.map((part, index) => (
            <Typography
              key={part}
              variant={index % 2 === 0 ? 'tiny' : 'tinyBold'}
              color="greyColors.grey300">
              {part}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <CustomAnalyticsUseFilteredCompaniesDialog
        totalNumOfComapnies={numberOfComapnies}
        filteredNumOfCompanies={filteredCompanies?.length}
        submitOptions={submitOptions}
        open={Boolean(submitOptions)}
        onClose={() => setSubmitOptions(null)}
      />
    </Stack>
  );
}

PromptInputComponent.propTypes = {
  listId: PropTypes.number,
  companyMetaId: PropTypes.number,
  onAddNewPost: PropTypes.func,
  placement: PropTypes.string,
  deepDiveId: PropTypes.number,
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func
};

export default PromptInputComponent;
