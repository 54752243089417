/* eslint-disable max-lines */
import { Box, Button, Grid, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import {
  updateCustomAnalyticsExtraDataForCompaniesInListAction,
  updateExtraDataByTypeForCompaniesInListAction
} from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { submitHiddenGemsAction } from 'actions/company_lists/submit-hidden-gems-action';
import { addExploreCustomColumnAction } from 'actions/explore/add-discovery-custom-column-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ColumnsIcon from 'icons/ColumnsIcon';
import CustomAnalyticsIcon from 'icons/CustomAnalyticsIcon';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getAllPredefinedColumns } from 'selectors/explore-table-ui';
import { getUserFeatureFlags } from 'selectors/user';
import { COLUMN_TYPE_TO_FEATURE_FLAG_NAME } from 'utils/explore-table-columns';

const LEFT_LEADERSHIP_DATA_NAME = 'LEFT_LEADERSHIP';

// eslint-disable-next-line max-lines-per-function
function ColumnsPlatterDialog({ open, onClose, companyListId }) {
  const predefinedColumns = useSelector(getAllPredefinedColumns);
  const listCustomColumns = useSelector((state) =>
    getListCustomColumnsByListId(state, companyListId)
  );
  const dispatch = useDispatch();
  const { addToast, cancelToast } = useAppUtils();
  const toastRef = useRef();
  const [categoryValue, setCategoryValue] = useState(0);
  const sectionsContainerRef = useRef();
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const canLeftLeadership = featureFlags?.includes('left_leadership');

  const columnsByCategories = useMemo(() => {
    if (!predefinedColumns) return {};
    const EmptyColumns = [
      { data_name: 'custom_str', title: 'Text', type: 'custom_column', category: 'Empty Columns' },
      {
        data_name: 'custom_number',
        title: 'Number',
        type: 'custom_column',
        category: 'Empty Columns'
      },
      {
        data_name: 'custom_boolean',
        title: 'Yes/No',
        type: 'custom_column',
        category: 'Empty Columns'
      }
    ];
    const BasicsColumns = [
      {
        data_name: 'company_website',
        title: 'Website',
        type: 'basic_data_column',
        category: 'Basic'
      },
      {
        data_name: 'linkedin_url',
        title: 'Linkedin',
        type: 'basic_data_column',
        category: 'Basic'
      },
      {
        data_name: 'cb_url',
        title: 'Crunchbase Link',
        type: 'basic_data_column',
        category: 'Basic'
      },
      {
        data_name: 'hidden_gems',
        title: 'Hidden Gems',
        type: 'basic_data_column',
        category: 'Basic'
      }
    ];
    return [
      ...EmptyColumns,
      ...BasicsColumns,
      ...predefinedColumns.filter((item) => {
        if (item.data_name === LEFT_LEADERSHIP_DATA_NAME) {
          return canLeftLeadership;
        }
        return true;
      })
    ]?.reduce((categories, column) => {
      const featureFlagName = COLUMN_TYPE_TO_FEATURE_FLAG_NAME?.[column.data_name.toUpperCase()];
      if (featureFlagName && !featureFlags?.includes(featureFlagName)) {
        return categories;
      }
      categories[column.category] = categories[column.category] || [];
      categories[column.category].push(column);
      return categories;
    }, {});
  }, [predefinedColumns, featureFlags, canLeftLeadership]);

  const handleClose = () => {
    setCategoryValue(0);
    onClose();
  };

  const onColumnCreated = (columnSetting) => {
    cancelToast(toastRef.current);
    toastRef.current = null;
    const id = addToast('Adding new column succeed', TOAST_TYPES.SUCCESS);
    setTimeout(() => cancelToast(id), 2000);
    if (columnSetting?.type === 'extra_data_column') {
      dispatch(updateExtraDataByTypeForCompaniesInListAction(companyListId, columnSetting.id));
    }
  };

  const onCreateCustomAnalyticsColumn = (column) => {
    dispatch(
      updateCustomAnalyticsExtraDataForCompaniesInListAction(companyListId, column.data_name)
    );
    onColumnCreated(column);
  };
  const onCustomAnalyticsColumnClick = (column) => {
    const answer_type = column?.answer_type || 'text';
    const customAnalyticsPayload = {
      question: column.question,
      answer_type,
      model: CHAT_GPT_MODEL_VERSION
    };
    dispatch(
      addExploreCustomColumnAction(
        companyListId,
        column.title,
        customAnalyticsPayload,
        'predefined_custom_analytics',
        answer_type,
        () => onCreateCustomAnalyticsColumn(column),
        column.data_name
      )
    );
    toastRef.current = addToast('Adding new column', TOAST_TYPES.INFO);
  };

  const onColumnClick = (column) => {
    if (column.type === 'predefined_custom_analytics') {
      onCustomAnalyticsColumnClick(column);
      handleClose();
      return;
    }
    if (column.data_name === 'hidden_gems') {
      dispatch(submitHiddenGemsAction(companyListId));
      handleClose();
      return;
    }
    const columnId = column.type === 'custom_column' ? null : column.data_name;
    const columnType = column.type === 'custom_column' ? column.data_name : column.type;
    dispatch(
      addExploreCustomColumnAction(
        companyListId,
        column.title,
        null,
        columnType,
        null,
        onColumnCreated,
        columnId
      )
    );

    handleClose();
  };
  const isColumnDisabled = (column) =>
    listCustomColumns && Object.keys(listCustomColumns).includes(column.data_name);

  const sectionTabStyle = {
    color: 'greyColors.grey200',
    alignItems: 'start',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'background.bg75',
      color: 'primary.primary100'
    },
    '&.Mui-selected': {
      backgroundColor: 'background.bg100',
      color: 'primary.primary100'
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setCategoryValue(newValue);
    const index = Object.keys(columnsByCategories).indexOf(newValue);
    sectionsContainerRef.current.children[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      title="Add Columns"
      width={1000}
      maxWidth={1000}>
      <Stack direction="row" sx={{ backgroundColor: 'background.bg100', gap: '16px', padding: 0 }}>
        <Stack
          sx={{
            backgroundColor: 'background.bg50',
            gap: '16px',
            padding: '16px',
            sectionTabStyle
          }}>
          <Tabs
            value={categoryValue}
            onChange={handleCategoryChange}
            orientation="vertical"
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}>
            {columnsByCategories &&
              Object.keys(columnsByCategories)?.map((category) => (
                <Tab
                  key={category}
                  value={category}
                  label={
                    <Typography variant="paragraphMedium" noWrap>
                      {category}
                    </Typography>
                  }
                  sx={sectionTabStyle}></Tab>
              ))}
          </Tabs>
        </Stack>
        <Stack
          overflow="scroll"
          sx={{ gap: '16px', padding: '16px', width: '100%' }}
          ref={sectionsContainerRef}>
          {columnsByCategories &&
            Object.entries(columnsByCategories)?.map(([category, columns]) => (
              <Stack key={category} gap="8px" padding="4px" id={category}>
                <Typography
                  variant={categoryValue === category ? 'paragraphBold' : 'paragraphMedium'}
                  color="primary.primary100">
                  {category}
                </Typography>
                <Stack direction="row">
                  <Grid container spacing={2} columns={3}>
                    {columns?.map((column) => (
                      <Grid xs={1} item key={column.data_name}>
                        <Tooltip
                          key={column.data_name}
                          title={isColumnDisabled(column) ? 'Already exists on your list' : ''}>
                          <Box sx={{ padding: 0, width: '300px' }}>
                            <Button
                              disabled={isColumnDisabled(column)}
                              onClick={() => onColumnClick(column)}
                              sx={{ padding: 0 }}>
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="8px"
                                sx={{
                                  padding: '8px',
                                  width: '200px',
                                  height: '40px',
                                  backgroundColor: isColumnDisabled(column)
                                    ? 'greyColors.grey50'
                                    : 'white',
                                  borderRadius: '4px',
                                  border: '1px solid',
                                  borderColor: 'greyColors.grey50',
                                  boxShadow:
                                    '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.2)',
                                  ':hover': { backgroundColor: 'background.bg50' }
                                }}>
                                <Box
                                  sx={{
                                    backgroundColor: 'background.bg75',
                                    borderRadius: '4px',
                                    padding: '4px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}>
                                  <ColumnsIcon />
                                </Box>
                                <Typography
                                  variant="paragraphMedium"
                                  textAlign="left"
                                  color={
                                    isColumnDisabled(column)
                                      ? 'greyColors.grey200'
                                      : 'primary.primary100'
                                  }>
                                  {column.title}
                                </Typography>
                                {column.type === 'predefined_custom_analytics' && (
                                  <Tooltip title={column.question}>
                                    <Box
                                      marginLeft="auto"
                                      color={
                                        isColumnDisabled(column)
                                          ? 'greyColors.grey200'
                                          : 'primary.primary50'
                                      }>
                                      <CustomAnalyticsIcon color="currentColor" />
                                    </Box>
                                  </Tooltip>
                                )}
                              </Box>
                            </Button>
                          </Box>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

ColumnsPlatterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyListId: PropTypes.number
};

export default wrapWithError(ColumnsPlatterDialog);
