import MultipleSelectionFilerComponent from 'components/elements/table/filters/columns/components/MultipleSelectionFilerComponent';
import PropTypes from 'prop-types';

function HeadquartersColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <MultipleSelectionFilerComponent
      columnId="hq"
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

HeadquartersColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default HeadquartersColumnFilter;
