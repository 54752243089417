import React from 'react';

export default function CommercialLeadershipIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M8.93122 12.4951C10.7212 12.4951 12.1712 13.9251 12.1712 15.7051C12.1712 17.4851 10.7412 18.9351 8.95122 18.9551C7.18122 18.9751 5.71122 17.5151 5.71122 15.7351C5.71122 13.9551 7.14122 12.5051 8.93122 12.4951Z"
        fill="#0593A2"
      />
      <path
        d="M18.6112 32.1651C18.6112 30.4551 18.6712 29.3151 18.5912 27.6051C18.4912 25.3651 17.4812 23.2151 15.6512 21.9351C13.8212 20.6551 11.6812 20.5751 9.63122 20.6951C8.67122 20.7551 7.72122 20.9651 6.86122 21.3951C4.27122 22.6851 2.95122 24.8051 2.83122 27.6551C2.77122 29.1351 2.80122 30.0451 2.82122 31.5251C2.82122 31.8451 3.08122 32.1051 3.40122 32.1051L18.6112 32.1651Z"
        fill="#0593A2"
      />
      <path
        d="M29.961 12.4951C31.751 12.4951 33.201 13.9251 33.201 15.7051C33.201 17.4851 31.771 18.9351 29.981 18.9551C28.211 18.9751 26.741 17.5151 26.741 15.7351C26.741 13.9551 28.171 12.5051 29.961 12.4951Z"
        fill="#374EF4"
      />
      <path
        d="M37.691 31.2551C37.701 29.8451 37.741 28.8351 37.661 27.2851C37.561 25.0751 36.521 22.9451 34.711 21.6851C32.901 20.4251 30.751 20.3351 28.701 20.4551C27.741 20.5151 26.791 20.7251 25.931 21.1551C23.341 22.4451 22.011 24.5651 21.901 27.4251C21.841 28.9051 21.871 29.7351 21.891 31.2151C21.891 31.5351 22.151 31.7951 22.471 31.7951L37.101 31.8551C37.421 31.8551 37.691 31.5951 37.691 31.2651V31.2551Z"
        fill="#374EF4"
      />
      <path
        d="M30.5111 31.4048C30.5211 29.8848 30.5611 28.8048 30.4811 27.1348C30.3811 24.7548 29.3411 22.4748 27.5311 21.1148C25.6311 19.6848 23.5711 19.6648 21.5211 19.7948C20.5611 19.8548 19.6111 20.0848 18.7511 20.5448C16.1611 21.9248 14.8311 24.2148 14.7211 27.2848C14.6611 28.8748 14.6911 29.7648 14.7111 31.3648C14.7111 31.7048 14.9711 31.9848 15.2911 31.9848L29.9211 32.0448C30.2411 32.0448 30.5111 31.7648 30.5111 31.4148V31.4048Z"
        fill="white"
      />
      <path
        d="M28.9311 33.0951H11.1111C10.7711 33.0951 10.4911 32.8351 10.4911 32.5051V27.2451C10.4911 22.5151 14.5611 18.6851 19.5711 18.6851H20.4811C25.6311 18.6851 29.4911 22.8251 29.5611 27.5451C29.5811 29.1951 29.5611 30.8551 29.5611 32.5051C29.5611 32.8251 29.2811 33.0951 28.9411 33.0951H28.9311Z"
        fill="#8EBCFC"
      />
      <path
        d="M28.9311 33.365H11.1111C10.6111 33.365 10.2111 32.975 10.2111 32.505V27.245C10.2111 22.375 14.4111 18.415 19.5711 18.415H20.4811C25.5611 18.415 29.7611 22.505 29.8411 27.545C29.8611 28.645 29.8511 29.755 29.8411 30.855C29.8411 31.405 29.8411 31.955 29.8411 32.505C29.8411 32.975 29.4411 33.365 28.9411 33.365H28.9311ZM19.5611 18.955C14.7011 18.955 10.7611 22.665 10.7611 27.235V32.495C10.7611 32.665 10.9211 32.805 11.1111 32.805H28.9311C29.1211 32.805 29.2711 32.665 29.2711 32.495C29.2711 31.945 29.2711 31.395 29.2711 30.835C29.2711 29.735 29.2811 28.635 29.2711 27.535C29.2011 22.805 25.2511 18.945 20.4711 18.945H19.5611V18.955Z"
        fill="#8EBCFC"
      />
      <path
        d="M27.6812 34.0649L9.91117 33.9749C9.59117 33.9749 9.32117 33.7149 9.32117 33.3849V28.1249C9.32117 26.0649 10.5412 23.7049 11.9412 22.2549C13.7612 20.3749 16.1212 19.6749 18.7912 19.5649H19.6512C24.5012 19.5649 28.1912 23.7949 28.2612 28.5149C28.2812 30.1649 28.2612 31.8249 28.2612 33.4749C28.2612 33.7949 28.0012 34.0649 27.6712 34.0649H27.6812Z"
        fill="white"
      />
      <path
        d="M24.8513 11.2149C24.8513 14.1949 22.4313 16.6149 19.4513 16.6149C16.4713 16.6149 14.0513 14.1849 14.0613 11.2049C14.0613 8.23494 16.4713 5.83494 19.4413 5.82494C22.4313 5.82494 24.8513 8.22494 24.8513 11.2049V11.2149Z"
        fill="white"
      />
      <path
        d="M19.4613 16.8949C17.9513 16.8949 16.5213 16.3049 15.4513 15.2249C14.3813 14.1449 13.7913 12.7249 13.7913 11.2149C13.7913 8.10494 16.3313 5.56494 19.4513 5.56494H19.4613C20.9813 5.56494 22.4013 6.14494 23.4713 7.21494C24.5413 8.28494 25.1313 9.70494 25.1413 11.2249C25.1413 12.7349 24.5513 14.1649 23.4813 15.2449C22.4113 16.3149 20.9813 16.9049 19.4713 16.9049L19.4613 16.8949ZM19.4613 6.11494C16.6513 6.11494 14.3613 8.41494 14.3513 11.2149C14.3513 12.5849 14.8813 13.8649 15.8513 14.8349C16.8213 15.8049 18.1013 16.3349 19.4713 16.3349C20.8313 16.3349 22.1213 15.8049 23.0913 14.8349C24.0613 13.8649 24.5913 12.5749 24.5913 11.2149C24.5913 9.84494 24.0513 8.56494 23.0913 7.59494C22.1313 6.63494 20.8413 6.10494 19.4713 6.10494L19.4613 6.11494Z"
        fill="#374EF4"
      />
      <path
        d="M27.3512 34.155C27.3512 32.505 27.3712 30.845 27.3512 29.195C27.2812 24.475 23.6512 20.335 18.7912 20.335H17.9312C13.2012 20.335 9.37122 24.165 9.37122 28.895V34.155H27.3512Z"
        fill="white"
      />
      <path
        d="M27.3512 34.4349H9.3812C9.2312 34.4349 9.1012 34.3149 9.1012 34.1549V28.8949C9.1012 24.0249 13.0612 20.0649 17.9312 20.0649H18.7912C23.5912 20.0649 27.5512 24.1549 27.6212 29.1949C27.6312 30.2949 27.6212 31.4049 27.6212 32.5049C27.6212 33.0549 27.6212 33.6049 27.6212 34.1549C27.6212 34.3049 27.5012 34.4349 27.3412 34.4349H27.3512ZM9.6612 33.8849H27.0812C27.0812 33.4249 27.0812 32.9649 27.0812 32.5049C27.0812 31.4049 27.0812 30.3049 27.0812 29.2049C27.0112 24.4749 23.3012 20.6149 18.8012 20.6149H17.9412C13.3712 20.6149 9.6612 24.3249 9.6612 28.8949V33.8849Z"
        fill="#374EF4"
      />
      <path
        d="M18.8612 24.1749L19.8512 26.1849C19.8912 26.2549 19.9612 26.3149 20.0412 26.3249L22.2612 26.6449C22.4712 26.6749 22.5512 26.9249 22.4012 27.0749L20.7912 28.6349C20.7312 28.6949 20.7012 28.7749 20.7212 28.8549L21.1012 31.0649C21.1412 31.2749 20.9212 31.4249 20.7312 31.3349L18.7512 30.2949C18.6812 30.2549 18.5912 30.2549 18.5212 30.2949L16.5412 31.3349C16.3612 31.4349 16.1412 31.2749 16.1712 31.0649L16.5512 28.8549C16.5612 28.7749 16.5412 28.6949 16.4812 28.6349L14.8712 27.0749C14.7212 26.9249 14.8012 26.6749 15.0112 26.6449L17.2312 26.3249C17.3112 26.3149 17.3812 26.2649 17.4212 26.1849L18.4112 24.1749C18.5012 23.9849 18.7712 23.9849 18.8612 24.1749Z"
        fill="#0593A2"
      />
    </svg>
  );
}
