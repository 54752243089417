import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { makePptxAll } from 'actions/make-new-pptx-all-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import IconButtonComponent from 'components/IconButtonComponent';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import CompanyPublicShareDialog from 'components/summary/company/CompanyPublicShareDialog';
import SharedStack from 'components/tile/SharedStack';
import { RESOURCE_TYPES } from 'constants/resources';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import DownloadIcon from 'icons/DownloadIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDeepDiveDynamicCardsByDeepDiveId, getDeepDiveIsPublicMode } from 'selectors/deep-dive';
import { getCurrentFolderId } from 'selectors/folders';
import { getPptxInProgress } from 'selectors/pptx';
import {
  getRunCompanyNameById,
  getRunRerunningById,
  getRunSummaryBodyById,
  getRunTimestampById
} from 'selectors/runs';
import { getUserCanCollaborate } from 'selectors/user';
import { groupToTitle } from 'utils/overviewGroup-utils';
import { createCaCardDatas, createCardDatas, createOverviewData } from 'utils/pptx-utils';
import { formatTimestamp } from 'utils/time-utils';

export default function CompanyActionsComponent({ recordId, elementsByGroups, insightsbyGroups }) {
  const { state } = useAppState();
  const { tabPath } = useParams();
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const canCollaborate = getUserCanCollaborate(state);
  const isPublicMode = useSelector(getDeepDiveIsPublicMode);
  const currentFolderId = useSelector(getCurrentFolderId);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const timestamp = getRunTimestampById(state, recordId);
  const rerunning = getRunRerunningById(state, recordId);
  const companyName = getRunCompanyNameById(state, recordId);
  const name = `${companyName}_${tabPath}`;
  const summary_body = getRunSummaryBodyById(state, recordId);
  const pptxInProgress = getPptxInProgress(state);
  const deepDiveDynamicCards = useSelector((reduxState) =>
    getDeepDiveDynamicCardsByDeepDiveId(reduxState, recordId)
  );
  const dynamicCards = deepDiveDynamicCards ? Object.values(deepDiveDynamicCards) : [];

  const doCollaborate = () => {
    setCollaborateOpen(true);
  };

  const openPublicShareDialog = () => {
    setOpenShareDialog(true);
  };

  const closePublisShareDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenShareDialog(false);
  };

  const onPptxClick = () => {
    dispatch(sendUserEvent(USER_EVENTS.PPTX_DOWNLOAD, { recordId }));
    const insights = Object.values(insightsbyGroups).reduce((acc, cur) => [...acc, ...cur], []);
    const overview_bodies = Object.keys({ ...insightsbyGroups, ...elementsByGroups })
      .filter((groupType) => {
        const points = insightsbyGroups?.[groupType];
        const element = elementsByGroups?.[groupType];
        return (points ? points.length > 0 : false) || element?.data;
      })
      .map((groupType) =>
        createOverviewData({
          recordId,
          groupType,
          title: groupToTitle(groupType),
          points: insightsbyGroups?.[groupType]
        })
      );
    const card_bodies = createCardDatas(
      insights.map((insight) => ({
        card: insight.card,
        insightId: insight.insight_id,
        groupId: insight.insight_group
      }))
    );
    const ca_card_bodies = createCaCardDatas(dynamicCards);
    const data = { overview_bodies, summary_body, card_bodies, ca_card_bodies };
    dispatch(makePptxAll(recordId, data, name));
  };

  return (
    <Stack alignItems="center" gap="16px" direction="row">
      {timestamp && (
        <Typography variant="paragraph" sx={{ color: 'secondary.secondary5_50' }}>
          Research Ran {formatTimestamp(timestamp)}
        </Typography>
      )}
      {!isPublicMode && (
        <React.Fragment>
          {rerunning && (
            <Typography variant="paragraph" sx={{ color: 'secondary.secondary5_50' }}>
              - Currently Rerunning...
            </Typography>
          )}
          {rerunning ||
            (timestamp && (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ width: '1px', borderColor: 'primary.white', height: '24px' }}
              />
            ))}
          <Stack direction="row" alignItems="center">
            {recordId && canCollaborate && (
              <CollaborateDialog
                open={collaborateOpen}
                onClose={() => setCollaborateOpen(false)}
                id={recordId}
                searchId={recordId}
                folderId={currentFolderId}
                resourceType={RESOURCE_TYPES.DEEP_DIVE}
              />
            )}
            <Stack direction="row" gap="8px">
              {canCollaborate && (
                <Button variant="contained" onClick={doCollaborate}>
                  Collaborate
                </Button>
              )}{' '}
              {canCollaborate && recordId && (
                <SharedStack
                  searchId={recordId}
                  resourceType={RESOURCE_TYPES.DEEP_DIVE}
                  size={32}
                  folderId={currentFolderId}
                />
              )}
            </Stack>
            {pptxInProgress && (
              <React.Fragment>
                <Typography variant="paragraph" sx={{ color: 'primary.primary3_50' }}>
                  Generating PowerPoint...
                </Typography>
                <CircularProgress color="info" size="24px" sx={{ marginX: '12px' }} />
              </React.Fragment>
            )}
            <IconButtonComponent tag="Share" variant="primary" onClick={openPublicShareDialog}>
              <ShareIcon />
            </IconButtonComponent>
            <IconButtonComponent
              tag={`Download Powerpoint`}
              variant="primary"
              onClick={onPptxClick}
              severity="info"
              disabled={pptxInProgress}
              message="Generating Powerpoint - this can take up to a minute">
              <DownloadIcon />
            </IconButtonComponent>
          </Stack>
          <CompanyPublicShareDialog
            recordId={recordId}
            title={`Share ${companyName} Links With Others`}
            open={openShareDialog}
            onClose={closePublisShareDialog}
          />
        </React.Fragment>
      )}
    </Stack>
  );
}

CompanyActionsComponent.propTypes = {
  recordId: PropTypes.string,
  elementsByGroups: PropTypes.object,
  insightsbyGroups: PropTypes.object
};
