import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function AdminTabOrgLogo({ logoUrl }) {
  return (
    <Box component={logoUrl && 'img'} src={logoUrl} sx={{ width: '32px', height: '32px' }}></Box>
  );
}

AdminTabOrgLogo.propTypes = {
  logoUrl: PropTypes.string
};

export default AdminTabOrgLogo;
