import { loadExplore } from 'actions/explore/load-explore-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';
import { setIsListMonitored } from 'reducer/explore-slice';
import { getIsExploreListMonitored } from 'selectors/explore';

export function setIsListMonitoredAction(listId, isMonitored) {
  return (dispatch, getState, apiClient) => {
    const isListMonitored = getIsExploreListMonitored(getState(), listId);
    if (isListMonitored === isMonitored) {
      return;
    }
    if (!isMonitored) {
      dispatch(sendUserEvent(USER_EVENTS.REMOVE_LIST_MONITORING, { listId }));
    }
    dispatch(setIsListMonitored({ listId, isMonitored }));

    apiClient.companiesLists
      .setIsMonitored(listId, isMonitored)
      .then((res) => {
        if (!res.ok) {
          dispatch(setIsListMonitored({ listId, isMonitord: !isMonitored }));
        }
        if (!isMonitored) {
          dispatch(loadExplore(listId, null, false));
        }
      })
      .catch(() => {
        dispatch(setIsListMonitored({ listId, isMonitord: !isMonitored }));
        if (!isMonitored) {
          dispatch(loadExplore(listId, null, false));
        }
      });
  };
}
