import MultiSelectComponent from 'components/MultiSelectComponent';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function MultipleSelectionFilerComponent({ columnId, rowMap, rows, existFilter, onUpdateFilter }) {
  const values = useMemo(() => {
    const allColumnValues = rows
      .map((row) => rowMap(row[columnId]))
      .filter((rowColumnvalue) => rowColumnvalue);
    const uniqeValues = [...new Set(allColumnValues)].map((value) => ({ id: value, name: value }));
    return [...uniqeValues, { id: 'No Information', name: 'No Information' }];
  }, [columnId, rows, rowMap]);

  const handleChange = (newFiltes) => {
    onUpdateFilter(newFiltes.length <= 0 ? null : newFiltes);
  };

  return (
    <>
      <MultiSelectComponent
        maxWidth={300}
        allValues={values}
        selectedValues={existFilter?.data || []}
        onValueChange={handleChange}
        allTag="All"
      />
    </>
  );
}

MultipleSelectionFilerComponent.propTypes = {
  columnId: PropTypes.string,
  rowMap: PropTypes.func,
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

MultipleSelectionFilerComponent.defaultProps = {
  rowMap: (value) => value
};

export default MultipleSelectionFilerComponent;
