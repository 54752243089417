import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

export function RenderTimeSeriesLegend({ payload, benchmarkLabels }) {
  if (benchmarkLabels) {
    return (
      <Stack direction="row" gap="32px" justifyContent="flex-end">
        {benchmarkLabels.map((benchmarkLabel, index) => (
          <Stack key={index} direction="row" gap="8px" alignItems="center">
            <Box
              component="span"
              sx={{
                borderRadius: '50%',
                width: '8px',
                height: '8px',
                backgroundColor: payload[index].color
              }}
            />
            {benchmarkLabel?.logo_url && (
              <Tooltip title={benchmarkLabel.tooltip || benchmarkLabel.name} arrow>
                <Box
                  component="img"
                  src={benchmarkLabel?.logo_url}
                  sx={{
                    width: `18px`,
                    height: `18px`
                  }}
                />
              </Tooltip>
            )}
            <Typography variant="paragraph" color="greyColors.grey250">
              {benchmarkLabel.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }
  return (
    <Stack direction="row" gap="16px" justifyContent="flex-end">
      {payload.map((entry, index) => (
        <Stack key={`${entry.dataKey}-${index}`} direction="row" gap="8px" alignItems="center">
          <Box
            component="span"
            sx={{ borderRadius: '50%', width: '8px', height: '8px', backgroundColor: entry.color }}
          />
          <Typography variant="paragraph" color="greyColors.grey250">
            {entry.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

RenderTimeSeriesLegend.propTypes = {
  payload: PropTypes.array,
  benchmarkLabels: PropTypes.array
};
