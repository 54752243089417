import { loadAllUsers } from 'actions/users/load-all-users-action';

export function toggleUserActive(userId) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.toggleUserActive(userId).then((res) => {
      if (res.ok) {
        dispatch(loadAllUsers());
      }
    });
  };
}
