import { createSelector } from 'reselect';

export const getPersons = (state) => state.persons;
export const getPersonById = (state, personId) => getPersons(state)[personId] || {};
export const getPersonNameById = (state, personId) => getPersonById(state, personId).name;
export const getPersonRecordIdById = (state, personId) => getPersonById(state, personId).recordId;
export const getPersonImageUrlById = (state, personId) => getPersonById(state, personId).imageUrl;
export const getPersonOwner = (state, id) => getPersonById(state, id)?.user;
export const getPersonOwnerId = (state, id) => getPersonById(state, id)?.user?.id;
export const getPersonIsMineById = (state, runId, userId) => {
  if (!userId) {
    return false;
  }
  return getPersonOwnerId(state, runId) === userId;
};
export const getPersonCollaboratorsUsers = (state, id) =>
  getPersonById(state, id)?.collaboratorsUsers;
export const getPersonCollaboratorsOrgs = (state, id) =>
  getPersonById(state, id)?.collaboratorsOrgs;
export const getPersonCollaboratorsTeams = (state, id) =>
  getPersonById(state, id)?.collaboratorsTeams;
export const getPersonShared = (state, runId) => {
  if (getPersonCollaboratorsUsers(state, runId)) {
    return true;
  }
  if (getPersonCollaboratorsOrgs(state, runId)) {
    return true;
  }
  if (getPersonCollaboratorsTeams(state, runId)) {
    return true;
  }
  return false;
};
export const getPersonCollaborateInfo = (state, id) => {
  return {
    resourceUsers: getPersonCollaboratorsUsers(state, id),
    resourceOrgs: getPersonCollaboratorsOrgs(state, id),
    resourceTeams: getPersonCollaboratorsTeams(state, id),
    resourceOwner: getPersonOwner(state, id)
  };
};
export const getPersonCurrentCompanyNameById = (state, personId) =>
  getPersonById(state, personId).currentCompanyName;
export const getPersonCurrentCompanyLogoUrlById = (state, personId) =>
  getPersonById(state, personId).currentCompanyLogo;
export const getPersonCurrentRoleById = (state, personId) =>
  getPersonById(state, personId).currentRole;
export const getPersonLinkedinUrlById = (state, personId) =>
  getPersonById(state, personId).linkedinUrl;
export const getPersonFacebookUrlById = (state, personId) =>
  getPersonById(state, personId).facebookUrl;
export const getPersonTwitterUrlById = (state, personId) =>
  getPersonById(state, personId).twitterUrl;
export const getPersonParametersById = (state, personId) =>
  getPersonById(state, personId).parameters;
export const getPersonReadyById = (state, personId) => getPersonById(state, personId).ready;
export const getPersonTimestampById = (state, personId) =>
  getPersonById(state, personId).timeSubmitted;
export const getPersonProgressById = (state, personId) => getPersonById(state, personId).progress;
export const getPersonCardById = (state, personId) => getPersonById(state, personId)?.card;

export const getCurrentlyRunningPersons = (state) =>
  createSelector(getPersons, (persons) => {
    return Object.keys(persons).reduce((acc, id) => {
      if (!getPersonReadyById(state, id)) {
        acc[id] = persons[id];
      }
      return acc;
    }, {});
  });
