import { ACTIONS } from 'constants/actions';

function deletePersonData(personId) {
  return { type: ACTIONS.DELETE_PERSON, personId };
}

export function deletePersonDataAction(personId) {
  return (_state, dispatch, apiClient) => {
    dispatch(deletePersonData(personId));
    apiClient.persons.deletePerson(personId);
  };
}
