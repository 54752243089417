import PropTypes from 'prop-types';

import RenderStr from './RenderStr';

export default function RenderNumberWithDelimeters({ value, color, variant }) {
  return <RenderStr value={value && value.toLocaleString()} color={color} variant={variant} />;
}

RenderNumberWithDelimeters.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderNumberWithDelimeters.defaultProps = {
  color: 'greyColors.grey300',
  variant: 'paragraph'
};
