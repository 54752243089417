import BaseClient from './BaseClient';

const URL_PREFIX = 'organizations';

export default class OrganizationsClient extends BaseClient {
  fetchAllOrganizations() {
    return this.get(`${URL_PREFIX}`);
  }

  submitOrganization(payload) {
    return this.post(URL_PREFIX, payload);
  }
}
