import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

const DEFAULT_SCATTER_SHAPE_SIZE = 20;

/* eslint-disable id-length */
/* eslint-disable no-mixed-operators */
function ScatterShape({ x, y, payload }) {
  const logo_exapansion = payload.isSelected ? 2.1 : 1;
  const size = logo_exapansion * (payload?.scatterShapeSize || DEFAULT_SCATTER_SHAPE_SIZE);
  const p = { x: x - size, y: y - size };
  const r = size * 2;
  const borderWidth = payload.isSelected ? DEFAULT_SCATTER_SHAPE_SIZE / 3 : 0;
  const color = payload.isSelected ? appTheme.palette.graphColors.graph9 : 'white';
  const borderRect = (
    <rect
      x={p.x - borderWidth / 2}
      y={p.y - borderWidth / 2}
      rx={borderWidth / 2}
      width={r + borderWidth}
      height={r + borderWidth}
      fill={color}
    />
  );
  if (!payload.scatterShapeUrl) {
    return (
      <svg fill={'appTheme.palette.graphColors.graph1'} onClick={payload.onClick} version="1.1">
        <a href={payload.link ? payload.link : null} target="_blank" rel="noreferrer">
          {borderRect}
        </a>
      </svg>
    );
  }
  return (
    <svg onClick={payload.onClick} version="1.1">
      <a href={payload.link ? payload.link : null} target="_blank" rel="noreferrer">
        {borderRect}
        <image
          href={payload.scatterShapeUrl}
          x={p.x}
          y={p.y}
          height={r}
          width={r}
          preserveAspectRatio="none"
        />
      </a>
    </svg>
  );
}

ScatterShape.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

export default ScatterShape;
