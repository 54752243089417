import { Box, Stack, Typography } from '@mui/material';
import { deleteSchedulesAction } from 'actions/schedules/delete-schedule-action';
import { getSchedulesAction } from 'actions/schedules/get-schedules-action';
import IconButtonComponent from 'components/IconButtonComponent';
import TableComponent from 'components/elements/table/TableComponent';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import {
  DISCOVERY_TYPES_TITLES,
  DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES
} from 'constants/discovery';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import DeleteIcon from 'icons/DeleteIcon';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getExploreListByListId } from 'selectors/explore';
import { getCompanyListSchedules } from 'selectors/schedules';
import { getUsers } from 'selectors/users';
import { isEmpty } from 'utils/objects-utils';
import { getFrequencyFromCron } from 'utils/schedules-utils';

function MonitoringsScreen() {
  const dispatch = useDispatch();
  const schedules = useSelector((state) => getCompanyListSchedules(state));

  useEffect(() => {
    dispatch(getSchedulesAction());
  }, [dispatch]);

  return (
    <Stack gap="16px">
      <Typography variant="h2" padding="48px">
        Monitoring Management
      </Typography>
      {isEmpty(schedules) ? (
        <Typography variant="paragraph">No monitoring schedules to display</Typography>
      ) : (
        Object.keys(schedules)
          .sort((listId1, listId2) => listId2 - listId1)
          .map((listId) => (
            <MonitoredListComponent key={listId} listId={listId} schedules={schedules[listId]} />
          ))
      )}
    </Stack>
  );
}

export function MonitoredListComponent({ listId, schedules, isAdminScreen }) {
  const companyList = useSelector((state) => getExploreListByListId(state, listId));
  const [expanded, setExpanded] = useState(false);
  const users = useOldStateSelector(getUsers);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToList = (event) => {
    event.stopPropagation();
    navigate(`${EXPLORE_PREFIX}/${listId}`);
  };

  const getDiscoveryTypeTitle = (discoveryType) => {
    const type = Object.keys(DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES).find(
      (key) => DISCOVERY_TYPES_TO_SCHEDULE_DISOVERY_TYPES[key] === discoveryType
    );
    return DISCOVERY_TYPES_TITLES[type];
  };

  const rows = useMemo(() => {
    return (
      schedules &&
      Object.values(schedules).map((schedule) => {
        const data = {
          id: schedule.id,
          discoveryType: getDiscoveryTypeTitle(schedule.parameters.discovery_type),
          input: {
            user_search_term: schedule.parameters.user_search_term,
            company_metas: schedule.parameters.company_metas
          },
          start: schedule.start,
          recurrence: `Every ${getFrequencyFromCron(schedule.cron)}`
        };
        if (isAdminScreen) {
          data.user = users?.[schedule.user_id]?.email;
        } else {
          data.remove = {
            onClick: () => dispatch(deleteSchedulesAction(listId, [schedule.id])),
            icon: <DeleteIcon />,
            sx: { color: 'red' }
          };
        }
        return data;
      })
    );
  }, [schedules, dispatch, listId, isAdminScreen, users]);

  const columns = useMemo(() => {
    const cols = [
      { id: 'discoveryType', name: 'Discovery Type', data_type: 'str' },
      { id: 'input', name: 'Input', data_type: 'discovery_input' },
      { id: 'start', name: 'Start', data_type: 'datetime' },
      { id: 'recurrence', name: 'Recurrence', data_type: 'str' }
    ];
    if (isAdminScreen) {
      cols.push({ id: 'user', name: 'User', data_type: 'str' });
    } else {
      cols.push({ id: 'remove', name: 'Remove', data_type: 'button' });
    }
    return cols.map((col) => {
      return { ...col, values: rows.map((row) => row[col.id]) };
    });
  }, [rows, isAdminScreen]);

  const title = companyList
    ? companyList.name
    : `user: ${users?.[Object.values?.(schedules)?.[0]?.user_id]?.email}, list id: ${listId}`;

  return (
    <Stack alignItems="center">
      <Stack alignItems="flex-start" width="500px">
        <Stack
          onClick={() => setExpanded(!expanded)}
          direction="row"
          alignItems="center"
          width="100%"
          padding="8px 16px"
          sx={{
            cursor: 'pointer',
            border: '1px solid',
            borderColor: 'greyColors.grey100',
            borderRadius: '4px'
          }}>
          <Typography variant="paragraphBold">{title}</Typography>
          <Stack direction="row" gap="8px" marginLeft="auto" alignItems="center">
            <IconButtonComponent
              tag="Go To List"
              onClick={goToList}
              sx={{ color: 'primary.primary50' }}>
              <GoToIcon />
            </IconButtonComponent>
            <Box sx={{ transform: expanded ? 'unset' : 'rotate(180deg)' }}>
              <ArrowIcon />
            </Box>
          </Stack>
        </Stack>
        <Stack padding="8px 16px" width="100%" gap="8px">
          {expanded && schedules && (
            <TableComponent rows={rows} columns={columns} usePagination={false} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
export default MonitoringsScreen;

MonitoredListComponent.propTypes = {
  listId: PropTypes.number,
  schedules: PropTypes.array,
  isAdminScreen: PropTypes.bool
};
