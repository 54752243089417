import { Card, CardContent, CardMedia, Divider, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export function RedditExtraInfoComponent({ rowData }) {
  return (
    <Card sx={{ height: 'calc(100% - 52px)' }}>
      <CardContent>
        <Stack direction="column" gap="16px" padding="24px">
          <Stack direction="row" gap="16px" alignItems="center">
            <CardMedia
              sx={{ width: '40px', alignSelf: 'flex-start' }}
              component="img"
              image={rowData.metadata.value.profile_img}
              alt={rowData.name.value.value}
            />
            <Divider orientation="vertical" flexItem sx={{ borderColor: 'greyColors.grey100' }} />
            <Stack gap="4px" flex={1}>
              <Link
                href={rowData.metadata.value.url}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <Typography gutterBottom variant="h2" color="primary.primary2">
                  {rowData.name.value.value}
                </Typography>
              </Link>
              <Stack direction="row" gap="40px">
                <Stack>
                  <Typography variant="paragraphSemiBold" color="secondary.secondary6">
                    Cake Day
                  </Typography>
                  <Typography variant="paragraph" color="greyColors.grey300">
                    {rowData.created.value}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="paragraphSemiBold" color="secondary.secondary6">
                    Karma
                  </Typography>
                  <Typography variant="paragraph" color="greyColors.grey300">
                    {rowData.total_karma.value}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          {rowData.metadata.value.posts?.length > 0 && (
            <Stack gap="12px">
              <Typography variant="paragraphBold" color="secondary.secondary6" marginBottom="16px">
                Recent Post:
              </Typography>
              <Stack gap="12px">
                {rowData.metadata.value.posts.map((post, index) => (
                  <Link
                    key={index}
                    href={post.url}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    <Typography variant="paragraphSemiBold" color="primary.primary2">
                      {post.title}
                    </Typography>
                  </Link>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

RedditExtraInfoComponent.propTypes = {
  rowData: PropTypes.object
};
