import LoaderComponent from 'components/LoaderComponent';
import { ROUTES_PATHS } from 'constants/app-routes';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToHomeScreen() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES_PATHS.MAIN_PATH);
  }, [navigate]);

  return <LoaderComponent />;
}

export default RedirectToHomeScreen;
