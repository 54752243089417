import { TOAST_TYPES } from 'constants/toasts';

import { loadAllRuns } from './load-all-runs-action';

export function copyRunToUsersAction(runId, userIds, addToast) {
  return (_state, dispatch, apiClient) => {
    apiClient.runs
      .copyRunToUsers(runId, { user_ids: userIds })
      .then((res) => {
        if (res.ok) {
          addToast?.('Succesfully copied run to users', TOAST_TYPES.SUCCESS);
        } else {
          addToast?.('Failed to copy run to users', TOAST_TYPES.ERROR);
        }
        dispatch(loadAllRuns({ all: true }));
      })
      .catch(() => {
        addToast?.('Failed to copy run to users', TOAST_TYPES.ERROR);
        dispatch(loadAllRuns({ all: true }));
      });
  };
}
