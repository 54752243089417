import PropTypes from 'prop-types';

import RenderDropFileToTable from './RenderDropFileToTable';
import RenderRunningRow from './RenderRunnningRow';

function RenderGeneralColumnsOrComponent({ Component, ...restOfProps }) {
  const { value, field } = restOfProps;

  if (value?.uploadFiles) {
    return <RenderDropFileToTable {...restOfProps} />;
  } else if (value?.running && field !== 'company_name') {
    return <RenderRunningRow {...restOfProps} />;
  }
  return <Component {...restOfProps} />;
}

RenderGeneralColumnsOrComponent.propTypes = {
  Component: PropTypes.func
};

export default RenderGeneralColumnsOrComponent;
