export default function CrunchbaseIcon() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      role="img"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#146aff"
        d="M16.2 0H1.8A1.808 1.808 0 0 0 0 1.8v14.4A1.808 1.808 0 0 0 1.8 18h14.4a1.808 1.808 0 0 0 1.8 -1.8V1.8A1.808 1.808 0 0 0 16.2 0zM5.284 10.849A1.583 1.583 0 0 0 7.38 10.065h1.245a2.768 2.768 0 1 1 0 -1.313H7.38a1.583 1.583 0 1 0 -2.096 2.096zm8.509 0.634a2.662 2.662 0 0 1 -0.795 0.473 2.76 2.76 0 0 1 -2.542 -0.285v0.285h-1.133V4.027h1.125v3.083a2.805 2.805 0 0 1 1.35 -0.473H12a2.752 2.752 0 0 1 1.792 4.845zm-0.167 -2.075a1.578 1.578 0 1 1 -3.155 0 1.578 1.578 0 0 1 3.155 0z"
      />
    </svg>
  );
}
