import { TOAST_TYPES } from 'constants/toasts';
import { updateArtists } from 'reducer/art-slice';

export function getArtistsAction(addToast) {
  return (dispatch, _getState, apiClient) => {
    apiClient.art
      .getArtists()
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updateArtists({ artists: res.data.artists }));
        } else {
          addToast('Failed to find artists', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to find artists', TOAST_TYPES.ERROR);
      });
  };
}
