import { Typography } from '@mui/material';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getCompanyListIdByDiscoveryId,
  getDiscoveryNameById,
  getDiscoveryProgressById,
  getDiscoveryProgressMessageById,
  getDiscoveryTimestampById
} from 'selectors/discoveries';

import FinishedRunningSearch from '../FinishedRunningSearch';

export default function RunningSearchAction({ id, mode, recentlyFinished }) {
  const navigate = useNavigate();
  const searchTimestamp = useSelector((state) => getDiscoveryTimestampById(state, id));
  const progress = useSelector((state) => getDiscoveryProgressById(state, id)) || 0;
  const progressExistInState = Boolean(useSelector((state) => getDiscoveryProgressById(state, id)));
  const companyListId = useSelector((state) => getCompanyListIdByDiscoveryId(state, id));
  const name = useSelector((state) => getDiscoveryNameById(state, id));
  const progressMessage = useSelector((state) => getDiscoveryProgressMessageById(state, id));

  const onSeeResults = () => navigate(`${EXPLORE_PREFIX}/${companyListId}`);

  if (mode === 'monitor' && recentlyFinished) {
    return <FinishedRunningSearch name={name} runId={id} onSeeResults={onSeeResults} />;
  }

  return (
    <RunningSearchActionBase {...{ progress, progressExistInState, searchTimestamp }}>
      {mode === 'tile' ? (
        <Typography variant="paragraph" color="greyColors.grey300" textAlign="left" flex={1}>
          Running Discovery
        </Typography>
      ) : (
        <Typography variant="paragraphBold" color="primary.primary100" textAlign="left" flex={1}>
          {name}
        </Typography>
      )}
      {progressMessage && (
        <Typography variant="paragraph" color="greyColors.grey300" textAlign="left">
          {progressMessage}
        </Typography>
      )}
    </RunningSearchActionBase>
  );
}

RunningSearchAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.oneOf(['tile', 'monitor']),
  recentlyFinished: PropTypes.bool
};

RunningSearchAction.defaultProps = {
  mode: 'tile'
};
