import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import ClustersScatterPlotComponent from '../elements/scatter/ClustersScatterPlotComponent';

/* eslint-disable id-length */
const MIN_ZOOM = 0.02;
const DEFAULT_ZOOM = { x1: null, y1: null, x2: null, y2: null };

function ClustersScatterPlotWithZoomArea({
  data,
  currentClusterLabel,
  setIsZoomed,
  clickedZoomOut,
  setClickedZoomOut,
  onDataPointClick,
  headerHeight
}) {
  const [filteredData, setFilteredData] = useState(data);
  const [zoomArea, setZoomArea] = useState(DEFAULT_ZOOM);
  // flag if currently zooming (press and drag)
  const [isZooming, setIsZooming] = useState(false);
  // flag if zoomed in
  const isZoomed = filteredData?.length !== data?.length;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setIsZoomed(isZoomed);
  }, [setIsZoomed, isZoomed]);

  useEffect(() => {
    if (clickedZoomOut) {
      setFilteredData(data);
      setZoomArea(DEFAULT_ZOOM);
      setClickedZoomOut(false);
    }
  }, [setClickedZoomOut, clickedZoomOut, data]);

  // flag to show the zooming area (ReferenceArea)
  const showZoomBox =
    isZooming &&
    !(Math.abs(zoomArea.x1 - zoomArea.x2) < MIN_ZOOM) &&
    !(Math.abs(zoomArea.y1 - zoomArea.y2) < MIN_ZOOM);

  function handleMouseDown(e) {
    setIsZooming(true);
    const { xValue, yValue } = e || {};
    setZoomArea({ x1: xValue, y1: yValue, x2: xValue, y2: yValue });
  }

  // Update zoom end coordinates
  function handleMouseMove(e) {
    if (isZooming) {
      setZoomArea((prev) => ({ ...prev, x2: e?.xValue, y2: e?.yValue }));
    }
  }

  // When zooming stops, update with filtered data points
  // Ignore if not enough zoom
  function handleMouseUp() {
    if (isZooming) {
      setIsZooming(false);
      let { x1, y1, x2, y2 } = zoomArea;

      // ensure x1 <= x2 and y1 <= y2
      if (x1 > x2) [x1, x2] = [x2, x1];
      if (y1 > y2) [y1, y2] = [y2, y1];

      if (!(x2 - x1 < MIN_ZOOM || y2 - y1 < MIN_ZOOM)) {
        const dataPointsInRange = filteredData.filter(
          (d) => d.x >= x1 && d.x <= x2 && d.y >= y1 && d.y <= y2
        );
        setFilteredData(dataPointsInRange);
        setZoomArea(DEFAULT_ZOOM);
      }
    }
  }

  return (
    <ClustersScatterPlotComponent
      currentClusterLabel={currentClusterLabel}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      handleMouseUp={handleMouseUp}
      showZoomBox={showZoomBox}
      data={filteredData}
      zoomArea={zoomArea}
      onDataPointClick={onDataPointClick}
      headerHeight={headerHeight}
    />
  );
}

ClustersScatterPlotWithZoomArea.propTypes = {
  data: PropTypes.array,
  currentClusterLabel: PropTypes.string,
  setIsZoomed: PropTypes.func,
  clickedZoomOut: PropTypes.bool,
  setClickedZoomOut: PropTypes.func,
  onDataPointClick: PropTypes.func,
  headerHeight: PropTypes.number
};

export default ClustersScatterPlotWithZoomArea;
