import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { addNewFolder, updateCurrentFolderId } from 'reducer/folders-slice';

export function loadFolderByIdAction(folderId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.folders.fetchFolder(folderId).then((res) => {
      if (res.ok && res.data) {
        const { data: folder } = res;
        dispatch(addNewFolder({ folder }));
      } else if (res.status === 403) {
        dispatch(updateCurrentFolderId({ folderId: SHARED_WITH_ME_FOLDER_ID }));
      }
    });
  };
}
