import PropTypes from 'prop-types';
import React from 'react';

export default function StackExchangeIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8331 18.4991V23.8469H9.29743V18.4991H7.51855V25.6291H23.6142V18.4991H21.8331Z"
        fill="#374EF4"
      />
      <path d="M20.0486 22.0669H11.0819V20.2814H20.0486V22.0669Z" fill="#374EF4" />
      <path
        d="M20.0475 19.8416L11.299 18.0149L11.6752 16.2861L20.426 18.114L20.0475 19.8416Z"
        fill="#374EF4"
      />
      <path
        d="M20.5373 17.5741L12.4322 13.7915V13.7803L13.1869 12.1617L21.2921 15.9455L20.5373 17.5741Z"
        fill="#374EF4"
      />
      <path
        d="M21.5637 15.5202L14.6997 9.79621L14.7064 9.7951L15.8374 8.4448L22.6969 14.1666L21.5637 15.5202Z"
        fill="#374EF4"
      />
      <path d="M19.131 5.59164L19.1325 5.59058H19.1302L19.131 5.59164Z" fill="#374EF4" />
      <path
        d="M19.131 5.59164L17.6731 6.66926L23.0197 13.8538L24.4814 12.7729L19.131 5.59164Z"
        fill="#374EF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16.9453C32 25.7819 24.8366 32.9453 16 32.9453C7.16344 32.9453 0 25.7819 0 16.9453C0 8.10876 7.16344 0.945312 16 0.945312C24.8366 0.945312 32 8.10876 32 16.9453ZM31.5 16.9453C31.5 25.5057 24.5604 32.4453 16 32.4453C7.43959 32.4453 0.5 25.5057 0.5 16.9453C0.5 8.3849 7.43959 1.44531 16 1.44531C24.5604 1.44531 31.5 8.3849 31.5 16.9453Z"
        fill="#374EF4"
      />
    </svg>
  );
}

StackExchangeIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

StackExchangeIcon.defaultProps = {
  width: '32',
  height: '33'
};
