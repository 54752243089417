import axios from 'axios';

import { getAuth0Token } from './client-api';

const MAX_CONCURRENT_CHUNKS_UPLOAD = 10;
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default class FilesUploaderManager {
  constructor(token) {
    this.pendingChunksQueue = [];
    this.numOfRunningChunks = 0;
    this.token = token;
  }

  async runChunks() {
    let availableSpots = MAX_CONCURRENT_CHUNKS_UPLOAD - this.numOfRunningChunks;
    const token = await getAuth0Token();
    while (availableSpots > 0 && this.pendingChunksQueue.length > 0) {
      const job = this.pendingChunksQueue.shift();
      this.numOfRunningChunks++;
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total / job.metadata.totalChunks
          );
          job.metadata.progress[job.chunkNumber - 1] = percentCompleted;
          job.handleProgress?.(
            job.metadata.progress.reduce((sum, chunkProgress) => sum + chunkProgress, 0)
          );
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      axios
        .post(`${BASE_URL}/${job.uploadPath}`, job.chunk, config)
        .then((res) => {
          job.metadata.finishedChunks++;
          this.numOfRunningChunks--;
          job.handleChunkUploaded(
            res,
            null,
            job.metadata.finishedChunks === job.metadata.totalChunks
          );
          this.runChunks();
        })
        .catch((error) => {
          job.metadata.finishedChunks++;
          this.numOfRunningChunks--;
          job.handleChunkUploaded(null, error);
          this.runChunks();
        });
      --availableSpots;
    }
  }

  uploadFile(resourceId, file, uploadPath, handleChunkUploaded, handleProgress, body) {
    const { name, type, size } = file;
    const chunkSize = 1 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    let start = 0;
    let end = chunkSize;
    const metadata = {
      resourceId,
      totalChunks,
      finishedChunks: 0,
      progress: Array.from({ length: totalChunks }, () => 0)
    };
    for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
      const chunk = file.slice(start, end);
      const formData = new FormData();
      formData.append('uploaded_file', chunk, resourceId);
      formData.append('chunk_number', chunkNumber);
      formData.append('total_chunks', totalChunks);
      formData.append('name', name);
      formData.append('type', type);
      formData.append('size', size);
      formData.append('resource_id', resourceId);
      formData.append('upload_id', resourceId);
      if (body) {
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });
      }
      this.pendingChunksQueue.push({
        chunk: formData,
        uploadPath,
        handleChunkUploaded,
        handleProgress,
        chunkNumber,
        metadata
      });
      start = end;
      end = start + chunkSize;
    }

    this.runChunks();
  }
}
