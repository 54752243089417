export const TILE_TYPE = Object.freeze({
  RUN: 'run',
  PERSON: 'person',
  DISCOVERY: 'discovery',
  TAGGING: 'tagging'
});

export const TILE_TYPE_TO_ID_KEY = Object.freeze({
  [TILE_TYPE.RUN]: 'record_id',
  [TILE_TYPE.PERSON]: 'record_id',
  [TILE_TYPE.DISCOVERY]: 'company_list_id'
});

export const EXCEEDED_LIMIT_TEXT =
  'You have exceeded your runs capacity as a trial user. If you would like to expand your plan please contact us at info@mathlabs.co.uk';
