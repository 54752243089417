import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { loadAdminListAction } from 'actions/company_lists/load-admin-company-list-action';
import ModalComponent from 'components/modal/ModalComponent';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

const actionToLabel = Object.freeze({
  dry_discovery: 'Create Empty List',
  discovery_via_companies: 'Discover Via Peers',
  discovery_via_partnership: 'Discover Via Partnership',
  discovery_via_search: 'Discover Via Search',
  discovery_via_url: 'Discover Via Custom URL'
});

function ListActionsTab({ hidden, lists }) {
  const [historyListId, setHistoryListId] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const historyList = historyListId ? lists?.[historyListId] : null;

  const onClickDiscovery = (listId) => {
    navigate(`${EXPLORE_PREFIX}/${listId}`);
  };
  const openHistoryModal = (listId) => {
    setHistoryListId(listId);
    setOpen(true);
  };

  useEffect(() => {
    if (historyListId) {
      dispatch(loadAdminListAction(historyListId));
    }
  }, [historyListId]);
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        <Stack
          direction="row"
          gap="32px"
          alignItems="center"
          sx={{ padding: '8px', borderBottom: '1px solid black' }}>
          <Button size="small" variant="contained" disabled sx={{ width: '60px' }}>
            Run ID
          </Button>
          <Typography variant="paragraph">Lastest Interaction</Typography>
          <Typography variant="paragraph">List Name</Typography>
          <Typography variant="paragraph" sx={{ marginLeft: 'auto' }}>
            User
          </Typography>
          <Button size="small" variant="contained" disabled sx={{ width: '60px' }}>
            History
          </Button>
        </Stack>
        {Object.keys(lists)
          .reverse()
          .map((key) => {
            const list = lists[key];
            return (
              <Stack
                key={list}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickDiscovery(list.company_list_id)}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {list.company_list_id}
                </Button>
                <Typography variant="paragraph">{formatTimestamp(list.time_submitted)}</Typography>
                <Typography variant="paragraph">{list.name}</Typography>
                <Typography variant="paragraph" sx={{ marginLeft: 'auto' }}>
                  {list.user_email}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  sx={{ width: '60px' }}
                  onClick={() => openHistoryModal(list.company_list_id)}>
                  History
                </Button>
              </Stack>
            );
          })}
      </Stack>
      <ModalComponent
        title={`${historyList?.name} - ${historyList?.user_email}`}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={800}>
        <Stack direction="column" gap="32px" padding="24px" width="90%">
          {historyList && historyList?.actions ? (
            historyList.actions.map((action, index) => (
              <Tooltip key={index} title={JSON.stringify(action.parameters)}>
                <Stack direction="row" gap="8px">
                  <Typography>{formatTimestamp(action.timestamp)}</Typography>
                  <Typography>
                    {action?.parameters?.discovery_type
                      ? actionToLabel?.[action.parameters.discovery_type]
                      : 'Create New List From Selection'}
                  </Typography>
                </Stack>
              </Tooltip>
            ))
          ) : (
            <Typography>Loading history...</Typography>
          )}
        </Stack>
      </ModalComponent>
    </Stack>
  );
}

export default ListActionsTab;

ListActionsTab.propTypes = {
  lists: PropTypes.object,
  hidden: PropTypes.bool
};
