import { updateExploreListCompaniesIsChecked } from 'reducer/explore-table-ui';

export function updateExploreCompanyIsCheckedByCompanyListMetaId(
  listId,
  companyListMetaId,
  checked
) {
  return (dispatch) => {
    dispatch(
      updateExploreListCompaniesIsChecked({
        listId,
        companyListMetaId,
        checked
      })
    );
  };
}
