import { sendExploreEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';

export function getSemanticSearchAction(listId, searchTerm, onSearchDone) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore.getSemanticSearch(listId, searchTerm).then((res) => {
      if (res.ok) {
        const { scored_companies: companies } = res.data;
        const companiesIds = companies.map((company) => company.id);
        const scores = companies.reduce((acc, company) => ({ ...acc, [company.id]: company }), {});
        onSearchDone?.(scores, companiesIds);
        dispatch(
          sendExploreEvent(
            USER_EVENTS.SEMANTIC_SEARCH,
            listId,
            `Semantic search for "${searchTerm}"`,
            {
              listId,
              search_term: searchTerm
            }
          )
        );
      }
    });
  };
}
