import { ACTIONS } from 'constants/actions';

function updateDebugMode(isEnabled) {
  return { type: ACTIONS.UPDATE_DEBUG_MODE, isEnabled };
}

export function updateDebugModeAction() {
  return (state, dispatch) => {
    dispatch(updateDebugMode(!state.debugMode));
  };
}
