import { sendUserEvent } from 'actions/users/send-user-event-action';
import { updateExploreFiles } from 'reducer/explore-slice';
import { addUploadFileProgress, fileUploadEnded, startUploadFile } from 'reducer/files';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';

export function addExploreFile(listId, companyListMetaId, filesInfo) {
  return (dispatch) => {
    dispatch(updateExploreFiles({ listId, companyListMetaId, filesInfo }));
  };
}

export function uploadFileToExploreRowAction(listId, companyListMetaId, file, onFinish) {
  return (dispatch, getState, apiClient) => {
    dispatch(
      sendUserEvent('upload_file_to_explore_column', {
        listId,
        companyListMetaId
      })
    );
    const allCompanies = getCompanyMetasMergedRowsMapByListId(getState(), listId);

    const localFileId = new Date().getTime();
    dispatch(
      startUploadFile({
        id: localFileId,
        file: {
          name: file.name,
          size: file.size,
          target: `explore/${listId}/${allCompanies[companyListMetaId]?.name?.toUpperCase()}`
        }
      })
    );

    apiClient.filesUploaderManager.uploadFile(
      localFileId,
      file,
      `company_list_metas/${companyListMetaId}/upload_file`,
      (res, error, wholeFileUploaded) => {
        const isSucceed = !error && res?.status === 200;
        const isFinishedUploadFile = error || wholeFileUploaded;
        if (isFinishedUploadFile) {
          if (isSucceed && res?.data?.files) {
            dispatch(addExploreFile(listId, companyListMetaId, res?.data?.files));
          }
          dispatch(fileUploadEnded({ id: localFileId, isSucceed: isSucceed }));
          onFinish?.(isSucceed);
        }
      },
      (progress) =>
        dispatch(
          addUploadFileProgress({
            id: localFileId,
            progress
          })
        )
    );
  };
}
