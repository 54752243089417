import BaseClient from './BaseClient';

const URL_PREFIX = 'tagging';

export default class TaggingClient extends BaseClient {
  fetchClusters(taggingId, params) {
    if (params) {
      const searchParams = new URLSearchParams(params);
      return this.get(`${URL_PREFIX}/${taggingId}?${searchParams}`);
    }

    return this.get(`${URL_PREFIX}/${taggingId}`);
  }

  getClustersStatuses(taggingId) {
    return this.get(`${URL_PREFIX}/${taggingId}`);
  }

  fetchClustersProgress(taggingId) {
    return this.get(`${URL_PREFIX}/${taggingId}/progress`);
  }

  submitClustersRun(company_list_id) {
    return this.post(URL_PREFIX, { company_list_id });
  }

  fetchAllClusters(company_list_id) {
    if (company_list_id) {
      const searchParams = new URLSearchParams({ company_list_id, all: 1 });
      return this.get(`${URL_PREFIX}?${searchParams}`);
    }
    const searchParams = new URLSearchParams({ all: 1 });
    return this.get(`${URL_PREFIX}?${searchParams}`);
  }
}
