import { updateExploreFinancePeerColumn } from './update-finance-peer-action';

export function updateFinancePeerInclusionAction(companyMetaId, listId, included) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companyMetas.updateFinancePeerInclusion(companyMetaId, included).then((res) => {
      if (res.ok && res.data) {
        const { company_metas } = res.data;
        dispatch(updateExploreFinancePeerColumn(listId, company_metas));
      }
    });
  };
}
