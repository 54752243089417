import PropTypes from 'prop-types';

import RenderMultipleLogos from './RenderMultipleLogos';

export default function RenderLeadInvestors({ value }) {
  const formatInvestorText = (investor) => `${investor.name}: ${investor.funding_round_message}`;
  const data = value?.map((investor) => {
    return { logo_url: investor.logo_url, text: formatInvestorText(investor) };
  });
  return <RenderMultipleLogos value={data} />;
}

RenderLeadInvestors.propTypes = {
  value: PropTypes.array
};
