import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  links: {},
  linkIds: {},
  uiSettings: {}
};

export const publicShareLinksSlice = createSlice({
  name: 'public-share-links',
  initialState: initialSliceState,
  reducers: {
    updateAllPublicShareLinks: (sliceState, { payload }) => {
      const { runId, publicShareLinks } = payload;
      sliceState.links[runId] = publicShareLinks;
    },
    addPublicShareLink: (sliceState, { payload }) => {
      const { runId, publicShareLink } = payload;
      sliceState.links[runId] = { [publicShareLink.id]: publicShareLink };
    },
    deletePublicShareLink: (sliceState, { payload }) => {
      const { runId, publicShareLink } = payload;
      const newRecordLinks = sliceState.links[runId] ? { ...sliceState.links[runId] } : {};
      delete newRecordLinks[publicShareLink.id];
      sliceState.links[runId] = newRecordLinks;
    },
    updateLinkIdData: (sliceState, { payload }) => {
      const { linkId, runId } = payload;
      sliceState.linkIds[linkId] = { linkId, searchId: runId };
    },
    updateUiSettingsLoadingState: (sliceState, { payload }) => {
      const { runId, isLoading } = payload;
      if (sliceState.uiSettings[runId]) {
        sliceState.uiSettings[runId].isLoading = isLoading;
      } else {
        sliceState.uiSettings[runId] = { isLoading };
      }
    },
    updateUiSettingsAddingInProgress: (sliceState, { payload }) => {
      const { runId, inAddProgress } = payload;
      if (sliceState.uiSettings[runId]) {
        sliceState.uiSettings[runId].inAddProgress = inAddProgress;
      } else {
        sliceState.uiSettings[runId] = { inAddProgress };
      }
    },
    updateUiSettingsLoadingPublicLinkData: (sliceState, { payload }) => {
      const { publicShareLinkId, isLoadingPublicLinkData } = payload;
      if (sliceState.uiSettings[publicShareLinkId]) {
        sliceState.uiSettings[publicShareLinkId].isLoadingPublicLinkData = isLoadingPublicLinkData;
      } else {
        sliceState.uiSettings[publicShareLinkId] = { isLoadingPublicLinkData };
      }
    }
  }
});

export const {
  updateAllPublicShareLinks,
  addPublicShareLink,
  deletePublicShareLink,
  updateLinkIdData,
  updateUiSettingsLoadingState,
  updateUiSettingsAddingInProgress,
  updateUiSettingsLoadingPublicLinkData
} = publicShareLinksSlice.actions;

export default publicShareLinksSlice.reducer;
