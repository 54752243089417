import { Avatar, Box, Divider, Link, Stack, Typography } from '@mui/material';
import SentimentComponent from 'components/SentimentComponent';
import RedditIcon from 'icons/RedditIcon';
import StackExchangeIcon from 'icons/StackExchangeIcon';
import TwitterIcon from 'icons/TwitterIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { convertCardFilterValue } from 'utils/card-filters-utils';

function TopMentionsElement({ data, cardFilters }) {
  const determineIcon = (source) => {
    if (source === 'StackExchange') {
      return <StackExchangeIcon width={16} height={16} />;
    }
    if (source === 'Reddit') {
      return <RedditIcon width={16} height={16} />;
    }
    if (source === 'Twitter') {
      return <TwitterIcon width={16} height={16} />;
    }
    return <StackExchangeIcon width={16} height={16} />;
  };
  const sortedData = useMemo(() => {
    const filteredData =
      cardFilters?.length > 0
        ? data.filter((item) => {
            for (let filterIndex = 0; filterIndex < cardFilters.length; ++filterIndex) {
              const value = cardFilters[filterIndex].key?.convert?.top_mentions
                ? convertCardFilterValue(
                    item[cardFilters[filterIndex].key.mapping.top_mentions],
                    cardFilters[filterIndex].key?.convert?.top_mentions
                  )
                : item[cardFilters[filterIndex].key.mapping.top_mentions];
              if (cardFilters[filterIndex].value !== value) {
                return false;
              }
            }
            return true;
          })
        : data;
    return filteredData.reduce((topMentions, item) => {
      if (topMentions.length === 0) {
        topMentions.push(item);
        return topMentions;
      }

      if (topMentions.length === 1) {
        topMentions.push(item.popularity > topMentions[0].popularity ? topMentions[0] : item);
        if (item.popularity > topMentions[0].popularity) {
          topMentions[0] = item;
        }
        return topMentions;
      }

      const [currentTop, currentSecond] = topMentions;
      if (currentTop.popularity < item.popularity) {
        topMentions[1] = currentTop;
        topMentions[0] = item;
      } else if (currentSecond.popularity < item.popularity) {
        topMentions[1] = item;
      }
      return topMentions;
    }, []);
  }, [data, cardFilters]);

  const sxString = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  };
  const sxArray = {};
  const bodyToBullets = (body) => {
    return (
      <ul>
        {body.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };
  return (
    <Stack direction="column" gap="16px" sx={{ marginTop: '16px' }}>
      {sortedData.map((mention) => (
        <Link
          key={mention.url}
          href={mention.url}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}>
          <Stack
            direction="column"
            gap="8px"
            sx={{
              border: '1px solid',
              borderColor: 'greyColors.grey100',
              borderRadius: '4px',
              backgroundColor: 'background.bg50',
              padding: '16px'
            }}>
            <Stack direction="row" gap="8px">
              <Typography variant="paragraphSemiBold" color="primary.primary2" flex={1}>
                {mention.title}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ borderColor: 'greyColors.grey100' }} />
              <SentimentComponent sentiment={mention.sentiment} />
            </Stack>
            <Divider />
            <Typography
              variant="paragraph"
              color="greyColors.grey300"
              sx={mention.body_is_list ? sxArray : sxString}>
              {mention.body_is_list ? bodyToBullets(mention.body) : mention.body}
            </Typography>
            <Stack direction="row" alignItems="center" gap="16px">
              {mention.creator_name && (
                <React.Fragment>
                  <Avatar
                    src={mention.creator_img}
                    alt={mention.creator_name}
                    sx={{ width: '24px', height: '24px' }}
                  />
                  <Typography variant="paragraphMedium" color="greyColors.grey300" flex={1}>
                    {mention.creator_name}
                  </Typography>
                </React.Fragment>
              )}
              {mention?.source ? (
                determineIcon(mention.source)
              ) : (
                <Box sx={{ marginLeft: 'auto' }}></Box>
              )}
              {(mention.creation_date || mention.date) && (
                <React.Fragment>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: 'greyColors.grey100' }}
                  />
                  <Typography variant="paragraph" width="100px" color="greyColors.grey200">
                    {new Date(mention.creation_date || mention.date).toLocaleDateString('en-UK', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    })}
                  </Typography>
                </React.Fragment>
              )}
            </Stack>
          </Stack>
        </Link>
      ))}
    </Stack>
  );
}

TopMentionsElement.propTypes = {
  data: PropTypes.array,
  cardFilters: PropTypes.array
};

export default TopMentionsElement;
