import PropTypes from 'prop-types';
import React from 'react';

export const NewActionIcon = ({ disabled }) => {
  const color = disabled ? '#D7DCE4' : '#0F2345';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M18 21.3672H6C5.17157 21.3672 4.5 20.6956 4.5 19.8672V4.86719C4.5 4.03876 5.17157 3.36719 6 3.36719H13.4348C13.8425 3.36719 14.2328 3.53322 14.5155 3.82704L19.0808 8.57061C19.3497 8.85008 19.5 9.22288 19.5 9.61076V19.8672C19.5 20.6956 18.8284 21.3672 18 21.3672Z"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 13.3672C15.5 15.3002 13.933 16.8672 12 16.8672C10.067 16.8672 8.5 15.3002 8.5 13.3672C8.5 11.4342 10.067 9.86719 12 9.86719C13.933 9.86719 15.5 11.4342 15.5 13.3672ZM16.5 13.3672C16.5 15.8525 14.4853 17.8672 12 17.8672C9.51472 17.8672 7.5 15.8525 7.5 13.3672C7.5 10.8819 9.51472 8.86719 12 8.86719C14.4853 8.86719 16.5 10.8819 16.5 13.3672ZM12 15.8672C11.7239 15.8672 11.5 15.6433 11.5 15.3672V13.8672H10C9.72386 13.8672 9.5 13.6433 9.5 13.3672C9.5 13.091 9.72386 12.8672 10 12.8672H11.5L11.5 11.3672C11.5 11.091 11.7239 10.8672 12 10.8672C12.2761 10.8672 12.5 11.091 12.5 11.3672V12.8672H14C14.2761 12.8672 14.5 13.091 14.5 13.3672C14.5 13.6433 14.2761 13.8672 14 13.8672H12.5V15.3672C12.5 15.6433 12.2761 15.8672 12 15.8672Z"
        fill={color}
      />
    </svg>
  );
};

NewActionIcon.propTypes = {
  disabled: PropTypes.bool
};

NewActionIcon.defaultProps = {
  disabled: false
};
