import React from 'react';

export default function FinanceGrowthIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none">
      <path
        d="M38.1487 7.396H6.01872C5.6984 7.396 5.43872 7.65567 5.43872 7.976V28.286C5.43872 28.6063 5.6984 28.866 6.01872 28.866H38.1487C38.469 28.866 38.7287 28.6063 38.7287 28.286V7.976C38.7287 7.65567 38.469 7.396 38.1487 7.396Z"
        fill="#8EBCFC"
      />
      <path
        d="M35.1687 9.90601H4.9887C4.6739 9.90601 4.4187 10.1612 4.4187 10.476V30.806C4.4187 31.1208 4.6739 31.376 4.9887 31.376H35.1687C35.4835 31.376 35.7387 31.1208 35.7387 30.806V10.476C35.7387 10.1612 35.4835 9.90601 35.1687 9.90601Z"
        fill="white"
      />
      <path
        d="M34.2187 10.946H4.03873C3.72393 10.946 3.46873 11.2012 3.46873 11.516V31.846C3.46873 32.1608 3.72393 32.416 4.03873 32.416H34.2187C34.5335 32.416 34.7887 32.1608 34.7887 31.846V11.516C34.7887 11.2012 34.5335 10.946 34.2187 10.946Z"
        fill="white"
      />
      <path
        d="M34.2287 32.686H4.03873C3.57873 32.686 3.19873 32.306 3.19873 31.846V11.506C3.19873 11.046 3.57873 10.666 4.03873 10.666H34.2287C34.6887 10.666 35.0687 11.046 35.0687 11.506V31.846C35.0687 32.306 34.6887 32.686 34.2287 32.686ZM4.03873 11.216C3.87873 11.216 3.74873 11.346 3.74873 11.506V31.846C3.74873 32.006 3.87873 32.136 4.03873 32.136H34.2287C34.3887 32.136 34.5187 32.006 34.5187 31.846V11.506C34.5187 11.346 34.3887 11.216 34.2287 11.216H4.03873Z"
        fill="#374EF4"
      />
      <path d="M34.9087 10.896H3.36865V16.036H34.9087V10.896Z" fill="#374EF4" />
      <path
        d="M5.48853 14.166C5.89723 14.166 6.22854 13.8347 6.22854 13.426C6.22854 13.0173 5.89723 12.686 5.48853 12.686C5.07984 12.686 4.74854 13.0173 4.74854 13.426C4.74854 13.8347 5.07984 14.166 5.48853 14.166Z"
        fill="white"
      />
      <path
        d="M7.90854 14.166C8.31723 14.166 8.64853 13.8347 8.64853 13.426C8.64853 13.0173 8.31723 12.686 7.90854 12.686C7.49984 12.686 7.16854 13.0173 7.16854 13.426C7.16854 13.8347 7.49984 14.166 7.90854 14.166Z"
        fill="white"
      />
      <path
        d="M10.3285 14.166C10.7372 14.166 11.0685 13.8347 11.0685 13.426C11.0685 13.0173 10.7372 12.686 10.3285 12.686C9.91984 12.686 9.58854 13.0173 9.58854 13.426C9.58854 13.8347 9.91984 14.166 10.3285 14.166Z"
        fill="white"
      />
      <path
        d="M17.699 21.5559H15.679C15.5519 21.5559 15.449 21.6589 15.449 21.7859V30.4459C15.449 30.5729 15.5519 30.6759 15.679 30.6759H17.699C17.826 30.6759 17.929 30.5729 17.929 30.4459V21.7859C17.929 21.6589 17.826 21.5559 17.699 21.5559Z"
        fill="#86AD28"
      />
      <path
        d="M17.699 30.9559H15.679C15.399 30.9559 15.179 30.7259 15.179 30.4559V21.7859C15.179 21.5059 15.409 21.2859 15.679 21.2859H17.699C17.979 21.2859 18.199 21.5159 18.199 21.7859V30.4559C18.199 30.7359 17.969 30.9559 17.699 30.9559ZM17.649 21.7859L15.679 21.8359L15.729 30.4559L17.659 30.4159V21.7959L17.649 21.7859Z"
        fill="#86AD28"
      />
      <path
        d="M12.8186 24.5759H10.7986C10.6716 24.5759 10.5686 24.6789 10.5686 24.8059V30.4459C10.5686 30.573 10.6716 30.6759 10.7986 30.6759H12.8186C12.9456 30.6759 13.0486 30.573 13.0486 30.4459V24.8059C13.0486 24.6789 12.9456 24.5759 12.8186 24.5759Z"
        fill="#86AD28"
      />
      <path
        d="M12.8186 30.9559H10.7986C10.5186 30.9559 10.2986 30.7259 10.2986 30.4559V24.8159C10.2986 24.5359 10.5286 24.3159 10.7986 24.3159H12.8186C13.0986 24.3159 13.3186 24.5459 13.3186 24.8159V30.4559C13.3186 30.7359 13.0886 30.9559 12.8186 30.9559ZM12.7686 24.8059L10.7986 24.8559L10.8486 30.4459L12.7786 30.4059V24.8059H12.7686Z"
        fill="#86AD28"
      />
      <path
        d="M22.5886 19.116H20.5686C20.4416 19.116 20.3386 19.2189 20.3386 19.346V30.446C20.3386 30.573 20.4416 30.676 20.5686 30.676H22.5886C22.7156 30.676 22.8186 30.573 22.8186 30.446V19.346C22.8186 19.2189 22.7156 19.116 22.5886 19.116Z"
        fill="#86AD28"
      />
      <path
        d="M22.5886 30.956H20.5686C20.2886 30.956 20.0686 30.726 20.0686 30.456V19.356C20.0686 19.076 20.2986 18.856 20.5686 18.856H22.5886C22.8686 18.856 23.0886 19.086 23.0886 19.356V30.456C23.0886 30.736 22.8586 30.956 22.5886 30.956ZM22.5386 19.356L20.5686 19.406L20.6186 30.456L22.5486 30.416V19.366L22.5386 19.356Z"
        fill="#86AD28"
      />
      <path
        d="M27.4687 17.6059H25.4487C25.3217 17.6059 25.2187 17.7089 25.2187 17.8359V30.4559C25.2187 30.583 25.3217 30.6859 25.4487 30.6859H27.4687C27.5958 30.6859 27.6987 30.583 27.6987 30.4559V17.8359C27.6987 17.7089 27.5958 17.6059 27.4687 17.6059Z"
        fill="#374EF4"
      />
      <path
        d="M27.4687 30.9559H25.4487C25.1687 30.9559 24.9487 30.7259 24.9487 30.4559V17.8359C24.9487 17.5559 25.1787 17.3359 25.4487 17.3359H27.4687C27.7487 17.3359 27.9687 17.5659 27.9687 17.8359V30.4559C27.9687 30.7359 27.7387 30.9559 27.4687 30.9559ZM27.4187 17.8359L25.4387 17.8859L25.4887 30.4559L27.4187 30.4159V17.8359Z"
        fill="#374EF4"
      />
    </svg>
  );
}
