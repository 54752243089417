import AniversaryIcon from 'icons/AniversaryIcon';
import HeadcountIcon from 'icons/HeadcountIcon';
import LocationIcon from 'icons/LocationIcon';
import ReactDOMServer from 'react-dom/server';
import { createSelector } from 'reselect';
import { isEmpty } from 'utils/objects-utils';

export const getRuns = (state) => state.runs;
export const getOnlyUserRuns = (state, userId) => {
  const runs = getRuns(state);
  if (!runs) {
    return {};
  }
  return Object.keys(runs)
    .filter((key) => runs[key].ownerId === userId)
    .reduce((cur, key) => ({ ...cur, [key]: runs[key] }), {});
};
export const getRunById = (state, runId) => getRuns(state)[runId] || {};
export const getRunParametersById = (state, runId) => getRunById(state, runId).parameters;
export const getRunCompanyNameById = (state, runId) => getRunById(state, runId).name;
export const getRunCompanyDescriptionById = (state, runId) => getRunById(state, runId).description;
export const getRunCompanyDomainById = (state, runId) => {
  const companyWebsite = getRunById(state, runId).domain;
  return (companyWebsite || '').replace('https://', '').replace('http://', '');
};
export const getRunOwner = (state, id) => getRunById(state, id)?.user;
export const getRunIsMineById = (state, runId, userId) => {
  if (!userId) {
    return false;
  }
  return getRunOwner(state, runId)?.id === userId;
};
export const getRunCollaboratorsUsers = (state, id) => getRunById(state, id)?.collaboratorsUsers;
export const getRunCollaboratorsOrgs = (state, id) => getRunById(state, id)?.collaboratorsOrgs;
export const getRunCollaboratorsTeams = (state, id) => getRunById(state, id)?.collaboratorsTeams;
export const getRunShared = (state, runId) => {
  if (getRunCollaboratorsOrgs(state, runId)?.length) {
    return true;
  }
  if (getRunCollaboratorsUsers(state, runId)?.length) {
    return true;
  }
  if (getRunCollaboratorsTeams(state, runId)?.length) {
    return true;
  }
  return false;
};
export const getRunOwnerIdById = (state, runId) => getRunById(state, runId).ownerId;

export const getRunCollaborateInfo = (state, id) => {
  return {
    resourceUsers: getRunCollaboratorsUsers(state, id),
    resourceOrgs: getRunCollaboratorsOrgs(state, id),
    resourceTeams: getRunCollaboratorsTeams(state, id),
    resourceOwner: getRunOwner(state, id)
  };
};
export const getRunCompanyHQById = (state, runId) => getRunById(state, runId).hq;
export const getRunCompanyFoundedDateById = (state, runId) => getRunById(state, runId).yearFounded;
export const getRunCompanySizeById = (state, runId) => getRunById(state, runId).size;
export const getRunCompanyLogoUrlById = (state, runId) => getRunById(state, runId).logoUrl;
export const getRunCompanyLinkedinNameById = (state, runId) =>
  getRunById(state, runId).linkedinName;
export const getRunCompanyLinkedinUrlById = (state, runId) => getRunById(state, runId).linkedinUrl;
export const getRunCompanyCBNameById = (state, runId) =>
  getRunById(state, runId).parameters.cb_name;
export const getRunCompanyCBPermalinkById = (state, runId) =>
  getRunById(state, runId).parameters.cb_permalink;
export const getRunStatusesById = (state, runId) => getRunById(state, runId).statuses;
export const getRunTimestampById = (state, runId) => getRunById(state, runId).timeSubmitted;
export const getRunRerunningById = (state, runId) => {
  const name = getRunCompanyNameById(state, runId);
  const runs = getRuns(state);
  return Object.values(runs).filter((run) => run.name === name).length > 1;
};
export const getRunProgressById = (state, runId) => getRunById(state, runId).progress;
export const getRunSearchStatusById = (state, runId) => {
  const statuses = getRunStatusesById(state, runId);
  const progress = getRunProgressById(state, runId);
  const run = getRunById(state, runId);
  if (isEmpty(run)) {
    return null;
  }
  if (!statuses) {
    return 'running';
  }
  if (Object.values(statuses).every((status) => status === 'SUCCEEDED') || progress >= 100) {
    return 'ready';
  } else if (
    Object.values(statuses).every(
      (status) => status === 'SUCCEEDED' || status === 'FAILED' || status === 'SUBMISSION_FAILED'
    )
  ) {
    return 'ready (with failures)';
  }

  return 'running';
};
export const getRunFailedServicesById = (state, runId) => {
  const statuses = getRunStatusesById(state, runId);
  if (!statuses) {
    return null;
  }
  return Object.keys(statuses).filter((service) => statuses[service] === 'FAILED');
};
export const getRunRunningServicesById = (state, runId) => {
  const statuses = getRunStatusesById(state, runId);
  if (!statuses) {
    return null;
  }
  return Object.keys(statuses).filter((service) => statuses[service] === 'RUNNING');
};
export const getIsLoadingRunFiles = (state) => state?.isLoadingRunFiles;
export const getCurrentlyRunningCompanies = (state) =>
  createSelector(getRuns, (runs) =>
    Object.keys(runs).reduce((acc, runId) => {
      if (getRunSearchStatusById(state, runId) === 'running') {
        acc[runId] = runs[runId];
      }
      return acc;
    }, {})
  );
export const getAllRecentlyFinishedRuns = (state) => state.recentlyFinishedRuns;
export const getRunSummaryBodyById = (state, runId) => {
  return {
    name: getRunCompanyNameById(state, runId),
    logo_url: getRunCompanyLogoUrlById(state, runId),
    description: getRunCompanyDescriptionById(state, runId),
    website: getRunCompanyDomainById(state, runId),
    founded: {
      value: getRunCompanyFoundedDateById(state, runId),
      icon: ReactDOMServer.renderToStaticMarkup(<AniversaryIcon />)
    },
    num_employees: {
      value: getRunCompanySizeById(state, runId),
      icon: ReactDOMServer.renderToStaticMarkup(<HeadcountIcon />)
    },
    location: {
      value: getRunCompanyHQById(state, runId),
      icon: ReactDOMServer.renderToStaticMarkup(<LocationIcon />)
    }
  };
};
export const getRunCompanyMetaById = (state, runId) => getRunById(state, runId).companyMetaId;
