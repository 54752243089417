import { addNewFolder, removeFolder, updateFolder } from 'reducer/folders-slice';
import { v4 as uuidv4 } from 'uuid';

export function addNewFolderAction(newFolderName) {
  return (dispatch, _getState, apiClient) => {
    const tmpFolderId = uuidv4();
    dispatch(addNewFolder({ folder: { id: tmpFolderId, name: newFolderName } }));
    apiClient.folders
      .addFolder({ tmp_folder_id: tmpFolderId, name: newFolderName })
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updateFolder({ tmpFolderId, folder: res.data }));
        } else {
          dispatch(removeFolder({ folderId: tmpFolderId }));
        }
      })
      .catch(() => {
        dispatch(removeFolder({ folderId: tmpFolderId }));
      });
  };
}
