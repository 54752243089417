import { loadFolderByIdAction } from 'actions/folders/load-folder-by-id-action';
import { increaseUserSubmissionsCountInState } from 'actions/users/add-user-data-action';
import { loadUserAction } from 'actions/users/load-user-action';
import { ACTIONS } from 'constants/actions';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { USER_EVENTS } from 'constants/userEvents';
import { getRunParametersById } from 'selectors/runs';
import { getUserHasRunsLeft } from 'selectors/user';

import {
  clearCurrentSearchAutocomplete,
  updateCurrentSearchAutcomplete
} from '../autocomplete/make-new-search-autocomplete-action';
import { sendUserEvent } from '../users/send-user-event-action';
import { loadAllRuns } from './load-all-runs-action';
import {
  monitorResearchProgressAction,
  monitorResearchStatusesAction
} from './monitor-research-progress-action';

export function updateCurrentSearchRun(suggestion, status) {
  return { type: ACTIONS.UPDATE_CURRENT_SEARCH_RUN, suggestion, status };
}

export function makeNewSearchRunAction(suggestion, folderId, reduxDispatch) {
  return (state, dispatch, apiClient) => {
    const hasRunsLeft = getUserHasRunsLeft(state);
    if (!hasRunsLeft) {
      dispatch(updateCurrentSearchRun(null, 'failure'));
      return;
    }
    dispatch(increaseUserSubmissionsCountInState());

    dispatch(clearCurrentSearchAutocomplete());
    dispatch(updateCurrentSearchRun(suggestion, 'running'));
    dispatch(sendUserEvent(USER_EVENTS.DEEP_DIVE_SUBMITTED, { company: suggestion.name }));
    apiClient.runs
      .submitRun({ ...suggestion, folder_id: folderId })
      .then((res) => {
        const status = res.ok ? 'success' : 'failure';
        const { record_id: recordId } = res.data;
        dispatch(updateCurrentSearchRun(null, status));
        dispatch(loadAllRuns());
        if (folderId && reduxDispatch) {
          reduxDispatch(loadFolderByIdAction(folderId));
        }
        if (status === 'success') {
          dispatch(monitorResearchProgressAction(recordId));
          dispatch(monitorResearchStatusesAction(recordId));
        }
      })
      .catch(() => {
        dispatch(loadUserAction());
        dispatch(updateCurrentSearchRun(null, 'failure'));
        dispatch(loadAllRuns());
      });
  };
}

export function makeNewSearchRerunAction(recordId) {
  return (state, dispatch, apiClient) => {
    const hasRunsLeft = getUserHasRunsLeft(state);
    if (!hasRunsLeft) {
      dispatch(updateCurrentSearchAutcomplete(null, null, null));
      dispatch(updateCurrentSearchRun(null, 'failure'));
    }
    dispatch(increaseUserSubmissionsCountInState());

    const parameters = getRunParametersById(state, recordId);
    dispatch(updateCurrentSearchAutcomplete(null, null, null));
    dispatch(updateCurrentSearchRun(parameters, 'running'));
    dispatch(sendUserEvent(USER_EVENTS.DEEP_DIVE_RERUN_SUBMITTED, { company: parameters.name }));
    apiClient.runs
      .submitRun(parameters)
      .then((res) => {
        const status = res.ok ? 'success' : 'failure';
        const newSearchId = res?.data?.record_id;
        dispatch(updateCurrentSearchRun(null, status));
        dispatch(loadAllRuns());
        if (newSearchId) {
          dispatch(monitorResearchProgressAction(newSearchId));
          dispatch(monitorResearchStatusesAction(newSearchId));
        }
      })
      .catch(() => {
        dispatch(loadUserAction());
        dispatch(updateCurrentSearchAutcomplete(null, null, null));
        dispatch(updateCurrentSearchRun(null, 'failure'));
        dispatch(loadAllRuns());
      });
  };
}

export function makeNewSubmitPreindexedAction(suggestion, navigate) {
  return (state, dispatch, apiClient) => {
    dispatch(
      sendUserEvent(USER_EVENTS.DEEP_DIVE_PREINDEXED_SUBMITTED, { company: suggestion.name })
    );
    apiClient.runs
      .submitRun(suggestion)
      .then((res) => {
        const { record_id: recordId } = res.data;
        navigate(`${COMPANY_INSIGHTS_PREFIX}/${recordId}/${OVERVIEW_TAB.path}`);
        dispatch(clearCurrentSearchAutocomplete());
      })
      .catch(() => {
        dispatch(loadAllRuns());
      });
  };
}
