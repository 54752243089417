import NumberRangeFilterComponent from 'components/elements/table/filters/columns/components/NumberRangeFilterComponent';
import PropTypes from 'prop-types';
import React from 'react';
import { getFinanceColumnValue } from 'utils/companyList-utils';

function FinanceColumnColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <NumberRangeFilterComponent
      columnId="revenues"
      rowMap={getFinanceColumnValue}
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

FinanceColumnColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default FinanceColumnColumnFilter;
