import React from 'react';

export default function TechPatentsIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M29.1279 1.99512H9.80786C9.45786 1.99512 9.17786 2.27512 9.17786 2.62512V31.5951H33.1579C33.5079 31.5951 33.7879 31.3151 33.7879 30.9651V6.64512C33.7879 6.39512 33.6879 6.15512 33.5079 5.97512L29.8079 2.27512C29.6279 2.09512 29.3879 1.99512 29.1379 1.99512H29.1279Z"
        fill="#8EBCFC"
      />
      <path
        d="M27.6279 3.07494H8.07788V32.6749H32.6779V8.12494C32.6779 7.92494 32.5979 7.73494 32.4579 7.59494L28.1479 3.28494C28.0079 3.14494 27.8179 3.06494 27.6179 3.06494L27.6279 3.07494Z"
        fill="white"
      />
      <path
        d="M31.548 9.0249L26.548 4.0249V8.5249C26.548 8.8049 26.768 9.0249 27.048 9.0249H31.548Z"
        fill="#9A2FC5"
      />
      <path
        d="M26.8279 33.6249H30.9279C31.2779 33.6249 31.5579 33.3449 31.5579 32.9949V9.0249H27.1879C26.8379 9.0249 26.5579 8.7449 26.5579 8.3949V4.0249H7.57788C7.22788 4.0249 6.94788 4.3049 6.94788 4.6549V32.9949C6.94788 33.3449 7.22788 33.6249 7.57788 33.6249H11.6779"
        fill="white"
      />
      <path
        d="M26.8279 33.8949C26.6779 33.8949 26.5479 33.7749 26.5479 33.6149C26.5479 33.4549 26.6679 33.3349 26.8279 33.3349H30.9279C31.1179 33.3349 31.2779 33.1749 31.2779 32.9849V9.30488H27.1779C26.6779 9.30488 26.2779 8.90488 26.2779 8.40488V4.30488H7.57786C7.38786 4.30488 7.22786 4.46488 7.22786 4.65488V32.9949C7.22786 33.1849 7.38786 33.3449 7.57786 33.3449H11.6779C11.8279 33.3449 11.9579 33.4649 11.9579 33.6249C11.9579 33.7849 11.8379 33.9049 11.6779 33.9049H7.57786C7.07786 33.9049 6.67786 33.4949 6.67786 33.0049V4.65488C6.67786 4.15488 7.08786 3.75488 7.57786 3.75488H26.5479C26.6979 3.75488 26.8279 3.87488 26.8279 4.02488V8.40488C26.8279 8.59488 26.9879 8.75488 27.1779 8.75488H31.5579C31.7079 8.75488 31.8379 8.87488 31.8379 9.02488V32.9949C31.8379 33.4949 31.4379 33.8949 30.9379 33.8949H26.8279Z"
        fill="#374EF4"
      />
      <path
        d="M31.5479 9.30488H27.0479C26.6179 9.30488 26.2679 8.95488 26.2679 8.53488V4.02488C26.2679 3.91488 26.3379 3.81488 26.4379 3.77488C26.5379 3.73488 26.6579 3.75488 26.7379 3.83488L31.7379 8.83488C31.8179 8.91488 31.8379 9.03488 31.7979 9.13488C31.7579 9.23488 31.6579 9.30488 31.5479 9.30488ZM26.8179 4.69488V8.53488C26.8179 8.65488 26.9179 8.76488 27.0379 8.76488H30.8779L26.8179 4.70488V4.69488Z"
        fill="#9A2FC5"
      />
      <path
        d="M23.6778 28.9949H14.8278C14.8278 28.9349 14.8078 28.8749 14.8078 28.8149C14.8078 28.0449 14.8078 27.2749 14.8078 26.5049C14.8378 24.3649 16.2178 22.6749 18.2978 22.2149C20.9778 21.6149 23.5778 23.5849 23.6778 26.2849C23.7078 27.1449 23.6778 28.0049 23.6878 28.8649C23.6878 28.9049 23.6878 28.9449 23.6678 28.9949H23.6778Z"
        fill="white"
      />
      <path
        d="M23.6778 29.2749H14.8278C14.6878 29.2749 14.5678 29.1649 14.5578 29.0249C14.5478 28.9249 14.5378 28.8649 14.5378 28.8149V28.1849C14.5378 27.6249 14.5378 27.0649 14.5378 26.5049C14.5678 24.2249 16.0178 22.4449 18.2478 21.9449C19.6178 21.6449 21.0578 21.9549 22.1778 22.8249C23.2678 23.6649 23.9178 24.9249 23.9678 26.2749C23.9878 26.8749 23.9878 27.4849 23.9778 28.0849V28.8649C23.9778 28.8649 23.9778 28.9449 23.9578 28.9949C23.9378 29.1249 23.8178 29.2749 23.6778 29.2749ZM15.0878 28.7249H23.4178V28.0949C23.4278 27.4949 23.4278 26.9049 23.4178 26.3049C23.3778 25.1149 22.7978 24.0149 21.8478 23.2649C20.8578 22.4949 19.5978 22.2149 18.3778 22.4849C16.4078 22.9249 15.1278 24.5049 15.0978 26.5149C15.0978 27.0749 15.0978 27.6349 15.0978 28.1849V28.7249H15.0878Z"
        fill="#374EF4"
      />
      <path
        d="M19.2478 28.4653C21.2078 28.4653 23.1778 28.4653 25.1378 28.4653C25.3978 28.4653 25.6078 28.6753 25.6078 28.9353C25.6078 31.8053 25.6078 34.6653 25.6078 37.5353C25.6078 37.7953 25.3978 38.0053 25.1378 38.0053C21.2178 38.0053 17.2878 38.0053 13.3678 38.0053C13.1078 38.0053 12.8978 37.7953 12.8978 37.5353C12.8978 34.6653 12.8978 31.8053 12.8978 28.9353C12.8978 28.6753 13.1078 28.4653 13.3678 28.4653C15.3278 28.4653 17.2878 28.4653 19.2578 28.4653H19.2478Z"
        fill="#374EF4"
      />
      <path
        d="M18.7278 34.5653C18.7278 34.7253 18.7278 34.8753 18.7278 35.0353C18.7278 35.3853 18.9278 35.5953 19.2578 35.5953C19.5778 35.5953 19.7778 35.3853 19.7778 35.0253C19.7778 34.6953 19.7778 34.3753 19.7778 34.0453C19.7778 33.9553 19.7978 33.9053 19.8878 33.8653C20.5578 33.5653 20.9178 32.9153 20.8178 32.2153C20.7178 31.4953 20.2078 30.9953 19.4778 30.8853C18.7678 30.7753 18.0678 31.1653 17.7978 31.8253C17.5178 32.5153 17.7378 33.2953 18.3178 33.7053C18.4478 33.8053 18.6478 33.8653 18.7078 33.9953C18.7778 34.1353 18.7278 34.3353 18.7278 34.5153V34.5653Z"
        fill="white"
      />
      <path
        d="M27.2279 12.4453H11.2779C11.1279 12.4453 10.9979 12.3253 10.9979 12.1753C10.9979 12.0253 11.1179 11.9053 11.2779 11.9053H27.2279C27.3779 11.9053 27.5079 12.0253 27.5079 12.1753C27.5079 12.3253 27.3879 12.4453 27.2279 12.4453Z"
        fill="#374EF4"
      />
      <path
        d="M15.2079 15.4052H11.2779C11.1279 15.4052 10.9979 15.2852 10.9979 15.1352C10.9979 14.9852 11.1179 14.8652 11.2779 14.8652H15.2079C15.3579 14.8652 15.4879 14.9852 15.4879 15.1352C15.4879 15.2852 15.3679 15.4052 15.2079 15.4052Z"
        fill="#374EF4"
      />
      <path
        d="M27.2279 15.4052H17.0379C16.8879 15.4052 16.7579 15.2852 16.7579 15.1352C16.7579 14.9852 16.8779 14.8652 17.0379 14.8652H27.2279C27.3779 14.8652 27.5079 14.9852 27.5079 15.1352C27.5079 15.2852 27.3879 15.4052 27.2279 15.4052Z"
        fill="#374EF4"
      />
      <path
        d="M27.3279 17.5952H11.1779C10.9017 17.5952 10.6779 17.8191 10.6779 18.0952V19.5852C10.6779 19.8614 10.9017 20.0852 11.1779 20.0852H27.3279C27.604 20.0852 27.8279 19.8614 27.8279 19.5852V18.0952C27.8279 17.8191 27.604 17.5952 27.3279 17.5952Z"
        fill="#9A2FC5"
      />
    </svg>
  );
}
