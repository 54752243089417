import { createSelector } from 'reselect';

export const getNotificationsSlice = (state) => state.notifications;

export const getAllNotifications = (state) => getNotificationsSlice(state)?.notifications;

export const getListNewNotifications = createSelector(
  getAllNotifications,
  (_state, listId) => listId,
  (allNotifications, listId) => {
    if (!allNotifications) {
      return [];
    }
    return Object.values(allNotifications).reduce((acc, notification) => {
      if (notification.company_list_id === listId && !notification.dismissed) {
        acc[notification.id] = notification;
      }
      return acc;
    }, {});
  }
);
