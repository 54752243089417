import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getClustersFailedById,
  getClustersProgressById,
  getClustersReadyById,
  getDiscoveryClustersByClustersId
} from 'selectors/discovery-clusters';
import { getClustersIdByListId } from 'selectors/explore';

import NoClustersComponent from './NoClustersComponent';

export default function NoClustersComponentConnector({ listId, ...args }) {
  const { state } = useAppState();

  const clustersId = useSelector((reduxState) => getClustersIdByListId(reduxState, listId));
  const isLoadedToState =
    clustersId && Boolean(getDiscoveryClustersByClustersId(state, clustersId));
  const isFail = isLoadedToState && getClustersFailedById(state, clustersId);
  const isDisabled = isLoadedToState && !getClustersReadyById(state, clustersId);
  const progress = isLoadedToState && getClustersProgressById(state, clustersId);

  const hasClusters = clustersId && isLoadedToState && !(isDisabled || isFail);

  return hasClusters ? null : (
    <NoClustersComponent
      isDisabled={isDisabled}
      isFailed={isFail}
      clustersId={clustersId}
      isLoadedToState={isLoadedToState}
      progress={progress}
      {...args}></NoClustersComponent>
  );
}
NoClustersComponentConnector.propTypes = {
  listId: PropTypes.number
};
