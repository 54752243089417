import { translateOrganizationForState } from 'actions/runs/load-all-runs-action';
import { ACTIONS } from 'constants/actions';
import { dispatch } from 'hooks/AppStateProvider';
import { updateClustersIdInExplore } from 'reducer/explore-slice';

import {
  monitorClustersProgressAction,
  monitorClustersStatusesAction
} from './monitor-clusters-progress-action';

function addDiscoveryClusters(clusters, clustersId) {
  return { type: ACTIONS.ADD_DISCOVERY_CLUSTERS, clusters, clustersId };
}

export function translateClustersRecordForState(clustersRecord) {
  const translated = {
    id: clustersRecord.id,
    recordId: clustersRecord.id,
    data: clustersRecord?.clusters_data?.data,
    name: clustersRecord.name,
    statuses: clustersRecord.statuses,
    timeSubmitted: clustersRecord.time_submitted,
    parameters: clustersRecord.parameters,
    progress: clustersRecord.progress,
    ready: clustersRecord.ready,
    owner: clustersRecord?.user?.email,
    ownerId: clustersRecord?.user?.id,
    companyListId: clustersRecord.company_list_id,
    ...translateOrganizationForState(clustersRecord?.user?.organization)
  };
  return translated;
}

export function loadClustersForDiscoveryAction(taggingId, queryParams, listId) {
  return (reduxDispatch, _getState, apiClient) => {
    apiClient.tagging.fetchClusters(taggingId, queryParams).then((res) => {
      if (res.ok && res.data) {
        const element = {
          ...translateClustersRecordForState(res.data)
        };

        try {
          const { id, statuses } = element;
          const statusesSet = new Set(Object.values(statuses));
          if (
            statusesSet.has('RUNNING') ||
            statusesSet.has('RUNNABLE') ||
            statusesSet.has('STARTING')
          ) {
            reduxDispatch(monitorClustersProgressAction(id));
            dispatch(monitorClustersStatusesAction(id));
          }
        } catch {
          /* eslint-disable no-console */
          console.log('error');
        }
        reduxDispatch(updateClustersIdInExplore({ clustersId: taggingId, listId }));
        dispatch(addDiscoveryClusters(element, taggingId));
      }
    });
  };
}
