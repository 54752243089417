import { UNIVERSAL_PPTX_CARD_TEMPLATE } from 'constants/components';
import ReactDOMServer from 'react-dom/server';

import { insightTypeToIcon, insightTypeToTitle } from './insights-utils';
import { groupToTitle } from './overviewGroup-utils';

const CUSTOM_ANALYTICS_INSIGHT_ID = 'CustomAnalyticsInsight';

export function createOverviewData({ groupType, title, points }) {
  const data = {};
  data.template_id = 'overview_section';
  data.group_id = groupType;
  data.title = title;
  if (points) {
    data.insights = points.map((insight) => {
      const Icon = insightTypeToIcon(insight.insight_id);
      return {
        insight_id: insight.insight_id,
        insight_title: insightTypeToTitle(insight.insight_id),
        icon: ReactDOMServer.renderToStaticMarkup(<Icon />)
      };
    });
  }
  return data;
}

function cardInputToCardBody(card_input) {
  const { insightId, groupId } = card_input;
  const data = { body: {}, insight_id: insightId };
  data.body.template_id = UNIVERSAL_PPTX_CARD_TEMPLATE;
  data.body.title = `${groupToTitle(groupId)}: ${insightTypeToTitle(insightId)}`;
  const Icon = insightTypeToIcon(insightId);
  data.body.icon = ReactDOMServer.renderToStaticMarkup(<Icon />);
  return data;
}

function caCardInputToCardBody(ca_card_input) {
  const { data: card } = ca_card_input;
  const data = { body: {}, insight_id: CUSTOM_ANALYTICS_INSIGHT_ID };
  data.body.template_id = UNIVERSAL_PPTX_CARD_TEMPLATE;
  data.body.title = card?.name;
  const Icon = insightTypeToIcon(null);
  data.body.icon = ReactDOMServer.renderToStaticMarkup(<Icon />);
  data.record_id = card?.custom_analytics_record_id;
  return data;
}

/* eslint-disable no-await-in-loop */
export function createCardDatas(card_inputs) {
  return card_inputs.map((card_input) => cardInputToCardBody(card_input));
}

export function createCaCardDatas(ca_card_inputs) {
  return (ca_card_inputs || []).map((ca_card_input) => caCardInputToCardBody(ca_card_input));
}
