import PropTypes from 'prop-types';
import React from 'react';

export default function CloseIcon({ color, ...args }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.1"
      {...args}>
      <path d="M6 18 17.94 6M18 18 6.06 6" />
    </svg>
  );
}

CloseIcon.propTypes = {
  color: PropTypes.string
};

CloseIcon.defaultProps = {
  color: '#0F2345'
};
