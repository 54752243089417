import { PUBLIC_ROUTES_PATHS } from 'constants/app-routes';
import { OnErrorProvider } from 'hooks/OnErrorProvider';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginRedirectorScreen from 'screens/LoginRedirector';
import PublicShareScreen from 'screens/PublicShareScreen';

function PublicRouter() {
  return (
    <Router>
      <OnErrorProvider>
        <Routes>
          <Route path={PUBLIC_ROUTES_PATHS.PUBLIC_SHARE_PATH} element={<PublicShareScreen />} />
          <Route path={PUBLIC_ROUTES_PATHS.PUBLIC_SHARE_TAB_PATH} element={<PublicShareScreen />} />
          <Route path="*" element={<LoginRedirectorScreen />} />
        </Routes>
      </OnErrorProvider>
    </Router>
  );
}

export default PublicRouter;
