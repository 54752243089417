import { Box, Typography, useMediaQuery } from '@mui/material';
import { MODES } from 'constants/components';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { appTheme } from 'theme';

export default function RechartsBox({ title, height, mode, widthTracker, children, responsive }) {
  const parentRef = useRef();
  const windowSizeChanged = useWindowSize();
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const [width, setWidth] = useState(100);

  useEffect(() => {
    if (!parentRef.current) return;

    const parentComputedStyle = getComputedStyle(parentRef.current);
    const paddingX =
      parseFloat(parentComputedStyle.paddingLeft) + parseFloat(parentComputedStyle.paddingRight);

    const borderX =
      parseFloat(parentComputedStyle.borderLeftWidth) +
      parseFloat(parentComputedStyle.borderRightWidth);

    const parentWidth = parentRef.current.offsetWidth - paddingX - borderX;

    setWidth(parentWidth);
    widthTracker?.(parentWidth);
  }, [parentRef.current?.offsetWidth, windowSizeChanged, widthTracker]);
  return (
    <Box
      ref={parentRef}
      sx={{
        width: '100%',
        height: mode === 'overview' ? 400 : height,
        padding: mode === 'powerpoint' ? null : '4px',
        paddingTop: mode === 'powerpoint' ? null : '10px',
        textAlign: 'center'
      }}>
      {mode === 'overview' && (
        <Typography
          variant="paragraphSemiBold"
          color="secondary.secondary2"
          sx={{ marginBottom: '24px' }}>
          {title}
        </Typography>
      )}
      {responsive ? (
        <ResponsiveContainer
          width={mode === 'overview' || !mediumViewport || mode === 'powerpoint' ? width : '100%'}
          height="100%">
          {children}
        </ResponsiveContainer>
      ) : (
        children
      )}
    </Box>
  );
}

RechartsBox.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(MODES).isRequired,
  widthTracker: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.any,
  responsive: PropTypes.bool
};

RechartsBox.defaultProps = {
  responsive: true,
  height: 250
};
