import { updateTeams } from 'reducer/teams-slice';

export function loadTeamsAction() {
  return (dispatch, _getState, apiClient) => {
    apiClient.teams.fetchTeams().then((res) => {
      if (res.ok && res.data) {
        dispatch(updateTeams({ teams: res.data.teams }));
      }
    });
  };
}
