import { useAuth0 } from '@auth0/auth0-react';
import LoaderComponent from 'components/LoaderComponent';
import React, { useEffect } from 'react';

const PATH_BEFORE_LOGIN_KEY = 'path_before_login';

function LoginRedirectorScreen() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    window.localStorage.setItem(PATH_BEFORE_LOGIN_KEY, window.location.pathname);
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <LoaderComponent />;
}

export default LoginRedirectorScreen;
