export const getOpinions = (state) => state.opinions;
export const getOpinionBySearchIdAndInsightId = (state, searchId, insightId) =>
  state?.opinions?.[searchId]?.[insightId] || {};
export const getOpinionsList = (state) => {
  const opinions = getOpinions(state);
  if (Object.keys(opinions).length === 0) return [];
  const allSearches = Object.values(opinions);
  const allOpinions = [];
  allSearches.forEach((searchInsights) => {
    allOpinions.push(...Object.values(searchInsights));
  });
  return allOpinions;
};
