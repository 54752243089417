import { ACTIONS } from 'constants/actions';
import { makeBase64Download } from 'utils/download-utils';

function updatePptxStateMetaData(data) {
  return { type: ACTIONS.MAKE_PPTX, data };
}

function updatePptxInProgress(pptxInProgress) {
  return { type: ACTIONS.UPDATE_PPTX_IN_PROGRESS, pptxInProgress };
}

export function monitorPowerpointAction(runId, data, name) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.runs.toPptxAll(runId, { ...data, monitoring: true }).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          if (res.data.pptx) {
            clearInterval(tickerId);
            const fileName = `${name.replaceAll(' ', '')}.pptx`;
            makeBase64Download(res.data.pptx, fileName);
            dispatch(updatePptxInProgress(false));
          }
        }
      });
    };
    tickerId = setInterval(ticker, 20000);
    ticker();
  };
}

export function makePptxAll(runId, data, name) {
  return (_state, dispatch, apiClient) => {
    dispatch(updatePptxInProgress(true));
    apiClient.runs.toPptxAll(runId, data).then((res) => {
      if (res.ok && res.data) {
        if (res.data.waiting) {
          dispatch(monitorPowerpointAction(runId, data, name));
        } else {
          const fileName = `${name.replaceAll(' ', '')}.pptx`;
          makeBase64Download(res.data.pptx, fileName);
          dispatch(updatePptxInProgress(false));
        }
      }
    });
    dispatch(updatePptxStateMetaData(data));
  };
}
