import { Stack } from '@mui/material';
import ToastComponent from 'components/ToastComponent';
import PropTypes from 'prop-types';
import React from 'react';

export default function ToastsComponent({ toastsList, setToastsList }) {
  const handleRemoveToast = (toastId) => {
    setToastsList((toasts) => toasts.filter((toast) => toast.id !== toastId));
  };

  if (toastsList.length === 0) return null;

  return (
    <Stack direction="column" gap="16px">
      {toastsList.map((toast) => (
        <ToastComponent
          key={toast.id}
          toast={toast.message}
          type={toast.type}
          anchorOrigin={toast.anchorOrigin}
          onRemove={() => handleRemoveToast(toast.id)}
        />
      ))}
    </Stack>
  );
}

ToastsComponent.propTypes = {
  toastsList: PropTypes.array,
  setToastsList: PropTypes.func
};
