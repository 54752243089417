import { ACTIONS } from 'constants/actions';

function addUserOrganizationUsersData(users) {
  return { type: ACTIONS.ADD_USER_ORGANIZATION_USERS_DATA, users };
}

export function loadUserOrganizationUsersAction() {
  return (_state, dispatch, apiClient) => {
    apiClient.users.getUserOrganizationUsers().then((res) => {
      if (res.ok && res.data) {
        dispatch(addUserOrganizationUsersData(res.data.users));
      }
    });
  };
}
