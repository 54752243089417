import { useAppUtils } from 'AppUtilsProvider';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { TILE_TYPE } from 'constants/tiles';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import {
  getCurrentlyRunningDiscoveriesByListId,
  getLastDiscoveryIdByListId
} from 'selectors/discoveries';
import {
  getCurrentDiscoveryClusterLabel,
  getDiscoveryClustersByClustersId
} from 'selectors/discovery-clusters';
import {
  getAreCompaniesNotFound,
  getClustersIdByListId,
  getCompanyListHistoryByListId,
  getCompanyListNameByListId,
  getCompanyListOwner,
  getExploreMismatchCompanies,
  getListCustomColumnsByListId,
  getRunningDiscoveriesToHide
} from 'selectors/explore';
import {
  getExploreCompaniesLoadingByListId,
  getExploreTableSelectedCompanies,
  getExploreUiColumnsByListId,
  getExploresTableUiListHighlightColumns
} from 'selectors/explore-table-ui';
import { getFirstParentFolderIdOfResource } from 'selectors/folders';
import { getUserFeatureFlags, getUserId, getUserIsBasic, getUserIsDev } from 'selectors/user';
import { createRunningRow } from 'utils/companyList-utils';

import DiscoveryRunBaseScreen from './DiscoveryRunBaseScreen';

export default function ExploreListBaseScreenConnector() {
  const { listId, viewId } = useParams();
  const companyListId = parseInt(listId, 10);
  const discoveryId = useSelector((state) => getLastDiscoveryIdByListId(state, companyListId));
  const name = useSelector((state) => getCompanyListNameByListId(state, companyListId));
  const folderId = useSelector((state) =>
    getFirstParentFolderIdOfResource(state, TILE_TYPE.DISCOVERY, companyListId)
  );
  const listOwnerId = useSelector((state) => getCompanyListOwner(state, companyListId))?.id;
  const clustersId = useSelector((state) => getClustersIdByListId(state, companyListId));
  const rows = useSelector((state) =>
    getCompanyMetasMergedRowsByCompanyListId(state, companyListId)
  );
  const companiesLoading = useSelector((state) =>
    getExploreCompaniesLoadingByListId(state, companyListId)
  );

  const areCompaniesNotFound = useSelector((state) => getAreCompaniesNotFound(state, listId));
  const selectedCompanies = useSelector((state) => getExploreTableSelectedCompanies(state, listId));

  const mismatchedComapanyMetas = useSelector((state) =>
    getExploreMismatchCompanies(state, listId)
  );

  const currentClusterLabel = useOldStateSelector((state) =>
    getCurrentDiscoveryClusterLabel(state)
  );
  const clustersElement = useOldStateSelector((state) =>
    getDiscoveryClustersByClustersId(state, clustersId)
  );
  const isDev = useOldStateSelector((state) => getUserIsDev(state));
  const isBasicUser = useOldStateSelector((state) => getUserIsBasic(state));
  const userId = useOldStateSelector((state) => getUserId(state));
  const isSharedWithMe = !folderId && listOwnerId !== userId;
  const featureFlags = useOldStateSelector(getUserFeatureFlags);
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const { addToast } = useAppUtils();
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const currentlyRunningDiscoveries = useSelector((state) =>
    getCurrentlyRunningDiscoveriesByListId(state, companyListId)
  );
  const listHistory = useSelector((state) => getCompanyListHistoryByListId(state, companyListId));
  const runningDiscoveriesToHide = useSelector((state) => getRunningDiscoveriesToHide(state));
  const highlightColumns = useSelector((state) =>
    getExploresTableUiListHighlightColumns(state, listId)
  );
  const runningRows = useMemo(() => {
    if (!currentlyRunningDiscoveries || !Object.values(currentlyRunningDiscoveries).length) {
      return [];
    }
    const runningDiscoveries = Object.values(currentlyRunningDiscoveries).filter(
      (discovery) => !runningDiscoveriesToHide?.[discovery.id]
    );
    return runningDiscoveries.reduce((prev, record) => {
      return listHistory?.[record.parameters.iteration]
        ? [
            ...prev,
            createRunningRow(prev.length, {
              progress: record.progress,
              parameters: {
                ...listHistory[record.parameters.iteration],
                time_submitted: null
              },
              timeSubmitted: record.timeSubmitted,
              companyListId: record.companyListId,
              recordId: record.recordId,
              discoveryId: record.id
            })
          ]
        : prev;
    }, []);
  }, [currentlyRunningDiscoveries, listHistory, runningDiscoveriesToHide]);
  const highlightedColumns = useMemo(() => {
    if (!highlightColumns) return [];

    return Object.keys(highlightColumns).filter((columnId) => Boolean(highlightColumns[columnId]));
  }, [highlightColumns]);
  return (
    <DiscoveryRunBaseScreen
      discoveryId={discoveryId}
      viewId={viewId}
      name={name}
      listId={companyListId}
      rows={rows}
      companiesLoading={companiesLoading}
      areCompaniesNotFound={areCompaniesNotFound}
      addToast={addToast}
      haveSelectedCompanies={Boolean(selectedCompanies?.length)}
      mismatchedComapanyMetas={mismatchedComapanyMetas}
      currentClusterLabel={currentClusterLabel}
      clustersElement={clustersElement}
      isDev={isDev}
      isBasicUser={isBasicUser}
      clustersId={clustersId}
      customColumns={customColumns}
      columnsSettings={columnsSettings}
      runningRows={runningRows}
      featureFlags={featureFlags}
      folderId={isSharedWithMe ? SHARED_WITH_ME_FOLDER_ID : folderId}
      highlightedColumns={highlightedColumns}
    />
  );
}
