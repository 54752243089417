import { getAPIClient } from 'api/client-api';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import React from 'react';

const PusherContext = React.createContext();

const pusherClient = {};

function customHandler(token) {
  return function ({ socketId, channelName }, cb) {
    getAPIClient()
      .pusherAuth.auth(socketId, channelName, token)
      .then((res) => {
        if (res.ok) {
          cb(false, { auth: res.data.auth });
        } else {
          cb(res.error, null);
        }
      })
      .catch((error) => {
        cb(error, null);
      });
  };
}

export function initPusherClient(token) {
  pusherClient.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    channelAuthorization: {
      customHandler: customHandler(token)
    },
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
  });
}

export function getPusherClient() {
  return pusherClient.pusher;
}

function PusherProvider({ children }) {
  const pusher = getPusherClient();
  return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
}

// Create custom hook for using the Pusher Context
function usePusher() {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  const { pusher } = context;
  return pusher;
}

PusherProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { PusherProvider, usePusher };
