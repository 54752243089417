import { Stack } from '@mui/material';
import DeepDiveDynamicCardComponent from 'components/deep-dive/DeepDiveDynamicCardComponent';
import PropTypes from 'prop-types';
import React from 'react';

function DeepDiveCustomCardsTabComponent({ deepDiveId, cards }) {
  return (
    <Stack direction="column" gap="24px">
      {cards.map((card) => (
        <DeepDiveDynamicCardComponent key={card.id} deepDiveId={deepDiveId} cardId={card.id} />
      ))}
    </Stack>
  );
}

DeepDiveCustomCardsTabComponent.propTypes = {
  deepDiveId: PropTypes.number,
  cards: PropTypes.array
};

export default DeepDiveCustomCardsTabComponent;
