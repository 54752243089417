import React from 'react';

export default function TrustIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M13.7224 6.51008C13.6724 7.52007 13.4924 8.50008 13.0324 9.42008C12.6624 10.1701 12.1424 10.7801 11.4924 11.2601C11.3224 11.3901 11.1424 11.5101 10.9424 11.6201C10.5424 11.8501 10.0924 12.0301 9.67237 12.2301C9.64237 12.2501 9.58237 12.2501 9.55237 12.2401C8.72237 11.9301 7.95237 11.5201 7.29237 10.9201C6.46237 10.1601 5.95237 9.22008 5.69237 8.12008C5.48237 7.22008 5.43237 6.31008 5.43237 5.39008C5.43237 4.89008 5.44237 4.38008 5.45237 3.88008C5.45237 3.85008 5.45237 3.81008 5.45237 3.76008C5.70237 3.74008 5.94237 3.71008 6.19237 3.69008C6.26237 3.68008 6.33237 3.67008 6.39237 3.66008C7.43237 3.51008 8.37237 3.12008 9.20237 2.48008C9.30237 2.41008 9.39237 2.33008 9.49237 2.25008C9.56237 2.19008 9.60237 2.20008 9.66237 2.25008C10.5124 2.98008 11.4924 3.44008 12.5924 3.63008C12.9524 3.69008 13.3224 3.72008 13.7124 3.77008C13.7124 3.80008 13.7124 3.84008 13.7124 3.88008C13.7124 4.75008 13.7324 5.63008 13.6924 6.50008L13.7224 6.51008Z"
        fill="#5F71F6"
      />
      <path
        d="M9.1124 8.40006C8.9824 8.40006 8.8524 8.35006 8.7524 8.25006L7.6224 7.16006C7.4124 6.96006 7.4124 6.63006 7.6124 6.43006C7.8124 6.22006 8.1324 6.22006 8.3424 6.42006L9.0924 7.15006L11.2524 4.88006C11.4524 4.67006 11.7724 4.67006 11.9824 4.86006C12.1924 5.06006 12.2024 5.38006 12.0024 5.59006L9.4824 8.24006C9.3924 8.34006 9.2524 8.40006 9.1224 8.40006H9.1124Z"
        fill="white"
      />
      <path
        d="M2.64245 12.9201L2.93245 10.3001C3.52245 10.3701 4.12245 10.4501 4.71245 10.5001C5.18245 10.5401 5.63245 10.4201 6.05245 10.2101C6.32245 10.0701 6.60245 9.94012 6.88245 9.80012C7.32245 9.58012 7.81245 9.56012 8.29245 9.53012C8.96245 9.49012 9.63245 9.45012 10.2925 9.40012C10.6025 9.38012 10.8225 9.53012 10.8725 9.81012C10.8925 9.95012 10.8525 10.0701 10.7725 10.1701C10.6225 10.3701 10.4325 10.5301 10.1825 10.6001C9.61245 10.7701 9.05245 10.9401 8.48245 11.1001C8.35245 11.1401 8.36245 11.1701 8.43245 11.2601C8.54245 11.3801 8.64245 11.5101 8.74245 11.6501C8.88245 11.8401 9.07245 11.9101 9.30245 11.8501C9.78245 11.7201 10.2625 11.5601 10.7525 11.4501C11.3325 11.3201 11.8025 11.0001 12.2725 10.6601C12.9025 10.2001 13.5425 9.75012 14.1825 9.30012C14.4225 9.13012 14.6725 9.17012 14.8625 9.39012C15.0625 9.62012 15.0625 9.88012 14.8625 10.0801C14.0825 10.8601 13.3125 11.6301 12.5225 12.4001C12.2425 12.6801 11.8725 12.8201 11.5025 12.9401C10.7325 13.1901 9.97245 13.4401 9.20245 13.6801C8.90245 13.7801 8.59245 13.8101 8.27245 13.7801C7.99245 13.7501 7.72245 13.7001 7.44245 13.6901C6.86245 13.6601 6.32245 13.5001 5.79245 13.2601C5.44245 13.1001 5.09245 12.9501 4.70245 12.9401C4.27245 12.9201 3.84245 12.9201 3.41245 12.9201C3.15245 12.9201 2.89245 12.9201 2.64245 12.9201Z"
        fill="#8EBCFC"
      />
      <path
        d="M3.52245 9.71012H2.48245C2.36095 9.71012 2.26245 9.80862 2.26245 9.93012V13.4801C2.26245 13.6016 2.36095 13.7001 2.48245 13.7001H3.52245C3.64395 13.7001 3.74245 13.6016 3.74245 13.4801V9.93012C3.74245 9.80862 3.64395 9.71012 3.52245 9.71012Z"
        fill="#8EBCFC"
      />
    </svg>
  );
}
