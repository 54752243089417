export const PROMPT_PREFIX = Object.freeze({
  FOR_EACH_COMPANY: { prefix: 'for each company', title: 'For each company' },
  USING_COMPANY: { prefix: 'using company', title: 'For Specific Company' },
  SEARCH_THE_WEB: { prefix: 'search the web', title: 'Search web' },
  USING_COLUMN: { prefix: 'using column', title: 'Use column' },
  USING_DOMAIN_KNOWLEDGE: { prefix: 'using domain knowledge', title: 'Use Domain' },
  WRITE_A_CODE: { prefix: 'write code to', title: 'Create Table function' },
  DISCOVER: { prefix: 'discover', title: 'Discover Companies' },
  USING_CARD: { prefix: 'using card', title: 'Use card' },
  USING_COLUMNS: { prefix: 'using columns', title: 'Use columns' }
});

export const PROMPT_FIELD_TO_PREFIX = Object.freeze({
  forEachCompany: PROMPT_PREFIX.FOR_EACH_COMPANY,
  searchTheWeb: PROMPT_PREFIX.SEARCH_THE_WEB,
  useColumn: PROMPT_PREFIX.USING_COLUMN,
  useDomainKnowledge: PROMPT_PREFIX.USING_DOMAIN_KNOWLEDGE,
  discover: PROMPT_PREFIX.DISCOVER,
  useCard: PROMPT_PREFIX.USING_CARD,
  useColumns: PROMPT_PREFIX.USING_COLUMNS
});

export const CHAT_GPT_MODEL_VERSION = '4';

export const CUSTOM_ANALYTICS_PLACEMENT = Object.freeze({
  EXPLORE: 'explore',
  DEEP_DIVE: 'deep-dive'
});
