export function makeDownload(fileName, href) {
  const fakeLink = document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;
  fakeLink.href = href;
  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
}

export function makeBase64Download(data, fileName) {
  return makeDownload(fileName, `data:application/zip;base64,${data}`);
}

export function makeBlobDownload(blob, fileName) {
  return makeDownload(fileName, URL.createObjectURL(blob));
}

export function openChooseFile(onFileChosen, uploadMultiple = false) {
  const fakeLink = document.createElement('input');
  fakeLink.style = 'display:none;';
  fakeLink.type = 'file';
  fakeLink.multiple = uploadMultiple;
  fakeLink.onchange = (event) => {
    if (uploadMultiple) {
      onFileChosen(event.target.files);
    } else {
      const [file] = event.target.files;
      if (file) {
        onFileChosen(file);
      }
    }
    document.body.removeChild(fakeLink);
  };
  document.body.appendChild(fakeLink);
  fakeLink.click();
}
