import MultipleSelectionFilerComponent from 'components/elements/table/filters/columns/components/MultipleSelectionFilerComponent';
import PropTypes from 'prop-types';

function RegionColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <MultipleSelectionFilerComponent
      columnId="region"
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

RegionColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default RegionColumnFilter;
