import { ACTIONS } from 'constants/actions';

function updateOpinionsState(opinions) {
  return { type: ACTIONS.ADD_OPINIONS, opinions };
}

export function loadAllSearchOpinions(runId) {
  return (_state, dispatch, apiClient) => {
    apiClient.opinions.fetchAllSearchOpinions(runId).then((res) => {
      const opinions = res.ok && res.data?.opinions ? res.data.opinions : null;
      if (opinions) {
        dispatch(updateOpinionsState(opinions));
      }
    });
  };
}
