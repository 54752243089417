import { translateRecordForState, updateRunsData } from 'actions/runs/load-all-runs-action';
import { updateCardData } from 'actions/runs/load-card-data-action';
import { handleOverviewData } from 'actions/runs/load-overview-data-action';
import { handleSummaryData } from 'actions/runs/load-run-summary-action';
import { initUnauthorizedAPIClient } from 'api/client-api';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { updateLinkIdData, updateUiSettingsLoadingPublicLinkData } from 'reducer/puclic-shares';

export function loadPublicShareLinkDataAction(publicLinkId) {
  return (dispatch, _getState, apiClient) => {
    initUnauthorizedAPIClient();
    dispatch(
      updateUiSettingsLoadingPublicLinkData({
        publicShareLinkId: publicLinkId,
        isLoadingPublicLinkData: true
      })
    );
    apiClient.unauthorizedRoutes
      .getPublicLinkData(publicLinkId)
      .then((res) => {
        if (res.ok) {
          const {
            id: linkId,
            record_id: runId,
            record_response: recordResponse,
            summary_response: summaryResponse,
            overview_response: overviewResponse,
            cards_response: cardsResponse
          } = res.data;
          dispatch(updateLinkIdData({ linkId, runId }));

          const record = translateRecordForState(recordResponse);
          oldDispatch(handleSummaryData(record.id, summaryResponse));
          oldDispatch(updateRunsData({ [record.id]: record }, false));
          dispatch(handleOverviewData(record.id, overviewResponse, false));
          cardsResponse.forEach((card) => {
            oldDispatch(updateCardData(record.id, card.insight.insight_id, card));
          });
        }
      })
      .finally(() => {
        dispatch(
          updateUiSettingsLoadingPublicLinkData({
            publicShareLinkId: publicLinkId,
            isLoadingPublicLinkData: false
          })
        );
      });
  };
}
