import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  threads: {},
  deepDiveThreads: {},
  clipboard: {}
};

export const getThreadKey = (listId, companyMetaId) => {
  const id = listId || companyMetaId;
  const prefix = listId ? 'list' : 'company';
  return `${prefix}_${id}`;
};
export const customAnalyticsSlice = createSlice({
  name: 'custom-analytics',
  initialState: initialSliceState,
  reducers: {
    updateInitializeEnded: (sliceState, { payload }) => {
      const { listId } = payload;
      const id = getThreadKey(listId);
      if (!sliceState.threads[id]) {
        sliceState.threads[id] = {};
      }

      sliceState.threads[id].isReady = true;
    },
    addPostQueryToThread: (sliceState, { payload }) => {
      const { threadKey, postId, query, body, discoveryId } = payload;
      if (!sliceState.threads[threadKey]) {
        sliceState.threads[threadKey] = {};
      }

      if (!sliceState.threads[threadKey].posts) {
        sliceState.threads[threadKey].posts = {};
      }

      sliceState.threads[threadKey].posts[postId] = { postId, query, body, discoveryId };
    },
    addPostAnswerToThread: (sliceState, { payload }) => {
      const { threadKey, threadId, postId, message, responseType, messages, image, plan } = payload;

      sliceState.threads[threadKey].threadId = threadId;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        sliceState.threads[threadKey].posts[postId] = {
          ...sliceState.threads[threadKey].posts[postId],
          message,
          responseType,
          messages,
          image,
          plan
        };
      }
    },
    addPostStatusToThread: (sliceState, { payload }) => {
      const { threadKey, postId, status, statusMessage } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (status) {
          const newVal = parseFloat(status.replace('%', ''));
          const oldValStr = sliceState.threads[threadKey].posts[postId].status;
          const oldVal = oldValStr ? parseFloat(oldValStr.replace('%', '')) : 0;
          if (newVal > oldVal) {
            sliceState.threads[threadKey].posts[postId].status = status;
          }
        }
        if (statusMessage) {
          sliceState.threads[threadKey].posts[postId].statusMessage = statusMessage;
        }
      }
    },
    addPostRecordIdToThread: (sliceState, { payload }) => {
      const { threadKey, postId, customAnalyticsRecordId } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        sliceState.threads[threadKey].posts[postId].recordId = customAnalyticsRecordId;
      }
    },
    addDiscoveryPostSuggestionsToThread: (sliceState, { payload }) => {
      const { threadKey, postId, suggestions } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (suggestions) {
          sliceState.threads[threadKey].posts[postId].suggestions = suggestions;
        }
      }
    },
    updatePostMessagesInThread: (sliceState, { payload }) => {
      const { threadKey, postId, messages } = payload;

      if (sliceState.threads[threadKey]?.posts?.[postId]) {
        if (sliceState.threads[threadKey]?.posts?.[postId]?.messages) {
          const companyListIdToIndex = sliceState.threads[threadKey].posts[postId].messages.reduce(
            (prev, message, index) => {
              prev[message.company_meta_id] = index;
              return prev;
            },
            {}
          );
          messages.forEach((item) => {
            const index = companyListIdToIndex[item.company_meta_id];
            sliceState.threads[threadKey].posts[postId].messages[index].message = item.message;
            sliceState.threads[threadKey].posts[postId].messages[index].loading = false;
          });
        } else {
          sliceState.threads[threadKey].posts[postId].messages = messages;
        }
      }
    },
    updateThreadProccessingMode: (sliceState, { payload }) => {
      const { threadKey, isProccessing } = payload;

      if (sliceState.threads[threadKey]) {
        sliceState.threads[threadKey].isProccessing = isProccessing;
      }
    },
    updatePromptClipboard: (sliceState, { payload }) => {
      const { threadKey, promptConfig } = payload;
      sliceState.clipboard[threadKey] = promptConfig;
    },
    updateCustomAnalyticsOpen: (sliceState, { payload }) => {
      const { customAnalyticsOpen } = payload;
      sliceState.customAnalyticsOpen = customAnalyticsOpen;
    },
    clearPromptClipboard: (sliceState, { payload }) => {
      const { threadKey } = payload;

      delete sliceState.clipboard[threadKey];
    }
  }
});

export const {
  addPostQueryToThread,
  addPostAnswerToThread,
  addPostStatusToThread,
  updateInitializeEnded,
  updatePostMessagesInThread,
  updateThreadProccessingMode,
  updatePromptClipboard,
  clearPromptClipboard,
  updateCustomAnalyticsOpen,
  addPostRecordIdToThread,
  addDiscoveryPostSuggestionsToThread
} = customAnalyticsSlice.actions;

export default customAnalyticsSlice.reducer;
