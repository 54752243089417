import React from 'react';

export default function RecognitionIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.0929 6.84413C30.0929 6.60413 29.8929 6.40413 29.6529 6.40413H12.4329C12.4429 6.17413 12.6329 5.98413 12.8729 5.98413H30.5829C30.8229 5.98413 31.0229 6.18413 31.0229 6.42413V7.33413C31.0229 7.57413 30.8229 7.77413 30.5829 7.77413H30.0929V6.84413Z"
        fill="#8EBCFC"
      />
      <path
        d="M9.6229 14.0642C8.7329 14.6642 8.1429 15.6542 8.1429 16.7742H8.1529C8.1529 18.5942 9.6829 20.0742 11.5729 20.0742H12.6129V20.4942H10.6429C8.7529 20.4942 7.2229 19.0142 7.2229 17.1942C7.2229 15.7242 8.2329 14.4842 9.6229 14.0642Z"
        fill="#8EBCFC"
      />
      <path d="M29.0029 13.894V13.904H28.9929L29.0029 13.894Z" fill="#8EBCFC" />
      <path
        d="M29.0029 13.904H29.9229C29.9229 15.984 29.9129 18.104 29.8829 20.074H31.8329C32.1629 20.074 32.4629 20.014 32.7629 19.924C32.2329 20.284 31.6029 20.494 30.9129 20.494H28.9629C29.0028 18.3972 29.0029 16.1209 29.0029 13.904Z"
        fill="#8EBCFC"
      />
      <path
        d="M12.6128 11.6141H10.6428C9.93282 11.6141 9.26282 11.7441 8.64282 11.9641C9.50282 11.4841 10.4928 11.1941 11.5628 11.1941H12.6128V11.6141Z"
        fill="#8EBCFC"
      />
      <path
        d="M28.9129 22.9242C28.9129 24.6242 28.0629 26.1642 26.9829 27.4741C26.3129 28.2241 25.5729 28.7941 24.7529 29.2341C25.9429 28.7742 26.9929 28.0641 27.9129 27.0441C28.9329 25.8041 29.7429 24.3542 29.8229 22.7542H28.9129V22.9242Z"
        fill="#8EBCFC"
      />
      <path
        d="M19.6372 30.1377C19.7052 30.1528 19.772 30.1676 19.843 30.174C19.493 30.234 19.163 30.314 18.833 30.434C19.0272 30.3193 19.2292 30.2357 19.439 30.1488L19.523 30.114C19.562 30.1211 19.5998 30.1295 19.6372 30.1377Z"
        fill="#8EBCFC"
      />
      <path
        d="M31.8329 11.1942H29.9329V8.07422C29.8529 8.14422 29.7529 8.18422 29.6429 8.18422H29.0029C29.0029 8.69646 29.0006 9.23204 28.9982 9.78534L28.9982 9.78703C28.9956 10.3782 28.9929 10.9895 28.9929 11.6142H30.8929C33.9729 11.6142 36.4729 14.1142 36.4729 17.1942C36.4729 19.2642 35.3229 21.0542 33.6429 22.0142C35.8229 21.2542 37.3929 19.2042 37.3929 16.7642C37.3929 13.6842 34.8929 11.1842 31.8129 11.1842L31.8329 11.1942Z"
        fill="#8EBCFC"
      />
      <path
        d="M22.143 29.2441C22.603 29.1741 23.043 29.0741 23.463 28.9441C26.003 29.6041 27.883 31.8841 27.883 34.6241H26.923C26.723 31.9141 24.723 29.7041 22.093 29.2441H22.143Z"
        fill="#8EBCFC"
      />
      <path
        d="M13.8928 35.4242C13.8928 35.5742 14.0228 35.6942 14.1728 35.6942H25.8528C26.0128 35.6942 26.1328 35.5742 26.1328 35.4242C26.1328 32.0542 23.3828 29.3042 20.0128 29.3042C16.6428 29.3042 13.8928 32.0542 13.8928 35.4242Z"
        fill="#374EF4"
      />
      <path
        d="M20.013 30.6042C18.143 30.9042 16.703 32.5042 16.643 34.4342H15.313C15.393 32.2742 17.173 30.5442 19.353 30.5442C19.583 30.5442 19.793 30.5642 20.013 30.6042Z"
        fill="white"
      />
      <path
        d="M11.863 9.15405C11.973 13.6541 11.743 19.7941 11.973 24.3041C12.433 26.6741 13.883 28.8941 16.103 30.0441C16.253 30.1241 16.483 30.1241 16.643 30.2741C16.723 30.3541 16.873 30.3541 16.953 30.3541C17.033 30.4341 17.103 30.4341 17.183 30.5041C18.403 30.9641 19.783 31.1141 21.083 30.9641C23.073 30.6541 24.753 29.8141 26.133 28.2841C27.203 26.9841 28.043 25.4541 28.043 23.7641C28.193 19.4041 28.123 13.4941 28.153 9.14405"
        fill="white"
      />
      <path
        d="M20.103 31.3041C19.063 31.3041 18.023 31.1241 17.083 30.7741C17.043 30.7641 17.013 30.7341 16.983 30.7141C16.933 30.6841 16.893 30.6641 16.853 30.6441C16.733 30.6441 16.573 30.6141 16.453 30.4941C16.403 30.4441 16.323 30.4241 16.223 30.3941C16.133 30.3741 16.053 30.3441 15.983 30.3141C13.803 29.1841 12.203 26.9641 11.703 24.3841C11.563 21.7341 11.583 18.5741 11.603 15.5241C11.613 13.2441 11.633 11.0841 11.583 9.18405C11.583 9.03405 11.703 8.90405 11.853 8.90405C12.013 8.91405 12.133 9.02405 12.133 9.17405C12.183 11.0841 12.163 13.2441 12.153 15.5341C12.133 18.5841 12.113 21.7241 12.243 24.3241C12.713 26.7141 14.203 28.7841 16.233 29.8341C16.273 29.8541 16.313 29.8641 16.363 29.8741C16.493 29.9141 16.683 29.9641 16.833 30.1141C16.833 30.1141 16.913 30.1141 16.943 30.1141C17.013 30.1141 17.083 30.1441 17.143 30.1941C17.213 30.2341 17.263 30.2541 17.323 30.3041C18.473 30.7241 19.793 30.8741 21.053 30.7341C23.043 30.4241 24.643 29.5841 25.933 28.1441C27.153 26.6641 27.773 25.2041 27.773 23.8141C27.873 20.8641 27.873 17.2841 27.873 13.8241C27.873 12.1841 27.873 10.6041 27.873 9.19405C27.873 9.04405 27.993 8.92405 28.153 8.92405C28.303 8.92405 28.423 9.04405 28.423 9.20405C28.413 10.6141 28.413 12.1941 28.413 13.8241C28.413 17.2941 28.413 20.8741 28.313 23.8241C28.313 25.3341 27.653 26.9141 26.333 28.5041C24.943 30.0441 23.243 30.9541 21.113 31.2841C20.773 31.3241 20.433 31.3441 20.093 31.3441L20.103 31.3041Z"
        fill="#374EF4"
      />
      <path
        d="M9.80292 23.3041C6.75292 23.3041 4.27292 20.8241 4.27292 17.7741C4.27292 14.7241 6.75292 12.2441 9.80292 12.2441H11.8129V14.5041H9.80292C8.00292 14.5041 6.53292 15.9741 6.53292 17.7741C6.53292 19.5741 8.00292 21.0441 9.80292 21.0441H11.8129V23.3041H9.80292Z"
        fill="white"
      />
      <path
        d="M11.8129 23.5841H9.80292C6.60292 23.5841 3.99292 20.9741 3.99292 17.7741C3.99292 14.5741 6.60292 11.9641 9.80292 11.9641H11.8129C11.9629 11.9641 12.0929 12.0841 12.0929 12.2341V14.4941C12.0929 14.6441 11.9729 14.7641 11.8129 14.7641H9.80292C8.15292 14.7641 6.80292 16.1041 6.80292 17.7641C6.80292 19.4241 8.14292 20.7641 9.80292 20.7641H11.8129C11.9629 20.7641 12.0929 20.8841 12.0929 21.0341V23.2941C12.0929 23.4441 11.9729 23.5641 11.8129 23.5641V23.5841ZM9.80292 12.5141C6.90292 12.5141 4.54292 14.8741 4.54292 17.7741C4.54292 20.6741 6.90292 23.0341 9.80292 23.0341H11.5329V21.3241H9.80292C7.84292 21.3241 6.25292 19.7341 6.25292 17.7741C6.25292 15.8141 7.84292 14.2241 9.80292 14.2241H11.5329V12.5141H9.80292Z"
        fill="#374EF4"
      />
      <path
        d="M30.163 23.6242C33.213 23.6242 35.693 21.1442 35.693 18.0942C35.693 15.0442 33.213 12.5642 30.163 12.5642H28.153V14.8242H30.163C31.963 14.8242 33.433 16.2942 33.433 18.0942C33.433 19.8942 31.963 21.3642 30.163 21.3642H28.153V23.6242H30.163Z"
        fill="white"
      />
      <path
        d="M30.163 23.8942H28.153C28.003 23.8942 27.873 23.7742 27.873 23.6242V21.3642C27.873 21.2142 27.993 21.0942 28.153 21.0942H30.163C31.813 21.0942 33.163 19.7542 33.163 18.0942C33.163 16.4342 31.823 15.0942 30.163 15.0942H28.153C28.003 15.0942 27.873 14.9742 27.873 14.8242V12.5642C27.873 12.4142 27.993 12.2942 28.153 12.2942H30.163C33.363 12.2942 35.973 14.9042 35.973 18.1042C35.973 21.3042 33.363 23.9142 30.163 23.9142V23.8942ZM28.433 23.3442H30.163C33.063 23.3442 35.423 20.9842 35.423 18.0842C35.423 15.1842 33.063 12.8242 30.163 12.8242H28.433V14.5342H30.163C32.123 14.5342 33.713 16.1242 33.713 18.0842C33.713 20.0442 32.123 21.6342 30.163 21.6342H28.433V23.3442Z"
        fill="#374EF4"
      />
      <path
        d="M28.783 7.38401H11.2329C10.9899 7.38401 10.7929 7.58101 10.7929 7.82401V8.71401C10.7929 8.95702 10.9899 9.15401 11.2329 9.15401H28.783C29.026 9.15401 29.2229 8.95702 29.2229 8.71401V7.82401C29.2229 7.58101 29.026 7.38401 28.783 7.38401Z"
        fill="#0593A2"
      />
      <path
        d="M28.7929 9.43401H11.2329C10.8429 9.43401 10.5229 9.11401 10.5229 8.72401V7.82401C10.5229 7.43401 10.8429 7.11401 11.2329 7.11401H28.7929C29.1829 7.11401 29.5029 7.43401 29.5029 7.82401V8.72401C29.5029 9.11401 29.1829 9.43401 28.7929 9.43401ZM11.2329 7.66401C11.1429 7.66401 11.0729 7.73401 11.0729 7.82401V8.72401C11.0729 8.81401 11.1429 8.88401 11.2329 8.88401H28.7929C28.8829 8.88401 28.9529 8.81401 28.9529 8.72401V7.82401C28.9529 7.73401 28.8829 7.66401 28.7929 7.66401H11.2329Z"
        fill="#0593A2"
      />
      <path
        d="M17.4983 23.2542C17.3801 23.2532 17.2716 23.2133 17.1729 23.1442C17.0029 23.0142 16.9129 22.8042 16.9529 22.5942L17.3829 20.0942L15.5629 18.3142C15.4129 18.1642 15.3529 17.9442 15.4229 17.7442C15.4929 17.5442 15.6629 17.3942 15.8729 17.3642L18.3829 17.0042L19.5129 14.7242C19.7029 14.3442 20.3329 14.3442 20.5129 14.7242L21.6329 17.0042L24.1529 17.3742C24.3629 17.4042 24.5429 17.5542 24.6029 17.7542C24.6629 17.9542 24.6129 18.1742 24.4629 18.3242L22.6429 20.0942L23.0729 22.6042C23.1129 22.8142 23.0229 23.0242 22.8529 23.1542C22.6829 23.2842 22.4529 23.2942 22.2629 23.1942L20.0129 22.0142L17.7529 23.1942C17.6745 23.2334 17.5865 23.2534 17.4983 23.2542Z"
        fill="#0593A2"
      />
      <path d="M17.4983 23.2542L17.5029 23.2542H17.4929L17.4983 23.2542Z" fill="#0593A2" />
    </svg>
  );
}
