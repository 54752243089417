import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButtonComponent from 'components/IconButtonComponent';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import { appTheme } from 'theme';

export default function DeleteTeamDialog({ open, onClose, teamName, onDelete }) {
  const closeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  const submitRemove = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete();
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 'topBar' }}>
      <DialogTitle
        id="alert-dialog-title"
        border="1px solid"
        borderColor="greyColors.grey100"
        backgroundColor="background.bg50"
        sx={{
          padding: '12px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...appTheme.typography.paragraphBold,
          color: 'greyColors.grey300',
          textTransform: 'capitalize'
        }}>
        Delete Team: {teamName}
        <IconButtonComponent tag="Close" onClick={closeHandler}>
          <CloseIcon />
        </IconButtonComponent>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            paddingTop: '40px',
            paddingBottom: '16px',
            ...appTheme.typography.paragraph,
            color: 'greyColors.grey300'
          }}>
          Are you sure you want to delete team &quot;{teamName}&quot;?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '8px' }}>
        <Button
          variant="outlined"
          onClick={closeHandler}
          sx={{ ...appTheme.typography.paragraphBold }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={submitRemove}
          autoFocus
          sx={{ ...appTheme.typography.paragraphMedium }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteTeamDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  teamName: PropTypes.string,
  onDelete: PropTypes.func
};
