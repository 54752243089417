import React from 'react';

export default function TeachIPAreasIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.2957 16.7915L19.4431 18.6441L29.3709 28.5719L31.2235 26.7192L21.2957 16.7915Z"
        fill="#8EBCFC"
      />
      <path
        d="M25.081 25.927L28.2276 22.7804C28.4256 22.5824 28.7367 22.5824 28.9347 22.7804L36.4442 30.2898C36.6422 30.4878 36.6422 30.799 36.4442 30.997L33.2976 34.1436L25.081 25.927Z"
        fill="#8EBCFC"
      />
      <path
        d="M16.3736 23.9C22.0014 23.9 26.5636 19.3378 26.5636 13.71C26.5636 8.08224 22.0014 3.52002 16.3736 3.52002C10.7458 3.52002 6.18359 8.08224 6.18359 13.71C6.18359 19.3378 10.7458 23.9 16.3736 23.9Z"
        fill="#8EBCFC"
      />
      <path
        d="M19.9415 18.1266L18.0889 19.9792L28.0166 29.907L29.8693 28.0544L19.9415 18.1266Z"
        fill="white"
      />
      <path
        d="M23.7267 27.2522L26.8733 24.1056C27.0713 23.9076 27.3824 23.9076 27.5804 24.1056L35.0899 31.615C35.2879 31.813 35.2879 32.1242 35.0899 32.3222L31.9433 35.4688L23.7267 27.2522Z"
        fill="white"
      />
      <path
        d="M15.0235 25.2401C20.6513 25.2401 25.2135 20.6779 25.2135 15.0501C25.2135 9.42233 20.6513 4.86011 15.0235 4.86011C9.39572 4.86011 4.8335 9.42233 4.8335 15.0501C4.8335 20.6779 9.39572 25.2401 15.0235 25.2401Z"
        fill="white"
      />
      <path
        d="M19.2567 18.8576L17.4041 20.7102L27.3319 30.638L29.1845 28.7854L19.2567 18.8576Z"
        fill="white"
      />
      <path
        d="M27.3334 30.91C27.2634 30.91 27.1934 30.88 27.1434 30.83L17.2134 20.9C17.1034 20.79 17.1034 20.62 17.2134 20.51L19.0634 18.66C19.1634 18.56 19.3534 18.56 19.4534 18.66L29.3834 28.59C29.4934 28.7 29.4934 28.87 29.3834 28.98L27.5334 30.84C27.4834 30.89 27.4134 30.92 27.3434 30.92L27.3334 30.91ZM17.7934 20.71L27.3334 30.25L28.8034 28.78L19.2634 19.24L17.7934 20.71Z"
        fill="#374EF4"
      />
      <path
        d="M26.5393 24.486L23.0392 27.9861L31.2557 36.2027L34.7559 32.7025L26.5393 24.486Z"
        fill="#374EF4"
      />
      <path
        d="M31.2535 36.48C31.1835 36.48 31.1135 36.45 31.0635 36.4L22.8535 28.19C22.7435 28.08 22.7435 27.91 22.8535 27.8L26.3535 24.3C26.4635 24.19 26.6335 24.19 26.7435 24.3L34.9535 32.51C35.0635 32.62 35.0635 32.79 34.9535 32.9L31.4535 36.4C31.4035 36.45 31.3335 36.48 31.2635 36.48H31.2535ZM23.4335 27.99L31.2535 35.81L34.3635 32.7L26.5435 24.88L23.4335 27.99Z"
        fill="#374EF4"
      />
      <path
        d="M14.3335 25.9801C19.9613 25.9801 24.5235 21.4179 24.5235 15.7901C24.5235 10.1623 19.9613 5.60008 14.3335 5.60008C8.70575 5.60008 4.14353 10.1623 4.14353 15.7901C4.14353 21.4179 8.70575 25.9801 14.3335 25.9801Z"
        fill="white"
      />
      <path
        d="M14.3335 26.2501C11.6535 26.2501 8.97354 25.2301 6.93354 23.1901C2.85354 19.1101 2.85354 12.4701 6.93354 8.39008C8.91354 6.41008 11.5335 5.33008 14.3335 5.33008C17.1335 5.33008 19.7535 6.42008 21.7335 8.39008C25.8135 12.4701 25.8135 19.1101 21.7335 23.1901C19.6935 25.2301 17.0135 26.2501 14.3335 26.2501ZM14.3335 5.88008C11.6835 5.88008 9.19353 6.91008 7.32353 8.78008C3.45354 12.6501 3.45354 18.9301 7.32353 22.8001C11.1935 26.6701 17.4735 26.6701 21.3435 22.8001C25.2135 18.9301 25.2135 12.6501 21.3435 8.78008C19.4735 6.91008 16.9835 5.88008 14.3335 5.88008Z"
        fill="#374EF4"
      />
      <path
        d="M14.3335 25.9801C19.9613 25.9801 24.5235 21.4179 24.5235 15.7901C24.5235 10.1623 19.9613 5.60008 14.3335 5.60008C8.70575 5.60008 4.14353 10.1623 4.14353 15.7901C4.14353 21.4179 8.70575 25.9801 14.3335 25.9801Z"
        fill="white"
      />
      <path
        d="M14.3335 26.2501C11.6535 26.2501 8.97354 25.2301 6.93354 23.1901C2.85354 19.1101 2.85354 12.4701 6.93354 8.39008C8.91354 6.41008 11.5335 5.33008 14.3335 5.33008C17.1335 5.33008 19.7535 6.42008 21.7335 8.39008C25.8135 12.4701 25.8135 19.1101 21.7335 23.1901C19.6935 25.2301 17.0135 26.2501 14.3335 26.2501ZM14.3335 5.88008C11.6835 5.88008 9.19353 6.91008 7.32353 8.78008C3.45354 12.6501 3.45354 18.9301 7.32353 22.8001C11.1935 26.6701 17.4735 26.6701 21.3435 22.8001C25.2135 18.9301 25.2135 12.6501 21.3435 8.78008C19.4735 6.91008 16.9835 5.88008 14.3335 5.88008Z"
        fill="#374EF4"
      />
      <path d="M19.6334 11.3401H9.03342V13.7201H19.6334V11.3401Z" fill="#9A2FC5" />
      <path
        d="M19.6334 14.0001H9.03342C8.88342 14.0001 8.75342 13.8801 8.75342 13.7301V11.3501C8.75342 11.2001 8.87342 11.0801 9.03342 11.0801H19.6334C19.7834 11.0801 19.9134 11.2001 19.9134 11.3501V13.7301C19.9134 13.8801 19.7934 14.0001 19.6334 14.0001ZM9.31342 13.4501H19.3634V11.6201H9.31342V13.4501Z"
        fill="#9A2FC5"
      />
      <path d="M19.6334 14.6001H9.03342V16.9801H19.6334V14.6001Z" fill="#9A2FC5" />
      <path
        d="M19.6334 17.2501H9.03342C8.88342 17.2501 8.75342 17.1301 8.75342 16.9801V14.6001C8.75342 14.4501 8.87342 14.3301 9.03342 14.3301H19.6334C19.7834 14.3301 19.9134 14.4501 19.9134 14.6001V16.9801C19.9134 17.1301 19.7934 17.2501 19.6334 17.2501ZM9.31342 16.7001H19.3634V14.8701H9.31342V16.7001Z"
        fill="#9A2FC5"
      />
      <path d="M19.6334 17.8501H9.03342V20.2301H19.6334V17.8501Z" fill="#374EF4" />
      <path
        d="M19.6334 20.5101H9.03342C8.88342 20.5101 8.75342 20.3901 8.75342 20.2401V17.8601C8.75342 17.7101 8.87342 17.5901 9.03342 17.5901H19.6334C19.7834 17.5901 19.9134 17.7101 19.9134 17.8601V20.2401C19.9134 20.3901 19.7934 20.5101 19.6334 20.5101ZM9.31342 19.9601H19.3634V18.1301H9.31342V19.9601Z"
        fill="#374EF4"
      />
      <path
        d="M16.8135 13.0402C17.0565 13.0402 17.2535 12.8432 17.2535 12.6002C17.2535 12.3572 17.0565 12.1602 16.8135 12.1602C16.5705 12.1602 16.3735 12.3572 16.3735 12.6002C16.3735 12.8432 16.5705 13.0402 16.8135 13.0402Z"
        fill="white"
      />
      <path
        d="M18.5235 13.0402C18.7665 13.0402 18.9635 12.8432 18.9635 12.6002C18.9635 12.3572 18.7665 12.1602 18.5235 12.1602C18.2805 12.1602 18.0835 12.3572 18.0835 12.6002C18.0835 12.8432 18.2805 13.0402 18.5235 13.0402Z"
        fill="white"
      />
      <path
        d="M16.8135 16.2302C17.0565 16.2302 17.2535 16.0332 17.2535 15.7902C17.2535 15.5472 17.0565 15.3502 16.8135 15.3502C16.5705 15.3502 16.3735 15.5472 16.3735 15.7902C16.3735 16.0332 16.5705 16.2302 16.8135 16.2302Z"
        fill="white"
      />
      <path
        d="M18.5235 16.2302C18.7665 16.2302 18.9635 16.0332 18.9635 15.7902C18.9635 15.5472 18.7665 15.3502 18.5235 15.3502C18.2805 15.3502 18.0835 15.5472 18.0835 15.7902C18.0835 16.0332 18.2805 16.2302 18.5235 16.2302Z"
        fill="white"
      />
      <path
        d="M16.8135 19.4202C17.0565 19.4202 17.2535 19.2232 17.2535 18.9802C17.2535 18.7372 17.0565 18.5402 16.8135 18.5402C16.5705 18.5402 16.3735 18.7372 16.3735 18.9802C16.3735 19.2232 16.5705 19.4202 16.8135 19.4202Z"
        fill="white"
      />
      <path
        d="M18.5235 19.4202C18.7665 19.4202 18.9635 19.2232 18.9635 18.9802C18.9635 18.7372 18.7665 18.5402 18.5235 18.5402C18.2805 18.5402 18.0835 18.7372 18.0835 18.9802C18.0835 19.2232 18.2805 19.4202 18.5235 19.4202Z"
        fill="white"
      />
      <path
        d="M6.25364 13.4301C6.25364 13.4301 6.20364 13.4301 6.18364 13.4201C6.03364 13.3801 5.95364 13.2301 5.99364 13.0801C6.20364 12.3201 6.53364 11.6001 6.95364 10.9301C7.27364 10.4301 7.65364 9.96008 8.07364 9.53008C9.39364 8.21008 11.1336 7.38008 12.9836 7.18008C13.1236 7.16008 13.2736 7.27008 13.2836 7.42008C13.2936 7.57008 13.1936 7.71008 13.0436 7.72008C11.3236 7.90008 9.69364 8.68008 8.46364 9.91008C8.06364 10.3101 7.71364 10.7501 7.41364 11.2201C7.02364 11.8401 6.71364 12.5101 6.51364 13.2201C6.48364 13.3401 6.37364 13.4201 6.25364 13.4201V13.4301Z"
        fill="#374EF4"
      />
    </svg>
  );
}
