import React from 'react';

export default function CollapseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path
        d="M445-253.847 218.848-480 445-706.153 487.153-664 303.769-480l183.384 184L445-253.847Zm254 0L472.847-480 699-706.153 741.152-664 557.768-480l183.384 184L699-253.847Z"
        fill="currentColor"
      />
    </svg>
  );
}
