import { RESOURCE_ID_TYPES } from 'constants/resources';

import BaseClient from './BaseClient';

const URL_PREFIX = 'permissions';

export default class PermissionsClient extends BaseClient {
  getPermissionsByResourceId(id, resourceIdType) {
    if (Object.values(RESOURCE_ID_TYPES).includes(resourceIdType)) {
      return this.get(`${URL_PREFIX}?${resourceIdType}=${id}`);
    }
    return null;
  }

  updatePermissionsByResourceId(id, resourceIdType, payload) {
    if (Object.values(RESOURCE_ID_TYPES).includes(resourceIdType)) {
      const modifiedPayload = { ...payload, [resourceIdType]: id };
      return this.put(URL_PREFIX, modifiedPayload);
    }
  }
}
