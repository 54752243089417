import { Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

export default function BenchmarkHeader({ benchmarkLabel }) {
  return (
    <Stack direction="row" alignItems="center" gap="8px">
      {benchmarkLabel?.logo_url && (
        <Tooltip title={benchmarkLabel.tooltip || benchmarkLabel.name} arrow>
          <Box
            component="img"
            src={benchmarkLabel?.logo_url}
            sx={{
              width: `18px`,
              height: `18px`
            }}
          />
        </Tooltip>
      )}
      <Typography variant="paragraphSemibold">{benchmarkLabel?.name}</Typography>
    </Stack>
  );
}

BenchmarkHeader.propTypes = {
  benchmarkLabel: PropTypes.object
};
