import { Box, Checkbox, FormControlLabel, MenuItem, Stack } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

function TableColumnFilterSection({
  sectionTitle,
  sectionColumns,
  hiddenColumns,
  onHiddenColumnsChange
}) {
  const [expanded, setExpanded] = useState(false);

  const numberOfHiddenColumns = useMemo(
    () =>
      sectionColumns.reduce(
        (result, column) => result + (hiddenColumns?.[column.id] === true ? 1 : 0),
        0
      ),
    [hiddenColumns, sectionColumns]
  );

  const handleExpandedChange = () => setExpanded(!expanded);

  const handleSectionChecked = () => {
    const columnsIds = sectionColumns.map((column) => column.id);
    if (numberOfHiddenColumns === 0) {
      onHiddenColumnsChange(columnsIds, true);
    } else {
      onHiddenColumnsChange(columnsIds, false);
    }
  };

  const handleColumnChecked = (columnId) => {
    onHiddenColumnsChange([columnId], !hiddenColumns?.[columnId]);
  };

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" gap="4px">
        <Box
          sx={{ cursor: 'pointer', transform: expanded ? 'rotate(180deg)' : 'rotate(90deg)' }}
          onClick={handleExpandedChange}>
          <ArrowIcon />
        </Box>
        <MenuItem>
          <FormControlLabel
            label={sectionTitle}
            sx={{ textTransform: 'capitalize' }}
            control={
              <Checkbox
                size="small"
                checked={numberOfHiddenColumns === 0}
                indeterminate={
                  numberOfHiddenColumns > 0 && numberOfHiddenColumns < sectionColumns.length
                }
                onChange={handleSectionChecked}
              />
            }
          />
        </MenuItem>
      </Stack>
      {expanded && (
        <Stack direction="column" marginLeft="48px">
          {sectionColumns.map((column) => (
            <MenuItem key={column.id}>
              <FormControlLabel
                label={column.title}
                control={
                  <Checkbox
                    size="small"
                    checked={hiddenColumns?.[column.id] !== true}
                    onChange={() => handleColumnChecked(column.id)}
                  />
                }
              />
            </MenuItem>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

TableColumnFilterSection.propTypes = {
  sectionTitle: PropTypes.string,
  sectionColumns: PropTypes.array,
  hiddenColumns: PropTypes.object,
  onHiddenColumnsChange: PropTypes.func
};

export default TableColumnFilterSection;
