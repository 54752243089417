import { Button, Tooltip } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { submitCompanyListViewAction } from 'actions/company_lists/submit-company-list-view-action';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getExploreViewByIdAndViewId } from 'selectors/explore';
import { combineViewAndUrl } from 'utils/explore-view-utils';

function ViewButton({ listId, viewId, toolTipPlacement, ...props }) {
  const [searchParams] = useSearchParams();
  const { addToast } = useAppUtils();

  const [saveViewDisabled, setSaveViewDisabled] = useState(false);
  const [resetViewDisabled, setResetViewDisabled] = useState(false);
  const view = useSelector((state) => getExploreViewByIdAndViewId(state, listId, viewId));
  const nonDefaultView =
    view &&
    Object.keys(view)
      .filter((key) => !['id', 'company_list_id'].includes(key))
      .some((key) => view[key]);
  const navigate = useNavigate();

  const paramsHaveChanged = searchParams && searchParams.size > 0;

  const handleSaveView = () => {
    dispatch(
      submitCompanyListViewAction(
        listId,
        combineViewAndUrl(view, searchParams),
        setSaveViewDisabled,
        addToast,
        navigate
      )
    );
  };
  const handleResetView = () => {
    dispatch(submitCompanyListViewAction(listId, null, setResetViewDisabled, addToast, navigate));
  };
  if (nonDefaultView && !paramsHaveChanged) {
    return (
      <Tooltip title="Reset table to default configuration" placement={toolTipPlacement} arrow>
        <Button disabled={resetViewDisabled} onClick={handleResetView} {...props}>
          Reset View
        </Button>
      </Tooltip>
    );
  }
  if (searchParams && searchParams.size > 0 && paramsHaveChanged) {
    return (
      <Tooltip title="Save current screening and column filters" placement={toolTipPlacement} arrow>
        <Button disabled={saveViewDisabled} onClick={handleSaveView} {...props}>
          Save View
        </Button>
      </Tooltip>
    );
  }
  return null;
}

ViewButton.propTypes = {
  listId: PropTypes.number.isRequired,
  viewId: PropTypes.number.isRequired,
  sx: PropTypes.object,
  toolTipPlacement: PropTypes.string
};

ViewButton.defaultProps = {
  toolTipPlacement: 'top'
};

export default ViewButton;
