export const INSIGHTS_TYPES = Object.freeze({
  TECH_DOMINANT_IP: 'DominantResearchFocusInsight',
  TECH_IP_PATENT_ASSETS: 'IPPatentSummaryInsight',
  TECH_TEAM: 'TechInsight',
  TECH_LEADERSHIP_TEAM: 'TeamLeadershipTechSummaryInsight',
  TECH_IP_PUBLICATION_ASSETS: 'IPPublicationSummaryInsight',
  TECH_IP_AREAS: 'ResearchFocusSummaryInsight',
  CAPABILITY: 'CapabilityInsight',
  PRODUCT_LAUNCHES: 'ProductLaunchInsight',
  COMMERCIAL_TRACTION: 'CommercialTractionInsight',
  RECOGNITION: 'RecognitionsInsight',
  MARKET_BELIEF: 'MarketBeliefTrends',
  COMPETITION: 'CompetitionInsightV2',
  COMMERCIAL_LEADERSHIP_TEAM: 'TeamLeadershipCommercialSummaryInsight',
  COMMERCIAL_TEAM: 'MNSInsight',
  COMMERCIAL_RISK: 'RiskInsight',
  FINANCING_TRENDS: 'FinanceTrends',
  FINANCING_STATUS: 'StatusInsight',
  FINANCING_GROWTH: 'GrowthInsight',
  FINANCING_OPERATIONAL_PERFORMANCE: 'OperationalInsight',
  FINANCING_INORGANIC_GROWTH: 'InorganicInsight',
  PUBLIC_STATUS: 'PublicGrowthInsight',
  INVESTORS: 'InvestorsInsight',
  PRIVATE_FUNDING: 'PrivateGrowthInsight',
  GITHUB: 'OpenSourceApprovalInsight',
  GITHUB_USER: 'SigSepOpenSourceApprovalInsight',
  STACK: 'StackInsight',
  STACK_USER: 'StackUsersInsight',
  TWITTER: 'TwitterInsight',
  TWITTER_EFFORT: 'TwitterEffortInsight',
  REDDIT: 'RedditInsight',
  REDDIT_USER: 'RedditUsersInsight',
  MENTIONS: 'MentionsInsight',
  USERS: 'UsersInsight',
  PRODUCT_REVIEW_OVERVIEW: 'ProductReviewOverviewInsight',
  G2_PRODUCT_REVIEW_SIZE_TYPE: 'G2ProductReviewSizeTypeInsight',
  COMMERCIAL_EVENTS: 'CommercialEventsInsight',
  OPERATIONAL_EVENTS: 'OperationalEventsInsight',
  FINANCIAL_EVENTS: 'FinancialEventsInsight',
  SUSTAINABILITY_EVENTS: 'SustainabilityEventsInsight',
  GROWTH_SIGNALS_EXTRA_DATA: 'GrowthSignalsExtraDataInsight'
});
