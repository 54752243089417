import { loadAllUsers } from './load-all-users-action';

export function deleteUserAction(userId, onError, onSuccess) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .deleteUser(userId)
      .then((res) => {
        if (res.ok) {
          onSuccess?.('User was deleted successfully!');
          dispatch(loadAllUsers());
        } else {
          onError?.(res.data.message);
        }
      })
      .catch(() => {
        onError?.('Failed to delete user');
      });
  };
}
