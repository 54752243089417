import { makeBlobDownload } from 'utils/download-utils';

export function downloadFileFromExploreRowAction(companyListMetaId, fileInfo, onFinish) {
  return (_dispatch, _getState, apiClient) => {
    apiClient.companyListMetas
      .downloadFile(companyListMetaId, fileInfo.id)
      .then((blob) => {
        makeBlobDownload(blob, fileInfo.file_name);
        onFinish?.(true);
      })
      .catch(() => onFinish?.(false));
  };
}
