import React from 'react';

export default function TechLeadershipIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M8.9112 12.4951C10.7012 12.4951 12.1512 13.9251 12.1512 15.7051C12.1512 17.4851 10.7212 18.9351 8.9312 18.9551C7.1612 18.9751 5.6912 17.5151 5.6912 15.7351C5.6912 13.9551 7.1212 12.5051 8.9112 12.4951Z"
        fill="#9A2FC5"
      />
      <path
        d="M18.5912 32.1651C18.5912 30.4551 18.6512 29.3151 18.5712 27.6051C18.4712 25.3651 17.4612 23.2151 15.6312 21.9351C13.8012 20.6551 11.6612 20.5751 9.6112 20.6951C8.6512 20.7551 7.7012 20.9651 6.8412 21.3951C4.2512 22.6851 2.9312 24.8051 2.8112 27.6551C2.7512 29.1351 2.7812 30.0451 2.8012 31.5251C2.8012 31.8451 3.0612 32.1051 3.3812 32.1051L18.5912 32.1651Z"
        fill="#9A2FC5"
      />
      <path
        d="M29.941 12.4951C31.731 12.4951 33.181 13.9251 33.181 15.7051C33.181 17.4851 31.751 18.9351 29.961 18.9551C28.191 18.9751 26.721 17.5151 26.721 15.7351C26.721 13.9551 28.151 12.5051 29.941 12.4951Z"
        fill="#374EF4"
      />
      <path
        d="M37.671 31.2551C37.681 29.8451 37.721 28.8351 37.641 27.2851C37.541 25.0751 36.501 22.9451 34.691 21.6851C32.881 20.4251 30.731 20.3351 28.681 20.4551C27.721 20.5151 26.771 20.7251 25.911 21.1551C23.321 22.4451 21.991 24.5651 21.881 27.4251C21.821 28.9051 21.851 29.7351 21.871 31.2151C21.871 31.5351 22.131 31.7951 22.451 31.7951L37.081 31.8551C37.401 31.8551 37.671 31.5951 37.671 31.2651V31.2551Z"
        fill="#374EF4"
      />
      <path
        d="M30.4911 31.4048C30.5011 29.8848 30.5411 28.8048 30.4611 27.1348C30.3611 24.7548 29.3211 22.4748 27.5111 21.1148C25.6111 19.6848 23.5511 19.6648 21.5011 19.7948C20.5411 19.8548 19.5911 20.0848 18.7311 20.5448C16.1411 21.9248 14.8111 24.2148 14.7011 27.2848C14.6411 28.8748 14.6711 29.7648 14.6911 31.3648C14.6911 31.7048 14.9511 31.9848 15.2711 31.9848L29.9011 32.0448C30.2211 32.0448 30.4911 31.7648 30.4911 31.4148V31.4048Z"
        fill="white"
      />
      <path
        d="M28.9111 33.0951H11.0911C10.7511 33.0951 10.4711 32.8351 10.4711 32.5051V27.2451C10.4711 22.5151 14.5411 18.6851 19.5511 18.6851H20.4611C25.6111 18.6851 29.4711 22.8251 29.5411 27.5451C29.5611 29.1951 29.5411 30.8551 29.5411 32.5051C29.5411 32.8251 29.2611 33.0951 28.9211 33.0951H28.9111Z"
        fill="#8EBCFC"
      />
      <path
        d="M28.911 33.365H11.091C10.591 33.365 10.191 32.975 10.191 32.505V27.245C10.191 22.375 14.391 18.415 19.551 18.415H20.461C25.541 18.415 29.741 22.505 29.821 27.545C29.841 28.645 29.831 29.755 29.821 30.855C29.821 31.405 29.821 31.955 29.821 32.505C29.821 32.975 29.421 33.365 28.921 33.365H28.911ZM19.541 18.955C14.681 18.955 10.741 22.665 10.741 27.235V32.495C10.741 32.665 10.901 32.805 11.091 32.805H28.911C29.101 32.805 29.251 32.665 29.251 32.495C29.251 31.945 29.251 31.395 29.251 30.835C29.251 29.735 29.261 28.635 29.251 27.535C29.181 22.805 25.231 18.945 20.451 18.945H19.541V18.955Z"
        fill="#8EBCFC"
      />
      <path
        d="M27.6611 34.0649L9.89115 33.9749C9.57115 33.9749 9.30115 33.7149 9.30115 33.3849V28.1249C9.30115 26.0649 10.5211 23.7049 11.9211 22.2549C13.7411 20.3749 16.1011 19.6749 18.7711 19.5649H19.6311C24.4811 19.5649 28.1711 23.7949 28.2411 28.5149C28.2611 30.1649 28.2411 31.8249 28.2411 33.4749C28.2411 33.7949 27.9811 34.0649 27.6511 34.0649H27.6611Z"
        fill="white"
      />
      <path
        d="M24.8312 11.2149C24.8312 14.1949 22.4112 16.6149 19.4312 16.6149C16.4512 16.6149 14.0312 14.1849 14.0412 11.2049C14.0412 8.23494 16.4512 5.83494 19.4212 5.82494C22.4112 5.82494 24.8312 8.22494 24.8312 11.2049V11.2149Z"
        fill="white"
      />
      <path
        d="M19.4412 16.8949C17.9312 16.8949 16.5012 16.3049 15.4312 15.2249C14.3612 14.1449 13.7712 12.7249 13.7712 11.2149C13.7712 8.10494 16.3112 5.56494 19.4312 5.56494H19.4412C20.9612 5.56494 22.3812 6.14494 23.4512 7.21494C24.5212 8.28494 25.1112 9.70494 25.1212 11.2249C25.1212 12.7349 24.5312 14.1649 23.4612 15.2449C22.3912 16.3149 20.9612 16.9049 19.4512 16.9049L19.4412 16.8949ZM19.4412 6.11494C16.6312 6.11494 14.3412 8.41494 14.3312 11.2149C14.3312 12.5849 14.8612 13.8649 15.8312 14.8349C16.8012 15.8049 18.0812 16.3349 19.4512 16.3349C20.8112 16.3349 22.1012 15.8049 23.0712 14.8349C24.0412 13.8649 24.5712 12.5749 24.5712 11.2149C24.5712 9.84494 24.0312 8.56494 23.0712 7.59494C22.1112 6.63494 20.8212 6.10494 19.4512 6.10494L19.4412 6.11494Z"
        fill="#374EF4"
      />
      <path
        d="M27.3312 34.155C27.3312 32.505 27.3512 30.845 27.3312 29.195C27.2612 24.475 23.6312 20.335 18.7712 20.335H17.9112C13.1812 20.335 9.3512 24.165 9.3512 28.895V34.155H27.3312Z"
        fill="white"
      />
      <path
        d="M27.3312 34.4349H9.36118C9.21118 34.4349 9.08118 34.3149 9.08118 34.1549V28.8949C9.08118 24.0249 13.0412 20.0649 17.9112 20.0649H18.7712C23.5712 20.0649 27.5312 24.1549 27.6012 29.1949C27.6112 30.2949 27.6012 31.4049 27.6012 32.5049C27.6012 33.0549 27.6012 33.6049 27.6012 34.1549C27.6012 34.3049 27.4812 34.4349 27.3212 34.4349H27.3312ZM9.64118 33.8849H27.0612C27.0612 33.4249 27.0612 32.9649 27.0612 32.5049C27.0612 31.4049 27.0612 30.3049 27.0612 29.2049C26.9912 24.4749 23.2812 20.6149 18.7812 20.6149H17.9212C13.3512 20.6149 9.64118 24.3249 9.64118 28.8949V33.8849Z"
        fill="#374EF4"
      />
      <path
        d="M18.8412 24.1749L19.8312 26.1849C19.8712 26.2549 19.9412 26.3149 20.0212 26.3249L22.2412 26.6449C22.4512 26.6749 22.5312 26.9249 22.3812 27.0749L20.7712 28.6349C20.7112 28.6949 20.6812 28.7749 20.7012 28.8549L21.0812 31.0649C21.1212 31.2749 20.9012 31.4249 20.7112 31.3349L18.7312 30.2949C18.6612 30.2549 18.5712 30.2549 18.5012 30.2949L16.5212 31.3349C16.3412 31.4349 16.1212 31.2749 16.1512 31.0649L16.5312 28.8549C16.5412 28.7749 16.5212 28.6949 16.4612 28.6349L14.8512 27.0749C14.7012 26.9249 14.7812 26.6749 14.9912 26.6449L17.2112 26.3249C17.2912 26.3149 17.3612 26.2649 17.4012 26.1849L18.3912 24.1749C18.4812 23.9849 18.7512 23.9849 18.8412 24.1749Z"
        fill="#9A2FC5"
      />
    </svg>
  );
}
