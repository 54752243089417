import PropTypes from 'prop-types';

export default function RenderDatetime({ value }) {
  return new Date(value).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
}

RenderDatetime.propTypes = {
  value: PropTypes.string
};
