import React from 'react';

export default function RerunIcon() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.801 6.23552L16.2539 6.78002C15.6664 2.94078 12.3498 0 8.3463 0C3.93258 0 0.353693 3.57434 0.346313 7.98633H1.34632C1.35369 4.12662 4.48487 1 8.3463 1C11.8014 1 14.6718 3.5032 15.243 6.79497L14.6778 6.22712C14.483 6.03139 14.1665 6.03063 13.9707 6.22542C13.775 6.42021 13.7742 6.7368 13.969 6.93253L15.3596 8.32978C15.3667 8.33784 15.374 8.34574 15.3817 8.35344C15.5765 8.54917 15.8931 8.54993 16.0888 8.35513L17.5064 6.94432C17.7021 6.74952 17.7029 6.43294 17.5081 6.23721C17.3133 6.04148 16.9967 6.04072 16.801 6.23552Z"
        fill="currentColor"
      />
      <path
        d="M1.50403 9.52734C1.50403 9.80349 1.28017 10.0273 1.00403 10.0273C0.727886 10.0273 0.504028 9.80349 0.504028 9.52734C0.504028 9.2512 0.727886 9.02734 1.00403 9.02734C1.28017 9.02734 1.50403 9.2512 1.50403 9.52734Z"
        fill="currentColor"
      />
      <path
        d="M1.73645 12.0312C2.01259 12.0312 2.23645 11.8074 2.23645 11.5312C2.23645 11.2551 2.01259 11.0312 1.73645 11.0312C1.46031 11.0312 1.23645 11.2551 1.23645 11.5312C1.23645 11.8074 1.46031 12.0312 1.73645 12.0312Z"
        fill="currentColor"
      />
      <path
        d="M3.66467 13.416C3.66467 13.6922 3.44082 13.916 3.16467 13.916C2.88853 13.916 2.66467 13.6922 2.66467 13.416C2.66467 13.1399 2.88853 12.916 3.16467 12.916C3.44082 12.916 3.66467 13.1399 3.66467 13.416Z"
        fill="currentColor"
      />
      <path
        d="M4.74084 15.0703C5.01699 15.0703 5.24084 14.8465 5.24084 14.5703C5.24084 14.2942 5.01699 14.0703 4.74084 14.0703C4.4647 14.0703 4.24084 14.2942 4.24084 14.5703C4.24084 14.8465 4.4647 15.0703 4.74084 15.0703Z"
        fill="currentColor"
      />
      <path
        d="M6.91028 15.252C6.91028 15.5281 6.68642 15.752 6.41028 15.752C6.13414 15.752 5.91028 15.5281 5.91028 15.252C5.91028 14.9758 6.13414 14.752 6.41028 14.752C6.68642 14.752 6.91028 14.9758 6.91028 15.252Z"
        fill="currentColor"
      />
      <path
        d="M8.43616 16C8.7123 16 8.93616 15.7761 8.93616 15.5C8.93616 15.2239 8.7123 15 8.43616 15C8.16001 15 7.93616 15.2239 7.93616 15.5C7.93616 15.7761 8.16001 16 8.43616 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.486 3.49609C10.8305 3.49609 12.7311 5.39553 12.7311 7.73861C12.7311 8.89867 12.2653 9.94998 11.5103 10.7159L14.6734 13.3684C14.8418 13.5096 14.8637 13.7605 14.7224 13.9287C14.5812 14.097 14.3301 14.1189 14.1617 13.9777L10.9065 11.2479C10.9031 11.2451 10.8997 11.2422 10.8965 11.2393C10.8948 11.2378 10.8931 11.2363 10.8915 11.2347C10.2078 11.7055 9.37908 11.9811 8.486 11.9811C6.14146 11.9811 4.24084 10.0817 4.24084 7.73861C4.24084 5.39553 6.14146 3.49609 8.486 3.49609ZM8.486 4.29157C10.3909 4.29157 11.9352 5.83486 11.9352 7.73861C11.9352 9.64236 10.3909 11.1857 8.486 11.1857C6.58106 11.1857 5.03681 9.64236 5.03681 7.73861C5.03681 5.83486 6.58106 4.29157 8.486 4.29157Z"
        fill="currentColor"
      />
    </svg>
  );
}
