import { createSelector } from 'reselect';

export const getUser = (state) => state.user;
export const getUserName = (state) => getUser(state).username;
export const getUserEmail = (state) => getUser(state).email;
export const getUserPictureUrl = (state) => getUser(state).picture;
export const getUserId = (state) => getUser(state).id;
export const getUserIsDev = (state) => getUser(state).is_dev;
export const getUserIsAdmin = (state) => getUser(state).is_admin;
export const getUserIsActive = (state) => getUser(state).is_active;
export const getUserIsVerified = (state) => getUser(state).is_verified;
export const getUserIsBasic = (state) => getUser(state).is_basic;
export const getUserAuth0Id = (state) => getUser(state).auth0_id;
export const getUserIsDevOrAdmin = (state) => getUserIsAdmin(state) || getUserIsDev(state);
export const getUserIsLimited = (state) => getUser(state).is_limited;
export const getUserSubmissionsLimit = (state) => getUser(state).submissions_limit;
export const getUserSubmissionsCount = (state) => getUser(state).submissions_count;
export const getUserFeatureFlags = (state) => getUser(state)?.feature_flags || [];
export const getUserRunsLeft = createSelector(
  getUserSubmissionsCount,
  getUserSubmissionsLimit,
  (submissionsCount, submissionsLimit) => submissionsLimit - submissionsCount
);
export const getUserHasRunsLeft = createSelector(
  getUserRunsLeft,
  getUserIsLimited,
  (runsLeft, isLimited) => !isLimited || runsLeft > 0
);
export const getUserOrganizationName = (state) => getUser(state).organization?.name;
export const getUserOrganizationUsers = (state) => getUser(state).organization?.users;

export const getUserOrganizationId = (state) => getUser(state).organization?.id;
export const getUserCanCollaborate = (state) => {
  return Boolean(getUserOrganizationId(state));
};
