import { sendUserEvent } from 'actions/users/send-user-event-action';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { updateExploreCustomColumnData } from 'reducer/explore-slice';
import { getObjectLength } from 'utils/objects-utils';

export function updateBulkOfCustomColumnValuesAction(
  listId,
  columnId,
  customColumnValues,
  onFinish,
  isRetry
) {
  return (dispatch, getState, apiClient) => {
    apiClient.companyListMetas
      .updateBulkOfCustomColumnValues({
        custom_column_id: columnId,
        custom_column_values: customColumnValues
      })
      .then((res) => {
        if (res.ok) {
          customColumnValues.forEach((row) => {
            dispatch(
              updateExploreCustomColumnData({
                listId,
                companyListMetaId: parseInt(row.company_list_meta_id, 10),
                columnId: columnId,
                value: row.value
              })
            );
          });
          onFinish?.(TOAST_TYPES.SUCCESS);
        }
      })
      .catch(() => {
        dispatch(
          sendUserEvent(USER_EVENTS.BULK_CC_UPDATE_FAILED, {
            listId,
            columnId,
            numCompanies: getObjectLength(customColumnValues),
            isRetry
          })
        );
        if (isRetry) {
          onFinish?.(TOAST_TYPES.ERROR);
        } else {
          dispatch(
            updateBulkOfCustomColumnValuesAction(
              listId,
              columnId,
              customColumnValues,
              onFinish,
              true
            )
          );
        }
      });
  };
}
