import BaseClient from './BaseClient';

const URL_PREFIX = 'opinions';

export default class OpinionsClient extends BaseClient {
  fetchOpinion(runId, insightId) {
    return this.get(`${URL_PREFIX}/${runId}/${insightId}`);
  }

  fetchAllSearchOpinions(runId) {
    return this.get(`${URL_PREFIX}/${runId}`);
  }

  fetchAllOpinions({ num = 50, all = false }) {
    return this.get(`${URL_PREFIX}?N=${num}&all=${all ? '1' : '0'}`);
  }

  updateOpinion(runId, insightId, opinion) {
    return this.put(`${URL_PREFIX}/${runId}/${insightId}`, opinion);
  }

  createOpinion(runId, insightId, opinion) {
    return this.post(`${URL_PREFIX}/${runId}/${insightId}`, opinion);
  }

  deleteOpinion(runId, insightId) {
    return this.httpDelete(`${URL_PREFIX}/${runId}/${insightId}`);
  }
}
