import PropTypes from 'prop-types';

import MultipleSelectionFilerComponent from './components/MultipleSelectionFilerComponent';

function CreatedAtColumnFilter({ rows, existFilter, onUpdateFilter }) {
  return (
    <MultipleSelectionFilerComponent
      columnId="created_at"
      rows={rows}
      rowMap={(data) => data}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

CreatedAtColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default CreatedAtColumnFilter;
