import { addAllNotifications } from 'reducer/notifications-slice';

export function loadUserNotificationsAction() {
  return (dispatch, _getState, apiClient) => {
    apiClient.notifications.fetchUserNotifications().then((res) => {
      if (res.ok && res.data) {
        const { notifications } = res.data;

        const allNotifications = notifications.reduce((all, notification) => {
          all[notification.id] = notification;

          return all;
        }, {});
        dispatch(addAllNotifications({ notifications: allNotifications }));
      }
    });
  };
}
