import { Button, Stack, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import IconButtonComponent from 'components/IconButtonComponent';
import RunTooltip from 'components/RunTooltip';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { useAppState } from 'hooks/state-context';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { getRunFailedServicesById, getRunRerunningById } from 'selectors/runs';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';
import CopyRunToUserDialog from './CopyRunToUserDialog';
import DownloadRunFilesButton from './DownloadRunFilesButton';

function DeepDiveTab({ runs, hidden, filter, users }) {
  const { state } = useAppState();
  const navigate = useNavigate();
  const { addToast } = useAppUtils();
  const [selectedRunId, setSelectedRunId] = useState(null);

  const onClickRun = (runId) => {
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${runId}/${OVERVIEW_TAB.path}`);
  };

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {Object.keys(runs)
          .filter((key) => {
            return filter ? filter(runs, key) : true;
          })
          .sort((key1, key2) => runs[key2].id - runs[key1].id)
          .map((key) => {
            const run = runs[key];
            const failed = getRunFailedServicesById(state, key);
            const running = getRunRerunningById(state, key);
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickRun(key)}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {run?.id ? run.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={run?.ownerOrganizationLogoUrl} />
                <IconButtonComponent
                  tag="Share run with other users"
                  onClick={() => setSelectedRunId(run.id)}>
                  <ShareIcon />
                </IconButtonComponent>
                <DownloadRunFilesButton
                  searchId={run.id}
                  recordId={run.recordId}
                  services={Object.keys(run.statuses)}></DownloadRunFilesButton>
                <Typography variant="paragraph">{run?.recordId}</Typography>
                <Typography variant="paragraph">
                  {run?.timeSubmitted ? formatTimestamp(run.timeSubmitted) : 'NA'}
                </Typography>
                <RunTooltip key={key} run={run}>
                  <Typography variant="paragraph">{run.name}</Typography>
                  {running && running.length > 0 && (
                    <Typography variant="paragraph" color="orange">
                      Waiting for: {running.join(', ')}
                    </Typography>
                  )}
                  {failed && failed.length > 0 && (
                    <Typography variant="paragraph" color="red">
                      Failed: {failed.join(', ')}
                    </Typography>
                  )}
                </RunTooltip>
                <Typography variant="paragraph" sx={{ marginLeft: 'auto' }}>
                  {run.owner}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
      {selectedRunId && users && (
        <CopyRunToUserDialog
          open={Boolean(selectedRunId)}
          onClose={() => {
            setSelectedRunId(null);
          }}
          runs={runs}
          selectedRunId={selectedRunId}
          type="company"
          users={users}
          addToast={addToast}
        />
      )}
    </Stack>
  );
}

export default DeepDiveTab;

DeepDiveTab.propTypes = {
  runs: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func,
  users: PropTypes.object
};
