import { ACTIONS } from 'constants/actions';

function updateOrganizationsData(data) {
  return { type: ACTIONS.ADD_ORGANIZATIONS_DATA, data };
}

function translateOrganizationForOrganizationsState(organization) {
  return {
    id: organization.id,
    name: organization.name,
    logoUrl: organization.logo_url
  };
}

export function loadAllOrganizations() {
  return (_state, dispatch, apiClient) => {
    apiClient.organizations.fetchAllOrganizations().then((res) => {
      if (res.ok && res.data) {
        const { organizations } = res.data;
        const organizationsData = organizations.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateOrganizationForOrganizationsState(data) };
        }, {});
        dispatch(updateOrganizationsData(organizationsData));
      }
    });
  };
}
