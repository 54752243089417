export const RESOURCE_ID_TYPES = Object.freeze({
  LIST: 'company_list_id',
  RECORD: 'record_id',
  FOLDER: 'folder_id'
});

export const RESOURCE_TYPES = Object.freeze({
  LIST: 'list',
  PERSON: 'person',
  DEEP_DIVE: 'deep_dive',
  FOLDER: 'folder'
});

export const RESOURCE_TYPE_TO_RESOURCE_ID_TYPE = Object.freeze({
  [RESOURCE_TYPES.LIST]: RESOURCE_ID_TYPES.LIST,
  [RESOURCE_TYPES.PERSON]: RESOURCE_ID_TYPES.RECORD,
  [RESOURCE_TYPES.DEEP_DIVE]: RESOURCE_ID_TYPES.RECORD,
  [RESOURCE_TYPES.FOLDER]: RESOURCE_ID_TYPES.FOLDER
});

export const RESOURCE_ID_TYPE_TO_PERMISSIONS_SLICE_LOCATION = Object.freeze({
  [RESOURCE_ID_TYPES.LIST]: 'lists',
  [RESOURCE_ID_TYPES.RECORD]: 'records',
  [RESOURCE_ID_TYPES.FOLDER]: 'folders'
});
