import { loadAllUsers } from './load-all-users-action';

export function makeNewUserAction(payload, onError, onSuccess) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .createNewUser(payload)
      .then((res) => {
        if (res.ok) {
          onSuccess?.('User was created successfully!');
          dispatch(loadAllUsers());
        } else {
          onError?.(res?.message || res?.data?.message || 'Failed to create user');
        }
      })
      .catch((res) => {
        onError?.(res?.message || res?.data?.message || 'Failed to create user');
      });
  };
}
