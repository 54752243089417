import { Box, Button, Stack, Typography } from '@mui/material';
import { loadClustersForDiscoveryAction } from 'actions/clusters/load-clusters-for-discovery-action';
import { updateCurrentDiscoveryCluster } from 'actions/clusters/update-current-cluster-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import LoaderComponent from 'components/LoaderComponent';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clustersColumnsFromMergedRows } from 'utils/companyList-utils';

import ClustersCompaniesSelectionsList from './ClustersCompaniesSelectionsListConnector';
import ClustersCompaniesTable from './ClustersCompaniesTableConnector';
import ClustersScatterPlotWithZoomArea from './ClustersScatterPlotWithZoomArea';
import ClustersSettingsComponent from './ClustersSettingsComponent';

/* eslint-disable id-length */
const DEFAULT_TOP_N = 3;

function DiscoveryRunScreenClusters({
  clustersElement,
  currentClusterLabel,
  onTriggerClusters,
  onExtend,
  onToBenchmark,
  onNewList,
  noClustersComponent,
  rows,
  isDev,
  headerHeight,
  listId
}) {
  const containerRef = useRef();
  const [initialLeft, setInitialLeft] = useState(0);

  const [clickedZoomOutClusters, setClickedZoomOutClusters] = useState(false);
  const [isZoomedClusters, setIsZoomedClusters] = useState(false);

  const [clickedZoomOutTags, setClickedZoomOutTags] = useState(false);
  const [isZoomedTags, setIsZoomedTags] = useState(false);

  const [selectedTopN, setSelectedTopN] = useState(DEFAULT_TOP_N);
  const [seeFullTags, setSeeFullTags] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showTags, setShowTags] = useState(true);

  const [hideTable, setHideTable] = useState(false);

  const reduxDispatch = useDispatch();
  const columns = useMemo(() => {
    return clustersColumnsFromMergedRows(rows);
  }, [rows]);

  const containerElement = containerRef?.current;
  useEffect(() => {
    if (!containerElement) return;

    const rect = containerElement.getBoundingClientRect();
    setInitialLeft(rect.left);
  }, [containerElement]);

  useEffect(() => {
    if (isLoading) {
      reduxDispatch(
        loadClustersForDiscoveryAction(clustersElement.id, {
          top_n: selectedTopN,
          get_tags: showTags,
          full_tags: seeFullTags
        })
      );
    }
  }, [selectedTopN, isLoading, clustersElement, showTags, seeFullTags, reduxDispatch]);

  useEffect(() => {
    setClickedZoomOutClusters(true);
    setClickedZoomOutTags(true);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [clustersElement]);

  useEffect(() => {
    setHideTable(false);
  }, [currentClusterLabel]);

  const clustersData = useMemo(() => {
    if (!clustersElement?.data) {
      return null;
    }
    const { x, y, size, labels } = clustersElement.data;

    return x.map((xVal, index) => ({
      x: xVal,
      y: y[index],
      size: size[index],
      label: labels[index]
    }));
  }, [clustersElement?.data]);

  const tagsData = useMemo(() => {
    if (!currentClusterLabel) return null;
    const localTagsData = clustersElement?.data?.tags?.[currentClusterLabel];
    if (!localTagsData) return null;

    const { x, y, tags, company_hashes } = localTagsData;
    return x.map((xVal, index) => ({
      x: xVal,
      y: y[index],
      label: tags[index],
      company_hashes: company_hashes[index]
    }));
  }, [clustersElement?.data?.tags, currentClusterLabel]);

  const filterRow = (row) => {
    if (currentClusterLabel && clustersElement.data) {
      const { company_hashes, labels } = clustersElement.data;
      const clusterIndex = labels.indexOf(currentClusterLabel);
      return company_hashes[clusterIndex].includes(row?.company_name?.hash);
    }
    return false;
  };

  if (!clustersData) {
    return noClustersComponent;
  }

  return (
    <Stack ref={containerRef} paddingBottom="8px">
      {isDev && (
        <Stack sx={{ position: 'fixed', top: 24 + 40 + 50, right: 24 }}>
          <ClustersSettingsComponent
            selectedTopN={selectedTopN}
            setSelectedTopN={setSelectedTopN}
            seeFullTags={seeFullTags}
            setSeeFullTags={setSeeFullTags}
            setIsLoading={setIsLoading}
            onTriggerClusters={onTriggerClusters}
            isZoomedClusters={isZoomedClusters}
            handleZoomOutClusters={() => setClickedZoomOutClusters(true)}
            showTags={showTags}
            setShowTags={setShowTags}
            listId={listId}
            clustersId={clustersElement?.id}
          />
        </Stack>
      )}
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Stack padding="16px" gap="50px">
          <Box sx={{ width: '100%', height: '100%', backgroundColor: 'background.bg50' }}>
            <ClustersScatterPlotWithZoomArea
              headerHeight={headerHeight}
              data={clustersData}
              currentClusterLabel={currentClusterLabel}
              setIsZoomed={setIsZoomedClusters}
              clickedZoomOut={clickedZoomOutClusters}
              setClickedZoomOut={setClickedZoomOutClusters}
              onDataPointClick={(event) => dispatch(updateCurrentDiscoveryCluster(event.label))}
            />
          </Box>
          {tagsData && (
            <Box sx={{ width: '100%', height: '100%', backgroundColor: 'background.bg50' }}>
              <Typography
                variant="h3"
                textAlign="center"
                color="greyColors.grey300"
                paddingTop="24px">
                {`Tags for ${currentClusterLabel}`}
                {isZoomedTags && (
                  <Button onClick={() => setClickedZoomOutTags(true)}>Zoom Out</Button>
                )}
              </Typography>
              <ClustersScatterPlotWithZoomArea
                headerHeight={headerHeight}
                data={tagsData}
                currentClusterLabel={currentClusterLabel}
                setIsZoomed={setIsZoomedTags}
                clickedZoomOut={clickedZoomOutTags}
                setClickedZoomOut={setClickedZoomOutTags}
              />
            </Box>
          )}

          {currentClusterLabel && !hideTable && columns && columns.length > 0 && (
            <ClustersCompaniesTable
              currentClusterLabel={currentClusterLabel}
              setHideTable={setHideTable}
              columns={columns}
              filterRow={filterRow}
              headerHeight={headerHeight}
              listId={listId}
            />
          )}
          <ClustersCompaniesSelectionsList
            listId={listId}
            onExtend={onExtend}
            onToBenchmark={onToBenchmark}
            onNewList={onNewList}
            headerHeight={headerHeight}
            initialLeft={initialLeft}
          />
        </Stack>
      )}
    </Stack>
  );
}

DiscoveryRunScreenClusters.propTypes = {
  clustersElement: PropTypes.object,
  currentClusterLabel: PropTypes.string,
  rows: PropTypes.array,
  onTriggerClusters: PropTypes.func,
  discoveryId: PropTypes.string,
  onExtend: PropTypes.func,
  onToBenchmark: PropTypes.func,
  onNewList: PropTypes.func,
  noClustersComponent: PropTypes.element,
  isDev: PropTypes.bool,
  headerHeight: PropTypes.number,
  listId: PropTypes.number
};

export default wrapWithError(DiscoveryRunScreenClusters);
