import { Divider, Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import CloseIcon from 'icons/CloseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import { NewListIcon } from 'icons/NewListIcon';
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';
import { appTheme } from 'theme';

import { NewListActionsMenu } from './NewListActionsMenu';
import { SelectedCompaniesComponent } from './SelectedCompaniesComponent';

export const BottomBarDiscovery = ({
  selectedCompanies,
  onClose,
  onRowsDeleted,
  onNewList,
  onDeepDive,
  onExtend,
  noRunsLeft
}) => {
  const numCompaniesSelected = selectedCompanies?.length;

  const maxLogosNum = 4;
  if (numCompaniesSelected <= 0) {
    return null;
  }

  return (
    <Draggable>
      <Stack
        id="bottom-bar"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: 'fixed',
          width: 'fit-content',
          bottom: '5%',
          padding: '6px 8px',
          backgroundColor: 'white',
          borderRadius: '4px',
          cursor: 'move',
          boxShadow:
            '0px 0px 5px 1px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
        }}>
        <Stack flexDirection="row" gap="16px">
          <SelectedCompaniesComponent
            selectedCompanies={selectedCompanies}
            maxLogosNum={maxLogosNum}
          />
        </Stack>

        <Stack flexDirection="row" gap="16px">
          <NewListActionsMenu
            onDeepDive={onDeepDive}
            numSelected={numCompaniesSelected}
            noRunsLeft={noRunsLeft}
            onExtend={onExtend}></NewListActionsMenu>
          <IconButtonComponent onClick={onNewList} sx={{ color: 'secondary.secondary1' }}>
            <NewListIcon />
            <Typography variant="paragraph">Add To List</Typography>
          </IconButtonComponent>
          <IconButtonComponent
            tag="Remove"
            onClick={onRowsDeleted}
            sx={{ color: appTheme.palette.indicator.negative, padding: '8px' }}>
            <DeleteIcon useDefaultColor={false} />
            <Typography variant="paragraph">Remove</Typography>
          </IconButtonComponent>
          <Divider
            orientation="vertical"
            sx={{ borderColor: 'greyColors.grey100', height: '20px', alignSelf: 'center' }}
          />
          <IconButtonComponent onClick={onClose}>
            <CloseIcon></CloseIcon>
          </IconButtonComponent>
        </Stack>
      </Stack>
    </Draggable>
  );
};

BottomBarDiscovery.propTypes = {
  selectedCompanies: PropTypes.array,
  onClose: PropTypes.func,
  onRowsDeleted: PropTypes.func,
  onNewList: PropTypes.func,
  onDeepDive: PropTypes.func,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func
};

export default React.memo(BottomBarDiscovery);
