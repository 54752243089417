import {
  Autocomplete,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getArtists } from 'selectors/art';

import Artwork from './Artwork';

function FindPanel({
  artistNameSelect,
  setArtistNameSelect,
  artworkName,
  setArtworkName,
  externalOnly,
  setExternalOnly,
  onPredict,
  onFind,
  findLoading,
  predictLoading,
  localArtworks,
  setLocalArtworks,
  resetArtwork
}) {
  const artists = useSelector(getArtists) || [];

  const [artistNameInput, setArtistNameInput] = React.useState(null);
  const handleSelect = (_event, newValue) => {
    setArtistNameSelect(newValue);
    setArtistNameInput(newValue);
  };
  return (
    <Stack gap="32px">
      <Stack gap="16px" alignItems="center">
        <Stack width="100%" gap="8px">
          <Stack direction="row" alignItems="center" gap="16px" textAlign="left">
            <Typography variant="paragraphSemiBold" color="greyColors.grey300" width="100px">
              Artist Name
            </Typography>
            <Autocomplete
              options={artists}
              value={artistNameSelect}
              size="small"
              onChange={handleSelect}
              inputValue={artistNameInput}
              disableClearable
              disabled={findLoading}
              onInputChange={(_event, newInputValue) => {
                setArtistNameInput(newInputValue);
              }}
              sx={{
                flex: 1,
                color: 'greyColors.grey300'
              }}
              renderInput={(params) => (
                <TextField {...params} label={artistNameInput ? null : 'Select Artist'} />
              )}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap="16px" textAlign="left">
            <Typography variant="paragraphSemiBold" color="greyColors.grey300" width="100px">
              Artwork Name
            </Typography>
            <TextField
              placeholder="Enter artwork name"
              value={artworkName}
              size="small"
              autoFocus
              disabled={findLoading}
              disableUnderline={true}
              sx={{
                flex: 1,
                color: 'greyColors.grey300'
              }}
              onChange={(event) => setArtworkName(event.target.value)}
            />
          </Stack>
        </Stack>
        <Stack width="100%" direction="row" justifyContent="space-between" gap="16px">
          <Button
            variant="contained"
            height="100%"
            sx={{ width: '100%' }}
            onClick={onFind}
            disabled={findLoading}>
            Find
          </Button>

          <FormControlLabel
            control={
              <Switch
                checked={externalOnly}
                onChange={() => {
                  setExternalOnly(!externalOnly);
                }}
              />
            }
            label={<Typography variant="paragraphMedium">External Search</Typography>}
          />
        </Stack>
      </Stack>
      {findLoading && <div>Loading...</div>}
      <Stack gap="8px">
        {localArtworks &&
          !findLoading &&
          Object.keys(localArtworks).map((id) => (
            <Artwork
              key={id}
              id={id}
              artwork={localArtworks[id]}
              predictLoading={predictLoading}
              onPredict={onPredict}
              setArtwork={(newArtwork) => {
                const newArtworks = { ...localArtworks };
                newArtworks[id] = newArtwork;
                setLocalArtworks(newArtworks);
              }}
              resetArtWork={() => resetArtwork(id)}
            />
          ))}
      </Stack>
    </Stack>
  );
}

FindPanel.propTypes = {
  onPredict: PropTypes.func.isRequired,
  onFind: PropTypes.func.isRequired,
  findLoading: PropTypes.bool,
  predictLoading: PropTypes.number,
  artistNameSelect: PropTypes.string,
  setArtistNameSelect: PropTypes.func,
  artworkName: PropTypes.string,
  setArtworkName: PropTypes.func,
  externalOnly: PropTypes.bool,
  setExternalOnly: PropTypes.func,
  localArtworks: PropTypes.object,
  setLocalArtworks: PropTypes.func,
  resetArtwork: PropTypes.func
};

export default FindPanel;
