import PropTypes from 'prop-types';

import RenderBulkCompanyName from './RenderBulkCompanyName';

export default function RenderPeerCompanyName({ value, ...args }) {
  return (
    <RenderBulkCompanyName
      value={value}
      showCheckBox={true}
      isChecked={value?.isChecked}
      onCheck={value?.onCheck}
      {...args}
    />
  );
}

RenderPeerCompanyName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
