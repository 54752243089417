export const getDeepDive = (state) => state.deepDive;
export const getDeepDiveUiSettings = (state) => getDeepDive(state).uiSettings;
export const getDeepDiveDynamicCards = (state) => getDeepDive(state).dynamicCards;
export const getDeepDiveIsPublicMode = (state) => getDeepDiveUiSettings(state).isPublicMode;
export const getDeepDiveDynamicCardsByDeepDiveId = (state, deepDiveId) =>
  getDeepDiveDynamicCards(state)[deepDiveId];
export const getDeepDiveDynamicCardByDeepDiveIdAndCardId = (state, deepDiveId, cardId) =>
  getDeepDiveDynamicCardsByDeepDiveId(state, deepDiveId)?.[cardId];
export const getDeepDiveDynamicCardDataById = (state, deepDiveId, cardId) =>
  getDeepDiveDynamicCardByDeepDiveIdAndCardId(state, deepDiveId, cardId)?.data;
export const getDeepDiveInsightsColumns = (state) => getDeepDive(state).insightsColumns;
export const getDeepDiveInsightsColumnsByDeepDiveId = (state, deepDiveId) =>
  getDeepDiveInsightsColumns(state)[deepDiveId];
