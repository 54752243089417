import { Box, Stack, Typography } from '@mui/material';
import { loadPublicShareLinkDataAction } from 'actions/public_share_links/load-public-share-link-data-action';
import LoaderComponent from 'components/LoaderComponent';
import OverviewComponent from 'components/OverviewComponent';
import TabsComponent from 'components/TabsComponent';
import TopbarComponent from 'components/TopbarComponent';
import GroupInsightsCardsComponent from 'components/group-insights-cards/GroupInsightsCardsComponent';
import CompanySummaryComponent from 'components/summary/company/CompanySummaryComponent';
import MobileCompanySummaryComponent from 'components/summary/company/MobileCompanySummaryComponent';
import { COMPANY_INSIGHTS_PREFIX, PUBLIC_SHARE_PREFIX } from 'constants/app-routes';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateUiSettingsPublicMode } from 'reducer/deep-dive';
import { getElementsByRecordId } from 'selectors/elements';
import { getInsightsByRecordId } from 'selectors/insights';
import {
  getIsLoadingPublicLinksDataByLinkId,
  getSearchIdByPublicLinkId
} from 'selectors/puclic-shares';
import { groupInsights } from 'utils/insights-utils';
import { groupSort, groupToTitle } from 'utils/overviewGroup-utils';

function PublicShareScreen({ noTopBar }) {
  const { linkId, tabPath } = useParams();
  const dispatch = useDispatch();
  const runId = useSelector((state) => getSearchIdByPublicLinkId(state, linkId));
  const isLoading = useSelector((state) => getIsLoadingPublicLinksDataByLinkId(state, linkId));

  const { state } = useAppState();
  const [insightsbyGroups, setInsightsbyGroups] = useState({});
  const [groupsSet, setGroupsSet] = useState(new Set());
  const insights = getInsightsByRecordId(state, runId);
  const elements = getElementsByRecordId(state, runId);

  useEffect(() => {
    dispatch(updateUiSettingsPublicMode({ isPublicMode: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!linkId) return;

    dispatch(loadPublicShareLinkDataAction(linkId));
  }, [linkId, dispatch]);

  useEffect(() => {
    if (!insights) return;
    const localInsightsbyGroups = groupInsights(insights);
    setInsightsbyGroups(localInsightsbyGroups);
    setGroupsSet(
      Array.from(new Set(Object.keys({ ...localInsightsbyGroups, ...elements }))).sort(groupSort)
    );
  }, [insights, elements]);

  return (
    <Box>
      {!noTopBar && <TopbarComponent />}
      {isLoading === false ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 50px)'
          }}>
          {runId ? (
            <React.Fragment>
              <CompanySummaryComponent
                recordId={runId}
                backTag="Go to all runs"
                backHref={COMPANY_INSIGHTS_PREFIX}
                addBottomPadding={true}
                insightsbyGroups={insightsbyGroups}
                elementsByGroups={elements}
                doNotFetchData={true}
                is_deep_dive_screen={false}
              />
              <MobileCompanySummaryComponent
                recordId={runId}
                backTag="Go to all runs"
                backHref={COMPANY_INSIGHTS_PREFIX}
                addBottomPadding={true}
                insightsbyGroups={insightsbyGroups}
                elementsByGroups={elements}
                doNotFetchData={true}
              />
              <TabsComponent activeTabName={tabPath} tabPrefix={`${PUBLIC_SHARE_PREFIX}/${linkId}`}>
                <OverviewComponent
                  group="overview"
                  title="Overview"
                  recordId={runId}
                  insightsbyGroups={insightsbyGroups}
                  elementsByGroups={elements}
                  doNotFetchData={true}
                  isMinified={false}
                />
                {groupsSet &&
                  [...groupsSet].map((group) => (
                    <GroupInsightsCardsComponent
                      key={group}
                      group={group}
                      title={groupToTitle(group)}
                      recordId={runId}
                      insights={insightsbyGroups[group]}
                      element={elements[group]}
                    />
                  ))}
              </TabsComponent>
            </React.Fragment>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: 'calc(100vh - 50px)' }}>
              <Typography variant="h1" color="greyColors.grey300">
                The public link was not found, please ask for a new link
              </Typography>
            </Stack>
          )}
        </Box>
      ) : (
        <LoaderComponent />
      )}
    </Box>
  );
}

PublicShareScreen.propTypes = {
  noTopBar: PropTypes.bool
};

export default PublicShareScreen;
