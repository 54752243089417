import { ACTIONS } from 'constants/actions';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';

import { loadClustersForDiscoveryAction } from './load-clusters-for-discovery-action';

function updateClustersProgress(searchId, progress) {
  return { type: ACTIONS.UPDATE_CLUSTERS_PROGRESS, searchId, progress };
}

export function monitorClustersProgressAction(searchId) {
  return (dispatch, _getState, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.tagging.fetchClustersProgress(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { progress } = res.data;
          oldDispatch(updateClustersProgress(searchId, progress));
          if (progress >= 100) {
            clearInterval(tickerId);
            dispatch(loadClustersForDiscoveryAction(searchId));
          }
        }
      });
    };
    tickerId = setInterval(ticker, 15000);
    ticker();
  };
}

function updateClustersStatuses(clustersId, statuses) {
  return { type: ACTIONS.UPDATE_CLUSTERS_STATUSES, statuses, clustersId };
}

export function monitorClustersStatusesAction(searchId) {
  return (_state, dispatch, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.tagging.getClustersStatuses(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { status, statuses } = res.data;
          if (status !== 'WAITING') {
            clearInterval(tickerId);
            return;
          }
          dispatch(updateClustersStatuses(searchId, statuses));
        }
      });
    };
    tickerId = setInterval(ticker, 20000);
    ticker();
  };
}
