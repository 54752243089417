export const SCHEDULE_FREQUENCIES = Object.freeze({
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
});

export const DEV_FREQUENCIES = Object.freeze([SCHEDULE_FREQUENCIES.DAILY]);

export const FREQUENCY_TO_DAYS = Object.freeze({
  [SCHEDULE_FREQUENCIES.DAILY]: 1,
  [SCHEDULE_FREQUENCIES.WEEKLY]: 7,
  [SCHEDULE_FREQUENCIES.MONTHLY]: 30
});

export const MAX_SCHEDULED_PER_LIST = 3;

export const SCHEDULE_TYPE = Object.freeze({
  DISCOVERY: 'DISCOVERY',
  COLUMN: 'COLUMN',
  CUSTOM_ANALLYTICS_COLUMN: 'CUSTOM_ANALLYTICS_COLUMN'
});
