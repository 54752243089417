import { Button, Menu, Stack } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import TableColumnFilterSection from 'components/elements/table/filters/TableColumnFilterSection';
import ColumnsIcon from 'icons/ColumnsIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateExploreHiddenColumns } from 'reducer/explore-slice';
import { getExploreHiddenColumnsById, getExploreViewByIdAndViewId } from 'selectors/explore';

function TableColumnsFilterComponent({ listId, viewId }) {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState();
  const hiddenColumns = useSelector((state) => getExploreHiddenColumnsById(state, listId));
  const view = useSelector((state) => getExploreViewByIdAndViewId(state, listId, viewId));

  const open = Boolean(anchorEl);
  const rawColumns = apiRef.current.getAllColumns();
  const allGroupsInfo = apiRef.current.unstable_getAllGroupDetails();

  const handleHiddenColumnChange = (columnsIds, isHidden) => {
    columnsIds.forEach((columnId) => {
      const hiddenInView = view && view?.hidden_columns?.includes(columnId);
      if (isHidden && hiddenInView) {
        searchParams.delete('hidden_columns', `!${columnId}`);
      } else if (isHidden) {
        searchParams.append('hidden_columns', columnId);
      } else if (hiddenInView) {
        searchParams.append('hidden_columns', `!${columnId}`);
      } else {
        searchParams.delete('hidden_columns', columnId);
      }
    });
    setSearchParams(searchParams);
    dispatch(updateExploreHiddenColumns({ listId, columnsIds, isHidden }));
  };

  const columnsAndGroups = useMemo(() => {
    const sections = {};
    rawColumns
      .filter((column) => !column.type.includes('custom_analytics_meta'))
      .forEach((column) => {
        const [sectionId] = column.groupPath;
        if (!sections[sectionId]) {
          sections[sectionId] = [];
        }
        sections[sectionId].push({ id: column.field, title: column.headerName });
      });
    return sections;
  }, [rawColumns]);

  const numberOfHiddenColumns = hiddenColumns
    ? Object.values(hiddenColumns).filter((isHidden) => isHidden).length
    : 0;

  return (
    <React.Fragment>
      <Button
        variant={numberOfHiddenColumns > 0 ? 'contained' : 'outlined'}
        sx={{ gap: '8px', padding: '4px 8px' }}
        onClick={(event) => setAnchorEl(event.currentTarget)}>
        <ColumnsIcon />
        Columns {numberOfHiddenColumns ? `(${numberOfHiddenColumns})` : ''}
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Stack direction="column" sx={{ overflowY: 'auto' }}>
          {Object.keys(columnsAndGroups)
            .filter((sectionId) => sectionId !== 'no-title')
            .map((sectionId) => {
              return (
                <TableColumnFilterSection
                  key={sectionId}
                  sectionTitle={allGroupsInfo[sectionId].headerName}
                  sectionColumns={columnsAndGroups[sectionId]}
                  hiddenColumns={hiddenColumns}
                  onClose={() => setAnchorEl(null)}
                  onHiddenColumnsChange={handleHiddenColumnChange}
                />
              );
            })}
        </Stack>
      </Menu>
    </React.Fragment>
  );
}

TableColumnsFilterComponent.propTypes = {
  listId: PropTypes.number,
  viewId: PropTypes.string
};

export default TableColumnsFilterComponent;
