import { Avatar, Card, CardMedia, Link, Stack, Typography } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import FacebookIcon from 'icons/FacebookIcon';
import LinkedinIcon from 'icons/LinkedinIcon';
import RerunIcon from 'icons/RerunIcon';
import TwitterIcon from 'icons/TwitterIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function PersonSuggestionTile({ suggestion, onRunSearch }) {
  return (
    <Stack gap="8px">
      <Card
        sx={{
          position: 'relative',
          padding: '24px',
          borderRadius: '4px',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'primary.white',
          display: 'flex',
          flexDirection: 'row',
          gap: '24px'
        }}>
        <Avatar
          alt={`${suggestion.name} image`}
          src={suggestion.person_image_url}
          sx={{ width: 80, height: 80 }}
        />
        <Stack direction="column" gap="12px">
          <Typography variant="h3" color="secondary.secondary6">
            {suggestion.name}
          </Typography>
          <Stack direction="row" gap="8px" alignItems="center">
            {suggestion.current_company_logo_url && (
              <CardMedia
                sx={{ width: '16px', height: '16px' }}
                component="img"
                image={suggestion.current_company_logo_url}
                alt={'the current company name'}
              />
            )}
            {suggestion.current_company_name && suggestion.role && (
              <Typography variant="paragraphMedium" color="greyColors.grey300">{`${
                suggestion.current_company_name
              }${suggestion.current_company_name && suggestion.role ? ' - ' : ''}${
                suggestion.role
              }`}</Typography>
            )}
          </Stack>
          <Stack direction="row" gap="8px" alignItems="center">
            {suggestion.linkedin_url && (
              <Link
                href={suggestion.linkedin_url}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <LinkedinIcon />
              </Link>
            )}
            {suggestion.facebook_url && (
              <Link
                href={suggestion.facebook_url}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <FacebookIcon />
              </Link>
            )}
            {suggestion.twitter_url && (
              <Link href={suggestion.twitter_url} underline="none" target="_blank" rel="noreferrer">
                <TwitterIcon />
              </Link>
            )}
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" justifyContent="flex-end" flex={1}>
          <IconButtonComponent
            onClick={onRunSearch}
            sx={{
              alignSelf: 'flex-end',
              backgroundColor: 'primary.primary3_50',
              color: 'primary.white',
              borderRadius: '4px',
              gap: '8px',
              '&:hover': {
                backgroundColor: 'primary.primary3_50'
              }
            }}>
            <RerunIcon />
            <Typography variant="paragraphMedium">Run Research</Typography>
          </IconButtonComponent>
        </Stack>
      </Card>
    </Stack>
  );
}

PersonSuggestionTile.propTypes = {
  suggestion: PropTypes.object,
  onRunSearch: PropTypes.func
};
