import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { appTheme } from 'theme';

export const dateStringToQuarter = (string) => {
  const date = new Date(string);
  const year = date.getFullYear();
  const quarter = Math.ceil((date.getMonth() + 1) / 3);
  return `${year}-Q${quarter}`;
};

export function monthsToQuartersData(labels, values) {
  const data = [];
  let currentValue = 0;
  let currentLabel = null;
  labels.forEach((label, index) => {
    const quarter = dateStringToQuarter(label);
    if (quarter !== currentLabel && currentLabel) {
      data.push({ label: currentLabel, value: currentValue });
      currentValue = 0;
    }
    currentValue += values[index];
    currentLabel = quarter;
  });
  if (currentValue !== 0) {
    data.push({ label: currentLabel, value: currentValue });
  }
  return data;
}

export function monthsToQuartersStackedData(labels, values) {
  const data = [];
  const initialValue = {};
  Object.keys(values).forEach((key) => {
    initialValue[key] = 0;
  });
  let currentValue = { ...initialValue };
  let currentLabel = null;
  labels.forEach((label, index) => {
    const quarter = dateStringToQuarter(label);
    if (quarter !== currentLabel && currentLabel) {
      data.push({ label: currentLabel, ...currentValue });
      currentValue = { ...initialValue };
    }
    Object.keys(initialValue).forEach((key) => {
      currentValue[key] += values[key][index];
    });
    currentLabel = quarter;
  });
  if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
    data.push({ label: currentLabel, ...currentValue });
  }
  return data;
}

/* eslint-disable id-length */
export function CustomizedAxisTick({ x, y, payload }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
}

/* eslint-disable id-length */
export function QuarterAxisTick({ x, y, payload }) {
  const { value } = payload;
  return (
    <text x={x} y={y + 4} textAnchor="middle" fill="#666">
      {value.split('-')[1]}
    </text>
  );
}

/* eslint-disable id-length */
export function MonthAxisTick({ x, y, payload }) {
  const { value } = payload;
  const date = new Date(value);
  const month = date.toLocaleString('default', { month: 'short' });
  return (
    <text x={x} y={y + 4} textAnchor="middle" fill="#666">
      {month}
    </text>
  );
}

/* eslint-disable id-length */
export function YearAxisTick({ x, y, payload }) {
  const { value, index } = payload;
  const ddate = new Date(value);
  const year = ddate.getFullYear();
  const month = ddate.getMonth();

  if (month !== 0 && index !== 0) return null;
  return (
    <text x={index === 0 ? x + 4 : x + 20} y={y} textAnchor="middle" fill="#666">
      {year}
    </text>
  );
}

/* eslint-disable id-length */
export function QuarterYearAxisTick({ x, y, payload }) {
  const { value, index } = payload;

  if (value.split('-')[1] !== 'Q1' && index !== 0) return null;
  return (
    <text x={index === 0 ? x + 4 : x + 20} y={y} textAnchor="middle" fill="#666">
      {value.split('-')[0]}
    </text>
  );
}

/* eslint-disable id-length */
export function BenchmarkAxisTick({ x, y, payload, benchmarkLabels }) {
  if (!benchmarkLabels) {
    return payload?.value;
  }
  const value = benchmarkLabels.find((label) => label?.name === payload.value);
  if (!value) {
    return payload?.value;
  }
  return (
    <g transform={`translate(${x - 25},${y})`}>
      <image
        href={value?.logo_url}
        x={value?.justLogo ? 16 : 0}
        y={7}
        height={value?.justLogo ? 22 : 16}
        width={value?.justLogo ? 22 : 16}
        preserveAspectRatio="none"
      />
      {!value?.justLogo && (
        <text
          x={value?.logo_url ? 20 : 0}
          y={0}
          dy={20}
          fill={appTheme.palette.greyColors.grey250}
          textAnchor="start">
          {value?.name}
        </text>
      )}
    </g>
  );
}

/* eslint-disable id-length */
export function renderBarChartLabel({ x, y, value }) {
  return (
    <text x={x} y={y - 8} fill={appTheme.palette.greyColors.grey250} textAnchor="start">
      {value}
    </text>
  );
}

export function renderBarChartLabelBenchmark({ x, y, value }) {
  return (
    <svg version="1.1">
      <image
        href={value?.logo_url}
        x={x}
        y={y - 20}
        height={16}
        width={16}
        preserveAspectRatio="none"
      />
      <text
        x={value?.logo_url ? x + 20 : x}
        y={y - 8}
        fill={appTheme.palette.greyColors.grey250}
        textAnchor="start">
        {value?.name}
      </text>
    </svg>
  );
}

export function renderBarChartLabelEnd({ x, y, width, value }) {
  return (
    <text x={x + width} y={y - 8} fill={appTheme.palette.greyColors.grey250} textAnchor="end">
      {value}%
    </text>
  );
}

renderBarChartLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string
};

export function renderBarChartLegend({ payload }) {
  return (
    <Stack direction="row" gap="16px" justifyContent="flex-end">
      {payload.map((entry) => (
        <Stack key={entry.dataKey} direction="row" gap="8px" alignItems="center">
          <Box
            component="span"
            sx={{ borderRadius: '50%', width: '8px', height: '8px', backgroundColor: entry.color }}
          />
          <Typography variant="paragraph" color="greyColors.grey250">
            {entry.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

renderBarChartLegend.propTypes = {
  payload: PropTypes.array
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

MonthAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

QuarterAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

YearAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

QuarterYearAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

BenchmarkAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  benchmarkLabels: PropTypes.array
};
