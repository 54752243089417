import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function LevelComponent({ level, showBgColor, shouldAdjustLevel }) {
  const adjustedLevel = shouldAdjustLevel ? Math.round((level / 10) * 4) : level;
  const borderPerIndex = { 1: '28px 0 0 28px', 4: '0 28px 28px 0' };
  const colorPerLevel = {
    1: 'sentiment.red',
    2: 'sentiment.yellow',
    3: 'sentiment.lightGreen',
    4: 'sentiment.darkGreen'
  };
  return (
    <Stack
      direction="row"
      sx={{
        padding: '1px',
        backgroundColor: showBgColor ? 'greyColors.grey50' : 'transparent',
        borderRadius: '4px',
        width: '112px'
      }}>
      {[1, 2, 3, 4].map((index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              flex: 1,
              maxWidth: '33px',
              height: '8px',
              backgroundColor:
                adjustedLevel >= index ? colorPerLevel[adjustedLevel] : 'primary.white',
              borderRadius: borderPerIndex[index] || 'none'
            }}
          />
          {index < 4 && <Box sx={{ width: '1px', backgroundColor: 'greyColors.grey50' }} />}
        </React.Fragment>
      ))}
    </Stack>
  );
}

LevelComponent.propTypes = {
  level: PropTypes.number,
  showBgColor: PropTypes.bool,
  shouldAdjustLevel: PropTypes.bool
};

LevelComponent.defaultProps = {
  showBgColor: true,
  shouldAdjustLevel: true
};

export default LevelComponent;
