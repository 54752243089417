import React from 'react';

export default function GoToIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.950323 2.36085C0.950323 1.57853 1.58853 0.944341 2.37581 0.944341H13.6242C14.4115 0.944341 15.0497 1.57854 15.0497 2.36085V13.6391C15.0497 14.4215 14.4115 15.0557 13.6242 15.0557H4.27555C4.01312 15.0557 3.80038 15.2671 3.80038 15.5278C3.80038 15.7886 4.01312 16 4.27555 16H13.6242C14.9363 16 16 14.943 16 13.6391V2.36085C16 1.05699 14.9363 0 13.6242 0H2.37581C1.06369 0 0 1.05699 0 2.36085V11.6507C0 11.9115 0.212737 12.1229 0.475162 12.1229C0.737586 12.1229 0.950323 11.9115 0.950323 11.6507V2.36085Z"
        fill="currentColor"
      />
      <path
        d="M4.68944 7.4881C4.42701 7.4881 4.21428 7.6995 4.21428 7.96027C4.21428 8.22104 4.42701 8.43244 4.68944 8.43244H6.92122L0.552154 14.7614C0.366592 14.9458 0.366592 15.2448 0.552154 15.4292C0.737716 15.6136 1.03857 15.6136 1.22413 15.4292L7.5932 9.10019V11.3179C7.5932 11.5787 7.80594 11.7901 8.06836 11.7901C8.33079 11.7901 8.54353 11.5787 8.54353 11.3179V7.96027C8.54353 7.6995 8.33079 7.4881 8.06836 7.4881H4.68944Z"
        fill="currentColor"
      />
    </svg>
  );
}
