import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { React, useState } from 'react';

function TextTooltip({ text, width }) {
  const [isHover, setIsHover] = useState(false);

  return (
    <Tooltip title={text} open={isHover}>
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        sx={{
          maxWidth: { width },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
        <Typography variant="paragraph">{text}</Typography>
      </Box>
    </Tooltip>
  );
}

export default TextTooltip;

TextTooltip.propTypes = {
  text: PropTypes.string,
  width: PropTypes.string
};
