import React from 'react';

export default function UploadIcon(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M37.6878 21.5439L37.7573 21.8943L38.1113 21.9421C39.9658 22.1926 41.6557 23.1386 42.8388 24.5885C44.0219 26.0384 44.6097 27.8837 44.4831 29.7507C44.3565 31.6178 43.525 33.3669 42.157 34.6438C40.7891 35.9207 38.9869 36.6299 37.1156 36.6277H37.115H12.1751C10.9928 36.6259 9.82319 36.3828 8.73805 35.9132C7.65291 35.4436 6.67503 34.7575 5.86427 33.8969C5.05352 33.0362 4.42696 32.0191 4.02295 30.9079C3.61894 29.7967 3.44599 28.6147 3.51467 27.4343C3.58336 26.254 3.89225 25.1 4.42242 24.0432C4.95259 22.9863 5.69289 22.0488 6.598 21.288C7.50311 20.5272 8.55397 19.9591 9.68625 19.6186C10.8185 19.2781 12.0084 19.1722 13.183 19.3075L13.5525 19.3501L13.6995 19.0085C14.765 16.5329 16.5945 14.4631 18.9206 13.1017C21.2466 11.7403 23.9472 11.1588 26.6274 11.442C29.3076 11.7253 31.8269 12.8586 33.8169 14.6762C35.807 16.4938 37.1634 18.9003 37.6878 21.5439Z"
        stroke="currentColor"
      />
      <path
        d="M24.7881 19.083C24.9048 18.9658 25.0952 18.9658 25.2119 19.083L27.8286 21.6992L27.1211 22.4062L25.5 20.7852V29.0046H24.5V20.7852L22.8789 22.4062L22.1714 21.6992L24.7881 19.083Z"
        fill="currentColor"
      />
    </svg>
  );
}
