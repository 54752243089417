export const ACTIONS = Object.freeze({
  ADD_USER_DATA: 'ADD_USER_DATA',
  ADD_USERS_DATA: 'ADD_USERS_DATA',
  ADD_RUNS_DATA: 'ADD_RUNS_DATA',
  ADD_RUN_SUMMARY: 'ADD_RUN_SUMMARY',
  ADD_RUN_INSIGHTS: 'ADD_RUN_INSIGHTS',
  ADD_RUN_ELEMENTS: 'ADD_RUN_ELEMENTS',
  ADD_PERSONS_DATA: 'ADD_PERSONS_DATA',
  ADD_VARIABLES: 'ADD_VARIABLES',
  ADD_RUN_CARD: 'ADD_RUN_CARD',
  ADD_PERSON_CARD: 'ADD_PERSON_CARD',
  ADD_NON_MATHLABS_SEARCHES: 'ADD_NON_MATHLABS_SEARCHES',
  UPDATE_PERSONS_SEARCH_AUTOCOMPLETE: 'UPDATE_PERSONS_SEARCH_AUTOCOMPLETE',
  REMOVE_PERSONS_SEARCH_AUTOCOMPLETE: 'REMOVE_PERSONS_SEARCH_AUTOCOMPLETE',
  UPDATE_RUN_STATUSES: 'UPDATE_RUN_STATUSES',
  UPDATE_RUN_PROGRESS: 'UPDATE_RUN_PROGRESS',
  UPDATE_DISCOVERY_STATUSES: 'UPDATE_DISCOVERY_STATUSES',
  UPDATE_DISCOVERY_PROGRESS: 'UPDATE_DISCOVERY_PROGRESS',
  UPDATE_PERSON_DATA: 'UPDATE_PERSON_DATA',
  UPDATE_PERSON_PROGRESS: 'UPDATE_PERSON_PROGRESS',
  UPDATE_CURRENT_SEARCH_AUTOCOMPLETE: 'UPDATE_CURRENT_SEARCH_AUTOCOMPLETE',
  CLEAR_CURRENT_SEARCH_AUTOCOMPLETE: 'CLEAR_CURRENT_SEARCH_AUTOCOMPLETE',
  UPDATE_CURRENT_SEARCH_AUTOCOMPLETE_STATE: 'UPDATE_CURRENT_SEARCH_AUTOCOMPLETE_STATE',
  SELECT_TARGET_COMPANY: 'SELECT_TARGET_COMPANY',
  CLEAR_CURRENT_SEARCH_AUTOCOMPLETE_AFTER_FAILURE:
    'CLEAR_CURRENT_SEARCH_AUTOCOMPLETE_AFTER_FAILURE',
  UPDATE_CURRENT_SEARCH_RUN: 'UPDATE_CURRENT_SEARCH_RUN',
  DELETE_RUN: 'DELETE_RUN',
  DELETE_PERSON: 'DELETE_PERSON',
  UPDATE_DEBUG_MODE: 'UPDATE_DEBUG_MODE',
  MAKE_PPTX: 'MAKE_PPTX',
  MAKE_RUN_FILES: 'MAKE_RUN_FILES',
  ADD_OPINION: 'ADD_OPINION',
  ADD_OPINIONS: 'ADD_OPINIONS',
  ADD_ALL_DISCOVERIES: 'ADD_ALL_DISCOVERIES',
  ADD_DISCOVERY: 'ADD_DISCOVERY',
  DELETE_DISCOVERY: 'DELETE_DISCOVERY',
  ADD_COMPANIES_TO_DISCOVERY: 'ADD_COMPANIES_TO_DISCOVERY',
  UPDATE_CLUSTERS_STATUS: 'UPDATE_CLUSTERS_STATUS',
  UPDATE_CURRENT_DISCOVERY_COMPANY_LIST_ID: 'UPDATE_CURRENT_DISCOVERY_COMPANY_LIST_ID',
  ADD_COMPANIES_FILTERS: 'ADD_COMPANIES_FILTERS',
  ADD_DISCOVERY_COMPANIES: 'ADD_DISCOVERY_COMPANIES',
  DELETE_DISCOVERY_COMPANIES_IDS: 'DELETE_DISCOVERY_COMPANIES_IDS',
  REMOVE_NOT_FOUND_COMPANY: 'REMOVE_NOT_FOUND_COMPANY',
  REPLACE_NOT_FOUND_COMPANY: 'REPLACE_NOT_FOUND_COMPANY',
  ADD_USER_SEARCHES: 'ADD_USER_SEARCHES',
  ADD_ORGANIZATIONS_DATA: 'ADD_ORGANIZATIONS_DATA',
  UPDATE_COMPANIES_LOADING: 'UPDATE_COMPANIES_LOADING',
  REMOVE_COMPANIES_LOADING: 'REMOVE_COMPANIES_LOADING',
  UPDATE_DISCOVERY_MISMATCH_COMPANIES: 'UPDATE_MISMATCH_COMPANIES',
  REPLACE_MISMATCHED_COMPANY: 'REPLACE_MISMATCHED_COMPANY',
  REPLACE_COMPANY_IN_DISCOVERY: 'REPLACE_COMPANY_IN_DISCOVERY',
  REMOVE_DISCOVERY_MISMATCH_COMPANY: 'REMOVE_DISCOVERY_MISMATCH_COMPANY',
  CLEAR_DISCOVERY_MISMATCH_COMPANIES: 'CLEAR_DISCOVERY_MISMATCH_COMPANIES',
  UPDATE_DISCOVERY_UI_STATE: 'UPDATE_DISCOVERY_UI_STATE',
  CLEAR_DISCOVERY_UI_STATE: 'CLEAR_DISCOVERY_UI_STATE',
  UPDATE_LIST_NAME: 'UPDATE_LIST_NAME',
  UPDATE_DISCOVERY_UI_CLICKED_OUTSIDE: 'UPDATE_DISCOVERY_UI_CLICKED_OUTSIDE',
  UPDATE_IGNORE_AMBIGUOUS_IN_DISCOVERY: 'UPDATE_IGNORE_AMBIGUOUS_IN_DISCOVERY',
  UPDATE_CURRENT_DISCOVERY_CLUSTER_LABEL: 'UPDATE_CURRENT_DISCOVERY_CLUSTER_LABEL',
  ADD_DISCOVERY_CLUSTERS: 'ADD_DISCOVERY_CLUSTERS',
  UPDATE_CLUSTERS_STATUSES: 'UPDATE_CLUSTERS_STATUSES',
  INCREASE_USER_SUBMISSIONS_COUNT: 'INCREASE_USER_SUBMISSIONS_COUNT',
  UPDATE_CLUSTERS_PROGRESS: 'UPDATE_CLUSTERS_PROGRESS',
  ADD_CLUSTERS_RECORDS: 'ADD_CLUSTERS_RECORDS',
  ADD_RECENTLY_FINISHED_RUN: 'ADD_RECENTLY_FINISHED_RUN',
  REMOVE_RECENTLY_FINISHED_RUN: 'REMOVE_RECENTLY_FINISHED_RUN',
  ADD_DISCOVERY_CUSTOM_COLUMN: 'ADD_DISCOVERY_CUSTOM_COLUMN',
  UPDATE_DISCOVERY_CUSTOM_COLUMN_DATA: 'UPDATE_DISCOVERY_CUSTOM_COLUMN_DATA',
  UPDATE_DISCOVERY_CUSTOM_COLUMN_NAME: 'UPDATE_DISCOVERY_CUSTOM_COLUMN_NAME',
  DELETE_DISCOVERY_CUSTOM_COLUMN_NAME: 'DELETE_DISCOVERY_CUSTOM_COLUMN_NAME',
  UPDATE_DISCOVERY_FILES: 'UPDATE_DISCOVERY_FILES',
  UPDATE_DISCOVERY_UI_COLUMN_WIDTH: 'UPDATE_DISCOVERY_UI_COLUMN_WIDTH',
  UPDATE_PPTX_IN_PROGRESS: 'UPDATE_PPTX_IN_PROGRESS',
  ADD_INDUSTRIES_MAPS: 'ADD_INDUSTRIES_MAPS',
  UPDATE_DISCOVERY_PARTIAL_COLUMN_DATA: 'UPDATE_DISCOVERY_PARTIAL_COLUMN_DATA',
  UPDATE_COLUMN_DATA_LOADING: 'UPDATE_COLUMN_DATA_LOADING',
  UPDATE_ALL_ADMIN_COMPANY_LISTS: 'UPDATE_ALL_ADMIN_COMPANY_LISTS',
  UPDATE_ADMIN_COMPANY_LIST: 'UPDATE_ADMIN_COMPANY_LIST',
  ADD_USER_ORGANIZATION_USERS_DATA: 'ADD_USER_ORGANIZATION_USERS_DATA',
  UPDATE_RESOURCE_LOADED: 'UPDATE_RESOURCE_LOADED'
});
