export const getFrequencyFromCron = (cron) => {
  const frequency = cron.split(' ');
  const checkIfNumber = (value) => {
    // eslint-disable-next-line wrap-regex
    return !/[*?]/u.test(value);
  };
  const hasMins = checkIfNumber(frequency[0]);
  const hasHours = checkIfNumber(frequency[1]);
  const hasDayOfMonth = checkIfNumber(frequency[2]);
  const hasMonth = checkIfNumber(frequency[3]);
  const hasDayOfWeek = checkIfNumber(frequency[4]);

  if (hasMins && hasHours && !hasDayOfMonth && !hasMonth && !hasDayOfWeek) {
    return 'Day';
  } else if (hasMins && hasHours && !hasDayOfMonth && !hasMonth && hasDayOfWeek) {
    return 'Week';
  } else if (hasMins && hasHours && hasDayOfMonth && !hasMonth && !hasDayOfWeek) {
    return 'Month';
  }
  return '';
};
