import { Box, Stack, Typography } from '@mui/material';
import UploadIcon from 'icons/UploadIcon';
import PropTypes from 'prop-types';
import { useRef } from 'react';

function RenderDropFileToTable({ value }) {
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    value?.handleFiles(files);
  };
  const resetInput = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  if (value?.uploadFiles && !value?.handleFiles) {
    return null;
  }
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="8px"
      marginRight="6px"
      width="100%">
      <Box display="flex" sx={{ color: 'secondary.secondary6' }} padding={0}>
        <UploadIcon style={{ width: '24px', height: '24px' }} />
      </Box>
      <Typography variant="paragraph" color="greyColors.grey300">
        Drag and drop files to the table, or{' '}
        <Typography
          variant="paragraphBold"
          color="primary.primary3_50"
          sx={{ cursor: 'pointer' }}
          onClick={() => fileInputRef.current.click()}>
          Browse
        </Typography>{' '}
        your computer
      </Typography>
      <input
        onChange={onFileInputChange}
        onClick={resetInput}
        ref={fileInputRef}
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
      />
    </Stack>
  );
}

RenderDropFileToTable.propTypes = {
  value: PropTypes.object
};

export default RenderDropFileToTable;
