import React from 'react';

export default function SendIcon() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        d="M1.5286 0.316017C1.42397 0.263689 1.30676 0.241774 1.1903 0.252765C1.07384 0.263757 0.962794 0.307213 0.869807 0.378191C0.776819 0.449169 0.705617 0.54482 0.664302 0.654262C0.622986 0.763705 0.613215 0.882546 0.6361 0.997267L2.38985 7.05977C2.42255 7.17275 2.48647 7.2742 2.57425 7.35248C2.66204 7.43076 2.77013 7.48268 2.8861 7.50227L9.9986 8.69352C10.3336 8.75977 10.3336 9.23977 9.9986 9.30602L2.8861 10.4973C2.77013 10.5169 2.66204 10.5688 2.57425 10.6471C2.48647 10.7253 2.42255 10.8268 2.38985 10.9398L0.6361 17.0023C0.613215 17.117 0.622986 17.2358 0.664302 17.3453C0.705617 17.4547 0.776819 17.5504 0.869807 17.6213C0.962794 17.6923 1.07384 17.7358 1.1903 17.7468C1.30676 17.7578 1.42397 17.7358 1.5286 17.6835L17.7786 9.55852C17.8823 9.50655 17.9694 9.42677 18.0304 9.3281C18.0913 9.22942 18.1236 9.11574 18.1236 8.99977C18.1236 8.8838 18.0913 8.77012 18.0304 8.67144C17.9694 8.57276 17.8823 8.49298 17.7786 8.44102L1.5286 0.316017Z"
        fill="currentColor"
      />
    </svg>
  );
}
