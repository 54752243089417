import { addPublicShareLink, deletePublicShareLink } from 'reducer/puclic-shares';
import { getRunPublicShareLinksData } from 'selectors/puclic-shares';

export function deletePublicShareLinkAction(runId, publicShareLinkId) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const existLinkData = getRunPublicShareLinksData(state, runId, publicShareLinkId);
    dispatch(deletePublicShareLink({ runId, publicShareLink: existLinkData }));
    apiClient.publicLinks
      .deletePublicLink(publicShareLinkId)
      .then((res) => {
        if (!res.ok) {
          dispatch(addPublicShareLink({ runId, publicShareLink: existLinkData }));
        }
      })
      .catch(() => {
        dispatch(addPublicShareLink({ runId, publicShareLink: existLinkData }));
      });
  };
}
