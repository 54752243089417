import NumberRangeFilterComponent from 'components/elements/table/filters/columns/components/NumberRangeFilterComponent';
import PropTypes from 'prop-types';
import React from 'react';

function CustomNumberColumnFilter({ ...args }) {
  return <NumberRangeFilterComponent {...args} rowMap={(value) => value?.value} />;
}

CustomNumberColumnFilter.propTypes = {
  rows: PropTypes.array
};

export default CustomNumberColumnFilter;
