import { translateListRecordForState } from 'actions/explore/load-all-explores-action';
import { ACTIONS } from 'constants/actions';

export function updateCurrentBulkCompanyList(list) {
  return { type: ACTIONS.UPDATE_CURRENT_BULK_COMPANY_LIST, list };
}

export function loadListAction(listId) {
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists.fetchList(listId).then((res) => {
      if (res.ok && res.data) {
        const companyList = translateListRecordForState(res.data);
        dispatch(updateCurrentBulkCompanyList(companyList));
      }
    });
  };
}
