import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import RenderUrlIcon from './RenderUrlIcon';

export default function RenderMultipleUrlIcons({ value }) {
  return (
    <Stack direction="row" alignItems="center">
      {value?.map((item, index) => (
        <RenderUrlIcon key={index} value={item.value} colDef={item.colDef} />
      ))}
    </Stack>
  );
}

RenderMultipleUrlIcons.propTypes = {
  value: PropTypes.array
};
