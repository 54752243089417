import { TOAST_TYPES } from 'constants/toasts';
import { updateTeam } from 'reducer/teams-slice';

export function addTeamAction(name, user_ids, addToast) {
  return (dispatch, _getState, apiClient) => {
    apiClient.teams
      .addTeam({ name, user_ids })
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updateTeam({ team: res.data }));
          addToast('Team Created', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to create team', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        addToast('Failed to create team', TOAST_TYPES.ERROR);
      });
  };
}
