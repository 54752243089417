export default function CustomAnalyticsSubmitIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5507_150282)">
        <path
          d="M20.9711 3.95437C21.0073 3.82608 21.0086 3.69047 20.9749 3.5615C20.9412 3.43253 20.8738 3.31487 20.7796 3.22062C20.6853 3.12637 20.5676 3.05893 20.4387 3.02527C20.3097 2.9916 20.1741 2.99291 20.0458 3.02906L2.0458 8.48719C1.89885 8.52866 1.76805 8.614 1.6709 8.7318C1.57375 8.84961 1.51487 8.99425 1.50213 9.14642C1.48939 9.29858 1.5234 9.45101 1.59961 9.58332C1.67582 9.71564 1.7906 9.82154 1.92861 9.88687L9.95549 13.6875C10.1118 13.7616 10.2376 13.8875 10.3117 14.0437L14.1133 22.0697C14.1786 22.2077 14.2845 22.3225 14.4169 22.3987C14.5492 22.4749 14.7016 22.5089 14.8538 22.4962C15.0059 22.4834 15.1506 22.4246 15.2684 22.3274C15.3862 22.2302 15.4715 22.0995 15.513 21.9525L20.9711 3.95437Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1646 13.8356L15.0002 9"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5507_150282">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
