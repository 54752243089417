import {
  ORGANIZATION_SELECTOR_ALL_VALUE,
  ORGANIZATION_SELECTOR_NONE_VALUE,
  ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE
} from 'constants/organizations';
import { MATHLABS_ORGANIZATION_ID } from 'constants/users';

export function non_mathlabs_filter(object, key) {
  return object[key]?.ownerOrganizationId !== MATHLABS_ORGANIZATION_ID;
}

export function none_organization_filter(object, key) {
  return object[key]?.ownerOrganizationId === null;
}

function organization_filter(object, key, organizationId) {
  return object[key]?.ownerOrganizationId === organizationId;
}

export function make_organization_filter(organizationId) {
  return function (object, key) {
    return organization_filter(object, key, organizationId);
  };
}

export function organization_selector_value_to_filter(value) {
  if (!value || value === ORGANIZATION_SELECTOR_ALL_VALUE) {
    return null;
  } else if (value === ORGANIZATION_SELECTOR_NON_MATHLABS_VALUE) {
    return non_mathlabs_filter;
  } else if (value === ORGANIZATION_SELECTOR_NONE_VALUE) {
    return none_organization_filter;
  }
  return make_organization_filter(value);
}
