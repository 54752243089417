import { loadAllUsers } from 'actions/users/load-all-users-action';

export function updateUserIsLimitedAction(userId, isLimited) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.updateUserIsLimited(userId, { is_limited: isLimited }).then((res) => {
      if (res.ok) {
        dispatch(loadAllUsers());
      }
    });
  };
}
