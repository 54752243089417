import { ACTIONS } from 'constants/actions';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { addAllInsightsAsColumns } from 'reducer/deep-dive';
import { normilzeElements } from 'utils/elements-utils';

import { loadCardData } from './load-card-data-action';

function updateInsights(runId, insights) {
  return { type: ACTIONS.ADD_RUN_INSIGHTS, runId, insights };
}

function updateElements(runId, elements) {
  return { type: ACTIONS.ADD_RUN_ELEMENTS, runId, elements };
}

export function handleOverviewData(runId, data, fetchCards = true) {
  return (reduxDispatch) => {
    const insights = {};
    const elements = {};
    data?.overview_groups.forEach((group) => {
      if (group.element || group.insights) {
        const groupType = group.overview_group_id;
        // const groupType = group.insights[0].insight_group;
        group.insights.forEach(
          (insight) => (insights[insight.insight_id] = { ...insight, insight_group: groupType })
        );
        elements[groupType] = { ...group.element };
      }
    });
    oldDispatch(updateInsights(runId, insights));
    oldDispatch(updateElements(runId, normilzeElements(elements)));

    reduxDispatch(addAllInsightsAsColumns({ deepDiveId: runId, insights: Object.keys(insights) }));
    if (fetchCards) {
      Object.keys(insights).forEach((insightId) => {
        oldDispatch(loadCardData(runId, insightId));
      });
    }
  };
}

export function loadOverviewData(runId) {
  return (reduxDispatch, _getState, apiClient) => {
    apiClient.runs.fetchOverviewData(runId).then((res) => {
      if (res.ok) {
        const { data } = res;
        reduxDispatch(handleOverviewData(runId, data));
      }
    });
  };
}
