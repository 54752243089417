import { ACTIONS } from 'constants/actions';
import { RESOURCE_TYPES } from 'constants/resources';
import { isForbidden } from 'utils/error-utils';

function updateRunSummary(recordId, data) {
  return { type: ACTIONS.ADD_RUN_SUMMARY, recordId, data };
}

export function handleSummaryData(recordId, data) {
  return (_state, dispatch) => {
    const {
      name,
      company_website: domain,
      headquarters: hq,
      employees_lower: employeesLower,
      employees_upper: employeesUpper,
      description,
      linkedin_name: linkedinName,
      linkedin_url: linkedinUrl,
      year_founded: yearFounded,
      logo_url: logoUrl,
      time_submitted: timeSubmitted,
      id,
      record_id: responseRecordId,
      parameters,
      statuses,
      company_meta_id: companyMetaId
    } = data;
    const size = employeesUpper ? `${employeesLower}-${employeesUpper}` : employeesLower;
    dispatch(
      updateRunSummary(recordId, {
        name,
        id,
        recordId: responseRecordId,
        domain,
        hq,
        description,
        size,
        linkedinName,
        linkedinUrl,
        yearFounded,
        logoUrl,
        timeSubmitted,
        parameters,
        statuses,
        companyMetaId
      })
    );
  };
}

export function loadRunSummary(recordId, forbiddenResource) {
  return (_state, dispatch, apiClient) => {
    apiClient.runs.fetchSummary(recordId).then((res) => {
      if (res.ok) {
        dispatch(handleSummaryData(recordId, res.data));
      } else if (isForbidden(res.status) && forbiddenResource) {
        forbiddenResource((recordId, RESOURCE_TYPES.DEEP_DIVE));
      }
    });
  };
}
