import { addDeepDiveDynamicCard, deleteDeepDiveDynamicCard } from 'reducer/deep-dive';
import { getDeepDiveDynamicCardDataById } from 'selectors/deep-dive';

export function deleteRunDynamicCardAction(deepDiveId, cardId) {
  return (dispatch, getState, apiClient) => {
    const cardData = getDeepDiveDynamicCardDataById(getState(), deepDiveId, cardId);
    dispatch(deleteDeepDiveDynamicCard({ deepDiveId, cardId }));
    apiClient.runs
      .deleteDynamicCard(deepDiveId, cardId)
      .then((res) => {
        if (!res.ok) {
          dispatch(addDeepDiveDynamicCard({ deepDiveId, card: { id: cardId, data: cardData } }));
        }
      })
      .catch(() => {
        dispatch(addDeepDiveDynamicCard({ deepDiveId, card: { id: cardId, data: cardData } }));
      });
  };
}
