import PropTypes from 'prop-types';
import React from 'react';

function CopyIcon({ color }) {
  return (
    <svg height="24" viewBox="0 -960 960 960" width="24">
      <path
        d="M364.615-280Q337-280 318.5-298.5 300-317 300-344.615v-430.77Q300-803 318.5-821.5 337-840 364.615-840h310.77Q703-840 721.5-821.5 740-803 740-775.385v430.77Q740-317 721.5-298.5 703-280 675.385-280h-310.77Zm0-40h310.77q9.231 0 16.923-7.692Q700-335.385 700-344.615v-430.77q0-9.23-7.692-16.923Q684.616-800 675.385-800h-310.77q-9.23 0-16.923 7.692Q340-784.615 340-775.385v430.77q0 9.23 7.692 16.923Q355.385-320 364.615-320Zm-120 160Q217-160 198.5-178.5 180-197 180-224.615v-470.77h40v470.77q0 9.231 7.692 16.923Q235.384-200 244.615-200h350.77v40h-350.77ZM340-320v-480 480Z"
        fill={color}
      />
    </svg>
  );
}

CopyIcon.propTypes = {
  color: PropTypes.string
};

CopyIcon.defaultProps = {
  color: '#0B1C38'
};

export default CopyIcon;
