import React from 'react';

export default function OutlinedInfoIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8" r="6.5" stroke="currentColor" />
      <rect
        x="9.16675"
        y="12.1023"
        width="1.33333"
        height="5.33333"
        rx="0.666667"
        transform="rotate(-180 9.16675 12.1023)"
        fill="currentColor"
      />
      <ellipse
        cx="8.50004"
        cy="4.73112"
        rx="0.833333"
        ry="0.833333"
        transform="rotate(-180 8.50004 4.73112)"
        fill="currentColor"
      />
    </svg>
  );
}
