import BaseClient from './BaseClient';

const URL_PREFIX = 'teams';

export default class TeamsClient extends BaseClient {
  fetchTeams() {
    return this.get(URL_PREFIX);
  }

  updateTeam(teamId, payload) {
    return this.put(`${URL_PREFIX}/${teamId}`, payload);
  }

  addTeam(payload) {
    return this.post(URL_PREFIX, payload);
  }

  deleteTeam(teamId) {
    return this.httpDelete(`${URL_PREFIX}/${teamId}`);
  }
}
