import React from 'react';

export default function DownloadIcon({ ...args }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...args}>
      <path
        d="M6.67912 10.9014C6.63723 10.8687 6.60067 10.8295 6.57098 10.7853L3.11771 7.33205C2.92245 7.13679 2.92245 6.82021 3.11771 6.62495C3.31297 6.42968 3.62956 6.42968 3.82482 6.62495L6.48963 9.28976L6.51384 0.505458C6.5146 0.229317 6.73907 0.00607692 7.01522 0.00683788C7.29136 0.00759883 7.5146 0.232073 7.51384 0.508214L7.48951 9.33413L10.1889 6.63471C10.3842 6.43945 10.7008 6.43945 10.896 6.63471C11.0913 6.82997 11.0913 7.14656 10.896 7.34182L7.3605 10.8774C7.1736 11.0643 6.87554 11.0723 6.67912 10.9014Z"
        fill="currentColor"
      />
      <path
        d="M13.5 9.49365C13.5 9.21751 13.2761 8.99365 13 8.99365C12.7239 8.99365 12.5 9.21751 12.5 9.49365V12.4935C12.5 12.7697 12.2761 12.9935 12 12.9935L2 12.9934C1.72386 12.9934 1.5 12.7696 1.5 12.4934V9.49369C1.5 9.21755 1.27614 8.99369 1 8.99369C0.723858 8.99369 0.5 9.21755 0.5 9.49369V12.4934C0.5 13.3219 1.17157 13.9934 1.99999 13.9934L12 13.9935C12.8284 13.9935 13.5 13.3219 13.5 12.4935V9.49365Z"
        fill="currentColor"
      />
    </svg>
  );
}
