import React from 'react';

export default function TwitterIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="8" fill="#55ACEE" />
      <path
        d="M13.5 4.58851C13.0881 4.77099 12.6512 4.89076 12.2038 4.94386C12.6698 4.66456 13.0276 4.22229 13.1961 3.69532C12.7531 3.95819 12.2684 4.14341 11.763 4.24295C11.3513 3.80437 10.7649 3.53027 10.1157 3.53027C8.86939 3.53027 7.85889 4.54073 7.85889 5.78699C7.85889 5.96389 7.87887 6.13611 7.91733 6.30133C6.04174 6.20718 4.37885 5.30875 3.26575 3.94338C3.07153 4.27668 2.96024 4.66439 2.96024 5.07792C2.96024 5.8609 3.35869 6.55162 3.9642 6.95634C3.60582 6.94512 3.25533 6.84832 2.94198 6.67404C2.94185 6.68349 2.94185 6.69295 2.94185 6.70244C2.94185 7.79587 3.71975 8.70801 4.75212 8.91533C4.4198 9.00571 4.07121 9.01894 3.73299 8.954C4.02015 9.85059 4.85361 10.503 5.84112 10.5213C5.06876 11.1265 4.09564 11.4874 3.03836 11.4874C2.85617 11.4874 2.67656 11.4767 2.5 11.4558C3.49872 12.0961 4.68496 12.4697 5.95941 12.4697C10.1105 12.4697 12.3804 9.03092 12.3804 6.04871C12.3804 5.95083 12.3783 5.85351 12.3739 5.75674C12.8157 5.43735 13.197 5.04175 13.5 4.58851Z"
        fill="white"
      />
    </svg>
  );
}
