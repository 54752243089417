import { addDiscoveryPostSuggestionsToThread } from 'reducer/custom-analytics';

export function getDiscoverySuggestionsAction(threadKey, postId, query) {
  return (dispatch, getState, apiClient) => {
    apiClient.discoveries.getDiscoverySuggestions(query).then((res) => {
      if (res.ok && res.data?.suggestions) {
        dispatch(
          addDiscoveryPostSuggestionsToThread({
            threadKey,
            postId,
            suggestions: res.data.suggestions
          })
        );
      }
    });
  };
}
