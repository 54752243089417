import { Stack, TextField, Typography } from '@mui/material';
import RechartsBox from 'components/elements/RechartsBox';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Bar, BarChart, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';

import ArtBartChartTooltip from './ArtBartChartTooltip';

const prettifyNumber = (num) => {
  if (num > 1) {
    return Math.round(num).toString();
  }
  return num.toPrecision(2);
};

// eslint-disable-next-line id-length
function renderBarChartLabel({ x, y, value }) {
  return (
    <text x={x} y={y - 8} fill={appTheme.palette.greyColors.grey250} textAnchor="start">
      ${prettifyNumber(value / 1000)}k
    </text>
  );
}

function ArtBarTitle({ targetProfit, setTargetProfit }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
      <Typography variant="paragraphSemiBold">Maximum buy price today for</Typography>
      <TextField
        size="small"
        type="number"
        value={targetProfit}
        variant="standard"
        textAlign="center"
        sx={{
          width: '50px',
          padding: '6px',
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          },
          'input[type=number]': {
            MozAppearance: 'textfield'
          }
        }}
        onChange={(event) => setTargetProfit(event.target.value)}
        inputProps={{ min: 0, style: { textAlign: 'center' } }}
      />
      <Typography variant="paragraphSemiBold">% profits per holding period</Typography>
    </Stack>
  );
}

ArtBarTitle.propTypes = {
  targetProfit: PropTypes.number.isRequired,
  setTargetProfit: PropTypes.func.isRequired
};

/* eslint-disable id-length */
export default function ArtBarChart({ finalPredictions }) {
  const [targetProfit, setTargetProfit] = useState(40);
  const curentYear = new Date().getFullYear();
  const predictionsData =
    finalPredictions &&
    Object.keys(finalPredictions)
      .filter((year) => year > curentYear)
      .map((year) => ({
        year: parseInt(year, 10) - curentYear,
        price: finalPredictions[year] / (1 + targetProfit / 100),
        predictedPrice: finalPredictions[year]
      }));

  return (
    <RechartsBox
      title={<ArtBarTitle targetProfit={targetProfit} setTargetProfit={setTargetProfit} />}
      mode="overview"
      height={400}>
      <BarChart
        style={{
          fontSize: appTheme.typography.paragraph.fontSize,
          color: appTheme.palette.greyColors.grey100,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.paragraph.fontWeight
        }}
        data={predictionsData}
        layout="horizontal"
        margin={{
          top: 20,
          right: 40,
          bottom: 40,
          left: 40
        }}>
        <XAxis
          type="number"
          dataKey="year"
          name="year"
          allowDecimals={false}
          domain={['dataMin-1', 'dataMax+1']}
          label={{
            value: 'Holding Period (Years)',
            position: 'bottom',
            style: {
              textAnchor: 'middle'
            },
            fill: appTheme.palette.greyColors.grey250
          }}
          ticks={predictionsData.map((data) => data.year)}
        />
        <YAxis
          type="number"
          dataKey="price"
          name="price"
          tickFormatter={(tick) => `${Math.floor(tick / 1000)}k`}
          allowDecimals={false}
          label={{
            value: 'Value (USD)',
            position: 'insideLeft',
            angle: -90,
            style: {
              textAnchor: 'middle'
            },
            fill: appTheme.palette.greyColors.grey250,
            offset: -10
          }}
          domain={['dataMin - 5000', 'dataMax + 5000']}
        />

        <Bar maxBarSize={24} dataKey="price" fill="#8884d8" radius={2} barSize={24}>
          <LabelList dataKey="price" content={renderBarChartLabel} />
        </Bar>

        <Tooltip cursor={{ fill: 'transparent' }} content={<ArtBartChartTooltip />} />
      </BarChart>
    </RechartsBox>
  );
}

ArtBarChart.propTypes = {
  finalPredictions: PropTypes.object,
  table: PropTypes.object,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
