import { loadNotificationByIdAction } from 'actions/notifications/load-user-notification-by-id-action';
import { usePusher } from 'hooks/PusherProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserId } from 'selectors/user';
import { getHomePageChannelName } from 'utils/pusher-utils';

export default function PusherHomePageListener() {
  const pusher = usePusher();
  const userId = useOldStateSelector(getUserId);
  const [channel, setChannel] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!pusher || !userId) {
      return;
    }
    const channelName = getHomePageChannelName(userId);
    const homepageChannel = pusher.subscribe(channelName);
    setChannel(homepageChannel);

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [userId, pusher]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    channel.bind('notifications', (data) => {
      const { notification_id } = data;
      dispatch(loadNotificationByIdAction(notification_id));
    });
  }, [channel, dispatch]);

  return null;
}
