import React from 'react';

export default function CompanyHealthIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.513 3.7356H10.073C9.72301 3.7356 9.44301 4.0156 9.44301 4.3656V33.5156H33.563C33.913 33.5156 34.193 33.2356 34.193 32.8856V8.4156C34.193 8.1656 34.093 7.9156 33.913 7.7456L30.183 4.0156C30.003 3.8356 29.763 3.7356 29.513 3.7356Z"
        fill="#8EBCFC"
      />
      <path
        d="M28.003 4.8256H8.33301V34.6056H33.083V9.9056C33.083 9.7056 33.003 9.5156 32.863 9.3756L28.533 5.0456C28.393 4.9056 28.203 4.8256 28.003 4.8256Z"
        fill="white"
      />
      <path
        d="M31.3128 35.5556C31.6628 35.5556 31.9428 35.2756 31.9428 34.9256V10.8156H27.5428C27.1928 10.8156 26.9128 10.5356 26.9128 10.1856V5.77561H7.82284C7.47284 5.77561 7.19284 6.05561 7.19284 6.40561V34.9256C7.19284 35.2756 7.47284 35.5556 7.82284 35.5556H31.3128Z"
        fill="white"
      />
      <path
        d="M31.3228 35.8356H7.82284C7.32284 35.8356 6.91284 35.4256 6.91284 34.9256V6.40561C6.91284 5.90561 7.32284 5.49561 7.82284 5.49561H26.9128C27.0628 5.49561 27.1928 5.61561 27.1928 5.76561V10.1656C27.1928 10.3656 27.3528 10.5256 27.5428 10.5256H31.9428C32.0928 10.5256 32.2228 10.6456 32.2228 10.7956V34.9156C32.2228 35.4156 31.8128 35.8256 31.3128 35.8256L31.3228 35.8356ZM7.82284 6.05561C7.62284 6.05561 7.46284 6.21561 7.46284 6.41561V34.9356C7.46284 35.1356 7.62284 35.2956 7.82284 35.2956H31.3228C31.5228 35.2956 31.6728 35.1356 31.6728 34.9356V11.0856H27.5428C27.0428 11.0856 26.6328 10.6756 26.6328 10.1756V6.05561H7.82284Z"
        fill="#374EF4"
      />
      <path
        d="M31.9528 10.8156L26.9228 5.78561V10.3156C26.9228 10.5956 27.1528 10.8156 27.4228 10.8156H31.9528Z"
        fill="#86AD28"
      />
      <path
        d="M31.9528 11.0856H27.4228C26.9928 11.0856 26.6428 10.7356 26.6428 10.3056V5.77562C26.6428 5.66562 26.7128 5.56562 26.8128 5.52562C26.9128 5.48562 27.0328 5.50562 27.1128 5.58562L32.1428 10.6156C32.2228 10.6956 32.2428 10.8156 32.2028 10.9156C32.1628 11.0156 32.0628 11.0856 31.9528 11.0856ZM27.1928 6.44562V10.3156C27.1928 10.4456 27.2928 10.5456 27.4228 10.5456H31.2928L27.1928 6.44562Z"
        fill="#86AD28"
      />
      <path
        d="M17.7729 21.9856C17.7729 21.4956 17.9629 21.0456 18.3029 20.6956C18.3829 20.6156 18.4629 20.5456 18.5629 20.4856C18.7329 20.3756 19.4029 20.0456 20.1929 19.6556L22.4229 18.5656L22.3229 18.4656L23.2129 17.5956C22.4729 17.0256 21.5829 16.6456 20.6129 16.5056V17.6456H18.9729V16.5056C17.9229 16.6656 16.9629 17.1156 16.1829 17.7756L16.9729 18.5456L15.8229 19.7156L15.0929 19.0056C14.7129 19.5956 14.4429 20.2556 14.2929 20.9656H15.3129L15.3329 22.5956H14.2129C14.2929 23.4956 14.5929 24.3256 15.0429 25.0456L18.0829 22.9756C17.8929 22.6856 17.7829 22.3356 17.7829 21.9756L17.7729 21.9856Z"
        fill="#86AD28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0041 21.4322L24.593 21.4155V22.1555L26.0314 22.1723C26.0087 23.2302 25.7217 24.2605 25.1982 25.1668L24.473 24.6855C24.353 24.6055 24.183 24.6355 24.093 24.7655C24.013 24.8955 24.043 25.0655 24.173 25.1455L25.1046 25.7638C25.1135 25.7717 25.1229 25.7789 25.1329 25.7856L27.8429 27.5856C27.8568 27.596 27.873 27.6052 27.8904 27.6128L28.663 28.1255C28.703 28.1655 28.763 28.1755 28.813 28.1755V28.1655C28.903 28.1655 28.993 28.1255 29.043 28.0455C29.123 27.9155 29.093 27.7455 28.963 27.6655L28.3637 27.2678C29.326 25.702 29.8329 23.9009 29.8329 22.0456C29.8329 16.5056 25.3229 11.9956 19.7829 11.9956C14.2429 11.9956 9.73291 16.5056 9.73291 22.0456C9.73291 23.8983 10.2345 25.7054 11.2029 27.2839L10.6129 27.6755C10.4829 27.7555 10.4529 27.9255 10.5329 28.0555C10.5829 28.1355 10.6729 28.1755 10.7629 28.1755V28.1855C10.8129 28.1855 10.9129 28.1355 10.9129 28.1355L15.4029 25.1555C15.5329 25.0755 15.5629 24.9055 15.4829 24.7755C15.4029 24.6455 15.2329 24.6155 15.1029 24.6955L14.3746 25.1789C13.8483 24.2725 13.559 23.2412 13.5346 22.182L14.8629 22.1655H14.8529V21.4255L13.5609 21.442C13.6769 20.2187 14.1476 19.0958 14.8692 18.1784L15.8029 19.0955L16.3229 18.5655H16.3129L15.3577 17.627C16.4081 16.5724 17.8319 15.8892 19.4128 15.7964V17.1956H20.1528V15.7964C21.6346 15.883 22.9783 16.4867 24.0059 17.4294L22.9529 18.4656L23.4729 18.9956L24.5265 17.9673C25.3423 18.9176 25.8778 20.1154 26.0041 21.4322ZM27.916 26.9706C28.814 25.4918 29.2929 23.7936 29.2929 22.0456C29.2929 16.8056 25.0329 12.5456 19.7929 12.5456C14.5529 12.5456 10.2929 16.8056 10.2929 22.0456C10.2929 23.7946 10.7624 25.4936 11.6713 26.973L13.92 25.4806C13.3119 24.4418 12.9929 23.2636 12.9929 22.0456C12.9929 18.2956 16.0429 15.2456 19.7929 15.2456C23.5429 15.2456 26.5929 18.2956 26.5929 22.0456C26.5929 23.2527 26.2744 24.4399 25.6673 25.4782L27.916 26.9706Z"
        fill="#374EF4"
      />
      <path
        d="M18.7329 22.7957C18.2329 22.2957 18.2329 21.4857 18.7329 20.9857C18.7829 20.9357 18.8429 20.8857 18.9129 20.8457C19.0325 20.766 19.5981 20.4875 20.0786 20.251C20.3212 20.1315 20.5421 20.0228 20.6729 19.9557L22.4429 19.0857L21.5729 20.8557L21.4781 21.0512C21.2381 21.5464 20.7955 22.4598 20.6829 22.6157C20.6429 22.6757 20.5929 22.7357 20.5429 22.7957C20.0429 23.2957 19.2329 23.2957 18.7329 22.7957Z"
        fill="#374EF4"
      />
      <path
        d="M23.4829 26.1558H15.6629C15.5303 26.1558 15.4229 26.2632 15.4229 26.3958V27.1258C15.4229 27.2583 15.5303 27.3658 15.6629 27.3658H23.4829C23.6154 27.3658 23.7229 27.2583 23.7229 27.1258V26.3958C23.7229 26.2632 23.6154 26.1558 23.4829 26.1558Z"
        fill="#374EF4"
      />
    </svg>
  );
}
