import { ICON_VARIANTS } from 'constants/icons';
import PropTypes from 'prop-types';
import React from 'react';

function AddIcon({ variant }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6663 9.99989C16.6663 10.23 16.4798 10.4166 16.2497 10.4166H10.4166V16.2503C10.4166 16.4804 10.23 16.667 9.99993 16.667C9.76981 16.667 9.58326 16.4804 9.58326 16.2503V10.4166H3.74967C3.51956 10.4166 3.33301 10.23 3.33301 9.99989C3.33301 9.76977 3.51956 9.58322 3.74967 9.58322H9.58326V3.75028C9.58326 3.52017 9.76981 3.33362 9.99993 3.33362C10.23 3.33362 10.4166 3.52017 10.4166 3.75028V9.58322L16.2497 9.58322C16.4798 9.58322 16.6663 9.76977 16.6663 9.99989Z"
        fill={variant === ICON_VARIANTS.PRIMARY ? '#584CA7' : 'white'}
      />
    </svg>
  );
}

AddIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_VARIANTS))
};

AddIcon.defaultProps = {
  variant: ICON_VARIANTS.PRIMARY
};

export default AddIcon;
