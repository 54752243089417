import { renameFolder } from 'reducer/folders-slice';
import { getFolderNameById } from 'selectors/folders';

export function renameFolderByIdAction(folderId, newFolderName) {
  return (dispatch, getState, apiClient) => {
    const currentFolderName = getFolderNameById(getState(), folderId);
    if (currentFolderName !== newFolderName) {
      dispatch(renameFolder({ folderId, folderName: newFolderName }));
      apiClient.folders
        .renameFolder(folderId, newFolderName)
        .then((res) => {
          if (!res.ok) {
            dispatch(renameFolder({ folderId, folderName: currentFolderName }));
          }
        })
        .catch(() => dispatch(renameFolder({ folderId, folderName: currentFolderName })));
    }
  };
}
