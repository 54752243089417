import { getCurrentSortableTimestamp } from 'utils/time-utils';

export function sendUserEvent(eventType, context = {}) {
  return (_state, _dispatch, apiClient) => {
    if (!process.env.REACT_APP_DISABLE_DATADOG) {
      apiClient?.users?.sendUserEvent(eventType, context);
    }
  };
}

export function sendExploreEvent(eventType, listId, message, context = {}) {
  return (_state, _dispatch, apiClient) => {
    if (!process.env.REACT_APP_DISABLE_DATADOG) {
      const timestamp = getCurrentSortableTimestamp();
      apiClient?.users?.sendUserEvent(eventType, {
        ...context,
        listId: listId,
        message: `${timestamp} || ${message}`,
        timestamp,
        exploreMetric: 1
      });
    }
  };
}
