import { useEffect, useState } from 'react';

export function useScrollPosition(scrollElement) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handler = () => {
      if (scrollElement) {
        setScrollPosition(scrollElement.scrollTop);
      } else {
        setScrollPosition(document.body.scrollTop || document.documentElement.scrollTop);
      }
    };
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handler);
    } else {
      window.addEventListener('scroll', handler);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handler);
      } else {
        window.removeEventListener('scroll', handler);
      }
    };
  }, [scrollElement]);

  return scrollPosition;
}
