import React from 'react';

export default function BackIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3585 7.52892L14.2979 6.46826L9.70178 11.0643L9.70167 11.0642L8.64101 12.1249L8.64112 12.125L8.64101 12.1251L9.70167 13.1858L9.70178 13.1857L14.2979 17.7817L15.3585 16.7211L10.7624 12.125L15.3585 7.52892Z"
        fill="currentColor"
      />
    </svg>
  );
}
