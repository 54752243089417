import { loadPersonCard } from 'actions/persons/load-person-card-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import ModalComponent from 'components/modal/ModalComponent';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { getPersonCardById, getPersonImageUrlById, getPersons } from 'selectors/persons';
import { isEmpty } from 'utils/objects-utils';

import PeopleCardInnerComponent from './PeopleCardInnerComponent';

function PersonCardDialog({ personId, open, closeModal, isResource }) {
  const { state } = useAppState();
  const personData = getPersonCardById(state, personId);
  const personImageUrl = getPersonImageUrlById(state, personId);
  const persons = getPersons(state);

  // Use picture from tile where possible
  if (personData) {
    personData.pic_url = personImageUrl || personData.pic_url;
  }

  useEffect(() => {
    if (open && isEmpty(personData)) {
      dispatch(loadPersonCard(personId));
    }
  }, [personId, open, personData]);

  return (
    <ModalComponent
      open={open}
      onClose={closeModal}
      title={personData?.name || persons?.[personId]?.name}>
      <PeopleCardInnerComponent
        personId={personId}
        personData={personData}
        mode="normal"
        isResource={isResource}
      />
    </ModalComponent>
  );
}

PersonCardDialog.propTypes = {
  personId: PropTypes.number,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  isResource: PropTypes.bool
};

export default wrapWithError(PersonCardDialog);
