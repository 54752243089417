import { Box, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

function SelectableButton({ isSelected, onClick, children, tooltip, allowOnly }) {
  const [isHover, setIsHover] = useState(false);

  return (
    <Tooltip title={tooltip} disableHoverListener={!tooltip}>
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        sx={{ position: 'relative' }}>
        {isHover && allowOnly && (
          <Box
            onClick={() => onClick(true)}
            sx={{
              borderRadius: '4px',
              borderColor: 'primary.primary100',
              position: 'absolute',
              padding: '2px',
              bottom: 0,
              left: 0,
              right: 0,
              transform: 'translateY(-36px)',
              cursor: 'pointer'
            }}>
            <Typography
              variant="paragraph"
              sx={{
                color: 'greyColors.grey300',
                '&:hover': {
                  color: 'primary.primary50'
                }
              }}>
              only
            </Typography>
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          padding="6px"
          border="1px solid"
          borderColor={isSelected ? 'primary.primary50' : 'background.bg150'}
          borderRadius="4px"
          bgcolor="background.bg50"
          sx={{
            cursor: 'pointer',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 4px rgba(98, 103, 123, 0.25)'
          }}
          onClick={() => onClick(false)}>
          {children}
        </Stack>
      </Box>
    </Tooltip>
  );
}

SelectableButton.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  children: PropTypes.any,
  allowOnly: PropTypes.bool
};

export default SelectableButton;
