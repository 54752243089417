import { Box, Divider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { formatSize } from 'utils/art-utils';

import ArtTargetDetail from './ArtTargetDetail';

function TargetArtwork({ artwork }) {
  return (
    <Stack
      direction="row"
      gap="32px"
      alignItems="top"
      sx={{
        padding: '16px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
        backgroundColor: 'primary.white',
        gap: '16px'
      }}>
      <Box
        component="img"
        src={
          artwork?.image_url?.split(' ')[0] ||
          'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
        }
        width={200}
        height={200}
      />

      <Stack gap="8px" justifyContent="start" textAlign="left">
        <Typography variant="h6">{artwork.title}</Typography>
        <Typography variant="paragraphBold">{artwork.artist_name}</Typography>
        <Divider />

        <ArtTargetDetail
          title="Size"
          text={artwork.size ? formatSize(artwork.size) : 'Unknown'}
          collapse={false}
        />
        <ArtTargetDetail title="Execution Year" text={artwork.execution_year} collapse={false} />
        <ArtTargetDetail
          title="Artwork's age at auction"
          text={artwork.transactions.map((transactionMapped) => transactionMapped.age).at(-1)}
          collapse={false}
        />
        <ArtTargetDetail
          title="Medium"
          text={`${artwork.medium_higher_level || 'Unknown'} - ${
            artwork.medium_lower_level || 'Unknown'
          }`}
          collapse={false}
        />
        <ArtTargetDetail title="Material" text={artwork.material} collapse={false} />
        <ArtTargetDetail
          title="Signatue"
          text={artwork.signature ? 'True' : `${artwork.signature === false ? 'False' : ''}`}
          collapse={false}
        />
        <ArtTargetDetail
          title="Authenticity verified"
          text={
            artwork.authenticity_verified
              ? 'True'
              : `${artwork.authenticity_verified === false ? 'False' : ''}`
          }
          collapse={false}
        />
        <ArtTargetDetail
          title="Height to width ratio"
          text={(artwork.size[0] / artwork.size[1]).toFixed(2)}
          collapse={false}
        />
        <ArtTargetDetail
          title="Previous prestige owners"
          text={
            artwork.transactions
              .map((transactionMapped) => transactionMapped.had_prestige_owner)
              .at(-1)
              ? 'True'
              : `${
                  artwork.transactions
                    .map((transactionMapped) => transactionMapped.had_prestige_owner)
                    .at(-1) === false
                    ? 'False'
                    : ''
                }`
          }
          collapse={false}
        />
        <ArtTargetDetail
          title="Recent prestige owners"
          text={
            artwork.transactions
              .map((transactionMapped) => transactionMapped.recent_prestige_owner)
              .at(-1)
              ? 'True'
              : `${
                  artwork.transactions
                    .map((transactionMapped) => transactionMapped.recent_prestige_owner)
                    .at(-1) === false
                    ? 'False'
                    : ''
                }`
          }
          collapse={false}
        />
        <ArtTargetDetail
          title="Mostly prestige owners"
          text={
            artwork.transactions
              .map((transactionMapped) => transactionMapped.dominantly_prestige_owners)
              .at(-1)
              ? 'True'
              : `${
                  artwork.transactions
                    .map((transactionMapped) => transactionMapped.dominantly_prestige_owners)
                    .at(-1) === false
                    ? 'False'
                    : ''
                }`
          }
          collapse={false}
        />
        <ArtTargetDetail
          title="Previously bought-in"
          text={
            artwork.transactions.map((transactionMapped) => transactionMapped.was_bought_in).at(-1)
              ? 'True'
              : `${
                  artwork.transactions
                    .map((transactionMapped) => transactionMapped.was_bought_in)
                    .at(-1) === false
                    ? 'False'
                    : ''
                }`
          }
          collapse={false}
        />
        <ArtTargetDetail
          title="Artist alive at time of sale"
          text={
            artwork.transactions
              .map((transactionMapped) => transactionMapped.is_artist_alive)
              .at(-1)
              ? 'True'
              : `${
                  artwork.transactions
                    .map((transactionMapped) => transactionMapped.is_artist_alive)
                    .at(-1) === false
                    ? 'False'
                    : ''
                }`
          }
          collapse={false}
        />
        <ArtTargetDetail title="Subjects" text={artwork.subject?.join(', ')} collapse={true} />
        <ArtTargetDetail title="Major colours" text={artwork.colours?.join(', ')} collapse={true} />
        <ArtTargetDetail title="Provenance" text={artwork.provenance?.join('. ')} collapse={true} />
        <ArtTargetDetail title="Description" text={artwork.description} collapse={true} />
      </Stack>
    </Stack>
  );
}

TargetArtwork.propTypes = {
  artwork: PropTypes.object
};

export default TargetArtwork;
