import { loadAllUsers } from 'actions/users/load-all-users-action';

export function updateUserIsReadOnlyAction(userId, isReadOnly) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.updateUserIsBasic(userId, { is_basic: isReadOnly }).then((res) => {
      if (res.ok) {
        dispatch(loadAllUsers());
      }
    });
  };
}
