/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  companyLists: {}
};

export const scheduleSlice = createSlice({
  name: 'schedules',
  initialState: initialSliceState,
  reducers: {
    updateCompanyListSchedulesById: (sliceState, { payload }) => {
      const { listId, schedules } = payload;
      sliceState.companyLists[listId] = schedules;
    },
    addCompanyListScheduleById: (sliceState, { payload }) => {
      const { listId, schedule } = payload;
      if (!sliceState.companyLists[listId]) {
        sliceState.companyLists[listId] = [];
      }
      sliceState.companyLists[listId].push(schedule);
    },
    addAllListSchedules: (sliceState, { payload }) => {
      const { schedules, userId, all } = payload;
      if (!all) {
        const localState = { ...(sliceState.companyLists || {}) };
        const newLocal =
          Object.keys?.(localState)?.reduce((acc, listId) => {
            if (Object.values?.(localState[listId])?.[0].user_id !== userId) {
              acc[listId] = localState[listId];
            }
            return acc;
          }, {}) || {};
        sliceState.companyLists = newLocal;
      }

      schedules.forEach((schedule) => {
        const listId = schedule.company_list_id;
        if (!sliceState.companyLists[listId]) {
          sliceState.companyLists[listId] = {};
        }
        sliceState.companyLists[listId][schedule.id] = { ...schedule };
      });
    }
  }
});

export const { updateCompanyListSchedulesById, addCompanyListScheduleById, addAllListSchedules } =
  scheduleSlice.actions;

export default scheduleSlice.reducer;
