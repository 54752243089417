import { useLayoutEffect, useState } from 'react';

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize((prevSize) => {
        if (prevSize?.[0] === window.innerWidth && prevSize?.[1] === window.innerHeight) {
          return prevSize;
        }
        return [window.innerWidth, window.innerHeight];
      });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
