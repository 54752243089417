import { getCurrentSearchAutocompleteSuggestionsByType } from 'selectors/current-search';

export const getSuggestions = (state) => state.currentSearchAutocomplete?.suggestions || {};
export const getSuggestionByType = (state, suggestionType) =>
  getCurrentSearchAutocompleteSuggestionsByType(state, suggestionType)?.[0] || {};
export const getSuggestionCompanySearchTermByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).name;
export const getSuggestionCompanyNameByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).display_name ||
  getSuggestionByType(state, suggestionType).cb_name ||
  getSuggestionByType(state, suggestionType).cb_permalink;
export const getSuggestionCompanyDescriptionByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).description;
export const getSuggestionCompanyLinkedinUrlByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).linkedin_url;
export const getSuggestionCompanyLinkedinNameById = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).linkedin_name;
export const getSuggestionCompanyCBNameByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).cb_name;
export const getSuggestionCompanyCBPermalinkByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).cb_permalink;
export const getSuggestionCompanyDomainByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType)
    .company_website?.replace('https://', '')
    ?.replace('http://', '');
export const getSuggestionCompanyLogoUrlByType = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).logo_url;
export const getSuggestionCompanyTimeStamp = (state, suggestionType) =>
  getSuggestionByType(state, suggestionType).timeSubmitted;
