import React from 'react';

export default function CommercialTeamIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M6.42283 32.9596H6.40283C6.25283 32.9496 6.13283 32.8196 6.15283 32.6696L6.32283 30.1696C6.32283 30.0196 6.44283 29.9096 6.61283 29.9196C6.76283 29.9296 6.88283 30.0596 6.86283 30.2096L6.69283 32.7096C6.69283 32.8496 6.56283 32.9696 6.42283 32.9696V32.9596Z"
        fill="#374EF4"
      />
      <path
        d="M9.10283 30.6296H9.08283L6.58283 30.4596C6.43283 30.4496 6.31283 30.3196 6.33283 30.1696C6.33283 30.0196 6.45283 29.9096 6.62283 29.9196L9.12283 30.0896C9.27283 30.0996 9.39283 30.2296 9.37283 30.3796C9.37283 30.5196 9.24283 30.6396 9.10283 30.6396V30.6296Z"
        fill="#374EF4"
      />
      <path
        d="M20.6128 37.5796C19.3428 37.5796 18.0728 37.4396 16.8028 37.1596C12.8428 36.2796 9.29283 34.0496 6.79283 30.8496C6.70283 30.7296 6.72283 30.5596 6.84283 30.4596C6.96283 30.3696 7.13283 30.3896 7.23283 30.5096C9.65283 33.5996 13.0928 35.7696 16.9228 36.6196C21.3428 37.5996 25.8828 36.7896 29.7028 34.3596C33.5228 31.9196 36.1628 28.1496 37.1328 23.7296C37.8228 20.6196 37.6228 17.3996 36.5628 14.4196C36.5128 14.2796 36.5828 14.1196 36.7328 14.0696C36.8728 14.0196 37.0328 14.0896 37.0828 14.2396C38.1728 17.3196 38.3828 20.6496 37.6728 23.8596C36.6628 28.4196 33.9428 32.3196 29.9928 34.8396C27.1428 36.6596 23.9128 37.5896 20.6128 37.5896V37.5796Z"
        fill="#374EF4"
      />
      <path
        d="M33.9128 10.0796H33.8928C33.7428 10.0696 33.6228 9.93957 33.6428 9.78957L33.8128 7.28957C33.8228 7.13957 33.9328 7.03957 34.1028 7.03957C34.2528 7.04957 34.3728 7.17957 34.3528 7.32957L34.1828 9.82957C34.1828 9.96957 34.0528 10.0896 33.9128 10.0896V10.0796Z"
        fill="#374EF4"
      />
      <path
        d="M33.9128 10.0796H33.8928L31.3928 9.89957C31.2428 9.88957 31.1228 9.75957 31.1428 9.60957C31.1428 9.45957 31.2628 9.34957 31.4328 9.35957L33.9328 9.52957C34.0828 9.53957 34.2028 9.66957 34.1828 9.81957C34.1828 9.95957 34.0528 10.0796 33.9128 10.0796Z"
        fill="#374EF4"
      />
      <path
        d="M3.68283 25.9396C3.57283 25.9396 3.46283 25.8696 3.42283 25.7596C2.33283 22.6796 2.12283 19.3496 2.83283 16.1396C3.84283 11.5796 6.56283 7.67957 10.5128 5.15957C14.4528 2.64957 19.1428 1.81957 23.7028 2.82957C27.6628 3.70957 31.2128 5.94957 33.7128 9.13957C33.8028 9.25957 33.7828 9.42957 33.6628 9.52957C33.5428 9.61957 33.3728 9.59957 33.2728 9.47957C30.8528 6.38957 27.4128 4.21957 23.5828 3.36957C19.1628 2.38957 14.6228 3.19957 10.8028 5.62957C6.98283 8.06957 4.34283 11.8396 3.37283 16.2596C2.68283 19.3696 2.88283 22.5896 3.94283 25.5696C3.99283 25.7096 3.92283 25.8696 3.77283 25.9196C3.74283 25.9296 3.71283 25.9396 3.68283 25.9396Z"
        fill="#374EF4"
      />
      <path
        d="M12.5828 12.9395C13.8328 12.9395 14.8428 13.9295 14.8428 15.1795C14.8428 16.4195 13.8428 17.4295 12.6028 17.4395C11.3728 17.4495 10.3428 16.4395 10.3428 15.1995C10.3428 13.9595 11.3428 12.9495 12.5828 12.9395Z"
        fill="#0593A2"
      />
      <path
        d="M19.3328 26.6595C19.3328 25.4595 19.3728 24.6695 19.3228 23.4795C19.2528 21.9195 18.5528 20.4195 17.2728 19.5195C15.9928 18.6195 14.5028 18.5695 13.0728 18.6595C12.4028 18.6995 11.7428 18.8495 11.1428 19.1495C9.33279 20.0495 8.41279 21.5295 8.33279 23.5195C8.29279 24.5495 8.31279 25.1895 8.33279 26.2195C8.33279 26.4395 8.51279 26.6195 8.74279 26.6195L19.3528 26.6595H19.3328Z"
        fill="#0593A2"
      />
      <path
        d="M27.2528 12.9395C28.5028 12.9395 29.5128 13.9295 29.5128 15.1795C29.5128 16.4195 28.5128 17.4295 27.2728 17.4395C26.0428 17.4495 25.0128 16.4395 25.0128 15.1995C25.0128 13.9595 26.0128 12.9495 27.2528 12.9395Z"
        fill="#374EF4"
      />
      <path
        d="M32.6428 26.0295C32.6428 25.0495 32.6728 24.3395 32.6228 23.2595C32.5528 21.7195 31.8328 20.2295 30.5628 19.3495C29.2928 18.4695 27.8028 18.4095 26.3728 18.4895C25.7028 18.5295 25.0428 18.6795 24.4428 18.9795C22.6328 19.8795 21.7128 21.3595 21.6328 23.3495C21.5928 24.3795 21.6128 24.9595 21.6328 25.9995C21.6328 26.2195 21.8128 26.3995 22.0428 26.3995L32.2428 26.4395C32.4728 26.4395 32.6528 26.2595 32.6528 26.0295H32.6428Z"
        fill="#374EF4"
      />
      <path
        d="M27.7628 26.2196C27.7628 25.1696 27.7928 24.4196 27.7428 23.2696C27.6728 21.6296 26.9528 20.0396 25.6828 19.0996C24.4128 18.1596 22.9228 18.0896 21.4928 18.1896C20.8228 18.2296 20.1628 18.3896 19.5628 18.7096C17.7528 19.6696 16.8328 21.2496 16.7528 23.3696C16.7128 24.4696 16.7328 25.0896 16.7528 26.1896C16.7528 26.4296 16.9328 26.6196 17.1628 26.6196L27.3628 26.6596C27.5928 26.6596 27.7728 26.4596 27.7728 26.2196H27.7628Z"
        fill="white"
      />
      <path
        d="M26.5428 27.3095H14.1028C13.8628 27.3095 13.6728 27.1295 13.6728 26.8995V23.2295C13.6728 19.9295 16.5128 17.2595 20.0028 17.2595H20.6428C24.2328 17.2595 26.9228 20.1395 26.9728 23.4395C26.9928 24.5895 26.9728 25.7495 26.9728 26.8995C26.9728 27.1195 26.7828 27.3095 26.5428 27.3095Z"
        fill="#8EBCFC"
      />
      <path
        d="M26.5428 27.5795H14.1028C13.7128 27.5795 13.3928 27.2695 13.3928 26.8995V23.2295C13.3928 19.7895 16.3528 16.9895 20.0028 16.9895H20.6428C24.2328 16.9895 27.1928 19.8795 27.2528 23.4395C27.2628 24.2095 27.2528 24.9795 27.2528 25.7495V26.8995C27.2528 27.2795 26.9328 27.5795 26.5428 27.5795ZM20.0028 17.5295C16.6628 17.5295 13.9428 20.0795 13.9428 23.2195V26.8895C13.9428 26.9595 14.0128 27.0195 14.1028 27.0195H26.5328C26.6128 27.0195 26.6928 26.9595 26.6928 26.8895V25.7295C26.6928 24.9595 26.7028 24.1995 26.6928 23.4295C26.6428 20.1695 23.9328 17.5195 20.6328 17.5195H19.9928L20.0028 17.5295Z"
        fill="#8EBCFC"
      />
      <path
        d="M25.7528 27.9794L13.1428 27.9194C12.9128 27.9194 12.7228 27.7394 12.7228 27.5094V23.8394C12.7228 22.3994 13.7328 20.7194 14.7328 19.6994C16.0228 18.3894 17.5528 17.9494 19.4528 17.8594H20.0628C23.5028 17.8594 26.1228 20.8094 26.1728 24.0994C26.1928 25.2494 26.1728 26.4094 26.1728 27.5594C26.1728 27.7894 25.9828 27.9694 25.7528 27.9694V27.9794Z"
        fill="white"
      />
      <path
        d="M23.6928 12.0495C23.6928 14.1295 22.0028 15.8195 19.9328 15.8195C17.8528 15.8195 16.1728 14.1295 16.1728 12.0495C16.1728 9.97952 17.8528 8.29952 19.9228 8.29952C22.0128 8.29952 23.6928 9.96952 23.6928 12.0495Z"
        fill="white"
      />
      <path
        d="M19.9328 16.0895C18.8528 16.0895 17.8428 15.6695 17.0728 14.8995C16.3028 14.1295 15.8928 13.1195 15.8928 12.0395C15.8928 9.81952 17.7028 8.01952 19.9228 8.00952C21.0028 8.00952 22.0128 8.42952 22.7728 9.17952C23.5328 9.93952 23.9628 10.9495 23.9628 12.0295C23.9628 13.1095 23.5428 14.1195 22.7828 14.8895C22.0228 15.6595 21.0028 16.0795 19.9228 16.0795L19.9328 16.0895ZM19.9328 8.56952C18.0128 8.56952 16.4528 10.1295 16.4528 12.0495C16.4528 12.9795 16.8128 13.8595 17.4728 14.5195C18.1328 15.1795 19.0128 15.5495 19.9428 15.5495C20.8728 15.5495 21.7528 15.1895 22.4128 14.5295C23.0728 13.8695 23.4328 12.9895 23.4328 12.0595C23.4328 11.1295 23.0728 10.2495 22.4128 9.59952C21.7528 8.94952 20.8828 8.58952 19.9428 8.58952L19.9328 8.56952Z"
        fill="#374EF4"
      />
      <path
        d="M25.4328 28.0494C25.4328 26.8994 25.4528 25.7394 25.4328 24.5894C25.3828 21.2994 22.8528 18.4094 19.4628 18.4094H18.8628C15.5628 18.4094 12.8928 21.0794 12.8928 24.3794V28.0494H25.4328Z"
        fill="white"
      />
      <path
        d="M25.4328 28.3294H12.9028C12.7528 28.3294 12.6228 28.2094 12.6228 28.0494V24.3794C12.6228 20.9394 15.4228 18.1394 18.8628 18.1394H19.4628C22.8528 18.1394 25.6528 21.0294 25.7028 24.5894C25.7028 25.3594 25.7028 26.1294 25.7028 26.8994V28.0494C25.7028 28.1994 25.5728 28.3294 25.4228 28.3294H25.4328ZM13.1728 27.7794H25.1528V26.8994C25.1528 26.1294 25.1528 25.3694 25.1528 24.5994C25.1028 21.3394 22.5528 18.6894 19.4628 18.6894H18.8628C15.7228 18.6894 13.1728 21.2394 13.1728 24.3794V27.7794Z"
        fill="#374EF4"
      />
    </svg>
  );
}
