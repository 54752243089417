import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { TILE_TYPE_TO_ID_KEY } from 'constants/tiles';

export const getFoldersSlice = (state) => state.folders;

export const getAllFolders = (state) => getFoldersSlice(state)?.folders;
export const getFolderById = (state, id) => getAllFolders(state)?.[id];
export const getFolderNameById = (state, id) => getFolderById(state, id)?.name;

export const getCurrentFolderId = (state) => getFoldersSlice(state)?.currentFolderId;
export const getCurrentFolderName = (state) => getFolderNameById(state, getCurrentFolderId(state));
export const getFolderResourcesById = (state, id) => getFolderById(state, id)?.folder_resources;
export const getFolderIsShared = (state, id) => {
  const folder = getFolderById(state, id);
  if (!folder) return null;
  return folder && folder.is_shared;
};
export const getFolderOwner = (state, id) => getFolderById(state, id)?.user;
export const getFolderCollaboratorsUsers = (state, id) =>
  getFolderById(state, id)?.collaborators_users;
export const getFolderCollaboratorsOrgs = (state, id) =>
  getFolderById(state, id)?.collaborators_orgs;
export const getFolderCollaboratorsTeams = (state, id) =>
  getFolderById(state, id)?.collaborators_teams;
export const getFolderCollaborateInfo = (state, id) => {
  return {
    resourceUsers: getFolderCollaboratorsUsers(state, id),
    resourceOrgs: getFolderCollaboratorsOrgs(state, id),
    resourceTeams: getFolderCollaboratorsTeams(state, id),
    resourceOwner: getFolderOwner(state, id)
  };
};
export const getAllFolderResources = (state) => {
  const allFolders = getAllFolders(state);
  if (!allFolders) return [];
  return Object.values(allFolders).reduce(
    (acc, folder) => [...acc, ...(folder.folder_resources || [])],
    []
  );
};
export const getAllFolderRecordIds = (state) =>
  getAllFolderResources(state)
    .map((resource) => resource.record_id)
    .filter(Boolean);
export const getAllFolderCompanyListIds = (state) => {
  return getAllFolderResources(state)
    .map((resource) => resource.company_list_id)
    .filter(Boolean);
};
export const getFolderRecordIdsById = (state, id) => {
  const folderResources = getFolderResourcesById(state, id);
  if (!folderResources) return [];
  return folderResources.map((resource) => resource.record_id).filter(Boolean);
};
export const getFolderCompanyListIdsById = (state, id) => {
  const folderResources = getFolderResourcesById(state, id);
  if (!folderResources) return [];
  return folderResources.map((resource) => resource.company_list_id).filter(Boolean);
};
export const getCurrentFolderResources = (state) =>
  getFolderById(state, getCurrentFolderId(state))?.folder_resources;
export const getFolderSupportingResourcesById = (state, id) => {
  if (id) {
    return getFolderById(state, id)?.supporting_resources;
  }
  return null;
};
export const getFolderSupportingResourceByIds = (state, id, resourceId) => {
  return getFolderSupportingResourcesById(state, id)?.[resourceId];
};
export const getCurrentFolderSupportingResources = (state) =>
  getFolderSupportingResourcesById(state, getCurrentFolderId(state));
export const getFolderBreadcrumbs = (state, firstFolderId) => {
  if (!firstFolderId || firstFolderId === SHARED_WITH_ME_FOLDER_ID) return [];
  const currentFolder = getFolderById(state, firstFolderId);
  if (!currentFolder) return [];
  const breadcrumbs = [currentFolder];
  let index = 0;
  while (breadcrumbs[index].parent_id) {
    const folder = getFolderById(state, breadcrumbs[index].parent_id);
    breadcrumbs.unshift(folder);
    index++;
  }
  return breadcrumbs;
};
export const getFirstParentFolderIdOfResource = (state, type, id) => {
  const allFolders = getAllFolders(state);
  if (!allFolders) return null;
  const idKey = TILE_TYPE_TO_ID_KEY[type];
  return Object.keys(allFolders).find((folderId) =>
    allFolders[folderId]?.folder_resources?.some((resource) => resource[idKey] === id)
  );
};
export const getResourceBreadcrumbs = (state, type, id) => {
  const firstFolderId = getFirstParentFolderIdOfResource(state, type, id);
  if (!firstFolderId) return [];
  return getFolderBreadcrumbs(state, firstFolderId);
};
export const getCurrentBreadcrumbs = (state) =>
  getFolderBreadcrumbs(state, getCurrentFolderId(state));
