import { TOAST_TYPES } from 'constants/toasts';
import { updateFound } from 'reducer/art-slice';

export function findArtAction(artistName, artworkTitle, externalOnly, addToast, setLoading) {
  return (dispatch, _getState, apiClient) => {
    setLoading(true);
    apiClient.art
      .findArt(artistName, artworkTitle, externalOnly)
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(updateFound({ artworks: res.data.data.artworks }));
          addToast('Found Artworks', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to find artworks', TOAST_TYPES.ERROR);
        }
        setLoading(false);
      })
      .catch(() => {
        addToast('Failed to find artworks', TOAST_TYPES.ERROR);
        setLoading(false);
      });
  };
}
