import React from 'react';

export default function ProductLaunchIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.403 14.8401C19.383 14.6101 19.373 14.3901 19.363 14.1601C19.353 13.8701 19.343 13.5901 19.343 13.3101C19.343 13.1401 19.343 12.9601 19.343 12.7901C19.343 12.7401 19.343 12.6801 19.343 12.6301H9.20299C8.31299 12.6301 7.59299 13.3501 7.59299 14.2401V30.0001C7.59299 30.8901 8.31299 31.6101 9.20299 31.6101H32.493C33.383 31.6101 34.103 30.8901 34.103 30.0001V14.2501C34.103 13.3601 33.383 12.6401 32.493 12.6401H22.433C22.433 12.6401 22.433 12.6901 22.433 12.7201C22.433 12.7401 22.433 12.7601 22.433 12.7801V12.8001C22.433 12.9701 22.433 13.1401 22.433 13.3201C22.433 13.6001 22.433 13.8801 22.413 14.1701C22.413 14.3901 22.393 14.6201 22.373 14.8501"
        fill="white"
      />
      <path
        d="M32.483 31.8901H9.20299C8.16299 31.8901 7.31299 31.0401 7.31299 30.0001V14.2501C7.31299 13.2101 8.16299 12.3601 9.20299 12.3601H19.363C19.433 12.3601 19.513 12.3901 19.563 12.4401C19.613 12.4901 19.643 12.5701 19.643 12.6401V12.8001C19.643 12.9701 19.633 13.1401 19.633 13.3101C19.633 13.5901 19.633 13.8701 19.653 14.1501C19.653 14.3701 19.673 14.5901 19.693 14.8201C19.703 14.9701 19.593 15.1001 19.443 15.1101C19.323 15.1301 19.163 15.0101 19.153 14.8601C19.133 14.6301 19.123 14.4001 19.113 14.1701C19.103 13.8801 19.093 13.6001 19.093 13.3101C19.093 13.1801 19.093 13.0401 19.093 12.9101H9.20299C8.46299 12.9101 7.86299 13.5101 7.86299 14.2501V30.0101C7.86299 30.7501 8.46299 31.3501 9.20299 31.3501H32.483C33.223 31.3501 33.823 30.7501 33.823 30.0101V14.2501C33.823 13.5101 33.223 12.9101 32.483 12.9101H22.703C22.703 13.0401 22.703 13.1801 22.703 13.3101C22.703 13.5901 22.703 13.8801 22.683 14.1701C22.683 14.3901 22.663 14.6201 22.643 14.8601C22.633 15.0101 22.483 15.1301 22.353 15.1101C22.203 15.1001 22.083 14.9701 22.103 14.8201C22.123 14.6001 22.133 14.3701 22.143 14.1501C22.153 13.8701 22.163 13.5901 22.163 13.3101C22.163 13.1401 22.163 12.9701 22.163 12.8001V12.7101C22.163 12.6401 22.183 12.5301 22.243 12.4801C22.293 12.4301 22.363 12.3601 22.443 12.3601H32.503C33.543 12.3601 34.393 13.2101 34.393 14.2501V30.0101C34.393 31.0501 33.543 31.9001 32.503 31.9001L32.483 31.8901Z"
        fill="#374EF4"
      />
      <path
        d="M8.01299 31.7001C7.84299 31.5301 7.70299 31.3201 7.60299 31.1001H5.34299C5.18299 31.1001 5.06299 31.2301 5.06299 31.3801V31.7001H8.02299H8.01299Z"
        fill="#8EBCFC"
      />
      <path
        d="M9.39299 11.7601H17.843C17.873 11.5501 17.913 11.3601 17.943 11.1601H9.99299C9.29299 11.1601 8.68299 11.5301 8.34299 12.0701C8.64299 11.8801 9.00299 11.7601 9.39299 11.7601Z"
        fill="#8EBCFC"
      />
      <path
        d="M38.283 31.0901H35.003C35.363 30.7301 35.583 30.2401 35.583 29.6901V13.1201C35.583 12.0401 34.703 11.1601 33.623 11.1601H25.523C24.703 5.58013 22.063 1.79013 22.033 1.75013C21.983 1.67013 21.893 1.63013 21.803 1.63013C21.713 1.63013 21.623 1.67013 21.573 1.75013C21.573 1.75013 21.443 1.94013 21.253 2.25013C21.323 2.26013 21.393 2.30013 21.433 2.36013C21.463 2.40013 24.103 6.19013 24.923 11.7701H33.023C34.103 11.7701 34.983 12.6501 34.983 13.7301V30.3001C34.983 30.8501 34.763 31.3401 34.403 31.7001H37.683C37.843 31.7001 37.963 31.8301 37.963 31.9801V32.9901C37.963 33.5401 37.783 34.0501 37.483 34.4601C38.133 34.0001 38.563 33.2401 38.563 32.3801V31.3701C38.563 31.2101 38.433 31.0901 38.283 31.0901Z"
        fill="#8EBCFC"
      />
      <path
        d="M36.9629 31.6201V32.5901C36.9629 33.7901 35.9829 34.7701 34.7829 34.7701H6.90288C5.70288 34.7701 4.72288 33.7901 4.72288 32.5901V31.6201H36.9629Z"
        fill="white"
      />
      <path
        d="M6.90288 35.0401C5.55288 35.0401 4.45288 33.9401 4.45288 32.5901V31.6201C4.45288 31.4701 4.57288 31.3501 4.73288 31.3501H36.9629C37.1129 31.3501 37.2429 31.4701 37.2429 31.6201V32.5901C37.2429 33.9401 36.1429 35.0401 34.7929 35.0401H6.90288ZM5.00288 31.8901V32.5801C5.00288 33.6301 5.85288 34.4801 6.90288 34.4801H34.7729C35.8229 34.4801 36.6729 33.6301 36.6729 32.5801V31.8901H5.00288Z"
        fill="#374EF4"
      />
      <path
        d="M22.0529 26.5601C22.0529 25.9201 21.5329 25.4101 20.9029 25.4101C20.2629 25.4101 19.7529 25.9301 19.7529 26.5601C19.7529 26.9401 19.8529 27.3201 20.0329 27.6601L20.9129 29.2701L21.7829 27.6601C21.9629 27.3201 22.0629 26.9501 22.0629 26.5701L22.0529 26.5601Z"
        fill="#9A2FC5"
      />
      <path
        d="M20.9029 29.5401C20.8029 29.5401 20.7129 29.4901 20.6629 29.4001L19.7829 27.7901C19.5729 27.4101 19.4729 26.9901 19.4729 26.5601C19.4729 25.7701 20.1129 25.1301 20.9029 25.1301C21.6929 25.1301 22.3329 25.7701 22.3329 26.5601C22.3329 26.9901 22.2229 27.4101 22.0229 27.7901L21.1529 29.4001C21.1029 29.4901 21.0129 29.5401 20.9129 29.5401H20.9029ZM20.9029 25.6801C20.4129 25.6801 20.0229 26.0701 20.0229 26.5601C20.0229 26.9001 20.1029 27.2301 20.2729 27.5301L20.9129 28.6901L21.5429 27.5201C21.7029 27.2301 21.7829 26.8901 21.7829 26.5601C21.7829 26.0701 21.3929 25.6801 20.9029 25.6801Z"
        fill="#9A2FC5"
      />
      <path
        d="M24.4728 21.03C24.3528 20.91 24.2328 20.79 24.1028 20.68C24.0628 20.65 24.0228 20.61 23.9828 20.57C23.9528 20.54 23.9228 20.51 23.8828 20.49C23.7528 20.38 23.6228 20.28 23.4828 20.18C23.4328 20.14 23.3728 20.1 23.3228 20.06L23.3428 24.59H18.5328L18.5528 20.06C18.1528 20.34 17.7928 20.64 17.4828 20.95C15.4328 10.79 20.9828 2.98999 20.9828 2.98999C20.9828 2.98999 26.5628 10.83 24.4628 21.03H24.4728Z"
        fill="#374EF4"
      />
      <path
        d="M20.9328 13.63C21.9104 13.63 22.7028 12.8376 22.7028 11.86C22.7028 10.8825 21.9104 10.09 20.9328 10.09C19.9553 10.09 19.1628 10.8825 19.1628 11.86C19.1628 12.8376 19.9553 13.63 20.9328 13.63Z"
        fill="white"
      />
      <path
        d="M20.9328 13.9C19.8028 13.9 18.8828 12.98 18.8828 11.85C18.8828 10.72 19.8028 9.80005 20.9328 9.80005C22.0628 9.80005 22.9828 10.72 22.9828 11.85C22.9828 12.98 22.0628 13.9 20.9328 13.9ZM20.9328 10.36C20.1128 10.36 19.4328 11.03 19.4328 11.86C19.4328 12.69 20.1028 13.36 20.9328 13.36C21.7628 13.36 22.4328 12.69 22.4328 11.86C22.4328 11.03 21.7628 10.36 20.9328 10.36Z"
        fill="#374EF4"
      />
      <path
        d="M24.9428 16.01H17.1328C17.1328 15.6 17.1628 15.2 17.1928 14.81H24.8828C24.9128 15.2 24.9328 15.6 24.9428 16.01Z"
        fill="#9A2FC5"
      />
      <path
        d="M24.9428 16.28H17.1328C17.0628 16.28 16.9828 16.25 16.9328 16.2C16.8828 16.15 16.8528 16.08 16.8528 16C16.8628 15.59 16.8828 15.18 16.9128 14.79C16.9228 14.65 17.0428 14.53 17.1828 14.53H24.8728C25.0128 14.53 25.1328 14.64 25.1428 14.78C25.1728 15.18 25.1928 15.58 25.2028 15.99C25.2028 16.06 25.1728 16.14 25.1228 16.19C25.0728 16.24 25.0028 16.27 24.9228 16.27L24.9428 16.28ZM17.4128 15.73H24.6628C24.6628 15.51 24.6428 15.29 24.6328 15.08H17.4528C17.4428 15.29 17.4328 15.51 17.4228 15.73H17.4128Z"
        fill="#9A2FC5"
      />
      <path
        d="M19.3128 19.2701L19.2928 23.7901C19.2928 23.7901 17.6028 23.8701 16.0728 25.4001C16.0728 25.4001 15.8028 21.71 19.3128 19.26V19.2701Z"
        fill="white"
      />
      <path
        d="M16.0728 25.6901C16.0728 25.6901 16.0128 25.6901 15.9728 25.6701C15.8728 25.6301 15.8028 25.5401 15.7928 25.4301C15.7828 25.2701 15.5528 21.5501 19.1528 19.0401C19.2328 18.9801 19.3428 18.9701 19.4428 19.0201C19.5428 19.0701 19.5928 19.1601 19.5928 19.2701L19.5728 23.7901C19.5728 23.9401 19.4528 24.0601 19.3128 24.0601C19.2928 24.0601 17.7028 24.1601 16.2728 25.5901C16.2228 25.6401 16.1528 25.6701 16.0828 25.6701L16.0728 25.6901ZM19.0328 19.8301C16.9128 21.5301 16.4528 23.7301 16.3628 24.7801C17.4528 23.8901 18.5328 23.6301 19.0128 23.5501L19.0328 19.8201V19.8301Z"
        fill="#374EF4"
      />
      <path
        d="M22.4829 19.27L22.5029 23.79C22.5029 23.79 24.1929 23.87 25.7229 25.4C25.7229 25.4 25.9929 21.71 22.4829 19.26V19.27Z"
        fill="white"
      />
      <path
        d="M25.7229 25.69C25.6529 25.69 25.5829 25.66 25.5329 25.61C24.0929 24.17 22.5129 24.08 22.4929 24.08C22.3429 24.08 22.2329 23.95 22.2329 23.81L22.2129 19.29C22.2129 19.19 22.2729 19.09 22.3629 19.05C22.4529 19.01 22.5629 19.01 22.6529 19.07C26.2429 21.58 26.0229 25.3 26.0129 25.46C26.0129 25.57 25.9329 25.66 25.8329 25.7C25.8029 25.71 25.7729 25.72 25.7329 25.72L25.7229 25.69ZM22.7729 23.56C23.2529 23.64 24.3329 23.9 25.4229 24.78C25.3329 23.73 24.8729 21.53 22.7529 19.83L22.7729 23.56Z"
        fill="#374EF4"
      />
    </svg>
  );
}
