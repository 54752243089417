import BaseClient from './BaseClient';

const URL_PREFIX = 'custom_analytics';

export default class CustomAnalyticsClient extends BaseClient {
  fetchAdminCustomAnalytics(excludeMathlabs = false) {
    return this.get(`${URL_PREFIX}?exclude_mathlabs=${excludeMathlabs ? '1' : '0'}`);
  }

  queryCustomAnalyticsAssistantAsync(body) {
    return this.post(`${URL_PREFIX}/async_custom_analytics`, body);
  }

  getCustomAnalyticsRecordData(recordId) {
    return this.get(`${URL_PREFIX}/async_custom_analytics/${recordId}`);
  }
}
