import GroupIcon from '@mui/icons-material/Group';
import {
  Card,
  CardContent,
  CardHeader,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { moveFolderResourceAction } from 'actions/folder-resources/move-folder-resource-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import DeleteFolderDialog from 'components/folders/DeleteFolderDialog';
import RenameFoldersDialog from 'components/folders/RenameFoldersDialog';
import { FOLDERS_PREFIX } from 'constants/app-routes';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { RESOURCE_TYPES } from 'constants/resources';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CollaborateIcon from 'icons/CollaborateIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from 'icons/EditIcon';
import FolderIcon from 'icons/FolderIcon';
import MenuIcon from 'icons/MenuIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFolderNameById } from 'selectors/folders';
import { getUserCanCollaborate } from 'selectors/user';

import SharedStack from '../SharedStack';

function HomePageFolderTile({ id, numberOfResearches, researchIcons, isShared }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastRef = useRef();

  const [isOnHover, setIsOnHover] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCollaborateDialog, setOpenCollaborateDialog] = useState(false);
  const { addToast, cancelToast } = useAppUtils();
  const canCollaborate = useOldStateSelector(getUserCanCollaborate);
  const folderName = useSelector((state) => getFolderNameById(state, id));
  const name = id === SHARED_WITH_ME_FOLDER_ID ? 'Shared With Me' : folderName;

  const onDropTile = (event) => {
    setIsDragOver(false);
    const recordId = event.dataTransfer.getData('recordId');
    const recordType = event.dataTransfer.getData('recordType');
    const currentFolderId = event.dataTransfer.getData('folderId');
    const researchName = event.dataTransfer.getData('researchName');
    const canMove = event.dataTransfer.getData('canMove');
    if (!canMove) {
      addToast('Cannot move this resource. It does not belong to you.', TOAST_TYPES.ERROR);
      return;
    }
    toastRef.current = addToast(`Moving ${researchName} to ${folderName}`, TOAST_TYPES.INFO);
    dispatch(
      moveFolderResourceAction(recordId, recordType, currentFolderId, id, (isSucceed) => {
        cancelToast(toastRef.current);
        toastRef.current = null;
        const toastMessage = isSucceed
          ? `${researchName} moved successfully to ${folderName}`
          : `${researchName} failed to moved to ${folderName}`;
        const toastId = addToast(toastMessage, isSucceed ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR);
        setTimeout(() => cancelToast(toastId), 4000);
      })
    );
  };

  const onClick = (event) => {
    event.stopPropagation();
    oldDispatch(sendUserEvent(USER_EVENTS.FOLDER_OPEN, { name, id }));
    navigate(`${FOLDERS_PREFIX}/${id}`);
  };

  const handleActionsClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onRenameClick = (event) => {
    handleMenuClick(event);
    setOpenRenameDialog(true);
  };

  const onDeleteClick = (event) => {
    handleMenuClick(event);
    setOpenDeleteDialog(true);
  };

  const onCollaborationClick = (event) => {
    handleMenuClick(event);
    setOpenCollaborateDialog(true);
  };

  const onDragHover = (event) => {
    if (id !== SHARED_WITH_ME_FOLDER_ID) {
      event.preventDefault();
    }
  };

  const openActionsMenu = Boolean(anchorEl);

  return (
    <Card
      onClick={onClick}
      onMouseEnter={() => setIsOnHover(id !== SHARED_WITH_ME_FOLDER_ID)}
      onMouseLeave={() => setIsOnHover(false)}
      onDrop={onDropTile}
      onDragEnter={() => setIsDragOver(id !== SHARED_WITH_ME_FOLDER_ID)}
      onDragLeave={() => setIsDragOver(false)}
      onDragOver={onDragHover}
      sx={{
        position: 'relative',
        boxSizing: 'border-box',
        height: '100px',
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
        backgroundColor: 'primary.white',
        borderColor: 'primary.primary50',
        display: 'flex',
        border: isDragOver ? '1px solid' : 'none',
        flexDirection: 'column',
        gap: '12px',
        '&:hover': {
          backgroundColor: 'background.bg50'
        },
        cursor: 'pointer'
      }}>
      <CardHeader
        sx={{
          padding: '16px 16px 4px 16px',
          '.MuiCardHeader-content': { overflow: 'hidden' },
          '.MuiCardHeader-avatar': { marginRight: '6px' }
        }}
        avatar={<FolderIcon />}
        title={
          <Stack direction="row" alignItems="center" gap="8px">
            {id === SHARED_WITH_ME_FOLDER_ID && <ShareIcon />}
            {isShared && <GroupIcon sx={{ width: '20px', height: '20px' }} />}
            <Typography
              variant="paragraphMedium"
              color="secondary.secondary1"
              textAlign="start"
              sx={{ display: 'block' }}>
              {name}
            </Typography>
          </Stack>
        }
        action={
          isOnHover && (
            <IconButtonComponent
              tag="Actions"
              variant="secondary"
              sx={{
                padding: 0
              }}
              onClick={handleActionsClick}>
              <MenuIcon />
            </IconButtonComponent>
          )
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
          height: '40px',
          padding: '0 16px'
        }}>
        <Stack direction="row" alignItems="center" gap="8px">
          {researchIcons.map((Icon, index) => (
            <Icon key={index} />
          ))}
        </Stack>
        <Stack direction="row" alignItems="center" gap="8px">
          {isShared && <SharedStack id={id} resourceType={RESOURCE_TYPES.FOLDER} />}
          <Typography variant="paragraphMedium" color="greyColors.grey200">
            {numberOfResearches === 0
              ? 'No Researches'
              : `${numberOfResearches} ${numberOfResearches > 1 ? 'Researches' : 'Research'}`}
          </Typography>
        </Stack>
      </CardContent>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openActionsMenu}
        onClose={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={onRenameClick}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="paragraph">Rename</Typography>
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="paragraph">Delete</Typography>
        </MenuItem>
        {canCollaborate && (
          <MenuItem onClick={onCollaborationClick}>
            <ListItemIcon>
              <CollaborateIcon />
            </ListItemIcon>
            <Typography variant="paragraph">Collaborate</Typography>
          </MenuItem>
        )}
      </Menu>
      <RenameFoldersDialog
        open={openRenameDialog}
        folderId={id}
        onClose={() => setOpenRenameDialog(false)}
      />
      <DeleteFolderDialog
        open={openDeleteDialog}
        folderId={id}
        numberOfResearches={numberOfResearches}
        onClose={() => setOpenDeleteDialog(false)}
      />
      {canCollaborate && (
        <CollaborateDialog
          open={openCollaborateDialog}
          onClose={() => setOpenCollaborateDialog(false)}
          id={id}
          resourceType={RESOURCE_TYPES.FOLDER}
        />
      )}
    </Card>
  );
}

HomePageFolderTile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  numberOfResearches: PropTypes.number,
  researchIcons: PropTypes.array,
  isShared: PropTypes.bool
};

export default wrapWithError(HomePageFolderTile);
