import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import StackedBarChartComponent from '../bar/StackedBarChartComponent';
import TableComponent from '../table/TableComponent';
import B2DUserSummaryCard from './B2DstackExchangeSummaryCard';
import { RedditExtraInfoComponent } from './RedditExtraInfoComponent';

/* eslint-disable id-length */
export default function B2DCompositeComponent({ elementId, barchart, table, mode }) {
  const barchart_data = barchart.data;
  const table_data = table.data;
  const rowData = useMemo(() => {
    const result = [];
    const numOfRows = table_data.columns?.[0]?.values?.length || 0;
    for (let i = 0; i < numOfRows; ++i) {
      const row = table_data.columns.reduce((acc, col) => {
        acc[col.id] = {
          name: col.name,
          value: col.id === 'metadata' ? JSON.parse(col.values[i]) : col.values[i]
        };
        return acc;
      }, {});
      result.push(row);
    }
    return result;
  }, [table_data.columns]);
  const [selectedRow, setRow] = useState(0);
  function onCellClick({ ...args }) {
    setRow(args.id);
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <Stack>
          <StackedBarChartComponent
            title={barchart_data.name}
            values={barchart_data.data.values}
            labels={barchart_data.data.labels}
            xAxisName={barchart_data.data.x_axis_name}
            yAxisName={barchart_data.data.y_axis_name}
            keyOrder={barchart_data.data.key_order}
            mode={mode}
          />
          <TableComponent
            elementId={table.elementId}
            showTitle={true}
            title={table_data.name}
            columns={table_data.columns}
            onCellClick={onCellClick}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        {elementId === 'RedditCompositeElement' ? (
          <RedditExtraInfoComponent rowData={rowData[selectedRow]} />
        ) : (
          <B2DUserSummaryCard rowData={rowData[selectedRow]} />
        )}
      </Grid>
    </Grid>
  );
}

B2DCompositeComponent.propTypes = {
  elementId: PropTypes.string,
  title: PropTypes.string,
  barchart: PropTypes.object,
  table: PropTypes.object,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
