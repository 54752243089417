import { loadExplore } from 'actions/explore/load-explore-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import { SCHEDULE_FREQUENCIES, SCHEDULE_TYPE } from 'constants/schedules';
import { USER_EVENTS } from 'constants/userEvents';

import { getScheduleDates } from './create-schedule-action';
import { getSchedulesAction } from './get-schedules-action';

export function createSchedulesByCustomAnalyticsColumnsAction(listId, data_types, frequency) {
  return async (dispatch, getState, apiClient) => {
    if (data_types?.length === 0) {
      return;
    }
    dispatch(
      sendUserEvent(USER_EVENTS.SUBMIT_SCHEDULES, {
        listId,
        data_types,
        frequency,
        type: SCHEDULE_TYPE.CUSTOM_ANALLYTICS_COLUMN
      })
    );
    const dates = getScheduleDates(data_types, frequency);

    const sortedColumns = Array.from(data_types).sort();

    const payloads = [];
    for (let index = 0; index < sortedColumns.length; index++) {
      const data_type = sortedColumns[index];

      const body = {
        company_list_id: listId,
        parameters: { company_list_id: listId, data_type, recompute: true },
        frequency: frequency,
        start: dates[index],
        monitoring_type: SCHEDULE_TYPE.CUSTOM_ANALLYTICS_COLUMN
      };
      if (frequency === SCHEDULE_FREQUENCIES.MONTHLY) {
        const end = new Date(dates[index]);
        end.setMonth(end.getMonth() + 3);
        body.end = end;
      } else if (frequency === SCHEDULE_FREQUENCIES.DAILY) {
        const end = new Date(dates[index]);
        end.setDate(end.getDate() + 14);
        body.end = end;
      }
      payloads.push(body);
    }

    const schedulesPromises = payloads.map((body) => {
      return new Promise((resolve) => {
        apiClient.schedules
          .createSchedule(body)
          .then(() => {
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    });

    await Promise.all(schedulesPromises);
    dispatch(loadExplore(listId, null, false));
    dispatch(getSchedulesAction());
  };
}
