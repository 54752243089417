export function formatNumberToMil(number) {
  if (!number) return null;
  const numInMil = Math.round(number / 1e6);
  return numInMil.toLocaleString();
}

export function formatNumberTo10k(number) {
  if (!number) return null;
  const numIn10k = Math.round(number / 1e4) * 10;
  return numIn10k.toLocaleString();
}

export function formatRevenueFromData(data) {
  if (data?.consensus?.clean_revenue_amount) {
    const numInMil = formatNumberToMil(data?.consensus?.clean_revenue_amount);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  if (data?.consensus?.revenue_range_lower) {
    return `>${formatNumberToMil(data?.consensus?.revenue_range_lower)}`;
  }
  if (data?.consensus?.revenue_range_upper) {
    return `<${formatNumberToMil(data?.consensus?.revenue_range_upper)}`;
  }
  return null;
}

export function toFixedIfNeeded(num) {
  const zerosPattern = /[.,]00$/u;
  return num.toFixed(2).replace(zerosPattern, '');
}

export function formatNumber(number) {
  if (!number) return null;

  if (Math.abs(number) >= 1e9) {
    return `${toFixedIfNeeded(number / 1e9)}B`;
  } else if (Math.abs(number) >= 1e6) {
    return `${toFixedIfNeeded(number / 1e6)}M`;
  } else if (Math.abs(number) >= 1e3) {
    return `${toFixedIfNeeded(number / 1e3)}K`;
  }

  return toFixedIfNeeded(number);
}

export function formatRevenueForPeers(revenue) {
  if (revenue) {
    const numInMil = formatNumberToMil(revenue);
    if (numInMil === '0') {
      return '<1';
    }
    return numInMil;
  }
  return null;
}

export function formatRevenueForPeersPerPerson(revenue) {
  if (revenue) {
    const numIn10k = formatNumberTo10k(revenue);
    if (numIn10k === '0') {
      return '<1';
    }
    return numIn10k;
  }
  return null;
}
