import PropTypes from 'prop-types';
import { isEmpty } from 'utils/objects-utils';

import RenderMultipleLogos from './RenderMultipleLogos';
import RenderStr from './RenderStr';

export default function RenderDiscoveryInput({ value }) {
  if (!isEmpty(value?.company_metas)) {
    return <RenderMultipleLogos value={value.company_metas} />;
  }
  if (value?.user_search_term) {
    return <RenderStr value={value.user_search_term} />;
  }
}

RenderDiscoveryInput.propTypes = {
  value: PropTypes.any
};
