import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { ACTIONS } from 'constants/actions';

import { loadPersonByIdAction } from './load-person-by-id-action';
import { loadPersonCard } from './load-person-card-action';

function updatePersonProgress(personId, progress) {
  return { type: ACTIONS.UPDATE_PERSON_PROGRESS, personId, progress };
}

// currently this function is being called upon every time that load all people called
// the function might be called multiple time per people id so we cache it to avoid
// multiple tickers per people id
const personsTickerCache = {};
export function monitorPersonProgressAction(personId) {
  return (_state, dispatch, apiClient) => {
    if (personsTickerCache[personId]) return;

    let tickerId = null;
    const ticker = () => {
      apiClient.persons.fetchPersonProgress(personId).then((res) => {
        if (!res.ok) {
          delete personsTickerCache[personId];
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { progress } = res.data;
          dispatch(updatePersonProgress(personId, progress));
          if (progress >= 100) {
            delete personsTickerCache[personId];
            clearInterval(tickerId);
            dispatch(loadPersonByIdAction(personId));
            dispatch(loadPersonCard(personId));
            dispatch(updateRecentlyFinishedRun(personId, 'persons'));
          }
        }
      });
    };
    tickerId = setInterval(ticker, 3000);
    personsTickerCache[personId] = tickerId;
    ticker();
  };
}
