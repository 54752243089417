import { ACTIONS } from 'constants/actions';
import { USER_EVENTS } from 'constants/userEvents';
import { getCurrentSearchAutocompleteStatus } from 'selectors/current-search';

import { updateCurrentSearchRun } from '../runs/make-new-search-run-action';
import { sendUserEvent } from '../users/send-user-event-action';

export function updateCurrentSearchAutcomplete(term, status, suggestions) {
  return { type: ACTIONS.UPDATE_CURRENT_SEARCH_AUTOCOMPLETE, term, status, suggestions };
}

export function clearCurrentSearchAutocomplete(term) {
  return { type: ACTIONS.CLEAR_CURRENT_SEARCH_AUTOCOMPLETE, term };
}

export function clearCurrentSearchAutocompleteAfterFailure(term) {
  return { type: ACTIONS.CLEAR_CURRENT_SEARCH_AUTOCOMPLETE_AFTER_FAILURE, term };
}

export function updateCurrentSearchAutcompleteStatus(term, status) {
  return { type: ACTIONS.UPDATE_CURRENT_SEARCH_AUTOCOMPLETE_STATE, term, status };
}

export function makeNewSearchAutocompleteAction(term, shouldSubmit) {
  return (state, dispatch, apiClient) => {
    if (!shouldSubmit) {
      const currentStatus = getCurrentSearchAutocompleteStatus(state);
      if (currentStatus) {
        dispatch(clearCurrentSearchAutocomplete(term));
        dispatch(updateCurrentSearchRun(null, null));
      }
      return;
    }
    dispatch(updateCurrentSearchRun(null, null));
    dispatch(updateCurrentSearchAutcompleteStatus(term, 'running'));
    dispatch(sendUserEvent(USER_EVENTS.AUTOCOMPLETE, { term }));
    apiClient.autocomplete
      .submitAutocomplete(term)
      .then((res) => {
        const status = res.ok ? 'success' : 'failure';
        if (status === 'success' && res.data) {
          Object.keys(res.data).forEach((suggestion_type) => {
            res.data[suggestion_type].forEach((suggestion) => {
              if (suggestion.ambiguous_website) {
                const { cb_permalink, company_website, linkedin_url } = suggestion;
                dispatch(
                  sendUserEvent(USER_EVENTS.AUTOCOMPLETE_AMBIGUOUS_WEBSITE, {
                    term,
                    suggestion_type,
                    cb_permalink,
                    company_website,
                    linkedin_url
                  })
                );
              }
            });
          });
        }
        if (status === 'failure') {
          dispatch(sendUserEvent(USER_EVENTS.AUTOCOMPLETE_FAILURE, { term }));
        }
        dispatch(updateCurrentSearchAutcomplete(term, status, res.data));
      })
      .catch(() => {
        dispatch(clearCurrentSearchAutocompleteAfterFailure(term));
        dispatch(sendUserEvent(USER_EVENTS.AUTOCOMPLETE_FAILURE, { term }));
      });
  };
}
