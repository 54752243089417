import { getAPIClient } from 'api/client-api';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { initialState, reducer } from 'reducer/reducer';

import { StateContext } from './state-context';

let reducerState = {};
let reducerDispatch = null;

export function dispatch(cbOrObject) {
  if (typeof cbOrObject === 'function') {
    return cbOrObject(reducerState, dispatch, getAPIClient());
  } else if (typeof cbOrObject === 'object' && reducerDispatch) {
    reducerDispatch(cbOrObject);
  }
}

export function AppStateProvider({ children }) {
  const [state, dispatchOfReducer] = useReducer(reducer, initialState);
  const value = { state };

  reducerDispatch = dispatchOfReducer;
  reducerState = state;

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
}

AppStateProvider.propTypes = {
  children: PropTypes.any
};
