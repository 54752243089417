import { EXPLORE_PREFIX } from 'constants/app-routes';
import { TOAST_TYPES } from 'constants/toasts';
import { updateExploreView } from 'reducer/explore-slice';

import { loadListAction } from './load-company-list-action';

export function submitCompanyListViewAction(listId, view, setSaveViewDisabled, addToast, navigate) {
  setSaveViewDisabled(true);
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists
      .submitView(listId, view)
      .then((res) => {
        setSaveViewDisabled(false);
        if (res.ok && res.data) {
          if (navigate && res?.data?.id) {
            const newViewId = res.data.id;
            dispatch(updateExploreView({ listId, view: res.data }));
            navigate(`${EXPLORE_PREFIX}/${listId}/views/${newViewId}`);
          } else {
            dispatch(loadListAction(listId));
          }
          addToast('View saved', TOAST_TYPES.SUCCESS);
        } else {
          addToast('Failed to save view', TOAST_TYPES.ERROR);
        }
      })
      .catch(() => {
        setSaveViewDisabled(false);
        addToast('Failed to save view', TOAST_TYPES.ERROR);
      });
  };
}
