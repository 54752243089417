import { Stack } from '@mui/material';
import CAPlanNode from 'components/custom-analytics/CAPlanNode';
import ConditionEdge from 'components/custom-analytics/ConditionEdge';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import ReactFlow, {
  Background,
  ConnectionLineType,
  addEdge,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import { extractNodesAndEdges } from 'utils/planner-utils';

const PLAN = [
  {
    step_id: 'start',
    objective: 'Search the private database for information about the company',
    if_failed: 'step_3',
    if_successful: 'step_2',
    function_use: {
      action: 'private_search',
      action_variables: {
        column_names: ['company_name', 'description', 'industries'],
        company_name: '<<COMPANY_NAME>>'
      }
    }
  },
  {
    step_id: 'step_2',
    objective: 'Use the magic wand to identify potential client companies',
    if_failed: 'step_3',
    if_successful: 'step_4',
    function_use: {
      action: 'magic_wand',
      action_variables: {
        question:
          'Based on the company description and industries, what are the likely clients of this company?',
        data: '$start.output'
      }
    }
  },
  {
    step_id: 'step_3',
    objective: "Try a general public search to find information about the company's clients",
    if_failed: 'end',
    if_successful: 'step_4',
    function_use: {
      action: 'public_search',
      action_variables: {
        entity: 'general',
        query: '<<COMPANY_NAME>> clients'
      }
    }
  },
  {
    step_id: 'step_4',
    objective: 'Summarize the information about potential client companies',
    if_failed: 'end',
    if_successful: 'end',
    function_use: {
      action: 'summarise_text',
      action_variables: {
        text: '$step_2.output'
      }
    }
  }
];

function CAPlanChart({ plan = PLAN }) {
  const nodeTypes = useMemo(() => ({ CAPlanNode: CAPlanNode }), []);
  const { nodes: layoutedNodes, edges: layoutedEdges } = extractNodesAndEdges(plan, 'CAPlanNode');
  const [nodes, , onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const edgeTypes = {
    condition: ConditionEdge
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: ConnectionLineType.SmoothStep,
            animated: true
          },
          eds
        )
      ),
    [setEdges]
  );

  return (
    <Stack width="100%" height="800px">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView>
        <Background />
      </ReactFlow>
    </Stack>
  );
}

CAPlanChart.propTypes = {
  plan: PropTypes.array
};

export default CAPlanChart;
