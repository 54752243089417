import { updateDebugModeAction } from 'actions/users/update-debug-mode-action';
import ToastsComponent from 'components/ToastsComponent';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getUserIsDev } from 'selectors/user';

export const UtilsContext = createContext();

export function useAppUtils() {
  return useContext(UtilsContext);
}

export function AppUtilsProvider({ children }) {
  const { state } = useAppState();
  const isDev = getUserIsDev(state);
  const [toastsList, setToastsList] = useState([]);

  const addToast = useCallback(
    (message, type, anchorOrigin) => {
      const toastId = new Date().getTime();
      setToastsList((toasts) => [{ id: toastId, message, type, anchorOrigin }, ...toasts]);
      return toastId;
    },
    [setToastsList]
  );

  const cancelToast = useCallback(
    (id) => setToastsList((toasts) => toasts.filter((toast) => toast.id !== id)),
    [setToastsList]
  );

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'd' && event.ctrlKey && isDev) {
        dispatch(updateDebugModeAction());
      }
    };

    window.addEventListener('keydown', keyDownHandler, true);
    return () => window.removeEventListener('keydown', keyDownHandler, true);
  }, [isDev]);

  useEffect(() => {
    if (state.debugMode === null) return;
    /* eslint-disable no-alert */
    alert(`Debug mode has been ${state.debugMode ? 'enabled' : 'disabled'}`);
  }, [state.debugMode]);

  return (
    <UtilsContext.Provider value={{ addToast, cancelToast }}>
      <div className="App">
        {children}
        <ToastsComponent toastsList={toastsList} setToastsList={setToastsList} />
      </div>
    </UtilsContext.Provider>
  );
}

AppUtilsProvider.propTypes = {
  children: PropTypes.any
};
