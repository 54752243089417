import { createSelector } from '@reduxjs/toolkit';

export const getExplore = (state) => state.explore;

export const getAllExploreLists = (state) => getExplore(state).exploreLists;
export const getExploreListByListId = (state, listId) => (getAllExploreLists(state) || {})[listId];
export const getCompanyMetasByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.companyMetas;
export const getDiscoveriesIdsByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.discoveryIds;
export const getClustersIdByListId = (state, listId) => {
  if (!listId) return null;
  return getExploreListByListId(state, listId)?.clustersId;
};
export const getCompanyListHistoryByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.history;

export const getExploreLatestViewByListId = (state, listId) => {
  return getExploreListByListId(state, listId)?.latestView;
};

export const getCompanyListNameByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.name;

export const getListCustomColumnsByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.customColumns;

export const getListDomainKnowledgeByListId = (state, listId) => {
  if (listId) {
    return getExploreListByListId(state, listId)?.domainKnowledge;
  }
  return null;
};
export const getListDomainKnowledgeResourceByListId = (state, listId, resourceId) =>
  getListDomainKnowledgeByListId(state, listId)?.[resourceId];

export const getCompanyListDiscoveredByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.discovered;
export const getCompanyListProvidedByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.provided;
export const getCompanyListExampleLogosByListId = (state, listId) =>
  getExploreListByListId(state, listId)?.exampleLogos;

export const getExploreFiltersBypass = (state) => getExplore(state).filtersBypass;
export const getExploreFiltersBypassById = (state, id) =>
  getExploreFiltersBypass(state)?.[id] || [];
export const getExploreFilters = (state) => getExplore(state).filters;
export const getExploreHiddenColumns = (state) => getExplore(state).hiddenColumns;
export const getExploreViews = (state) => getExplore(state).views;
export const getExploreFiltersById = (state, id) => getExploreFilters(state)[id];
export const getExploreHiddenColumnsById = (state, id) => getExploreHiddenColumns(state)[id];
export const getExploreViewsById = (state, id) => getExploreViews(state)?.[id];
export const getExploreViewByIdAndViewId = (state, id, viewId) =>
  getExploreViewsById(state, id)?.[viewId];
export const getIsExploreColumnHiddenById = (state, id, columnId) =>
  getExploreHiddenColumns(state, id)[columnId];
export const getExploreViewFiltersByIdAndViewId = (state, id, viewId) =>
  getExploreViewByIdAndViewId(state, id, viewId)?.filters;
export const getExploreViewHiddenColumnsByIdAndViewId = (state, id, viewId) =>
  getExploreViewByIdAndViewId(state, id, viewId)?.hiddenColumns;
export const getIsScreeningOpen = (state) => getExplore(state).isScreeningOpen;
export const getRunningDiscoveriesToHide = (state) => getExplore(state).hideDiscoveryRunningInTable;

export const getExploreMismatchCompanies = (state, listId) =>
  getExplore(state).mismatchedComapanyMetas?.[listId];

export const getAreCompaniesNotFound = createSelector(getCompanyMetasByListId, (companyMetas) => {
  if (!companyMetas) return false;
  const notFoundCompanies = companyMetas.filter((company) => company.notFound);
  return notFoundCompanies?.length > 0;
});

export const getExploreAllClustersSearchIds = (state, listId) =>
  getExploreListByListId(state, listId)?.clusters_record_ids;

export const getCompanyListIsShared = (state, listId) => {
  const list = getExploreListByListId(state, listId);
  if (!list) return null;
  return list && list.isShared;
};
export const getCompanyListOwner = (state, listId) => getExploreListByListId(state, listId)?.user;
export const getCompanyListCollaboratorsUsers = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsUsers;
export const getCompanyListCollaboratorsOrgs = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsOrgs;
export const getCompanyListCollaboratorsTeams = (state, listId) =>
  getExploreListByListId(state, listId)?.collaboratorsTeams;
export const getCompanyListIsMine = (state, listId, userId) => {
  if (!userId) {
    return false;
  }
  return getCompanyListOwner(state, listId)?.id === userId;
};
export const getCompanyListCollaborateInfo = (state, id) => {
  return {
    resourceUsers: getCompanyListCollaboratorsUsers(state, id),
    resourceOrgs: getCompanyListCollaboratorsOrgs(state, id),
    resourceTeams: getCompanyListCollaboratorsTeams(state, id),
    resourceOwner: getCompanyListOwner(state, id)
  };
};

export const getIsExploreListMonitored = (state, listId) =>
  getExploreListByListId(state, listId)?.isMonitored;
