import PropTypes from 'prop-types';

function WarningIcon({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M10.9244 1.61765C11.4024 0.794118 12.5976 0.794117 13.0756 1.61765L23.8317 20.1471C24.3098 20.9706 23.7122 22 22.7561 22H1.24386C0.287763 22 -0.309799 20.9706 0.168251 20.1471L10.9244 1.61765Z"
        fill="#F0CF50"
      />
      <rect x="11.209" y="8" width="1.67505" height="6.70022" rx="0.837527" fill="black" />
      <circle cx="12.0469" cy="17.2608" r="1.04691" fill="black" />
    </svg>
  );
}

WarningIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

WarningIcon.defaultProps = {
  size: 24
};

export default WarningIcon;
