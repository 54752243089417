import { dateStringToQuarter } from 'components/elements/bar/bar-chart-utils';

function convertDateToYearMonth(dateStr) {
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
}

function convertToIntAndString(value) {
  return Math.floor(value).toString();
}

const CONVERTION_FUNCTION_MAP = Object.freeze({
  toYearMonth: convertDateToYearMonth,
  floorToString: convertToIntAndString,
  toQuarter: dateStringToQuarter
});

export function convertCardFilterValue(value, convertFunction) {
  const convertionFuncntion = CONVERTION_FUNCTION_MAP[convertFunction];
  return convertionFuncntion ? convertionFuncntion(value) : value;
}
