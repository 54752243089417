import React from 'react';

export default function SearchIcon({ ...args }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...args}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6419 11.0441C15.6419 8.16365 13.3053 5.82858 10.4231 5.82858C7.54085 5.82858 5.20433 8.16365 5.20433 11.0441C5.20433 13.9246 7.54085 16.2596 10.4231 16.2596C13.3053 16.2596 15.6419 13.9246 15.6419 11.0441ZM16.8462 11.0441C16.8462 7.49893 13.9705 4.625 10.4231 4.625C6.87572 4.625 4 7.49893 4 11.0441C4 14.5893 6.87572 17.4632 10.4231 17.4632C11.7744 17.4632 13.0282 17.0462 14.0627 16.3339C14.0701 16.3407 14.0776 16.3474 14.0853 16.3539L19.0108 20.4842C19.2655 20.6978 19.6453 20.6646 19.8591 20.41C20.0729 20.1554 20.0396 19.7758 19.7849 19.5622L14.999 15.5488C16.1413 14.39 16.8462 12.7993 16.8462 11.0441Z"
        fill="currentColor"
      />
    </svg>
  );
}
