import { FOLDER_RESOURCE_TYPE } from 'constants/researches';
import { addAllFolders } from 'reducer/folders-slice';
import { updateLodededResource } from 'reducer/resources-slice';

export function loadAllFoldersAction() {
  return (dispatch, _getState, apiClient) => {
    apiClient.folders.fetchFolders().then((res) => {
      if (res.ok && res.data) {
        const { folders } = res.data;
        const allFolders = folders.reduce((all, folder) => {
          all[folder.id] = folder;
          return all;
        }, {});
        dispatch(addAllFolders({ folders: allFolders }));
      }
      dispatch(updateLodededResource({ resourceType: FOLDER_RESOURCE_TYPE }));
    });
  };
}
