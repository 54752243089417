import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import IconButtonComponent from 'components/IconButtonComponent';
import DiscoveryTable from 'components/elements/table/DiscoveryTable';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

function ClustersCompaniesTable({
  currentClusterLabel,
  setHideTable,
  columns,
  filterRow,
  headerHeight,
  isChecked,
  onCheck
}) {
  return (
    <Draggable>
      <Box
        sx={{
          width: '35vw',
          position: 'fixed',
          right: 24,
          top: headerHeight + 24,
          backgroundColor: 'background.bg100',
          borderRadius: '6px',
          boxShadow: '0px 0px 11px rgba(66, 82, 110, 0.21)'
        }}>
        <Stack
          direction="row"
          backgroundColor="background.bg100"
          borderRadius="4px"
          alignItems="center"
          paddingTop="6px"
          paddingLeft="16px"
          gap="8px"
          sx={{ cursor: 'move' }}>
          <FormControlLabel
            sx={{ width: '16px', height: '16px', padding: '0' }}
            control={
              <Checkbox
                onChange={(event) => {
                  onCheck(event.target.checked);
                  event.stopPropagation();
                }}
                checked={isChecked}
              />
            }
          />
          <Typography variant="paragraphBold" color="greyColors.grey300">
            {currentClusterLabel}
          </Typography>
          <IconButtonComponent
            onClick={() => setHideTable(true)}
            sx={{ marginLeft: 'auto', marginRight: '24px' }}>
            <CloseIcon></CloseIcon>
          </IconButtonComponent>
        </Stack>
        <DiscoveryTable
          columns={columns}
          elementId="DiscoveryTable"
          tableHeaderHeight={400}
          setIsFullScreen={() => null}
          filterRow={filterRow}
          disableToolBar={true}
        />
      </Box>
    </Draggable>
  );
}

ClustersCompaniesTable.propTypes = {
  currentClusterLabel: PropTypes.string,
  setHideTable: PropTypes.func,
  columns: PropTypes.array,
  filterRow: PropTypes.func,
  headerHeight: PropTypes.number,
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func
};

export default wrapWithError(ClustersCompaniesTable);
