import BaseClient from './BaseClient';

const URL_PREFIX = 'public_links';

export default class PublicLinksClient extends BaseClient {
  getPublicLinks(recordId) {
    return this.get(`${URL_PREFIX}?record_id=${recordId}`);
  }

  createPublicLink(recordId) {
    return this.post(URL_PREFIX, { record_id: recordId });
  }

  deletePublicLink(publicLinkId) {
    return this.httpDelete(`${URL_PREFIX}/${publicLinkId}`);
  }
}
