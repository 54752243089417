import { ICON_VARIANTS } from 'constants/icons';
import PropTypes from 'prop-types';
import React from 'react';

function ExploreIcon({ variant }) {
  let rectFill = 'transparent';
  if (variant === ICON_VARIANTS.PRIMARY) {
    rectFill = '#24A3A0';
  } else if (variant === ICON_VARIANTS.SECONDARY) {
    rectFill = 'white';
  }
  const pathFill = variant === ICON_VARIANTS.SECONDARY ? '#24A3A0' : 'white';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="4" fill={rectFill} />
      <path
        d="M5 12H5.77778M12 5V5.77778M18.2222 12H19M7.02222 7.02222L7.56667 7.56667M16.9778 7.02222L16.4333 7.56667M10.2111 15.8889H13.7889M9.66667 15.1111C9.0137 14.6214 8.53137 13.9386 8.288 13.1595C8.04464 12.3805 8.05257 11.5446 8.31068 10.7702C8.56878 9.9959 9.06398 9.32242 9.72613 8.84517C10.3883 8.36792 11.1838 8.11111 12 8.11111C12.8162 8.11111 13.6117 8.36792 14.2739 8.84517C14.936 9.32242 15.4312 9.9959 15.6893 10.7702C15.9474 11.5446 15.9554 12.3805 15.712 13.1595C15.4686 13.9386 14.9863 14.6214 14.3333 15.1111C14.0297 15.4117 13.801 15.7796 13.6659 16.1849C13.5308 16.5903 13.493 17.0218 13.5556 17.4444C13.5556 17.857 13.3917 18.2527 13.0999 18.5444C12.8082 18.8361 12.4126 19 12 19C11.5874 19 11.1918 18.8361 10.9001 18.5444C10.6083 18.2527 10.4444 17.857 10.4444 17.4444C10.507 17.0218 10.4692 16.5903 10.3341 16.1849C10.199 15.7796 9.97034 15.4117 9.66667 15.1111Z"
        stroke={pathFill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ExploreIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(ICON_VARIANTS))
};

ExploreIcon.defaultProps = {
  variant: ICON_VARIANTS.PRIMARY
};

export default ExploreIcon;
