import { Divider, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { loadOpinion } from 'actions/opinions/load-opinion-action';
import { makeOpinion } from 'actions/opinions/make-new-opinion-action';
import { updateOpinion } from 'actions/opinions/update-opinion-action';
import IconButtonComponent from 'components/IconButtonComponent';
import { MultipleChoiceComponent } from 'components/MultipleChoiceComponent';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import DislikeIcon from 'icons/DislikeIcon';
import LikeIcon from 'icons/LikeIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getOpinionBySearchIdAndInsightId } from 'selectors/opinions';

const OpinionValue = Object.freeze({
  LIKE: 1,
  NEUTRAL: 0,
  DISLIKE: -1
});

const DislikeCommentOptions = Object.freeze([
  'Irrelevant information',
  'Missing information',
  'Insight not useful'
]);

const LikeCommentOptions = Object.freeze([
  'Saved effort',
  'Incremental information',
  'New insight type'
]);

function OpinionComponent({ insightId, recordId, visible }) {
  const { state } = useAppState();
  const opinion = getOpinionBySearchIdAndInsightId(state, recordId, insightId);
  const [opinionVal, setOpinionVal] = useState(null);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [commentText, setCommentText] = useState(null);
  const [commentSelections, setCommentSelections] = useState(new Set());

  useEffect(() => {
    if (opinion && Object.keys(opinion).length > 0) {
      setOpinionVal((prev) => prev || opinion.value || OpinionValue.NEUTRAL);
    }
  }, [opinion]);

  useEffect(() => {
    dispatch(loadOpinion(recordId, insightId));
  }, [recordId, insightId]);

  const handleChecklistChange = (update) => {
    setCommentSelections({ ...commentSelections, ...update });
  };

  function onOpinionClick(val) {
    if (opinionVal === null) return;
    if (opinion?.valid) {
      const value = opinionVal === val ? OpinionValue.NEUTRAL : val;
      setOpinionVal(value);
      setOpenCommentDialog(true);
    }
  }

  function onFinishDialog(val, comment) {
    setOpenCommentDialog(false);
    if (opinion?.valid) {
      const newOpinion = {};
      if (val !== null) newOpinion.value = val;
      if (comment !== null) newOpinion.comment = comment;
      if (opinion?.opinion_exists) {
        dispatch(updateOpinion(recordId, insightId, newOpinion));
      } else {
        dispatch(makeOpinion(recordId, insightId, newOpinion));
      }
    }
  }

  function onSubmitComment() {
    const allText = [];
    if (commentSelections?.comments) allText.push(commentSelections.comments.join(', '));
    if (commentText) allText.push(commentText);
    setCommentText(null);
    setCommentSelections(new Set());
    onFinishDialog(opinionVal, allText.join(', '));
  }

  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      onClick={(event) => event.stopPropagation()}
      sx={{ visibility: visible ? null : 'hidden' }}>
      <IconButtonComponent
        tag="Helpful"
        variant="secondary"
        severity={'error'}
        message={
          !opinion?.valid && opinionVal !== null
            ? 'You do not have permission for this action.'
            : null
        }
        onClick={() => onOpinionClick(OpinionValue.LIKE)}
        sx={{ padding: '2px' }}>
        <LikeIcon selected={opinionVal === OpinionValue.LIKE} disabled={opinionVal === null} />
      </IconButtonComponent>
      <IconButtonComponent
        tag="Unhelpful"
        variant="secondary"
        severity={'error'}
        message={
          !opinion?.valid && opinionVal !== null
            ? 'You do not have permission for this action.'
            : null
        }
        onClick={() => onOpinionClick(OpinionValue.DISLIKE)}
        sx={{ padding: '2px' }}>
        <DislikeIcon
          selected={opinionVal === OpinionValue.DISLIKE}
          disabled={opinionVal === null}
        />
      </IconButtonComponent>
      <Dialog
        PaperProps={{
          style: {
            padding: '8px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px'
          }
        }}
        open={openCommentDialog}
        onClose={() => {
          if (opinionVal === OpinionValue.NEUTRAL) {
            setOpinionVal(opinion?.value);
            setOpenCommentDialog(false);
            return;
          }
          onFinishDialog(opinionVal, null);
        }}>
        <DialogTitle variant="paragraphBold" color="secondary.secondary6">
          {opinionVal === OpinionValue.DISLIKE && 'Help us improve. What was the problem?'}
          {opinionVal === OpinionValue.LIKE && 'Help us improve. What did you like?'}
          {opinionVal === OpinionValue.NEUTRAL &&
            `Are you sure you want to remove your ${
              opinion?.value === OpinionValue.LIKE ? 'Like' : 'Dislike'
            }?`}
        </DialogTitle>
        {opinionVal === OpinionValue.NEUTRAL ? (
          <DialogActions>
            <Button
              onClick={() => {
                setOpinionVal(opinion?.value);
                setOpenCommentDialog(false);
              }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => onFinishDialog(opinionVal, null)}>
              Yes
            </Button>
          </DialogActions>
        ) : (
          <>
            <Divider flexItem />
            <MultipleChoiceComponent
              labels={
                opinionVal === OpinionValue.DISLIKE ? DislikeCommentOptions : LikeCommentOptions
              }
              onChange={(newValue) => handleChecklistChange({ comments: newValue })}
            />
            <TextareaAutosize
              aria-label="comment textarea"
              placeholder="Leave a comment..."
              style={{ padding: '8px', fontFamily: 'Inter' }}
              maxRows={4}
              minRows={4}
              onChange={(event) => setCommentText(event.currentTarget.value)}
            />
            <DialogActions>
              <Button onClick={() => onFinishDialog(opinionVal, null)}>Skip</Button>
              <Button variant="contained" onClick={() => onSubmitComment()}>
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Stack>
  );
}

OpinionComponent.propTypes = {
  recordId: PropTypes.string.isRequired,
  insightId: PropTypes.string.isRequired,
  visible: PropTypes.bool
};

OpinionComponent.defaultProps = {
  visible: true
};

export default OpinionComponent;
