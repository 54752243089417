import { loadClustersForDiscoveryAction } from 'actions/clusters/load-clusters-for-discovery-action';
import { updateAllExtraDataForCompaniesInListAction } from 'actions/company_extra_data/update-extra-data-for-companies-in-list-action';
import { RESOURCE_TYPES } from 'constants/resources';
import { addExploreList } from 'reducer/explore-slice';
import { isForbidden } from 'utils/error-utils';

import { translateListRecordForState } from './load-all-explores-action';

export function isCompanyNeedUpdate(dataStatus) {
  return dataStatus === 'NONE' || dataStatus === 'FAILED';
}

export function loadExplore(companyListId, forbiddenResource, updateExtraData = true) {
  return (dispatch, _getState, apiClient) => {
    apiClient.explore
      .fetchExploreList(companyListId)
      .then((res) => {
        if (res.ok && res.data) {
          const exploreData = translateListRecordForState(res.data);
          dispatch(addExploreList({ listId: companyListId, data: exploreData }));
          if (updateExtraData) {
            dispatch(updateAllExtraDataForCompaniesInListAction(companyListId));
          }

          if (exploreData.clustersId) {
            dispatch(loadClustersForDiscoveryAction(exploreData.clustersId));
          }
        } else if (isForbidden(res.status) && forbiddenResource) {
          forbiddenResource(companyListId, RESOURCE_TYPES.LIST);
        }
      })
      .catch(() => {
        if (forbiddenResource) {
          forbiddenResource(companyListId, RESOURCE_TYPES.LIST);
        }
      });
  };
}
