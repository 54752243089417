import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';
import { SENTIMENT_COLORS } from 'utils/charts-utils';

function SentimentComponent({ sentiment }) {
  const color = SENTIMENT_COLORS[sentiment.toLowerCase()] || appTheme.palette.greyColors.grey200;
  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <Box
        component="span"
        sx={{ width: '8px', height: '8px', backgroundColor: color, borderRadius: '50%' }}
      />
      <Typography variant="paragraphSemiBold" color={color}>
        {sentiment}
      </Typography>
    </Stack>
  );
}

SentimentComponent.propTypes = {
  sentiment: PropTypes.string
};

export default SentimentComponent;
