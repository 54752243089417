import { MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { LabelList, Scatter } from 'recharts';

import ScatterPlotBaseComponent from './ScatterPlotBaseComponent';
import ScatterShape from './ScatterShape';
import warpPoints, { getConfigByElementId } from './scatterUtils';

/* eslint-disable id-length */
export default function ScatterPlotLogoComponent({
  title,
  xAxisValues,
  yAxisValues,
  xAxisName,
  yAxisName,
  labels,
  selectedIndex,
  mode,
  elementId,
  clickHandler
}) {
  const data = useMemo(() => {
    const { logoSize, warpThreshold } = getConfigByElementId(elementId);
    const { xWarped, yWarped } = warpPoints(xAxisValues, yAxisValues, warpThreshold);
    return xAxisValues.map((xVal, index) => ({
      x: xVal,
      y: yAxisValues[index],
      xWarped: xWarped[index],
      yWarped: yWarped[index],
      label: labels[index].name ? labels[index].name : null,
      link: labels[index].url && !clickHandler ? labels[index].url : null,
      onClick: () => clickHandler(index),
      isSelected: index === selectedIndex,
      scatterShapeUrl: labels[index].image_url ? labels[index].image_url : null,
      scatterShapeSize: logoSize,
      textLabel: labels[index].image_url ? null : labels[index].name
    }));
  }, [xAxisValues, yAxisValues, labels, elementId, clickHandler, selectedIndex]);

  return (
    <ScatterPlotBaseComponent
      title={title}
      xAxisName={xAxisName}
      yAxisName={yAxisName}
      dataKeyX="xWarped"
      dataKeyY="yWarped"
      mode={mode}>
      <Scatter name={title} data={data} shape={<ScatterShape />}>
        <LabelList dataKey="textLabel" position="bottom" />
      </Scatter>
    </ScatterPlotBaseComponent>
  );
}

ScatterPlotLogoComponent.propTypes = {
  title: PropTypes.string,
  xAxisValues: PropTypes.array,
  yAxisValues: PropTypes.array,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  labels: PropTypes.array,
  selectedIndex: PropTypes.number,
  mode: PropTypes.oneOf(MODES).isRequired,
  elementId: PropTypes.string,
  clickHandler: PropTypes.func
};
