import React from 'react';

export default function CheckIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <rect
        x="3.16943"
        y="7.55225"
        width="1"
        height="5"
        transform="rotate(-49.5128 3.16943 7.55225)"
        fill="white"
      />
      <rect
        x="7.63672"
        y="11.3672"
        width="1"
        height="8"
        transform="rotate(-139.513 7.63672 11.3672)"
        fill="white"
      />
    </svg>
  );
}
