import { sendUserEvent } from 'actions/users/send-user-event-action';
import { USER_EVENTS } from 'constants/userEvents';

export function handleErrorAction(error, errorInfo, props = {}) {
  return (_state, dispatch) => {
    /* eslint-disable no-console */
    console.log('ERROR | ', error, errorInfo);
    const goodPropKeys = Object.keys(props).filter((key) => typeof props[key] === 'string');
    const goodProps = {};
    goodPropKeys.forEach((key) => {
      goodProps[key] = props[key];
    });
    const context = {
      message: error?.message,
      props: JSON.stringify(goodProps),
      stack: errorInfo?.componentStack ? JSON.stringify(errorInfo?.componentStack) : error?.stack,
      url: window.location.href
    };
    dispatch(sendUserEvent(USER_EVENTS.FRONTEND_ERROR, context));
  };
}
