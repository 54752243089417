import React from 'react';

export default function IndustryIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.7806 1.55371C5.83559 1.55371 5.06951 2.31979 5.06951 3.26481V4.28396H6.4534V3.26481C6.4534 3.08409 6.59989 2.9376 6.7806 2.9376H9.20153C9.38224 2.9376 9.52873 3.08409 9.52873 3.26481V4.28396H10.9126V3.26481C10.9126 2.31979 10.1465 1.55371 9.20153 1.55371H6.7806Z"
        fill="#5F71F6"
      />
      <path
        d="M2.46458 4.29795C1.95505 4.29795 1.54199 4.71101 1.54199 5.22054V13.5239C1.54199 14.0334 1.95505 14.4465 2.46458 14.4465H13.5357C14.0452 14.4465 14.4583 14.0334 14.4583 13.5239V5.22054C14.4583 4.71101 14.0452 4.29795 13.5357 4.29795H2.46458Z"
        fill="#5F71F6"
      />
      <path
        d="M2.46458 4.2981C1.95505 4.2981 1.54199 4.71115 1.54199 5.22069V7.06587C1.54199 8.59447 2.78117 9.83364 4.30977 9.83364H6.61625C6.61625 10.3432 7.0293 10.8998 7.53884 10.8998H8.46143C8.97096 10.8998 9.38402 10.3432 9.38402 9.83364H11.6905C13.2191 9.83364 14.4583 8.59447 14.4583 7.06587V5.22069C14.4583 4.71115 14.0452 4.2981 13.5357 4.2981H2.46458Z"
        fill="#8EBCFC"
      />
      <rect x="6.15479" y="8.90942" width="3.69037" height="2.30648" rx="0.5" fill="white" />
    </svg>
  );
}
