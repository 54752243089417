import { LinearProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getExploreCompaniesLoadingByListId } from 'selectors/explore-table-ui';

const BULK_SIZE = 20;

function DiscoveryTableLoader({ listId }) {
  const companiesLoading = useSelector((state) =>
    getExploreCompaniesLoadingByListId(state, listId)
  );
  const numberOfCompanies = companiesLoading ? Object.keys(companiesLoading).length : 0;

  const showLabel = numberOfCompanies > BULK_SIZE;

  return showLabel ? (
    <Stack direction="row" alignItems="flex-start" gap="8px" width="100%">
      <Typography
        color="primary.primary200"
        variant="paragraphBold"
        sx={{
          transform: 'translateY(-72px)',
          padding: '0 40px',
          zIndex: 2
        }}>{`Adding ${numberOfCompanies} Companies`}</Typography>
      <LinearProgress variant="indeterminate" sx={{ flex: 1 }} />
    </Stack>
  ) : (
    <LinearProgress />
  );
}

DiscoveryTableLoader.propTypes = {
  listId: PropTypes.number
};

export default DiscoveryTableLoader;
