import { Box, Button, Stack, Typography } from '@mui/material';
import { removeRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import IconButtonComponent from 'components/IconButtonComponent';
import { dispatch } from 'hooks/AppStateProvider';
import CheckIcon from 'icons/CheckIcon';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';

export default function FinishedRunningSearch({ runNameRender, name, onSeeResults, runId }) {
  const removeRunFromState = () => dispatch(removeRecentlyFinishedRun(runId));

  const onClick = () => {
    onSeeResults();
    removeRunFromState();
  };
  return (
    <Stack color="primary.primary50" direction="row" alignItems="center" gap="24px">
      {runNameRender || (
        <Typography variant="paragraphBold" color="primary.primary100" textAlign="left" flex={1}>
          {name}
        </Typography>
      )}
      <Stack direction="row" alignItems="center">
        <Box width="24px" height="24px" display="flex" paddingRight="4px" alignItems="center">
          <CheckIcon />
        </Box>
        <Box>
          <Typography noWrap variant="paragraph" color="greyColors.grey300">
            Research Complete
          </Typography>
        </Box>

        <Button sx={{ width: '100%' }} onClick={onClick}>
          <Typography noWrap variant="paragraphBold" color="primary.primary50">
            See Results
          </Typography>
        </Button>
        <IconButtonComponent tag="Close" onClick={removeRunFromState}>
          <CloseIcon />
        </IconButtonComponent>
      </Stack>
    </Stack>
  );
}

FinishedRunningSearch.propTypes = {
  runNameRender: PropTypes.element,
  runId: PropTypes.string,
  onSeeResults: PropTypes.func,
  name: PropTypes.string
};
