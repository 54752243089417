import IconButtonComponent from 'components/IconButtonComponent';
import PropTypes from 'prop-types';

export default function RenderButton({ value }) {
  return (
    <IconButtonComponent onClick={value.onClick} sx={value.sx || {}}>
      {value.icon}
    </IconButtonComponent>
  );
}

RenderButton.propTypes = {
  value: PropTypes.any
};
