import { updateAllPublicShareLinks, updateUiSettingsLoadingState } from 'reducer/puclic-shares';

export function loadAllPublicShareLinksAction(runId) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateUiSettingsLoadingState({ runId, isLoading: true }));
    apiClient.publicLinks
      .getPublicLinks(runId)
      .then((res) => {
        if (res.ok && res.data) {
          const { public_links: publicLinks } = res.data;
          const publicShareLinks = publicLinks.reduce((result, publicLink) => {
            result[publicLink.id] = publicLink;
            return result;
          }, {});
          dispatch(updateAllPublicShareLinks({ runId, publicShareLinks }));
        }
      })
      .finally(() => {
        dispatch(updateUiSettingsLoadingState({ runId, isLoading: false }));
      });
  };
}
