import { loadExplore } from 'actions/explore/load-explore-action';
import { updateRecentlyFinishedRun } from 'actions/update-recently-finished-run';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { updateDiscoveryProgress, updateDiscoveryStatuses } from 'reducer/explore-slice';

export function monitorDiscoveryProgressAction(searchId, companyListId) {
  return (dispatch, _getState, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.discoveries.fetchDiscoveryProgress(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { progress } = res.data;
          dispatch(updateDiscoveryProgress({ searchId, progress }));
          if (progress >= 100) {
            clearInterval(tickerId);
            dispatch(loadExplore(companyListId));
            oldDispatch(updateRecentlyFinishedRun(searchId, 'discoveries'));
          }
        }
      });
    };
    tickerId = setInterval(ticker, 15000);
    ticker();
  };
}

export function monitorDiscoveryStatusesAction(searchId) {
  return (dispatch, _getState, apiClient) => {
    let tickerId = null;
    const ticker = () => {
      apiClient.discoveries.getDiscoveryStatuses(searchId).then((res) => {
        if (!res.ok) {
          clearInterval(tickerId);
          return;
        }
        if (res.ok) {
          const { status, statuses } = res.data;
          dispatch(updateDiscoveryStatuses({ searchId, statuses }));
          if (status !== 'WAITING') {
            clearInterval(tickerId);
          }
        }
      });
    };
    tickerId = setInterval(ticker, 20000);
    ticker();
  };
}
