export default function ScreeningIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5.48987 5.45898C5.21932 5.45898 5 5.68193 5 5.95695C5 6.23197 5.21932 6.45491 5.48987 6.45491H12.3277C12.5983 6.45491 12.8176 6.23197 12.8176 5.95695C12.8176 5.68193 12.5983 5.45898 12.3277 5.45898H5.48987Z"
        fill="#0F2345"
      />
      <path
        d="M5.48987 8.87488C5.21932 8.87488 5 9.09783 5 9.37285C5 9.64787 5.21932 9.87081 5.48987 9.87081H10.4059C10.6764 9.87081 10.8958 9.64787 10.8958 9.37285C10.8958 9.09783 10.6764 8.87488 10.4059 8.87488H5.48987Z"
        fill="#0F2345"
      />
      <path
        d="M5 12.7885C5 12.5135 5.21932 12.2905 5.48987 12.2905H8.44757C8.71811 12.2905 8.93743 12.5135 8.93743 12.7885C8.93743 13.0635 8.71811 13.2864 8.44757 13.2864H5.48987C5.21932 13.2864 5 13.0635 5 12.7885Z"
        fill="#0F2345"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.5C2 3.11929 3.11929 2 4.5 2H14C15.3807 2 16.5 3.11929 16.5 4.5V12L15.5 11.5V4.5C15.5 3.67157 14.8284 3 14 3H4.5C3.67157 3 3 3.67157 3 4.5V16C3 16.8284 3.67157 17.5 4.5 17.5H10.5L11 18.5H4.5C3.11929 18.5 2 17.3807 2 16V4.5Z"
        fill="#0F2345"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7314 15.8143C18.7314 13.654 16.979 11.9027 14.8173 11.9027C12.6556 11.9027 10.9032 13.654 10.9032 15.8143C10.9032 17.9747 12.6556 19.726 14.8173 19.726C16.979 19.726 18.7314 17.9747 18.7314 15.8143ZM19.6346 15.8143C19.6346 13.1554 17.4779 11 14.8173 11C12.1568 11 10 13.1554 10 15.8143C10 18.4732 12.1568 20.6287 14.8173 20.6287C15.8308 20.6287 16.7711 20.3159 17.547 19.7817C17.5526 19.7868 17.5582 19.7918 17.564 19.7966L21.2581 22.8944C21.4491 23.0546 21.734 23.0297 21.8943 22.8388C22.0547 22.6478 22.0297 22.3631 21.8387 22.2029L18.2492 19.1929C19.106 18.3238 19.6346 17.1307 19.6346 15.8143Z"
        fill="#0F2345"
      />
    </svg>
  );
}
