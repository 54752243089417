import PropTypes from 'prop-types';
import React from 'react';

import MultipleSelectionFilerComponent from './components/MultipleSelectionFilerComponent';

function CustomBoolColumnFilter({ columnId, ...args }) {
  return (
    <MultipleSelectionFilerComponent
      columnId={columnId}
      rowMap={(row) => {
        if (row?.value) return 'Yes';
        if (row?.value === false) return 'No';
        return 'Not set';
      }}
      {...args}
    />
  );
}

CustomBoolColumnFilter.propTypes = {
  columnId: PropTypes.string
};

export default CustomBoolColumnFilter;
