import moment from 'moment';
import { appTheme } from 'theme';

import { formatTimestamp } from './time-utils';

function sentimentTickFormatter(tick) {
  switch (tick) {
    case 0.3:
      return 'Positive';
    case -0.3:
      return 'Negative';
    case 0:
      return 'Neutral';
    default:
      return '';
  }
}

export const unixTimeToString = (unixTime) => moment(unixTime).format('MMM YYYY');
export const unixTimetoDateString = (unixTime) => moment(unixTime).format('YYYY-MM-DD');
export const unixTimeToStringDay = (unixTime) => moment(unixTime).format('DD MMM YYYY');

const daysDifference = (unixTime1, unixTime2) => (unixTime2 - unixTime1) / 1000 / 24 / 60 / 60;

/* eslint-disable max-lines */
export const determineEventTickFormatter = (unixTime1, unixTime2) => {
  const diff = daysDifference(unixTime1, unixTime2);
  if (diff < 100) {
    return unixTimeToStringDay;
  }
  return unixTimeToString;
};

export const SENTIMENT_COLORS = Object.freeze({
  positive: appTheme.palette.sentiment.darkGreen,
  negative: appTheme.palette.sentiment.red,
  neutral: appTheme.palette.greyColors.grey200
});

/* eslint-disable id-length */
const sentimentConfig = {
  axes: {
    y: {
      domain: [-0.3, 0.3],
      ticks: [-0.3, 0, 0.3],
      tickFormatter: sentimentTickFormatter
    }
  },
  stroke: 'sentiment',
  hoverGradient: 'none'
};

const starConfig = {
  axes: {
    y: {
      domain: [1, 5],
      ticks: [1, 2, 3, 4, 5]
    },
    x: {
      domain: [1, 5],
      ticks: [1, 2, 3, 4, 5],
      tickFormatter: unixTimeToString
    }
  }
};

const ratioConfig = {
  axes: {
    y: {
      domain: [(dataMin) => Math.min(dataMin, 0), (dataMax) => Math.max(dataMax, 0)],
      noDecimals: true
    }
  },
  stroke: 'peratio',
  hoverGradient: 'url(#pehover)',
  referenceLines: [
    {
      axis: 'y',
      value: 0,
      stroke: 'black'
    }
  ]
};

const GRAPH_CONFIG = {
  StackSentimentTimeSeries: sentimentConfig,
  StackSegmentedSentimentTimeSeries: sentimentConfig,
  TwitterSentiment: sentimentConfig,
  RedditSentimentTimeSeries: sentimentConfig,
  RedditSegmentedSentimentTimeSeries: sentimentConfig,
  G2ReviewsSentimentTimeSeries: sentimentConfig,
  ReviewsSentimentTimeSeries: sentimentConfig,
  TrustReviewsSentimentTimeSeries: sentimentConfig,
  CapterraReviewsSentimentTimeSeries: sentimentConfig,
  G2ReviewsStarTimeSeries: starConfig,
  ReviewsStarTimeSeries: starConfig,
  TrustReviewsStarTimeSeries: starConfig,
  CapterraReviewsStarTimeSeries: starConfig,
  G2ReviewsStarSizeTimeSeries: starConfig,
  G2ReviewsStarRoleTimeSeries: starConfig,
  PERatio: ratioConfig,
  CommunityPerceptionBenchmarkElement: { axes: { y: { tickFormatter: Math.floor } } }
};

const GRAPH_COLORS = [
  appTheme.palette.graphColors.graph1,
  appTheme.palette.graphColors.graph2,
  appTheme.palette.graphColors.graph3,
  appTheme.palette.graphColors.graph4,
  appTheme.palette.graphColors.graph5,
  appTheme.palette.graphColors.graph6,
  appTheme.palette.graphColors.graph7,
  appTheme.palette.graphColors.graph8,
  appTheme.palette.graphColors.graph9,
  appTheme.palette.graphColors.graph10,
  appTheme.palette.graphColors.graph11,
  appTheme.palette.graphColors.graph12
];

const BAR_COLORS = [7, 5, 1, 2, 3, 4];

const LINE_COLORS = [10, 2, 7, 6, 4, 5, 1, 8, 9];

const TIMELINE_COLORS = [4, 6, 7, 3, 1];

const BENCHMARK_COLORS = [2, 1, 7, 10];

const MULTI_BAR_COLORS = [2, 1, 10, 7];

const STAR_COLORS = {
  1: appTheme.palette.sentiment.red,
  2: appTheme.palette.sentiment.orange,
  3: appTheme.palette.sentiment.yellow,
  4: appTheme.palette.sentiment.lightGreen,
  5: appTheme.palette.sentiment.darkGreen
};

export function getGraphColorByIndex(index) {
  return GRAPH_COLORS[index % GRAPH_COLORS.length];
}

export function getBarColorByIndex(index) {
  return GRAPH_COLORS[BAR_COLORS[index % BAR_COLORS.length] - 1];
}

export function getLineColorByIndex(index) {
  return GRAPH_COLORS[LINE_COLORS[index % LINE_COLORS.length] - 1];
}

export function getTimelineColorByIndex(index) {
  return GRAPH_COLORS[TIMELINE_COLORS[index % TIMELINE_COLORS.length] - 1];
}

export function getMultiBarColorByIndex(index) {
  return GRAPH_COLORS[MULTI_BAR_COLORS[index % MULTI_BAR_COLORS.length] - 1];
}

export function getStarColorByStarCount(starCount) {
  return STAR_COLORS[parseInt(starCount, 10)] || 'gray';
}

export function getBenchmarkBarColorByIndexAndTotal(index, total) {
  if (index + 1 === total) {
    return appTheme.palette.background.bg150;
  }
  return GRAPH_COLORS[BENCHMARK_COLORS[index % BENCHMARK_COLORS.length] - 1];
}

/* eslint-disable id-length */
/* eslint-disable no-bitwise */
function lightenDarkenColor(col, index, total) {
  const usePound = true;
  const amt = -20 + (200 / total) * index;

  const num = parseInt(col.slice(1), 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const determinePieColor = (index, overrideColor, labels, elementId) => {
  if (elementId === 'SentimentPieChart') {
    switch (labels[index]) {
      case 'Positive':
        return SENTIMENT_COLORS.positive;
      case 'Negative':
        return SENTIMENT_COLORS.negative;
      default:
        return SENTIMENT_COLORS.neutral;
    }
  }
  if (overrideColor) {
    return lightenDarkenColor(overrideColor, index, labels.length);
  }
  return getGraphColorByIndex(index);
};

export function determineDomain(elementId, axis) {
  const config = GRAPH_CONFIG?.[elementId]?.axes?.[axis]?.domain;
  return config ? config : null;
}

export function determineTicks(elementId, axis, values) {
  const config = GRAPH_CONFIG?.[elementId]?.axes?.[axis]?.ticks;
  if (typeof config === 'string') {
    const min = Math.min(...values);
    const max = Math.max(...values);
    switch (config) {
      case 'ratio':
        return [
          Math.round(1.2 * min),
          Math.round(0.6 * min),
          0,
          Math.round(0.6 * max),
          Math.round(1.2 * max)
        ];
      default:
        return null;
    }
  }
  return config ? config : null;
}

export function determineTickFormatter(elementId, axis) {
  const config = GRAPH_CONFIG?.[elementId]?.axes?.[axis]?.tickFormatter;
  return config ? config : null;
}

export function determineAllowDecimals(elementId, axis) {
  const config = GRAPH_CONFIG?.[elementId]?.axes?.[axis]?.noDecimals;
  return !config;
}

export const dateStringToTick = (tickItem, allTicks) => {
  const years = allTicks.map((item) => formatTimestamp(item, 'yyyy'));
  const hasDuplicatesY = years.length !== [...new Set(years)].length;
  if (!hasDuplicatesY) {
    return formatTimestamp(tickItem, 'yyyy');
  }
  const monthYears = allTicks.map((item) => formatTimestamp(item, 'MMM yyyy'));
  const hasDuplicatesMY = monthYears.length !== [...new Set(monthYears)].length;
  if (!hasDuplicatesMY) {
    return formatTimestamp(tickItem, 'MMM yyyy');
  }
  return formatTimestamp(tickItem, 'dd.MM.yy');
};

export const unixTimeToTick = (tickItem, dateStringTicks) => {
  const dateStringTick = unixTimetoDateString(tickItem);
  return dateStringToTick(dateStringTick, dateStringTicks);
};

export function determineStroke(elementId, min, max) {
  const config = GRAPH_CONFIG?.[elementId]?.stroke;
  if (config && config === 'sentiment') {
    if (min >= 0.3) {
      return SENTIMENT_COLORS.positive;
    }
    if (max <= -0.3) {
      return SENTIMENT_COLORS.negative;
    }
    if (max < 0.1) {
      return 'url(#colorSentimentRB)';
    }
    if (min > -0.1) {
      return 'url(#colorSentimentBG)';
    }

    return 'url(#colorSentimentRBG)';
  }
  if (config && config === 'peratio') {
    return 'url(#colorPERatio)';
  }
  return config ? config : getLineColorByIndex(0);
}

export function determineHoverGradient(elementId) {
  const config = GRAPH_CONFIG?.[elementId]?.hoverGradient;
  return config ? config : false;
}

export function determineReferenceLines(elementId) {
  const lines = GRAPH_CONFIG?.[elementId]?.referenceLines;
  return lines ? lines : null;
}

export const mkTicks = (xMin, xMax, nTicks) =>
  [...Array(nTicks).keys()].map((index) => xMin + (index * (xMax - xMin)) / (nTicks - 1));

const NEUTRAL_GRAPH_COLORS = [
  '#9090F1',
  '#9B9BEC',
  '#AFAFF4',
  '#B9B9F5',
  '#C9C9F7',
  '#D7D7F9',
  '#E4E4FB',
  '#F2F2FD'
];

const NEGATIVE_GRAPH_COLORS = [
  '#E33C3C',
  '#FF4D4D',
  '#FF7A7A',
  '#FF8C8C',
  '#FFA3A3',
  '#FFB8B8',
  '#FFCCCC',
  '#FFE0E0'
];

const POSITIVE_GRAPH_COLORS = [
  '#28A792',
  '#52B8A7',
  '#7CCABD',
  '#A5DBD2',
  '#BAE4DD',
  '#CFEDE8',
  '#E9F4F0',
  '#DEEEE8'
];

export const determineTreemapColor = (index, sentiment) => {
  switch (sentiment) {
    case 'neutral':
      return NEUTRAL_GRAPH_COLORS[index % NEUTRAL_GRAPH_COLORS.length];
    case 'negative':
      return NEGATIVE_GRAPH_COLORS[index % NEGATIVE_GRAPH_COLORS.length];
    case 'positive':
      return POSITIVE_GRAPH_COLORS[index % POSITIVE_GRAPH_COLORS.length];
    default:
      return NEUTRAL_GRAPH_COLORS[index % NEUTRAL_GRAPH_COLORS.length];
  }
};
