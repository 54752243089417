import { loadAllUsers } from 'actions/users/load-all-users-action';

export function updateUserOrganizationAction(userId, organizationId) {
  return (_state, dispatch, apiClient) => {
    apiClient.users
      .updateUserOrganization(userId, { organization_id: organizationId })
      .then((res) => {
        if (res.ok) {
          dispatch(loadAllUsers());
        }
      });
  };
}
