import PropTypes from 'prop-types';
import React from 'react';

function HomePageIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.33333 20V14.6667C9.33333 14.1952 9.52063 13.743 9.85403 13.4096C10.1874 13.0762 10.6396 12.8889 11.1111 12.8889H12.8889C13.3604 12.8889 13.8126 13.0762 14.146 13.4096C14.4794 13.743 14.6667 14.1952 14.6667 14.6667V20M5.77778 12H4L12 4L20 12H18.2222V18.2222C18.2222 18.6937 18.0349 19.1459 17.7015 19.4793C17.3681 19.8127 16.9159 20 16.4444 20H7.55556C7.08406 20 6.63187 19.8127 6.29848 19.4793C5.96508 19.1459 5.77778 18.6937 5.77778 18.2222V12Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

HomePageIcon.propTypes = {
  color: PropTypes.string
};

HomePageIcon.defaultProps = {
  color: '#09162D'
};
export default HomePageIcon;
