import React from 'react';

export default function CommercialComperitorAnalysisIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M18.148 6.18506V18.9251H5.40796C5.40796 11.8851 11.118 6.18506 18.148 6.18506Z"
        fill="#0593A2"
      />
      <path
        d="M22.528 8.11499C29.468 8.11499 35.098 13.745 35.098 20.685C35.098 27.625 29.468 33.255 22.528 33.255C15.588 33.255 9.95801 27.625 9.95801 20.685H22.528V8.11499Z"
        fill="#8EBCFC"
      />
      <path
        d="M20.068 7.55493C27.318 7.55493 33.198 13.4349 33.198 20.6849C33.198 27.9349 27.318 33.8149 20.068 33.8149C12.818 33.8149 6.93799 27.9349 6.93799 20.6849H20.068V7.55493Z"
        fill="white"
      />
      <path
        d="M19.778 8.11496C26.718 8.11496 32.348 13.745 32.348 20.685C32.348 27.625 26.718 33.255 19.778 33.255C12.838 33.255 7.20799 27.625 7.20799 20.685H19.778V8.11496Z"
        fill="white"
      />
      <path
        d="M19.778 33.525C12.698 33.525 6.93799 27.765 6.93799 20.685C6.93799 20.535 7.05799 20.405 7.21799 20.405H19.508V8.11496C19.508 7.96496 19.628 7.83496 19.788 7.83496C26.868 7.83496 32.628 13.595 32.628 20.675C32.628 27.755 26.868 33.515 19.788 33.515L19.778 33.525ZM7.48799 20.955C7.63799 27.605 13.088 32.975 19.778 32.975C26.468 32.975 32.068 27.465 32.068 20.685C32.068 13.905 26.698 8.54496 20.048 8.39496V20.685C20.048 20.835 19.928 20.965 19.768 20.965H7.48799V20.955Z"
        fill="#374EF4"
      />
      <path
        d="M19.8478 20.975L20.0478 20.825L30.5178 13.875C28.3578 10.625 23.6678 8.505 20.0678 8.125L19.7778 20.975H19.8478Z"
        fill="#374EF4"
      />
    </svg>
  );
}
