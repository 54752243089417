import BaseClient from './BaseClient';

const URL_PREFIX = 'schedules';

export default class SchedulesClient extends BaseClient {
  fetchAllSchedules({ num = 50, all = false }) {
    return this.get(`${URL_PREFIX}?verbose=1&N=${num}&all=${all ? '1' : '0'}`);
  }

  createSchedule(schedule) {
    return this.post(`${URL_PREFIX}`, schedule);
  }

  deleteSchedule(scheduleId) {
    return this.httpDelete(`${URL_PREFIX}/${scheduleId}`);
  }
}
