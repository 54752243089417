import { updateExploreCompanyIsCheckedByCompanyListMetaId } from 'actions/explore-table-ui/update-discovery-ui-state-by-company-meta-id';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreTableUICheckedCompaniesByListId } from 'selectors/explore-table-ui';

import ClustersCompaniesTable from './ClustersCompaniesTable';

function ClustersCompaniesTableConnector({ columns, filterRow, listId, ...args }) {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const companiesIsChecked = useSelector((state) =>
    getExploreTableUICheckedCompaniesByListId(state, listId)
  );

  const getClusterComapnies = useCallback(
    () =>
      columns?.[0]?.values?.filter((company) =>
        filterRow({ company_name: { hash: company.hash } })
      ),
    [columns, filterRow]
  );

  const allCompaniesSelected = useMemo(() => {
    const filteredCompanies = getClusterComapnies();
    if (!filteredCompanies) return false;

    const unselectedCompany = filteredCompanies.find((company) => {
      return !companiesIsChecked?.[company.companyListMetaId];
    });
    return Boolean(unselectedCompany);
  }, [getClusterComapnies, companiesIsChecked]);

  const onCheck = (checked) => {
    setIsChecked(checked);
    const filteredCompanies = getClusterComapnies();
    filteredCompanies?.forEach((company) =>
      dispatch(
        updateExploreCompanyIsCheckedByCompanyListMetaId(listId, company.companyListMetaId, checked)
      )
    );
  };

  useEffect(() => {
    setIsChecked(!allCompaniesSelected);
  }, [allCompaniesSelected]);

  return (
    <ClustersCompaniesTable
      columns={columns}
      filterRow={filterRow}
      isChecked={isChecked}
      onCheck={onCheck}
      {...args}
    />
  );
}

ClustersCompaniesTableConnector.propTypes = {
  columns: PropTypes.array,
  filterRow: PropTypes.func,
  listId: PropTypes.number
};

export default ClustersCompaniesTableConnector;
