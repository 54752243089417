import { setDismissNotifications } from 'reducer/notifications-slice';

export function dismissNotificationAction(notificationIds, dismissed = true) {
  return (dispatch, _getState, apiClient) => {
    apiClient.notifications.dismissNotifications(notificationIds, dismissed).then((res) => {
      if (res.ok) {
        dispatch(setDismissNotifications({ notificationIds, dismissed }));
      }
    });
  };
}
