import { loadListAction } from './load-company-list-action';

export function submitHiddenGemsAction(listId) {
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists.submitHiddenGems(listId).then((res) => {
      if (res.ok) {
        dispatch(loadListAction(listId));
      }
    });
  };
}
