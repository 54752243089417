export const getCustomAnalytics = (state) => state.customAnalytics;
export const getCustomAnalyticsThreads = (state) => getCustomAnalytics(state).threads;
export const getCustomAnalyticsThreadById = (state, threadKey) =>
  getCustomAnalyticsThreads(state)[threadKey];
export const getIsCustomAnalyticsThreadIsReadyById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isReady;
export const getCustomAnalyticsThreadIdById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.threadId;
export const getCustomAnalyticsThreadPostsById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.posts;
export const getCustomAnalyticsThreadSpecificPostById = (state, threadKey, postId) =>
  getCustomAnalyticsThreadPostsById(state, threadKey)?.[postId];
export const getIsCustomAnalyticsThreadProccessingById = (state, threadKey) =>
  getCustomAnalyticsThreadById(state, threadKey)?.isProccessing;
export const getCustomAnalyticClipboard = (state) => getCustomAnalytics(state).clipboard;
export const getCustomAnalyticsOpen = (state) => getCustomAnalytics(state).customAnalyticsOpen;
export const getCustomAnalyticClipboardById = (state, threadKey) =>
  getCustomAnalyticClipboard(state)?.[threadKey];

export const getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const posts = getCustomAnalyticsThreadPostsById(state, threadKey);
  if (!posts) {
    return null;
  }
  return Object.values(posts).find((post) => post?.discoveryId === discoveryId);
};

export const getCustomAnalyticsDiscoveryPostIdByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.postId;
};

export const getCustomAnalyticsDiscoverySuggestionsByThreadIdAndDiscoveryId = (
  state,
  threadKey,
  discoveryId
) => {
  const post = getCustomAnalyticsDiscoveryPostByThreadIdAndDiscoveryId(
    state,
    threadKey,
    discoveryId
  );
  return post?.suggestions;
};
