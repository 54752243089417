import { updateIgnoreAmbiguousInExploreCompany } from 'reducer/explore-slice';

export function updateIgnoreAmbiguousInCompanyMeta(companyMetaId, ignoreAmbiguous, listId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.companyMetas.updateIgnoreAmbiguous(companyMetaId, ignoreAmbiguous).then((res) => {
      if (res.ok) {
        dispatch(updateIgnoreAmbiguousInExploreCompany({ companyMetaId, ignoreAmbiguous, listId }));
      }
    });
  };
}
