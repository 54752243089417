import { translateMetaRecordForState } from 'actions/explore/load-all-explores-action';
import { updateColumnDataLoading, updateListPartialColumnData } from 'reducer/explore-slice';

function updateExploreFinancePeerLoading(companyMetaId, listId, recompute) {
  return (dispatch) => {
    dispatch(
      updateColumnDataLoading({
        listId,
        companyMetaIds: [companyMetaId],
        recompute,
        statusField: 'finance_peer_data_status'
      })
    );
  };
}

export function updateExploreFinancePeerColumn(listId, newCompanies) {
  return (dispatch) => {
    dispatch(
      updateListPartialColumnData({
        listId,
        newCompanies: newCompanies?.map(translateMetaRecordForState),
        columns: ['finance_peer_data_status', 'finance_peer_data', 'finance_peer_data_last_updated']
      })
    );
  };
}

export function updateFinancePeerAction(companyMetaId, listId) {
  return (dispatch, _getState, apiClient) => {
    dispatch(updateExploreFinancePeerLoading(companyMetaId, listId, true));
    apiClient.companyMetas.updateFinancePeer(companyMetaId).then((res) => {
      if (res.ok && res.data) {
        const { company_metas } = res.data;
        dispatch(updateExploreFinancePeerColumn(listId, company_metas));
      }
    });
  };
}
