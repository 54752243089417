import { Button, Stack, TextField } from '@mui/material';
import { sendMessageToGeneralChannel } from 'actions/pusher/send-message-to-general-channel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGeneralChannelName } from 'utils/pusher-utils';

export default function SendPusherMessagesTab() {
  const dispatch = useDispatch();
  const [eventName, setEventName] = useState('');
  const [message, setMessage] = useState('');
  const channelName = getGeneralChannelName();

  const onSubmitNewVersion = (force_refresh) => {
    const versionEventName = 'new-version';
    const versionMessage = { message: 'New version is available', force_refresh };

    dispatch(sendMessageToGeneralChannel(channelName, versionEventName, versionMessage));
  };

  const onSubmitNewMessage = () => {
    if (!eventName) return;
    dispatch(sendMessageToGeneralChannel(channelName, eventName, message));
  };

  return (
    <Stack justifyContent="center" alignItems="center" gap="16px">
      <Button variant="contained" onClick={() => onSubmitNewVersion(false)}>
        Suggest all users to refresh the page
      </Button>
      Or
      <Button variant="contained" onClick={() => onSubmitNewVersion(true)}>
        Force refresh for all users
      </Button>
      Or
      <Stack
        border="1px solid"
        borderColor="greyColors.grey300"
        borderRadius="4px"
        gap="8px"
        padding="6px">
        <TextField
          label="Event name for binding"
          variant="outlined"
          value={eventName}
          onChange={(event) => setEventName(event.target.value)}
          placeholder="Event name">
          {eventName}
        </TextField>
        <TextField
          label="Message text to send"
          variant="outlined"
          value={message}
          onChange={(event) => setMessage(event.target.value)}>
          {message}
        </TextField>
        <Button variant="contained" onClick={onSubmitNewMessage}>
          Send Message To All Users
        </Button>
      </Stack>
    </Stack>
  );
}
