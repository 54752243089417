import { addNewFolder, removeFolder } from 'reducer/folders-slice';
import { getFolderById } from 'selectors/folders';

export function deleteFolderByIdAction(folderId) {
  return (dispatch, getState, apiClient) => {
    const currentFolder = getFolderById(getState(), folderId);
    dispatch(removeFolder({ folderId }));
    apiClient.folders
      .deleteFolder(folderId)
      .then((res) => {
        if (!res.ok) {
          dispatch(addNewFolder({ folder: currentFolder }));
        }
      })
      .catch(() => dispatch(addNewFolder({ folder: currentFolder })));
  };
}
