import { ACTIONS } from 'constants/actions';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { updateClustersIdsInExplore } from 'reducer/explore-slice';

import { translateClustersRecordForState } from './load-clusters-for-discovery-action';

function addClustersRecords(clustersRecords) {
  return { type: ACTIONS.ADD_CLUSTERS_RECORDS, clustersRecords };
}
export function loadAllClustersAction(listId) {
  return (dispatch, _getState, apiClient) => {
    apiClient.tagging.fetchAllClusters(listId).then((res) => {
      if (res.ok && res.data?.records) {
        const { records } = res.data;
        const recordsData = records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateClustersRecordForState(data) };
        }, {});
        if (listId) {
          dispatch(updateClustersIdsInExplore({ listId, searchIds: Object.keys(recordsData) }));
        }
        oldDispatch(addClustersRecords(recordsData));
      }
    });
  };
}
