import { translateOrganizationForState } from 'actions/runs/load-all-runs-action';
import { ACTIONS } from 'constants/actions';

function addNonMathlabsSearches(searches) {
  return { type: ACTIONS.ADD_NON_MATHLABS_SEARCHES, searches };
}

export function translateNonMathlabsRecordForState(record) {
  return {
    id: record.id,
    recordId: record.id,
    name: record.name,
    statuses: record.statuses,
    timeSubmitted: record.time_submitted,
    owner: record?.user?.email,
    recordType: record.record_type,
    companyListId: record.company_list_id,
    ...translateOrganizationForState(record?.user?.organization)
  };
}

export function loadAllNonMathlabsSearchesAction() {
  return (_state, dispatch, apiClient) => {
    apiClient.searches.fetchNonMathlabsRecords({ num: 100 }).then((res) => {
      if (res.ok && res.data) {
        const searchesData = res.data?.records.reduce((acc, dataItem) => {
          const data = { ...dataItem };
          return { ...acc, [data.id]: translateNonMathlabsRecordForState(data) };
        }, {});
        dispatch(addNonMathlabsSearches(searchesData));
      }
    });
  };
}
