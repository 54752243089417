import React from 'react';

export default function TwitterInsightIcon() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0425 10.6004C24.9164 10.4956 25.7478 10.2639 26.5216 9.92104L26.5198 9.92382C25.9404 10.7903 25.2114 11.5466 24.3669 12.1573C24.3752 12.3427 24.3798 12.5299 24.3798 12.7153C24.3798 18.4205 20.0379 24.9987 12.0954 24.9987C9.74917 25.0014 7.45182 24.3279 5.47827 23.059C5.82032 23.0992 6.16444 23.1193 6.50884 23.1192C8.45311 23.1221 10.3418 22.4709 11.8712 21.2703C10.9703 21.2538 10.0971 20.956 9.37385 20.4185C8.65062 19.8811 8.11355 19.131 7.83784 18.2731C8.48504 18.3965 9.15181 18.3711 9.78777 18.199C8.81056 18.0015 7.93175 17.472 7.30048 16.7004C6.66921 15.9287 6.32435 14.9625 6.32442 13.9655V13.9099C6.92392 14.2431 7.59435 14.4284 8.27991 14.4502C7.68878 14.0554 7.20423 13.5208 6.86925 12.8939C6.53427 12.2669 6.35922 11.567 6.35963 10.8562C6.35963 10.0665 6.57279 9.32512 6.9435 8.68565C8.02768 10.0194 9.3802 11.1103 10.9133 11.8876C12.4463 12.6648 14.1257 13.111 15.8424 13.1972C15.6251 12.2711 15.7196 11.2992 16.1113 10.4323C16.503 9.56549 17.1699 8.8522 18.0085 8.40321C18.8471 7.95423 19.8105 7.79466 20.749 7.9493C21.6876 8.10393 22.5489 8.56411 23.1991 9.25839C24.1661 9.06858 25.0933 8.71437 25.9405 8.21114C25.6189 9.21156 24.9443 10.0608 24.0425 10.6004Z"
        fill="#374EF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16.4453C32 25.2819 24.8366 32.4453 16 32.4453C7.16344 32.4453 0 25.2819 0 16.4453C0 7.60876 7.16344 0.445312 16 0.445312C24.8366 0.445312 32 7.60876 32 16.4453ZM31.5 16.4453C31.5 25.0057 24.5604 31.9453 16 31.9453C7.43959 31.9453 0.5 25.0057 0.5 16.4453C0.5 7.8849 7.43959 0.945312 16 0.945312C24.5604 0.945312 31.5 7.8849 31.5 16.4453Z"
        fill="#374EF4"
      />
    </svg>
  );
}
