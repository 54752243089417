import { PropTypes } from 'prop-types';
import React from 'react';

export default function LinkedinIcon({ color }) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9326 13.5407H11.5619V9.88891C11.5619 9.01812 11.5461 7.89711 10.3289 7.89711C9.09412 7.89711 8.90518 8.84592 8.90518 9.82553V13.5405H6.53455V6.0309H8.81036V7.05717H8.84224C9.06999 6.67412 9.39911 6.359 9.79454 6.14538C10.19 5.93176 10.6369 5.82763 11.0877 5.84408C13.4906 5.84408 13.9336 7.39867 13.9336 9.42109L13.9326 13.5407ZM3.85955 5.00445C3.09974 5.00457 2.48368 4.39879 2.48355 3.65144C2.48343 2.90408 3.09924 2.29812 3.85905 2.29799C4.61887 2.29781 5.23493 2.90359 5.23505 3.65095C5.23512 4.00984 5.09024 4.35406 4.83229 4.60789C4.57433 4.86172 4.22443 5.00437 3.85955 5.00445ZM5.04493 13.5408H2.67174V6.0309H5.04487V13.5407L5.04493 13.5408ZM15.1144 0.132402H1.48049C0.836117 0.12527 0.30768 0.632996 0.299805 1.26681V14.7332C0.30743 15.3673 0.835804 15.8755 1.48043 15.8689H15.1144C15.7604 15.8767 16.2909 15.3685 16.2998 14.7332V1.26576C16.2906 0.630721 15.7601 0.123057 15.1144 0.131356"
        fill={color || '#0A66C2'}
      />
    </svg>
  );
}

LinkedinIcon.propTypes = {
  color: PropTypes.string
};
