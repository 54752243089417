import { Box, Link, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function SmallCompanyLogoComponent({ tooltipText, linkUrl, imageUrl, size }) {
  const imgSize = size ? size : '20px';
  if (tooltipText) {
    if (linkUrl) {
      return (
        <Tooltip title={tooltipText}>
          <Link href={linkUrl} underline="none" target="_blank" rel="noreferrer">
            <Box component="img" src={imageUrl} width={imgSize} height={imgSize} />
          </Link>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={tooltipText}>
        <Box component="img" src={imageUrl} width={imgSize} height={imgSize} />
      </Tooltip>
    );
  }
  if (linkUrl) {
    <Link href={linkUrl} underline="none" target="_blank" rel="noreferrer">
      <Box component="img" src={imageUrl} width={imgSize} height={imgSize} />
    </Link>;
  }
  return <Box component="img" src={imageUrl} width={imgSize} height={imgSize} />;
}

SmallCompanyLogoComponent.propTypes = {
  tooltipText: PropTypes.string,
  linkUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  size: PropTypes.string
};

export default SmallCompanyLogoComponent;
