import { getReactState } from 'actions/get-react-state-action';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getExploreTableSelectedCompanies } from 'selectors/explore-table-ui';
import { getUserIsLimited, getUserRunsLeft } from 'selectors/user';

import { makeNewSearchRunAction } from './make-new-search-run-action';

export function makeNewMultipleRunsAction(listId) {
  return (dispatch, getState) => {
    const state = getState();
    const oldState = getReactState();
    const suggestions = getExploreTableSelectedCompanies(state, listId);

    const isUserLimited = getUserIsLimited(oldState);
    const userRunsLeft = isUserLimited ? getUserRunsLeft(oldState) : suggestions.length;
    suggestions.slice(0, userRunsLeft).forEach((suggestion) => {
      oldDispatch(makeNewSearchRunAction(suggestion));
    });
    dispatch(clearExploreTableUIState({ listId }));
  };
}
