import React from 'react';

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
export default function KeyPPByCompanyRoleIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46506 7H35.9886C36.2929 7 36.5396 7.24669 36.5396 7.551V26.8455C36.5396 27.1498 36.2929 27.3965 35.9886 27.3965H34.5164V9.43894C34.5164 9.14129 34.2719 8.9 33.9702 8.9H4.91406V7.551C4.91406 7.24669 5.16075 7 5.46506 7Z"
        fill="#8EBCFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.754 14.7328H3.5225V32.6025C3.5225 32.7572 3.64797 32.8827 3.80275 32.8827H32.4737C32.6285 32.8827 32.754 32.7572 32.754 32.6025V14.7328ZM3.16641 9.94002C3.06206 10.0755 3 10.2452 3 10.4295V32.6025C3 33.0458 3.3594 33.4052 3.80275 33.4052H32.4737C32.9171 33.4052 33.2765 33.0458 33.2765 32.6025V10.4295C33.2765 10.2569 33.2221 10.0971 33.1294 9.96619V9.84982H33.0291C32.8849 9.71162 32.6892 9.62671 32.4737 9.62671H3.80275C3.58725 9.62671 3.39159 9.71162 3.24739 9.84982H3.16641V9.94002ZM5.17953 12.9567C5.56778 12.9567 5.88253 12.642 5.88253 12.2537C5.88253 11.8654 5.56778 11.5507 5.17953 11.5507C4.79127 11.5507 4.47653 11.8654 4.47653 12.2537C4.47653 12.642 4.79127 12.9567 5.17953 12.9567ZM8.18228 12.2537C8.18228 12.642 7.86753 12.9567 7.47928 12.9567C7.09102 12.9567 6.77628 12.642 6.77628 12.2537C6.77628 11.8654 7.09102 11.5507 7.47928 11.5507C7.86753 11.5507 8.18228 11.8654 8.18228 12.2537ZM9.778 12.9567C10.1663 12.9567 10.481 12.642 10.481 12.2537C10.481 11.8654 10.1663 11.5507 9.778 11.5507C9.38974 11.5507 9.075 11.8654 9.075 12.2537C9.075 12.642 9.38974 12.9567 9.778 12.9567Z"
        fill="#374EF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7153 19.5875C10.635 19.5875 9.57884 19.9079 8.68053 20.5081C7.78223 21.1084 7.08209 21.9615 6.66864 22.9596C6.2552 23.9578 6.14702 25.0561 6.3578 26.1157C6.56857 27.1754 7.08882 28.1487 7.85276 28.9126C8.61671 29.6766 9.59003 30.1968 10.6497 30.4076C11.7093 30.6184 12.8076 30.5102 13.8057 30.0967C14.8039 29.6833 15.657 28.9832 16.2572 28.0849C16.8575 27.1865 17.1778 26.1304 17.1778 25.05H17.6528C17.6528 26.2244 17.3046 27.3723 16.6522 28.3487C15.9998 29.3252 15.0725 30.0862 13.9875 30.5356C12.9026 30.985 11.7087 31.1026 10.557 30.8735C9.40522 30.6444 8.34726 30.0789 7.51689 29.2485C6.68651 28.4181 6.12102 27.3602 5.89192 26.2084C5.66282 25.0566 5.7804 23.8628 6.2298 22.7779C6.6792 21.6929 7.44022 20.7656 8.41664 20.1132C9.39305 19.4608 10.541 19.1125 11.7153 19.1125V19.5875Z"
        fill="#374EF4"
      />
      <path
        d="M11.7153 20.3C10.7759 20.3 9.85751 20.5786 9.07637 21.1006C8.29524 21.6225 7.68642 22.3644 7.3269 23.2323C6.96739 24.1003 6.87332 25.0553 7.0566 25.9767C7.23988 26.8981 7.69228 27.7445 8.35657 28.4088C9.02087 29.0731 9.86724 29.5255 10.7887 29.7088C11.7101 29.8921 12.6651 29.798 13.5331 29.4385C14.401 29.079 15.1429 28.4701 15.6648 27.689C16.1867 26.9079 16.4653 25.9895 16.4653 25.05H11.7153V20.3Z"
        fill="#FF8811"
      />
      <path
        d="M13.1415 17.6875H29.2915C29.4227 17.6875 29.529 17.7938 29.529 17.925V23.625C29.529 23.7561 29.4227 23.8625 29.2915 23.8625H15.4025H13.1415C13.0103 23.8625 12.904 23.7561 12.904 23.625V17.925C12.904 17.7938 13.0103 17.6875 13.1415 17.6875Z"
        stroke="#374EF4"
        strokeWidth="0.475"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6195 19.095C16.6906 18.8762 17.0001 18.8762 17.0712 19.095L17.3039 19.8112C17.3357 19.909 17.4269 19.9753 17.5298 19.9753H18.2828C18.5129 19.9753 18.6085 20.2697 18.4224 20.4049L17.8132 20.8475C17.73 20.908 17.6951 21.0152 17.7269 21.1131L17.9596 21.8292C18.0307 22.048 17.7803 22.23 17.5941 22.0948L16.9849 21.6521C16.9017 21.5917 16.789 21.5917 16.7057 21.6521L16.0965 22.0948C15.9104 22.23 15.66 22.048 15.7311 21.8292L15.9638 21.1131C15.9956 21.0152 15.9607 20.908 15.8775 20.8475L15.2683 20.4049C15.0821 20.2697 15.1778 19.9753 15.4079 19.9753H16.1609C16.2638 19.9753 16.355 19.909 16.3868 19.8112L16.6195 19.095ZM21.3695 19.095C21.4406 18.8762 21.7501 18.8762 21.8212 19.095L22.0539 19.8112C22.0857 19.909 22.1769 19.9753 22.2798 19.9753H23.0328C23.2629 19.9753 23.3585 20.2697 23.1724 20.4049L22.5632 20.8475C22.48 20.908 22.4451 21.0152 22.4769 21.1131L22.7096 21.8292C22.7807 22.048 22.5303 22.23 22.3441 22.0948L21.7349 21.6521C21.6517 21.5917 21.539 21.5917 21.4557 21.6521L20.8465 22.0948C20.6604 22.23 20.41 22.048 20.4811 21.8292L20.7138 21.1131C20.7456 21.0152 20.7107 20.908 20.6275 20.8475L20.0183 20.4049C19.8321 20.2697 19.9278 19.9753 20.1579 19.9753H20.9109C21.0138 19.9753 21.105 19.909 21.1368 19.8112L21.3695 19.095ZM26.5712 19.095C26.5001 18.8762 26.1906 18.8762 26.1195 19.095L25.8868 19.8112C25.855 19.909 25.7638 19.9753 25.6609 19.9753H24.9079C24.6778 19.9753 24.5821 20.2697 24.7683 20.4049L25.3775 20.8475C25.4607 20.908 25.4956 21.0152 25.4638 21.1131L25.2311 21.8292C25.16 22.048 25.4104 22.23 25.5965 22.0948L26.2057 21.6521C26.289 21.5917 26.4017 21.5917 26.4849 21.6521L27.0941 22.0948C27.2803 22.23 27.5307 22.048 27.4596 21.8292L27.2269 21.1131C27.1951 21.0152 27.23 20.908 27.3132 20.8475L27.9224 20.4049C28.1085 20.2697 28.0129 19.9753 27.7828 19.9753H27.0298C26.9269 19.9753 26.8357 19.909 26.8039 19.8112L26.5712 19.095Z"
        fill="#FF8811"
      />
    </svg>
  );
}
