import { getReactState } from 'actions/get-react-state-action';
import { addAllListSchedules } from 'reducer/schedules-slice';
import { getUserId } from 'selectors/user';

export function getSchedulesAction(parameters = { num: 50, all: false }) {
  return (dispatch, _getState, apiClient) => {
    const userId = getUserId(getReactState());
    apiClient.schedules.fetchAllSchedules(parameters).then((res) => {
      if (res.ok) {
        dispatch(
          addAllListSchedules({ schedules: res.data.schedules, userId, all: parameters.all })
        );
      }
    });
  };
}
