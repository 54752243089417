import { Skeleton, Stack } from '@mui/material';
import React from 'react';

function TableRowLoading() {
  return (
    <Stack direction="row" flex={1} gap="8px">
      <Skeleton key={0} variant="circular" width="8px" height="8px" />
      <Skeleton key={1} variant="circular" width="8px" height="8px" />
      <Skeleton key={2} variant="circular" width="8px" height="8px" />
      <Skeleton key={3} variant="circular" width="8px" height="8px" />
    </Stack>
  );
}

export default TableRowLoading;
