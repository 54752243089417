import MultipleSelectionFilerComponent from 'components/elements/table/filters/columns/components/MultipleSelectionFilerComponent';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

function DiscoveredColumnFilter({ rows, existFilter, onUpdateFilter }) {
  const rowMap = useCallback((value) => value?.text, []);
  return (
    <MultipleSelectionFilerComponent
      columnId="discovered"
      rowMap={rowMap}
      rows={rows}
      existFilter={existFilter}
      onUpdateFilter={onUpdateFilter}
    />
  );
}

DiscoveredColumnFilter.propTypes = {
  rows: PropTypes.array,
  existFilter: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default DiscoveredColumnFilter;
