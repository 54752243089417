import { createSlice } from '@reduxjs/toolkit';

const initialSliceState = {
  currentOutputId: null,
  customAnalytics: []
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialSliceState,
  reducers: {
    setCurrentDevScreenOutputId: (sliceState, { payload }) => {
      const { id } = payload;
      sliceState.currentOutputId = id;
    },
    clearCurrentDevScreenOutputId: (sliceState) => {
      sliceState.currentOutputId = null;
    },
    updateCustomAnalytics: (sliceState, { payload }) => {
      const { records } = payload;
      sliceState.customAnalytics = records;
    }
  }
});

export const { setCurrentDevScreenOutputId, clearCurrentDevScreenOutputId, updateCustomAnalytics } =
  adminSlice.actions;

export default adminSlice.reducer;
