import { loadUserAction } from './load-user-action';

export function updateUserRecieveMonitoringEmailsAction(value) {
  return (_state, dispatch, apiClient) => {
    apiClient.users.update_recieve_monitoring_emails(value).then((res) => {
      if (res.ok) {
        dispatch(loadUserAction());
      }
    });
  };
}
