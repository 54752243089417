import { ACTIONS } from 'constants/actions';

export function updateCurrentSearchRun(runId) {
  return { type: ACTIONS.DELETE_RUN, runId };
}

export function deleteSearchRunAction(runId) {
  return (_state, dispatch, apiClient) => {
    dispatch(updateCurrentSearchRun(runId));
    apiClient.runs.deleteRun(runId);
  };
}
