import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

const DEFAULT_SCATTER_SHAPE_SIZE = 8;

/* eslint-disable id-length */
/* eslint-disable no-mixed-operators */
function ScatterShape({ x, y, payload }) {
  const exapansion = payload.isHover ? 2 : 1;
  const size = exapansion * (payload?.scatterShapeSize || DEFAULT_SCATTER_SHAPE_SIZE);
  const p = { x: x + size / 2, y: y + size / 2 };
  const color = payload.color || payload.fill;
  const circle = <circle cx={p.x} cy={p.y} r={size / 2} fill={color} />;
  return (
    <svg fill={appTheme.palette.graphColors.graph1} onClick={payload.onClick} version="1.1">
      <a href={payload.link ? payload.link : null} target="_blank" rel="noreferrer">
        {circle}
      </a>
    </svg>
  );
}

ScatterShape.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};

export default ScatterShape;
