import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import LinkedinButtonVerbose from 'components/LinkedinButtonVerbose';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React from 'react';
import { getRunCompanyDomainById, getRunCompanyLinkedinUrlById } from 'selectors/runs';
import {
  getSuggestionCompanyDomainByType,
  getSuggestionCompanyLinkedinUrlByType
} from 'selectors/suggestions';
import { simplifyDomain } from 'utils/string-utils';

import LinkedinButton from '../../LinkedinButton';

function LinkStack({ type, id, linkedinUrl, companyWebsite }) {
  const { state } = useAppState();
  const linkedin =
    linkedinUrl ||
    (type === 'run'
      ? getRunCompanyLinkedinUrlById(state, id)
      : getSuggestionCompanyLinkedinUrlByType(state, type));
  const website =
    companyWebsite ||
    (type === 'run'
      ? getRunCompanyDomainById(state, id)
      : getSuggestionCompanyDomainByType(state, type));

  return (
    <Stack
      direction="row"
      alignItems="center"
      alignSelf="flex-start"
      justifyContent="flex-start"
      gap="8px"
      sx={{ height: '20px', width: '100%' }}>
      {linkedin && (
        <Box onClick={(event) => event.stopPropagation()}>
          {type === 'run' ? (
            <LinkedinButton linkedinUrl={linkedin} />
          ) : (
            <LinkedinButtonVerbose linkedinUrl={linkedin} />
          )}
        </Box>
      )}
      {linkedin && website && (
        <Divider orientation="vertical" flexItem sx={{ borderColor: 'greyColors.grey100' }} />
      )}
      {website && (
        <Box onClick={(event) => event.stopPropagation()}>
          <Link href={`https://${website}`} underline="none" target="_blank" rel="noreferrer">
            <Typography variant="paragraphBold" color="primary.primary2">
              {simplifyDomain(website)}
            </Typography>
          </Link>
        </Box>
      )}
    </Stack>
  );
}

LinkStack.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['run', 'new_suggestions', 'existing_suggestions', 'longlist']),
  companyWebsite: PropTypes.string,
  linkedinUrl: PropTypes.string,
  showTags: PropTypes.string
};

LinkStack.defaultProps = {
  showTags: true
};
export default wrapWithError(LinkStack);
