import { loadExplore } from 'actions/explore/load-explore-action';
import { TOAST_TYPES } from 'constants/toasts';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { getExploreTableSelectedCompanies } from 'selectors/explore-table-ui';

export function copyCompaniesToExistListAction(listId, oldListId, addToast, filteredCompanies) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    let companies = getExploreTableSelectedCompanies(state, oldListId);
    if (!companies?.length) {
      const rowsMapPerCompanyListMetaId = getCompanyMetasMergedRowsMapByListId(state, oldListId);
      companies = filteredCompanies.map(
        (comapnyListMetaId) => rowsMapPerCompanyListMetaId[comapnyListMetaId]
      );
    }

    dispatch(clearExploreTableUIState({ listId: oldListId }));
    if (!companies || companies?.length === 0) return;
    const companyListMetasIds = companies.map((company) => company.companyListMetaId);

    apiClient.companiesLists
      .copyCompaniesToList(listId, companyListMetasIds)
      .then((res) => {
        if (res.ok) {
          addToast?.(`Successfully added companies to list`, TOAST_TYPES.SUCCESS);
        } else {
          addToast?.('Failed to add companies to list', TOAST_TYPES.ERROR);
        }
        dispatch(loadExplore(listId));
      })
      .catch((res) => {
        if (res?.status) addToast?.('Failed to add companies to list', TOAST_TYPES.ERROR);
      });
  };
}
