import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Link, Stack } from '@mui/material';
import IconButtonComponent from 'components/IconButtonComponent';
import RenderStr from 'components/elements/table/RenderStr';
import { DomainKnowledge, DomainKnowledgeResource } from 'constants/domain-knowledge';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getListDomainKnowledgeByListId } from 'selectors/explore';
import { limitLength } from 'utils/string-utils';

import AddDomainKnowledgeLinkDialog from './AddDomainKnowledgeLinkDialog';

interface LinkWithAddToDomainKnowledgeProps {
  href?: string;
  listId: string | number;
  context?: string;
  children?: any;
}

function LinkWithAddToDomainKnowledge({
  href,
  listId,
  context,
  children
}: LinkWithAddToDomainKnowledgeProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const domainKnowledge: DomainKnowledge = useSelector((state) =>
    getListDomainKnowledgeByListId(state, listId)
  );
  const linkAlreadyExists =
    domainKnowledge &&
    Object.values(domainKnowledge).find(
      (resource: DomainKnowledgeResource) => resource.data.link === href
    );
  const initialDescription = () => {
    if (context) {
      return `${context} link`;
    }
    if (children && children?.length) {
      return children[0];
    }

    return '';
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      sx={{
        display: 'inline-flex',
        backgroundColor: 'background.bg50',
        border: '1px solid',
        borderColor: 'background.bg150',
        paddingY: '4px',
        paddingX: '8px',
        borderRadius: '16px'
      }}>
      <Box width={'calc(100% - 32px)'}>
        <Link href={href ? href : '#'} underline="none" target="_blank" rel="noreferrer">
          <RenderStr
            variant="paragraph"
            color="graphColors.graph1"
            value={limitLength(children ? children : href, 100)}
          />
        </Link>
      </Box>
      {listId && (
        <Box>
          {linkAlreadyExists ? (
            <IconButtonComponent
              sx={{ width: '20px', height: '20px', color: 'indicator.positive' }}
              tag={`Link already exists in Domain Knowledge as "${linkAlreadyExists.name}"`}>
              <TaskAltIcon />
            </IconButtonComponent>
          ) : (
            <IconButtonComponent
              sx={{ width: '20px', height: '20px' }}
              tag="Add to Domain Knowledge"
              onClick={() => setOpenDialog(true)}>
              <AddLinkIcon />
            </IconButtonComponent>
          )}
        </Box>
      )}
      {listId && (
        <AddDomainKnowledgeLinkDialog
          listId={listId}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          initialLink={href}
          initialDescription={initialDescription()}
        />
      )}
    </Stack>
  );
}

export default LinkWithAddToDomainKnowledge;
