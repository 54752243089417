import { Alert, Snackbar } from '@mui/material';
import { TOAST_TYPES } from 'constants/toasts';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export default function ToastWidget({ toast, onRemove, type, anchorOrigin }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove();
    }, 8000);
    return () => clearTimeout(timer);
  }, [onRemove]);

  return (
    <Snackbar open={true} anchorOrigin={anchorOrigin}>
      <Alert
        severity={type}
        sx={{ whiteSpace: 'pre-line', textAlign: 'left', alignItems: 'center' }}>
        {toast}
      </Alert>
    </Snackbar>
  );
}

ToastWidget.propTypes = {
  toast: PropTypes.string,
  onRemove: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TOAST_TYPES)),
  anchorOrigin: PropTypes.object
};

ToastWidget.defaultProps = {
  type: TOAST_TYPES.SUCCESS,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};
